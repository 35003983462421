import { Classes, Dialog } from '@blueprintjs/core'
import { useSurvey } from '../contexts/SurveyProvider'
import EmailMultiSelect from './EmailMultiSelect'

export default function UserManagementDialog() {
  const { setSurveyDialogOpen, surveyDialogOpen } = useSurvey()

  const onClose = () => setSurveyDialogOpen()

  const isOpen = surveyDialogOpen === 'UserManagementDialog'

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Manage Users"
      icon="people"
    >
      <form onSubmit={e => e.preventDefault()}>
        <div className={Classes.DIALOG_BODY}>
          <EmailMultiSelect />
        </div>
      </form>
    </Dialog>
  )
}
