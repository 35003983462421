import { useContext, useMemo, useState } from 'react'
import { WindowContext } from '../contexts/WindowProvider'
import {
  Button,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  NumericInput,
  Text,
} from '@blueprintjs/core'
import { sum } from '../utils/stats'
import './AugMdDesignerDialog.css'
import { shuffle } from 'lodash'
import { useRef } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { AppToaster } from './toaster'
import { downloadFile } from '../utils/downloadFile'

export default function AugMdDesignerDialog() {
  const { dialogOpen, setDialogOpen, loading, setLoading } =
    useContext(WindowContext)

  const versionsRef = useRef()
  const [downloaded, setDownloaded] = useState(false)
  const [numItems, setNumItems] = useState(23)
  const [numScreens, setNumScreens] = useState(5)
  const [maxPerScreen, setMaxPerScreen] = useState(5)
  const [numScreensWithMax, setNumScreensWithMax] = useState(3)

  const numItemsIfMaxUsedForAll = numScreens * maxPerScreen
  const hasEnoughScreens = numItems <= numItemsIfMaxUsedForAll
  const minItemsPerScreen = 2
  const itemsLeftAfterAllScreensWithMax =
    numItems - numScreensWithMax * maxPerScreen
  const screensLeftAfterAllScreensWithMax = numScreens - numScreensWithMax
  const maxItemsPerEachRemainingScreen = screensLeftAfterAllScreensWithMax
    ? Math.ceil(
        itemsLeftAfterAllScreensWithMax / screensLeftAfterAllScreensWithMax
      )
    : 0
  const [error, setError] = useState(null)
  const design = useMemo(() => {
    setDownloaded(false)
    let items = Array(numItems)
      .fill()
      .map((_, i) => i + 1)

    if (maxItemsPerEachRemainingScreen >= maxPerScreen) {
      setError(
        `Current settings would result in the max number of items per screen being shown for more screens than `
      )
    }
    if (!hasEnoughScreens) {
      setError(`With ${maxPerScreen} items per screen, you need at least ${Math.ceil(
        numItems / maxPerScreen
      )} screens.
      `)
      return
    }
    const items_per_screen = []
    let items_seen = 0
    for (let screen = 0; screen < numScreens; screen++) {
      if (screen < numScreensWithMax) {
        items_per_screen.push(maxPerScreen)
        items_seen += maxPerScreen
      } else {
        const items_left_to_see = numItems - items_seen
        if (items_left_to_see > maxItemsPerEachRemainingScreen) {
          items_per_screen.push(maxItemsPerEachRemainingScreen)
          items_seen += maxItemsPerEachRemainingScreen
        } else {
          if (items_left_to_see < minItemsPerScreen) {
            setError(
              `Current settings would result in ${items_left_to_see} item${
                items_left_to_see === 1 ? '' : 's'
              } on the last screen, but you need at least ${minItemsPerScreen} items on a screen.`
            )
            return
          }
          items_per_screen.push(items_left_to_see)
          items_seen += items_left_to_see
        }
      }
    }
    const numItemsUsed = sum(items_per_screen)
    if (numItemsUsed !== numItems) {
      setError(
        `Current settings would result in ${numItemsUsed} items, but you have ${numItems} items.`
      )
      return
    } else {
      setError(null)
    }
    const screens = []
    for (let s = 0; s < numScreens; s++) {
      const screen = []
      for (let i = 0; i < items_per_screen[s]; i++) {
        items = shuffle(items)
        screen.push(items.shift())
      }
      const blanksToAdd = maxPerScreen - screen.length
      for (let i = 0; i < blanksToAdd; i++) {
        screen.push(null)
      }
      screens.push(screen)
    }
    return screens
  }, [
    numItems,
    maxItemsPerEachRemainingScreen,
    maxPerScreen,
    hasEnoughScreens,
    numScreens,
    numScreensWithMax,
  ])

  const handleClose = () => setDialogOpen(null)

  const api = useApi()
  const handleSubmit = async () => {
    setLoading(true)
    const response = await api.post('/augmddesigner', {
      versions: parseInt(versionsRef.current.value),
      number_of_items: numItems,
      number_of_screens: numScreens,
      max_items_per_screen: maxPerScreen,
      screens_with_max: numScreensWithMax,
    })

    if (response.ok) {
      const data = await response.body.blob()
      const filename = `AugMD_Design ${numItems}items ${numScreens}screens ${versionsRef.current.value} versions`
      downloadFile(data, filename + '.xlsx')
      AppToaster.show({
        message: 'Design Download Started',
        intent: 'success',
        icon: 'tick',
      })
      setDownloaded(true)
    } else {
      const message = Object.values(response.body.messages.json).join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Dialog
      isOpen={dialogOpen === 'AugMdDesigner'}
      onClose={handleClose}
      title="AugMD Designer"
      icon="style"
      style={{ width: '80vw' }}
    >
      <form
        onSubmit={ev => {
          ev.preventDefault()
          handleSubmit()
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div id="preview">
              {error ? (
                <Text
                  style={{
                    color: Colors.RED3,
                    fontWeight: 600,
                    padding: '24px 12px',
                    maxWidth: 350,
                  }}
                >
                  {error}
                </Text>
              ) : design ? (
                <table
                  className={[
                    Classes.HTML_TABLE,
                    Classes.HTML_TABLE_BORDERED,
                  ].join(' ')}
                  id="design-preview"
                >
                  <thead>
                    <tr>
                      <th />
                      {design[0].map((_, i) => (
                        <th key={i}> Item {i + 1}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {design.map((screen, s) => (
                      <tr key={s}>
                        <th>Screen {s + 1}</th>
                        {screen.map((item, i) => (
                          <td key={i}>{item}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                'A valid design could not be generated'
              )}
            </div>
            <div id="parameters">
              <FormGroup label="Number of Items" labelFor="num-items">
                <NumericInput
                  min={10}
                  id="num-items"
                  value={numItems}
                  onValueChange={v => setNumItems(v)}
                />
              </FormGroup>
              <FormGroup
                label="Number of Screens"
                labelFor="num-screens"
                helperText={
                  !hasEnoughScreens
                    ? `With ${maxPerScreen} items per screen, you need at least ${Math.ceil(
                        numItems / maxPerScreen
                      )} screens.`
                    : null
                }
              >
                <NumericInput
                  onValueChange={v => setNumScreens(v)}
                  value={numScreens}
                  id="num-screens"
                />
              </FormGroup>
              <FormGroup label="Max Items Per Screen" labelFor="max-per-screen">
                <NumericInput
                  onValueChange={v => setMaxPerScreen(v)}
                  value={maxPerScreen}
                  min={2}
                  id="max-per-screen"
                />
              </FormGroup>
              <FormGroup
                label="Number of Screens With Max Items"
                labelFor="num-screens-with-max"
                helperText={
                  maxItemsPerEachRemainingScreen >= maxPerScreen ? (
                    <Text style={{ color: Colors.ORANGE3, fontWeight: 500 }}>
                      With the current settings, the max number of items per
                      screen would be shown for more screens than this.
                    </Text>
                  ) : null
                }
              >
                <NumericInput
                  value={numScreensWithMax}
                  onValueChange={v => setNumScreensWithMax(v)}
                  min={2}
                  max={Math.max(2, numScreens)}
                  id="num-screens-with-max"
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup
            label="Number of Versions"
            labelFor="versions"
            style={{ alignItems: 'end' }}
          >
            <NumericInput
              min={1}
              max={20000}
              id="versions"
              style={{ width: 88 }}
              defaultValue={300}
              inputRef={versionsRef}
              onValueChange={() => setDownloaded(false)}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={handleClose}
              minimal
              disabled={loading}
              intent="danger"
            >
              Close
            </Button>
            <Button
              // onClick={handleClose}
              intent="success"
              loading={loading}
              type="submit"
              disabled={downloaded || error}
            >
              {downloaded ? 'Design downloaded' : 'Get Design'}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  )
}
