import { Button, Classes } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { memo } from 'react'
export default function valuesColumn(options) {
  return {
    component: ValuesComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) => (rowData ? JSON.stringify(rowData) : ''),
    pasteValue: ({ value }) => (value ? JSON.parse(value) : null),
  }
}

const ValuesComponent = memo(
  /**
   *
   * @param {CellProps} param0
   * @returns
   */
  ({ focus, columnData, rowData, setRowData }) => {
    return rowData ? (
      <div className="show-values-button-container">
        <Popover2
          content={
            <div className="popover-table-container">
              <table className="popover-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Punches</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(rowData).map(punch => (
                    <tr key={punch}>
                      <th className="values-table-cell">{punch}</th>
                      <td className="values-table-cell">{rowData[punch]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          interactionKind="hover"
        >
          <Button icon="more" small className="show-values-button" />
        </Popover2>
      </div>
    ) : (
      ''
    )
  }
)
