import { Button, Card, Classes, H5, Icon, Intent } from '@blueprintjs/core'
import { TEXT_MUTED } from '@blueprintjs/core/lib/esm/common/classes'
import { Tooltip2 } from '@blueprintjs/popover2'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import { useUser } from '../contexts/UserProvider'
import { useWindow } from '../contexts/WindowProvider'
import AugMdDesignerDialog from './AugMdDesignerDialog'
import CreateAxesDialog from './CreateAxesDialog'
import LoadingSpinner from './LoadingSpinner'
import NewConfirmitSurveyDialog, {
  ConfirmitSurveyStatsDialog,
} from './NewConfirmitSurveyDialog'
import './Surveys.css'
import TimeAgo from './TimeAgo'
import WelcomeDialog from './WelcomeDialog'
import { AppToaster } from './toaster'
import { errorObjectToString } from '../ApiClient'

/**
 * Component to display all survey cards
 * @returns {JSX.Element} JSX Element
 */
export default function Surveys() {
  const {
    surveys,
    settings: { surveysView },
  } = useWindow()

  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const isResearcher = user.role === 'researcher'
  const isAnalystOrResearcher = isAnalyst || isResearcher

  return (
    <div className="surveys-container">
      {surveys === undefined ? (
        <LoadingSpinner />
      ) : surveys === null ? (
        <p>Couldn't retrieve surveys</p>
      ) : (
        <>
          <WelcomeDialog />
          <AugMdDesignerDialog />
          <NewConfirmitSurveyDialog />
          <CreateAxesDialog />
          <ConfirmitSurveyStatsDialog />
          {surveysView === 'table' ? (
            <table
              className={[
                Classes.HTML_TABLE,
                Classes.HTML_TABLE_BORDERED,
                Classes.HTML_TABLE_CONDENSED,
                'sticky-header',
              ].join(' ')}
              style={{ width: '100%', margin: 16 }}
              id="surveys-table"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quick Links</th>
                  <th className="align-right">Respondents</th>
                  <th className="align-right">Variables</th>
                  <th className="align-right">Created time</th>
                  <th>Created by</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {surveys.map(survey => {
                  const showConfirmitButtons =
                    isAnalystOrResearcher &&
                    survey?.confirmit_id !== null &&
                    survey?.confirmit_id !== undefined
                  return (
                    <tr key={survey.id}>
                      <td>
                        <Link to={`/survey/${survey.id}`}>{survey.name}</Link>
                      </td>
                      <td>
                        <Tooltip2 content="Data" position="top">
                          <a href={`/survey/${survey.id}/explorer`}>
                            <Button
                              icon={<Icon icon="numerical" />}
                              minimal
                              small
                            />
                          </a>
                        </Tooltip2>
                        <Tooltip2 content="Variables" position="top">
                          <a href={`/survey/${survey.id}/variables`}>
                            <Button
                              icon={<Icon icon="variable" />}
                              minimal
                              small
                            />
                          </a>
                        </Tooltip2>
                        <Tooltip2 content="Viz" position="top">
                          <a href={`/survey/${survey.id}/viz?tableType=index`}>
                            <Button
                              icon={<Icon icon="panel-table" />}
                              minimal
                              small
                            />
                          </a>
                        </Tooltip2>
                      </td>
                      <td className="align-right">
                        {survey.n?.toLocaleString()}
                      </td>
                      <td className="align-right">
                        {survey.num_variables?.toLocaleString()}
                      </td>
                      <td className="align-right">
                        {<TimeAgo isoDate={survey.created_on} />}
                      </td>
                      <td>{survey.created_by.email.split('@')[0]}</td>
                      <td className="actions-cell">
                        <span>
                          {showConfirmitButtons && (
                            <>
                              <ConfirmitSyncButton survey={survey} />
                              <ConfirmitStatsButton
                                confirmitSurveyId={survey.confirmit_id}
                              />
                            </>
                          )}
                          {isAnalystOrResearcher && (
                            <>
                              <RenameSurveyButton surveyId={survey.id} />
                              <DuplicateSurveyButton surveyId={survey.id} />
                              <DeleteSurveyButton surveyId={survey.id} />
                            </>
                          )}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex-grid" style={{ padding: '32px', gap: '24px' }}>
              {isAnalystOrResearcher && <NewSurveyCard />}
              {surveys.map(survey => (
                <SurveyCard key={survey.id} survey={survey} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

/**
 * Empty card with a plus icon to create a new survey
 * @returns {JSX.Element} JSX Element
 */
function NewSurveyCard() {
  const navigate = useNavigate()
  return (
    <Card
      interactive
      className="new-survey-card"
      onClick={() => navigate('/new')}
    >
      <Tooltip2 content="New Survey" minimal>
        <Link to="/new">
          <Icon size={36} icon="plus" intent={Intent.NONE} />
        </Link>
      </Tooltip2>
    </Card>
  )
}

/**
 * Card to display a survey
 * @param {Object} survey survey object from the API
 * @returns {JSX.Element} JSX Element
 */
function SurveyCard({ survey }) {
  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const isResearcher = user.role === 'researcher'
  const isAnalystOrResearcher = isAnalyst || isResearcher

  const showConfirmitButtons =
    isAnalystOrResearcher &&
    survey?.confirmit_id !== null &&
    survey?.confirmit_id !== undefined

  const navigate = useNavigate()

  return (
    <Card
      key={survey.id}
      interactive
      className="survey-card"
      onClick={() => navigate('/survey/' + survey.id)}
    >
      <H5 className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
        <Link to={'/survey/' + survey.id}>{survey.name}</Link>
      </H5>
      <div className={TEXT_MUTED}>
        {survey.has_data === true ? (
          <ul>
            <li>{survey.n} respondents</li>
            <li>{survey.num_variables} variables</li>
          </ul>
        ) : null}
        <p>
          {survey?.created_on && (
            <>
              created <TimeAgo isoDate={survey.created_on} />
            </>
          )}
          {survey?.created_by?.email ? (
            <span style={{ padding: 0, display: 'block' }}>{`by ${
              survey.created_by.email.split('@')[0]
            }`}</span>
          ) : null}
        </p>
      </div>
      <div className="icon-group">
        {showConfirmitButtons && (
          <>
            <ConfirmitSyncButton survey={survey} />
            <ConfirmitStatsButton confirmitSurveyId={survey.confirmit_id} />
          </>
        )}
        {isAnalystOrResearcher && (
          <>
            <RenameSurveyButton surveyId={survey.id} />
            <DuplicateSurveyButton surveyId={survey.id} />
            <DeleteSurveyButton surveyId={survey.id} />
          </>
        )}
      </div>
    </Card>
  )
}

/**
 * Button to display the Confirmit survey stats dialog
 * @param {number} confirmitSurveyId Confirmit survey ID
 * @returns {JSX.Element} JSX Element
 */

function ConfirmitStatsButton({ confirmitSurveyId }) {
  const { setDialogOpen } = useWindow()
  return (
    <div className="survey-icon">
      <Tooltip2 content="Confirmit stats">
        <Button
          icon="cloud"
          intent="primary"
          small
          minimal
          onClick={ev => {
            ev.stopPropagation()
            setDialogOpen('ConfirmitSurveyStatsDialog - ' + confirmitSurveyId)
          }}
        />
      </Tooltip2>
    </div>
  )
}

/**
 * Button to sync a survey with Confirmit
 * @param {number} surveyId survey ID
 * @returns {JSX.Element} JSX Element
 */
function ConfirmitSyncButton({ survey }) {
  const [isSyncing, setIsSyncing] = useState(false)
  const { setSurveys } = useWindow()
  const api = useApi()

  const sync_confirmit = async () => {
    setIsSyncing(true)
    const response = await api.post('/confirmit/sync', {
      id: survey.id,
    })
    if (response.ok) {
      const {
        n_new_responses: nNewResponses,
        n_updated_responses: nUpdatedResponses,
        survey: newSurvey,
      } = response.body

      setSurveys(prev => {
        return prev.map(s => {
          if (s.id === newSurvey.id) {
            return newSurvey
          } else {
            return s
          }
        })
      })

      let message = 'Survey synced.'
      if (nNewResponses === 1) {
        message += ' 1 new response.'
      } else if (nNewResponses > 1) {
        message += ` ${nNewResponses} new responses.`
      }
      if (nUpdatedResponses === 1) {
        message += ' 1 response updated.'
      } else if (nUpdatedResponses > 1) {
        message += ` ${nUpdatedResponses} responses updated.`
      }

      if (nNewResponses === 0 && nUpdatedResponses === 0) {
        message += ' No new or updated responses.'
      }

      AppToaster.show({
        message: message,
        intent: 'success',
        icon: 'tick',
      })
    } else {
      AppToaster.show({
        message: 'Survey sync failed',
        intent: 'danger',
        icon: 'error',
      })
    }
    setIsSyncing(false)
  }

  const lastSynced = <TimeAgo isoDate={survey.last_synced} />
  const content = (
    <div>
      <p>Sync with Confirmit</p>
      <p>Last synced: {lastSynced}</p>
    </div>
  )

  return (
    <div className="survey-icon">
      <Tooltip2 content={content}>
        <Button
          icon="refresh"
          intent="primary"
          small
          minimal
          loading={isSyncing}
          onClick={ev => {
            ev.stopPropagation()
            sync_confirmit()
          }}
        />
      </Tooltip2>
    </div>
  )
}

/**
 * Button to delete a survey
 * @param {number} surveyId survey ID
 * @returns {JSX.Element} JSX Element
 */
function DeleteSurveyButton({ surveyId }) {
  const api = useApi()
  const { setSurveys, loading, setLoading } = useWindow()

  const handleDeleteSurvey = async (ev, surveyId) => {
    ev.stopPropagation()
    const deleteConfirmed = window.confirm(
      'Are you sure you want to delete this survey? This cannot be undone.'
    )
    if (!deleteConfirmed) return
    setLoading(surveyId)
    const response = await api.delete('/survey/' + surveyId)
    setLoading(false)
    if (response.ok) {
      setSurveys(prev => prev.filter(s => s.id !== surveyId))
      AppToaster.show({
        message: 'Survey successfully deleted',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      if (response.status === 404) {
        alert('Survey not found. It may have already been deleted.')
      } else if (response.status === 403) {
        alert("Not allowed to delete other user's surveys")
      }
    }
  }

  return (
    <div className="survey-icon">
      <Tooltip2 content="Delete survey">
        <Button
          icon="trash"
          intent="danger"
          small
          loading={loading === surveyId}
          minimal
          onClick={ev => handleDeleteSurvey(ev, surveyId)}
        />
      </Tooltip2>
    </div>
  )
}

function DuplicateSurveyButton({ surveyId }) {
  const api = useApi()
  const { setSurveys, loading, setLoading } = useWindow()

  const handleDuplicateSurvey = async (ev, surveyId) => {
    ev.stopPropagation()
    const dupeConfirmed = window.confirm(
      'Are you sure you want to duplicate this survey?'
    )
    if (!dupeConfirmed) return
    setLoading(`${surveyId}-dupe`)
    const response = await api.get('/survey/' + surveyId + '/duplicate')
    setLoading(false)
    if (response.ok) {
      const survey = response.body
      setSurveys(prev => [survey, ...prev])
      AppToaster.show({
        message: 'Survey successfully duplicated.',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }

  return (
    <div className="survey-icon">
      <Tooltip2 content="Duplicate survey">
        <Button
          icon="duplicate"
          small
          loading={loading === `${surveyId}-dupe`}
          minimal
          onClick={ev => handleDuplicateSurvey(ev, surveyId)}
        />
      </Tooltip2>
    </div>
  )
}

function RenameSurveyButton({ surveyId }) {
  const api = useApi()
  const { setSurveys, loading, setLoading } = useWindow()

  const handleRenameSurvey = async (ev, surveyId) => {
    ev.stopPropagation()
    const newName = prompt('New Survey Name')
    if (!newName) return
    setLoading(`${surveyId}-rename`)
    const response = await api.put(`/survey`, {
      id: surveyId,
      name: newName,
    })
    setLoading(false)
    if (response.ok) {
      setSurveys(prev =>
        prev.map(survey => {
          if (survey.id === parseInt(surveyId)) {
            return { ...survey, name: newName }
          }
          return survey
        })
      )
      AppToaster.show({
        message: 'Survey renamed',
        intent: 'success',
        icon: 'tick-circle',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <div className="survey-icon">
      <Tooltip2 content="Rename survey">
        <Button
          icon="edit"
          // intent="danger"
          small
          loading={loading === `${surveyId}-rename`}
          minimal
          onClick={ev => handleRenameSurvey(ev, surveyId)}
        />
      </Tooltip2>
    </div>
  )
}
