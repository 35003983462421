// https://go.smol.place/TimeAgo
import { useState, useEffect } from 'react'

const secondsTable = [
  ['year', 60 * 60 * 24 * 365],
  ['month', 60 * 60 * 24 * 30],
  ['week', 60 * 60 * 24 * 7],
  ['day', 60 * 60 * 24],
  ['hour', 60 * 60],
  ['minute', 60],
]
const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })

function getTimeAgo(date) {
  const seconds = Math.round((date.getTime() - new Date().getTime()) / 1000)
  const absSeconds = Math.abs(seconds)
  let bestUnit, bestTime, bestInterval
  for (let [unit, unitSeconds] of secondsTable) {
    if (absSeconds >= unitSeconds) {
      bestUnit = unit
      bestTime = Math.round(seconds / unitSeconds)
      bestInterval = unitSeconds / 2
      break
    }
  }
  if (!bestUnit) {
    bestUnit = 'second'
    bestTime = parseInt(seconds / 10) * 10
    bestInterval = 10
  }
  return [bestTime, bestUnit, bestInterval]
}

function getDuration(start, end = new Date()) {
  const seconds = Math.round((end.getTime() - start.getTime()) / 1000)
  const absSeconds = Math.abs(seconds)
  let bestUnit, bestTime
  for (let [unit, unitSeconds] of secondsTable) {
    if (absSeconds >= unitSeconds) {
      bestUnit = unit
      bestTime = Math.round(seconds / unitSeconds)
      break
    }
  }
  if (!bestUnit) {
    bestUnit = 'second'
    bestTime = absSeconds
  }
  return [bestTime, bestUnit]
}

export default function TimeAgo({ isoDate }) {
  const date = new Date(Date.parse(isoDate))
  const [time, unit, interval] = getTimeAgo(date)
  const [, setUpdate] = useState(0)

  useEffect(() => {
    const timerId = setInterval(
      () => setUpdate(update => update + 1),
      interval * 1000
    )
    return () => clearInterval(timerId)
  }, [interval])

  return isoDate ? (
    <span title={date.toString()}>{rtf.format(time, unit)}</span>
  ) : (
    <span />
  )
}

export function Duration({ isoDateStart = new Date(), isoDateEnd, seconds }) {
  const start = new Date(Date.parse(isoDateStart))
  let end
  if (!isoDateEnd) {
    // if only seconds are passed in
    end = new Date()
    end.setSeconds(end.getSeconds() + seconds)
  } else {
    end = new Date(Date.parse(isoDateEnd))
  }
  const [time, unit] = getDuration(start, end)

  return time && unit ? (
    <span>{`${time} ${unit}${time !== 1 ? 's' : ''}`}</span>
  ) : (
    <span />
  )
}
