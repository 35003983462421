import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import {
  Button,
  ButtonGroup,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Navbar,
  NavbarGroup,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core'
import { groupBy } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useApi } from '../../contexts/ApiProvider'
import { CrosstabContext } from '../../contexts/CrosstabProvider'
import { DataContext } from '../../contexts/DataProvider'
import './crosstab-ui.css'
import DraggableVariable from './DraggableVariable'
import VariableArea from './VariableArea'
import 'simplebar/dist/simplebar.min.css'
import CrosstabTable from './CrosstabTable'
import { Popover2 } from '@blueprintjs/popover2'
import { WindowContext } from '../../contexts/WindowProvider'

// const variables = [{ name: 'Variable A' }, { name: 'Variable B' }]
const variableAreas = ['holding-area', 'top-variables', 'left-variables']

function groupVariablesByLocation(variableStates) {
  const variablesByLocation = groupBy(
    variableStates.filter(location => location !== 'holding-area'),
    'location'
  )
  const columns = variablesByLocation['top-variables']
    ? variablesByLocation['top-variables'].map(({ name }) => name)
    : []
  const rows = variablesByLocation['left-variables']
    ? variablesByLocation['left-variables'].map(({ name }) => name)
    : []

  const filters = Object.fromEntries(
    variableStates.flatMap(variable =>
      variable.filterOutPunches.length
        ? [[variable.name, variable.filterOutPunches]]
        : []
    )
  )
  return { rows, columns, filters }
}
export default function CrosstabUI() {
  const { selectedColumns: variables } = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const {
    options: { viz, aggregation, aggregationColumn },
    setOptions,
  } = useContext(CrosstabContext)
  const {
    settings: { statTesting },
  } = useContext(WindowContext)
  const [variableStates, setVariableStates] = useState(
    variables.map(variable => ({
      name: variable,
      location: 'holding-area',
      filterOutPunches: [],
    }))
  )

  const handleTogglePunch = useCallback((variableName, punch) => {
    return setVariableStates(prev => {
      const variableIndex = prev.findIndex(({ name }) => name === variableName)
      const variableState = prev[variableIndex]
      let { filterOutPunches } = variableState
      const filterOut = !filterOutPunches.includes(punch)
      if (filterOut) {
        filterOutPunches = [...filterOutPunches, punch]
      } else {
        filterOutPunches = filterOutPunches.filter(name => name !== punch)
      }
      return [
        ...prev.slice(0, variableIndex),
        { ...variableState, filterOutPunches },
        ...prev.slice(variableIndex + 1),
      ]
    })
  }, [])
  const api = useApi()
  const [data, setData] = useState()

  const changeVariableLocation = useCallback((variableName, newLocation) => {
    setVariableStates(prev => {
      console.log('changeVariableLocation', { prev, variableName, newLocation })
      const variable = prev.find(({ name }) => name === variableName)
      const moveNeeded = variable.location !== newLocation
      if (!moveNeeded) {
        return prev
      }
      console.log(`${variableName} to ${newLocation}`)
      return [
        ...prev.filter(({ name }) => name !== variableName),
        { ...variable, location: newLocation },
      ]
    })
  }, [])

  useEffect(
    function selectedVariablesChangeEffect() {
      setVariableStates(prev => {
        const prevMinusUnselectedVariables = prev.filter(({ name }) =>
          variables.includes(name)
        )
        const newlySelectedVariables = variables
          .filter(
            selectedVariableName =>
              !prev.find(({ name }) => name === selectedVariableName)
          )
          .map(name => ({
            name,
            location: 'holding-area',
            filterOutPunches: [],
          }))
        let newVariableState = [
          ...prevMinusUnselectedVariables,
          ...newlySelectedVariables,
        ]
        if (newVariableState.length) {
          // place newly selected variables in left and top areas if they're empty
          let { rows, columns } = groupVariablesByLocation(newVariableState)
          if (rows.length === 0) {
            newVariableState = [
              { ...newVariableState[0], location: 'left-variables' },
              ...newVariableState.slice(1),
            ]
          }
          console.log({ rows, columns, newVariableState })
          if (columns.length === 0 && newVariableState.length > 1) {
            newVariableState = [
              newVariableState[0],
              { ...newVariableState[1], location: 'top-variables' },
              ...newVariableState.slice(2),
            ]
          }
        }
        return newVariableState
      })
    },
    [variables]
  )

  let aggrForApi = aggregation.split('-')[0]
  useEffect(() => {
    ;(async function variableLocationsChangeEffect() {
      const { rows, columns, filters } =
        groupVariablesByLocation(variableStates)
      if (aggrForApi === 'mean' && aggregationColumn === '') {
        setData(undefined)
        return
      }
      if (rows.length + columns.length === 0) {
        setData(undefined)
        return
      }
      setLoading(true)
      const response = await api.post('/crosstab2', {
        columns,
        rows,
        filters,
        viz,
        aggregation: aggrForApi,
        aggregationColumn,
        statTesting,
      })
      console.log({ response })
      setData(response.ok ? response.body : null)
      setLoading(false)
    })()
  }, [api, variableStates, viz, aggrForApi, aggregationColumn, statTesting])

  return (
    <div id="crosstab-ui-container">
      <Navbar
        className="slim-navbar"
        style={{
          width: 'calc(100vw - 270px)',
        }}
      >
        {/* <NavbarGroup>
          <Switch labelElement="Stat Test" alignIndicator="right" />
        </NavbarGroup> */}
        <NavbarGroup align="right">
          <Popover2
            minimal
            placement="bottom"
            content={
              <Menu>
                <MenuItem
                  icon="small-tick"
                  text="Total Sample"
                  labelElement={<Icon icon="inherited-group" />}
                />
                <MenuItem
                  icon="small-tick"
                  text="Heat Map"
                  labelElement={<Icon icon="heat-grid" />}
                />
                <MenuItem
                  shouldDismissPopover={false}
                  icon={statTesting ? 'small-tick' : 'blank'}
                  text="Stat Testing"
                  labelElement={<Icon icon="calculator" />}
                  onClick={() =>
                    setOptions(prev => ({
                      ...prev,
                      statTesting: !prev.statTesting,
                    }))
                  }
                />
              </Menu>
            }
          >
            <Button
              style={{ marginRight: 6 }}
              minimal
              rightIcon="caret-down"
              icon="settings"
              text="View"
            />
          </Popover2>
          <ButtonGroup minimal>
            <Button
              icon="heat-grid"
              active={viz === 'table'}
              // onClick={() => setOptions(prev => ({ ...prev, viz: 'table' }))}
            />
            <Button
              icon="timeline-bar-chart"
              active={viz === 'chart'}
              // onClick={() => setOptions(prev => ({ ...prev, viz: 'chart' }))}
            />
          </ButtonGroup>
        </NavbarGroup>
      </Navbar>

      <DndProvider backend={HTML5Backend}>
        <div id="crosstab-container">
          <div id="aggregation-area">
            <div className={[Classes.HTML_SELECT].join(' ')}>
              <select
                id="aggregation-select"
                value={aggregation}
                onChange={ev =>
                  setOptions(prev => ({
                    ...prev,
                    aggregation: ev.target.value,
                  }))
                }
              >
                <optgroup label="Counts">
                  <option value="counts">Count</option>
                  <option value="counts-normalized">%</option>
                  <option value="counts-combo">% and Count</option>
                </optgroup>
                <option value="mean">Average</option>
              </select>
              <Icon icon="function" />
            </div>
            {aggregation === 'mean' ? (
              <div className={[Classes.HTML_SELECT].join(' ')}>
                <select
                  id="aggregation-column"
                  value={aggregationColumn}
                  onChange={ev =>
                    setOptions(prev => ({
                      ...prev,
                      aggregationColumn: ev.target.value,
                    }))
                  }
                >
                  <option value="">Column to average</option>
                  {variableStates
                    .filter(({ location }) => location === 'holding-area')
                    .map(variable => (
                      <option value={variable.name} key={variable.name}>
                        {variable.name}
                      </option>
                    ))}
                </select>
                <Icon icon="double-caret-vertical" />
              </div>
            ) : null}
          </div>
          {variableAreas.map(variableArea => (
            <VariableArea
              id={variableArea}
              areaName={variableArea}
              changeVariableLocation={changeVariableLocation}
              key={variableArea}
            >
              {variableStates
                .filter(({ location }) => location === variableArea)
                .map(variable => (
                  <DraggableVariable
                    filterOutPunches={variable.filterOutPunches}
                    handleTogglePunch={handleTogglePunch}
                    name={variable.name}
                    key={variable.name}
                  >
                    {variable.name}
                  </DraggableVariable>
                ))}
            </VariableArea>
          ))}
          <div id="chart-container">
            {loading ? (
              <Spinner size={SpinnerSize.LARGE} />
            ) : data ? (
              <CrosstabTable data={data} />
            ) : (
              'Select some variables'
            )}
          </div>
        </div>
      </DndProvider>
    </div>
  )
}
