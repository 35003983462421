import { renderToStaticMarkup } from 'react-dom/server'

function SVGfromPath(path, color, fill, viewBox, width, height) {
  // remove the path:// prefix
  const pathNoURL = path.replace('path://', '')
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox}
      preserveAspectRatio="none"
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      {fill ? (
        <path d={pathNoURL} fill={color} />
      ) : (
        <path d={pathNoURL} stroke={color} fill="transparent" strokeWidth={3} />
      )}
    </svg>
  )
}
export function debounceClick({
  clickNumber,
  clickCallback,
  dblClickCallback,
}) {
  clickNumber.current += 1
  setTimeout(() => {
    if (clickNumber.current === 1) {
      clickCallback()
    } else if (clickNumber.current === 2) {
      dblClickCallback()
    }
    clickNumber.current = 0
  }, 400)
}

export default class Tree {
  constructor() {
    this.nodeMap = new Map()
    this.addNode({ id: '0', collapsed: false, children: [] })
    this.rootNode = this.getNode('0')
  }

  static getNode(tree, id) {
    if (Array.isArray(id)) {
      id = id.join('|')
    }
    return tree.nodeMap.get(id)
  }

  getNode(id) {
    return Tree.getNode(this, id)
  }

  addNode(node) {
    const id = node.id

    if (id === '0') {
      node.claimId = 0
      node.parentId = null
    } else {
      node.claimId = parseInt(id.slice(id.lastIndexOf('|') + 1))
      node.parentId = id.slice(0, id.lastIndexOf('|'))
    }

    if (node.parentId === null) {
      this.rootNode = node
      this.nodeMap.set(id, node)
      return node
    }

    const parent = this.getNode(node.parentId)
    Object.defineProperty(node, 'parent', {
      get: function () {
        return parent
      },
    })

    // define a hasChildren method on the node
    Object.defineProperty(node, 'hasChildren', {
      get: function () {
        return this.children && this.children.length > 0
      },
    })

    Object.defineProperty(node, 'depth', {
      get: function () {
        return this.parentId.split('|').length
      },
    })

    if (parent) {
      if (parent.children === undefined) {
        parent.children = []
      }
      parent.children.push(node)
      this.nodeMap.set(id, node)
      return node
    } else {
      throw new Error(
        `Parent node ${node.parentId} not found. Remember to add the nodes in order.`
      )
    }
  }

  static filterTree(node, conditionFn) {
    const filteredChildren = node.children
      .map(childNode => Tree.filterTree(childNode, conditionFn))
      .filter(childNode => childNode != null)
    if (conditionFn(node) || filteredChildren.length > 0) {
      return { ...node, children: filteredChildren }
    } else {
      return null
    }
  }

  filterTree(conditionFn) {
    return Tree.filterTree(this.rootNode, conditionFn)
  }

  static getSelectedLeaves(node, accumulator = []) {
    if (node.selectedLeaf) {
      accumulator.push(node)
    }
    if (node.children) {
      node.children.forEach(child => {
        child.selected && Tree.getSelectedLeaves(child, accumulator)
      })
    }
  }

  getSelectedLeaves(node, accumulator = []) {
    return Tree.getSelectedLeaves(node, accumulator)
  }

  static traverseNodeAndChildren(node, callback, accumulator = []) {
    const result = callback(node)
    if (result !== undefined) {
      accumulator.push(result)
    }
    Tree.traverseChildren(node, callback, accumulator)
    return accumulator
  }

  traverseNodeAndChildren(node, callback, accumulator = []) {
    return Tree.traverseNodeAndChildren(node, callback, accumulator)
  }

  static traverseChildren(node, callback, accumulator = []) {
    if (node.children) {
      node.children.forEach(child => {
        Tree.traverseNodeAndChildren(child, callback, accumulator)
      })
    }
  }

  traverseChildren(node, callback, accumulator = []) {
    return Tree.traverseChildren(node, callback, accumulator)
  }

  getLeaves(condition) {
    const leaves = []
    Tree.traverseChildren(this.rootNode, node => {
      if (!node.hasChildren) {
        if (condition(node)) {
          leaves.push(node)
        }
      }
    })
    return leaves
  }

  static traverseAncestors(tree, node, callback, accumulator = []) {
    if (node.parentId === null) {
      // we hit the root node
      return
    }
    const parent = Tree.getNode(tree, node.parentId)
    if (parent) {
      const result = callback(parent)
      if (result !== undefined) {
        accumulator.push(result)
      }
      Tree.traverseAncestors(tree, parent, callback, accumulator)
    }
  }

  traverseAncestors(node, callback, accumulator = []) {
    return Tree.traverseAncestors(this, node, callback, accumulator)
  }

  static getVisibleLeaves(node, accumulator = []) {
    node.children.forEach(n => {
      if (n.hidden) {
        return
      }
      if (!n.hasChildren) {
        accumulator.push(n)
        return
      }
      if (n.collapsed) {
        accumulator.push(n)
        return
      }
      Tree.getVisibleLeaves(n, accumulator)
    })
  }

  getVisibleLeaves(node, accumulator = []) {
    return Tree.getVisibleLeaves(node, accumulator)
  }

  static getAncestors(tree, node) {
    const ancestors = []
    Tree.traverseAncestors(tree, node, node => node, ancestors)
    return ancestors
  }

  getAncestors(node) {
    return Tree.getAncestors(this, node)
  }

  static collapseNodeAndChildren(node, excludeSelected = false) {
    Tree.traverseNodeAndChildren(node, n => {
      if (excludeSelected && n.selected) return
      n.collapsed = true
    })
  }

  collapseNodeAndChildren(node, excludeSelected = false) {
    Tree.collapseNodeAndChildren(node, excludeSelected)
  }

  static expandNodeAndChildren(node, excludeSelected = false) {
    Tree.traverseNodeAndChildren(node, n => {
      if (excludeSelected && n.selected) return
      n.collapsed = false
    })
  }
}

export class DecisionTree {
  // base options for the chart
  #chartRef
  #option = {
    dataZoom: [
      {
        type: 'slider',
        show: true,
        yAxisIndex: [0],
        start: 0,
        end: 100,
        filterMode: 'none',
      },
      {
        type: 'inside',
        show: true,
        yAxisIndex: [0],
        start: 0,
        end: 100,
        filterMode: 'none',
        zoomOnMouseWheel: 'shift',
        moveOnMouseWheel: true,
      },
    ],

    grid: {
      top: '75%',
      bottom: '1%',
    },
    xAxis: [
      {
        type: 'category',
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100,
        name: 'Total Reach',
        nameRotate: 90,
        nameLocation: 'middle',
        nameGap: 50,
      },
    ],

    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          title: 'Save as image',
          type: 'png',
          name: `decision-tree`,
        },
        restore: {
          title: 'Restore',
        },
      },
    },
  }

  static valuesDigitFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  })

  static percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 1,
  })

  constructor({
    pallette,
    symbols,
    fetchNode,
    mostRespondents,
    sortByReach = true,
    clickNumber,
    exportLeaves,
    useLabels = true,
  }) {
    this.variablesMap = new Map()
    this.tree = new Tree()
    this.fetchNode = fetchNode
    this.sortByReach = sortByReach
    this.mostRespondents = mostRespondents
    this.clickNumber = clickNumber
    this.initPermutations(symbols, pallette)
    this.initRootNode()
    this.barSeries = []
    this.visibleLeaves = []
    this.exportLeaves = exportLeaves
    this.useLabels = useLabels
  }

  get chartRef() {
    if (this.#chartRef === undefined) {
      throw new Error('chartRef is undefined')
    }
    return this.#chartRef
  }
  set chartRef(chartRef) {
    this.#chartRef = chartRef
  }

  initPermutations(symbols, pallette) {
    const fillStates = [true, false]
    this.permutations = []
    for (let i = 0; i < symbols.length; i++) {
      for (let k = 0; k < fillStates.length; k++) {
        for (let j = 0; j < pallette.length; j++) {
          this.permutations.push({
            symbol: 'path://' + symbols[i].path,
            symbolViewBox: symbols[i].viewBox,
            color: pallette[j].hex(),
            fill: fillStates[k],
          })
        }
      }
    }
  }

  /**
   * @description Assigns the colour + shape + filling to each claim,
   * based on a first run of the fetchNode function, so that the first
   * nodes use the more common shapes and colours
   */
  async initSymbols() {
    this.showLoading()
    const { nodeChildren } = await this.fetchNode([])

    const sortedNodeChildren = this.sortByReach
      ? nodeChildren.sort((a, b) => b.deduplicated_reach - a.deduplicated_reach)
      : nodeChildren.sort((a, b) => b.respondents - a.respondents)

    if (sortedNodeChildren.length > this.permutations.length) {
      console.warn(
        `There are more variables than permutations available. Some variables will have the same colour and shape.`
      )
    }

    sortedNodeChildren.forEach((variable, index) => {
      this.variablesMap.set(variable.id, {
        ...this.permutations[index % this.permutations.length],
        claimName:
          this.useLabels && variable.label ? variable.label : variable.name,
      })
    })
    this.addChildren(this.tree.rootNode, nodeChildren)
    this.tree.rootNode.collapsed = false
    this.updateTree()
    this.assignPositionsToNodes()
    this.hideLoading()
  }

  addNode(node) {
    let newNode = { ...node, ...this.variablesMap.get(node.claimId) }
    newNode.itemStyle = {
      color: newNode.fill ? newNode.color : 'transparent',
      borderColor: newNode.fill ? 'transparent' : newNode.color,
      borderWidth: 3,
    }
    newNode.label = {
      color: newNode.fill ? 'white' : 'black',
    }
    newNode.dataIndex = newNode.id
    newNode.name = newNode.id
    this.tree.addNode(newNode)
  }

  initRootNode() {
    const rootNode = this.tree.rootNode
    rootNode.value = 0
    rootNode.dedupReach = 0
    rootNode.cumReach = 0
    rootNode.cumSum = 0
    rootNode.emphasis = false
    rootNode.hidden = false
    rootNode.claimName = 'No items'
    rootNode.claimId = 0
    Object.defineProperty(rootNode, 'hasChildren', {
      get: () => rootNode.children && rootNode.children.length > 0,
    })
    rootNode.symbol = this.permutations[0].symbol
    rootNode.symbolViewBox = this.permutations[0].symbolViewBox
    rootNode.color = 'black'
    rootNode.fill = true
    rootNode.collapsed = false
  }

  /**
   * @description Callback to provide when the chart is ready
   */
  async onChartReady() {
    if (this.tree.rootNode.hasChildren) return
    await this.initSymbols()
  }

  /**
   * @description Builds the tooltip for a node
   * @param {*} node
   * @returns
   */
  buildTooltip(node) {
    const ancestorNodes = this.tree.getAncestors(node).reverse()
    const trStyle =
      ancestorNodes.length > 0
        ? { borderTop: '1px dashed black', verticalAlign: 'middle' }
        : { verticalAlign: 'middle' }

    const showButton =
      node.children && node.children.length > 0 && !node.collapsed

    const someHidden = node.children.some(child => child.hidden)

    const toggleHidden = () => {
      const children = node.children
      const someHidden = children.some(child => child.hidden)
      if (someHidden) {
        children.forEach(child => {
          child.hidden = false
        })
      } else {
        children.forEach((child, index) => {
          child.hidden = !child.selected && index > 9
        })
      }
      this.updateTree()
      this.assignPositionsToNodes()

      // get the button of id tooltip-button
      const button = document.getElementById('tooltip-button')
      button.innerHTML = someHidden ? '-' : '+'
    }

    const reachSum = node.cumReach

    const res = (
      <table style={{ borderCollapse: 'collapse' }}>
        <tr>
          <th></th>
          <th></th>
          <th>Item</th>
          <th>N° Resp.</th>
          <th>Cum. N°</th>
          <th>Reach Contrib.</th>
          <th>Total reach</th>
        </tr>
        {ancestorNodes.map((node, index) => {
          const reachSum = node.cumReach

          return (
            <tr style={{ verticalAlign: 'middle' }}>
              <td align="right">{index}</td>
              <td align="right">
                {SVGfromPath(
                  node.symbol,
                  node.color,
                  node.fill,
                  node.symbolViewBox,
                  '14px',
                  '14px'
                )}
              </td>
              <td style={{ padding: '0 15px' }}>{node.claimName}</td>
              <td align="center">
                {DecisionTree.valuesDigitFormatter.format(node.value)}
              </td>
              <td align="center">
                {DecisionTree.valuesDigitFormatter.format(node.cumSum)}
              </td>
              <td align="center">
                +{DecisionTree.percentFormatter.format(node.dedupReach)}
              </td>
              <td align="center">
                {DecisionTree.percentFormatter.format(reachSum)}
              </td>
            </tr>
          )
        })}
        <tr style={trStyle}>
          <td align="right">{ancestorNodes.length}</td>
          <td align="center" style={{ verticalAlign: 'middle' }}>
            {showButton && (
              <button id="tooltip-button">{someHidden ? '+' : '-'}</button>
            )}{' '}
            {SVGfromPath(
              node.symbol,
              node.color,
              node.fill,
              node.symbolViewBox,
              '14px',
              '14px'
            )}
          </td>
          <td style={{ padding: '0 15px' }}>{node.claimName}</td>
          <td align="center">
            {DecisionTree.valuesDigitFormatter.format(node.value)}
          </td>
          <td align="center">
            {/* {DecisionTree.percentFormatter.format(
              node.value / this.mostRespondents
            )} */}
            {DecisionTree.valuesDigitFormatter.format(node.cumSum)}
          </td>
          <td align="center">
            +{DecisionTree.percentFormatter.format(node.dedupReach)}
          </td>
          <td align="center">
            {DecisionTree.percentFormatter.format(reachSum)}
          </td>
        </tr>
      </table>
    )
    const resStr = renderToStaticMarkup(res)
    const htmlElement = document.createElement('div')
    htmlElement.innerHTML = resStr
    if (showButton) {
      //get to the button
      const button = htmlElement.querySelector('button')
      button.onclick = () => {
        toggleHidden()
      }
    }
    return htmlElement
  }

  /**
   * @description Returns the size of the symbol for a node
   * @param {*} params
   * @returns {number}
   */
  symbolSize(node) {
    if (!node) return 0
    // If node is emphasized, return 50 minus the zoom level
    if (node.emphasis || node.id === '0') {
      const zoom = this.chartRef.current?.getEchartsInstance().getOption()
        .series[0].zoom
      return 50 - (zoom ?? 0)
    }

    // get width of the container
    const width = this.chartRef.current
      ? this.chartRef.current.getEchartsInstance().getWidth()
      : 0

    // get the width of the tree from the lefmost node to the rightmost node
    const treeWidth = this.width ?? 2.8

    // Get the value of the node
    const value = this.sortByReach ? node.dedupReach : node.value

    // Now compute the size of the symbol

    // To compute the size of the symbol, we take into account:
    // - the width of the container
    // - the width of the tree
    // - the value of the node

    const size = 10 + Math.sqrt((width * value) / treeWidth)

    return size
  }

  /**
   * @description Returns the tree data for the chart
   * @returns {object} the tree data for the chart
   */
  getTreeData() {
    return this.tree.filterTree(node => !node.hidden)
  }

  /**
   * @description Returns the tree series for the chart
   * @returns {object} the tree series for the chart
   */
  getTreeSeries() {
    return {
      tooltip: {
        show: true,
        position: 'top',
        extraCssText: 'max-height: 300px; overflow: auto',
        showDelay: 200,
      },
      animationDurationUpdate: 200,
      id: '0',
      name: '0',
      type: 'tree',
      expandAndCollapse: false,
      collapsed: false,
      data: [this.getTreeData()],
      orient: 'TB',
      top: '10%',
      bottom: '35%',
      nodeGap: 60,
      initialTreeDepth: 2,
      roam: true,
      symbolSize: (value, params) => {
        if (!params) return 0
        const node = this.tree.getNode(params.data.id)
        return this.symbolSize(node)
      },
      emphasis: {
        focus: 'relative',
        label: {
          formatter: params => {
            const node = this.tree.getNode(params.data.id)
            if (node === undefined) return ''
            if (!node.emphasis) return ''
            if (this.sortByReach) {
              return DecisionTree.percentFormatter.format(node.cumReach)
            }
            return DecisionTree.valuesDigitFormatter.format(node.cumSum)
          },
          show: true,
        },
      },
      label: {
        show: false,
        formatter: params => {
          return params.data.id === '0'
            ? this.sortByReach
              ? DecisionTree.percentFormatter.format(params.data.dedupReach)
              : 'n=' +
                DecisionTree.valuesDigitFormatter.format(params.data.value)
            : ''
        },
      },
    }
  }

  /**
   * @description Returns the tooltip for the chart
   * @returns {object} the tooltip for the chart
   *
   */
  getTooltip() {
    return {
      trigger: 'item',
      confine: true,
      enterable: true,
      formatter: params => {
        const node = this.tree.getNode(params.data.id)
        return this.buildTooltip(node)
      },
      show: true,
    }
  }

  getEmptyBarSeries() {
    return {
      name: 'Column Chart',
      type: 'bar',
      id: '1',
    }
  }

  getOption() {
    if (!this.#chartRef) return {}
    const n = {
      tooltip: this.getTooltip(),
      series: [
        this.getTreeSeries(),
        this.getEmptyBarSeries(),
        ...this.barSeries,
      ],
    }
    const option = { ...this.#option, ...n }
    return option
  }

  /**
   * @description Given a node, fetches the children and adds them to the tree
   * @param {*} node
   */
  async fillNode(node) {
    // If it's the root node, don't fetch again

    this.showLoading()

    const ancestors = this.tree.getAncestors(node)
    ancestors.splice(0, 0, node)
    const claimsReached = ancestors
      .map(ancestor => ancestor.claimId)
      .slice(0, ancestors.length - 1)

    const { nodeChildren } = await this.fetchNode(claimsReached)
    this.addChildren(node, nodeChildren)
    node.collapsed = false
    this.updateTree()
    this.hideLoading()
  }

  /**
   *
   * @description formats and adds the children to the node
   * @param {*} node the node to add the children to
   * @param {*} nodeChildren the children to add
   */
  addChildren(node, nodeChildren) {
    const sortedNodeChildren = this.sortByReach
      ? nodeChildren.sort((a, b) => b.deduplicated_reach - a.deduplicated_reach)
      : nodeChildren.sort((a, b) => b.respondents - a.respondents)

    sortedNodeChildren.forEach((child, index) => {
      const newNode = {
        id: node.id + '|' + child.id,
        claimId: child.id,
        value: child.respondents - node.cumSum,
        dedupReach: child.deduplicated_reach,
        cumReach: node.cumReach + child.deduplicated_reach,
        collapsed: true,
        hidden: index > 9,
        children: [],
        emphasis: false,
      }
      newNode.cumSum = newNode.value + node.cumSum
      this.addNode(newNode)
    })
  }

  /**
   * @description Shows the loading animation
   */
  showLoading() {
    this.chartRef?.current?.getEchartsInstance()?.showLoading()
  }

  /**
   * @description Hides the loading animation
   */
  hideLoading() {
    this.chartRef?.current?.getEchartsInstance()?.hideLoading()
  }

  /**
   * @description Gets all the visible leaves in the tree
   */
  getVisibleLeaves() {
    const leaves = []
    this.tree.getVisibleLeaves(this.tree.rootNode, leaves)
    this.visibleLeaves = leaves
    this.updateTree()
  }

  /**
   * @description Updates the tree with the new data
   */
  updateTree() {
    this.chartRef.current.getEchartsInstance().setOption({
      series: {
        type: 'tree',
        data: [this.getTreeData()],
      },
    })
  }

  /**
   * @description Updates the bars, optionally sorting them
   * @param {*} sort
   */
  updateBars(sort = true) {
    const [startValue, endValue] = this.getZoomBarValues()
    sort && this.sortBars()
    // TODO: see if there's another way to do this
    this.forceUpdate()
    this.zoomBar(startValue, endValue)
  }

  /**
   * @description Force updates the chart's option, without merging
   */
  forceUpdate() {
    this.chartRef.current
      .getEchartsInstance()
      .setOption(this.getOption(), { notMerge: true })
  }

  /**
   * @description Emphasizes all the nodes in the array
   * @param {Object[]} nodes
   */
  emphasizeNodes(nodes) {
    nodes.forEach(node => {
      node.emphasis = true
    })
    this.updateTree()
  }

  /**
   * @description Deemphasizes all the nodes in the array
   * @param {Object[]} nodes
   */
  deEmphasizeNodes(nodes) {
    nodes.forEach(node => {
      node.emphasis = false
    })
    this.updateTree()
  }

  /**
   * @description Highlights a particular node
   * @param {Object} node
   * @param {boolean} silent whether this action should trigger other events
   */
  highlightNode(node, silent = false) {
    if (node.id === '0') return

    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'highlight',
      seriesType: 'tree',
      name: node.name,
      silent: silent,
    })
  }

  /**
   * @description Downplays a particular node
   * @param {Object} node
   * @param {boolean} silent weather this action should trigger other events
   */
  downplayNodes(nodes, silent = true) {
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'downplay',
      batch: nodes.map(node => ({
        seriesId: '0',
        dataIndex: node.dataIndex,
      })),
      silent: silent,
    })
  }

  /**
   * @description Highlights a list of bars
   * @param {number[]} barIds
   */
  highlightBars(barIds) {
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'highlight',
      seriesId: barIds,
    })
  }

  /**
   * @description Downplays a list of bars
   * @param {number[]} barIds
   */
  downplayBars(barIds) {
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'downplay',
      seriesId: barIds,
    })
  }

  /**
   * @description Highlights the bar stack of a particular node
   * @param {Object} node
   */
  highlightBarStack(node) {
    const barSeries = []
    const allOtherSeries = []
    this.barSeries.forEach(s => {
      if (s.id.startsWith(node.id + '-')) {
        barSeries.push(s)
      } else {
        allOtherSeries.push(s)
      }
    })

    if (barSeries.length) {
      this.highlightBars(barSeries.map(s => s.id))
    }
    if (allOtherSeries.length) {
      this.downplayBars(allOtherSeries.map(s => s.id))
    }
  }

  /**
   * @description Downplays the bar stack of a particular node
   * @param {Object} node
   */
  downplayBar(node) {
    // TODO: refactor this. Don't use chartRef
    const series = this.chartRef.current.getEchartsInstance().getOption().series
    const barSeries = series.filter(
      s => s.type === 'bar' && s.id.startsWith(node.id + '-')
    )
    // TODO: refactor this. Don't use chartRef
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'downplay',
      seriesId: barSeries.map(s => s.id),
    })
  }

  mouseover(params) {
    if (params.componentSubType === 'tree') {
      const node = this.tree.getNode(params.data.id)
      this.emphasizeNodes([node])
      this.onFinished(() => {
        this.highlightBarStack(node)
        this.highlightNode(node)
      })
    }
    if (params.componentSubType === 'bar') {
      const node = this.tree.getNode(params.name)
      this.emphasizeNodes([node])
      this.onFinished(() => this.highlightNodePath(params))
    }
  }

  mouseout(params) {
    if (params.componentSubType === 'tree') {
      const node = this.tree.getNode(params.data.id)
      this.deEmphasizeNodes([node])
      this.onFinished(() => {
        this.downplayBar(node)
        this.downplayNodes([node])
      })
    }
    if (params.componentSubType === 'bar') {
      const node = this.tree.getNode(params.name)
      this.deEmphasizeNodes([node])
      this.onFinished(() => this.downplayNodePath(params))
    }
  }

  onFinished(callback) {
    this.chartRef.current.getEchartsInstance().off('finished')
    this.chartRef.current.getEchartsInstance().on('finished', callback)
  }
  onRendered(callback) {
    this.chartRef.current.getEchartsInstance().off('rendered')
    this.chartRef.current.getEchartsInstance().on('rendered', callback)
  }

  click(params) {
    if (params.seriesType === 'bar') return
    const node = this.tree.getNode(params.data.id)
    const clickCallback = async () => await this.nodeClick(node)
    const dblClickCallback = () => this.togglePathSelection(node)

    return debounceClick({
      clickNumber: this.clickNumber,
      clickCallback,
      dblClickCallback,
    })
  }

  async nodeClick(node) {
    if (node.hasChildren) {
      // is it expanded?
      if (node.collapsed === false) {
        // recursively collapse all children
        this.tree.collapseNodeAndChildren(node)
      }
      // if it's collapsed, expand it
      else {
        node.collapsed = false
      }
      this.updateTree()
      this.assignPositionsToNodes()
      return
    }
    await this.fillNode(node)
    this.updateTree()
    this.assignPositionsToNodes()
  }

  getOnEvents() {
    return {
      mouseover: params => this.mouseover(params),
      mouseout: params => this.mouseout(params),
      click: params => this.click(params),
    }
  }

  highlightNodePath(params) {
    // find the last bar of the stack
    const node = this.tree.getNode(params.name)
    this.highlightBars(params.seriesId)

    const otherBars = this.barSeries.filter(
      series => series.id !== params.seriesId
    )

    this.downplayBars(otherBars.map(bar => bar.id))
    this.highlightNode(node, true)
    this.displayNodeTooltip(node)
  }

  downplayNodePath(params) {
    // get all series from the second onwards
    const node = this.tree.getNode(params.name)
    this.deEmphasizeNodes([node])
    this.downplayNodes([node])
  }

  displayNodeTooltip(node) {
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      name: node.name,
    })
  }

  togglePathSelection(node) {
    if (node.selected) {
      this.deselectNode(node)
    } else {
      this.selectNode(node)
    }
  }

  getSelectedLeaves() {
    const leaves = []

    this.tree.getSelectedLeaves(this.tree.rootNode, leaves)
    // store the claimId and the claimIds of all the ancestors of the leaf in an array of arrays
    const leavesWithAncestors = leaves.map(leaf => {
      const ancestors = []
      this.tree.traverseAncestors(leaf, ancestor => {
        ancestor.claimId !== 0 && ancestors.push(ancestor.claimId)
      })
      return [leaf.claimId, ...ancestors]
    })
    this.exportLeaves.current = leavesWithAncestors
    return leaves
  }

  selectNode(node) {
    node.selected = true // selected means it's part of the path
    node.selectedLeaf = true // selectedLeaf means it's the last node in the path
    node.lineStyle = {
      color: 'red',
      width: 3,
    }
    this.tree.traverseAncestors(node, ancestor => {
      ancestor.selected = true
      ancestor.lineStyle = {
        color: 'red',
        width: 3,
      }
    })

    this.updateTree()

    this.createBar(node)
    this.getSelectedLeaves()
  }

  deselectNode(node) {
    node.selected = false
    node.selectedLeaf = false
    node.lineStyle = {}
    this.removeBar(node)

    // Deselect all ancestors if none of their children are selected
    this.tree.traverseAncestors(node, ancestor => {
      if (ancestor.children.some(child => child.selected === true)) {
        return
      }
      ancestor.selected = false
      ancestor.lineStyle = {}
      this.removeBar(ancestor)
    })

    // If there are any selected children, deselect them
    this.tree.traverseChildren(node, child => {
      if (!child.selected) return
      child.selected = false
      child.lineStyle = {}
      this.removeBar(child)
    })

    this.updateTree()
    this.getSelectedLeaves()
  }

  createBar(node, withDecals = true) {
    const variables = this.tree.getAncestors(node)
    // Remove the root node
    // Insert at the beginning
    variables.unshift(node)

    // Remove all series that have the same stack id
    this.barSeries = this.barSeries.filter(
      series => series.stack !== `Stack ${node.id}`
    )

    // Add the new series
    variables.reverse().forEach((n, index) => {
      let color, backgroundColor
      if (!withDecals) {
        color = n.color
        backgroundColor = n.color
      } else {
        if (n.fill) {
          color = n.color
          backgroundColor = 'white'
        } else {
          color = 'white'
          backgroundColor = n.color
        }
      }

      const decal = withDecals
        ? {
            decal: {
              symbol: n.symbol,
              symbolSize: 1,
              color: color,
              backgroundColor: backgroundColor,
              dashArrayX: 10,
              dashArrayY: 10,
            },
          }
        : {}

      const newSeries = {
        name: `${node.id}-${index}`,
        id: `${node.id}-${index}`,
        stack: `Stack ${node.id}`,
        type: 'bar',
        tooltip: {
          show: false,
        },
        data: [
          {
            value: n.dedupReach * 100,
            name: n.name,
            itemStyle: {
              ...n.itemStyle,
              ...decal,
              borderWidth: 2,
              borderColor: n.color,
            },
          },
        ],

        emphasis: {
          focus: 'series',
        },
      }

      this.barSeries.push(newSeries)
    })

    // Add the sum of all bars to the last bar's label
    // const sum = variables.reduce((acc, n) => acc + n.dedupReach, 0)
    const lastBar = this.barSeries[this.barSeries.length - 1]
    lastBar.data[0].label = {
      show: true,
      position: 'top',
      formatter: params => {
        return (
          DecisionTree.percentFormatter.format(node.cumReach) +
          ' (' +
          DecisionTree.valuesDigitFormatter.format(node.cumSum) +
          ')'
        )
      },
    }

    this.updateBars()
  }

  sortBars() {
    // Sort the bars by the sum of its values
    const seriesFromTheSameStack = this.barSeries.reduce((acc, s) => {
      if (!acc[s.stack]) {
        acc[s.stack] = []
      }
      acc[s.stack].push(s)
      return acc
    }, {})

    // Get the sum of the values of each stack
    const stackValues = Object.keys(seriesFromTheSameStack).map(stack => {
      const series = seriesFromTheSameStack[stack]
      const sum = series.reduce((acc, s) => {
        return acc + s.data[0].value
      }, 0)
      return { stack, sum }
    })
    // Modify the series order to match the stack order
    stackValues.sort((a, b) => b.sum - a.sum)

    // Apply the new order to the series
    const newSeries = []
    stackValues.forEach(stackValue => {
      const series = seriesFromTheSameStack[stackValue.stack]
      series.forEach(s => newSeries.push(s))
    })
    this.barSeries = newSeries
  }

  removeBar(node) {
    this.barSeries = this.barSeries.filter(
      bar => bar.stack !== `Stack ${node.id}`
    )
    this.updateBars()
  }

  getZoomBarValues() {
    const zoomBar = this.chartRef.current.getEchartsInstance().getOption()
      .dataZoom[0]
    return [zoomBar.startValue, zoomBar.endValue]
  }

  zoomBar(startValue, endValue) {
    this.chartRef.current.getEchartsInstance().dispatchAction({
      type: 'dataZoom',
      startValue: startValue,
      endValue: endValue,
    })
  }
  assignPositionsToNodes() {
    const width = {
      leftMostNode: this.tree.rootNode,
      rightMostNode: this.tree.rootNode,
      shallowestNode: this.tree.rootNode,
      deepestNode: this.tree.rootNode,
    }
    assignPositionsToNodes(this.tree.rootNode, 0, 0, width)
    this.height = width.deepestNode.y + 1
  }
}

function assignPositionsToNodes(node, x, y, width) {
  node.x = x
  node.y = y

  if (x < width.leftMostNode.x) width.leftMostNode = node
  if (x > width.rightMostNode.x) width.rightMostNode = node
  if (y < width.shallowestNode.y) width.shallowestNode = node

  if (node.collapsed) {
    return
  }
  if (!node.children) {
    return
  }

  const visibleChildren = node.children.filter(c => !c.hidden)
  const childrenCount = visibleChildren.length
  if (childrenCount === 0) return

  visibleChildren.forEach((child, index) => {
    const newY = y + 1
    // const newX = x + (2 * index - childrenCount + 1) / childrenCount
    const newX = x + index

    assignPositionsToNodes(child, newX, newY, width)
  })
}
