import { useUser } from '../contexts/UserProvider'
// import { useEffect, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { Icon, OverlayToaster, Toast } from '@blueprintjs/core'
import { errorObjectToString } from '../ApiClient'
import { AppToaster } from './toaster'
import { useSurvey } from '../contexts/SurveyProvider'

export default function NotificationWrapper({ children }) {
  const { setUser, user } = useUser()
  const { surveyDialogOpen } = useSurvey()
  const api = useApi()

  function getNotificationProps(n) {
    const {
      progress,
      survey_id,
      name,
      variables,
      succeeded,
      progress_message,
    } = n.payload
    let status = ''
    switch (progress) {
      case 100: {
        if (succeeded) {
          status = 'has completed'
        } else {
          status = 'has failed'
        }
        break
      }
      case 1: {
        status = 'has started'
        break
      }
      case undefined: {
        break
      }
      default: {
        status = `is ${progress}% complete`
        if (progress_message) status += ` (${progress_message})`
        break
      }
    }
    const showLink = succeeded === true
    const href = `/survey/${survey_id}/explorer?vids=` + variables.join(',')
    const action = showLink
      ? {
          // onClick: () => {
          //   console.log('clicked')
          // },
          href,
          icon: <Icon icon="document-open" />,
        }
      : null
    const message = <span>{`Task ${name} ${status}`} </span>
    const intent =
      progress === 100 ? (succeeded === true ? 'success' : 'danger') : 'primary'
    const onDismiss = async didTimeoutExpire => {
      if (!didTimeoutExpire) {
        const response = await api.delete(`/notification/${n.id}`)
        if (!response.ok) {
          const message = errorObjectToString(response.body.messages.json)
          AppToaster.show({ message, intent: 'danger', icon: 'error' })
        }
      }
      setUser(prev => ({
        ...prev,
        notifications: prev.notifications.filter(({ id }) => n.id !== id),
      }))
    }
    return { message, intent, onDismiss, action, timeout: 0 }
  }

  // useEffect(() => {
  //   let intervalId = null
  //   intervalId = setInterval(async () => {
  //     const response = await api.get('/notifications?since=' + since)
  //     if (response.ok) {
  //       if (response.body.length === 0) return
  //       const newNotifications = response.body
  //       const prevTaskIds = notifications.map(n => n.payload?.task_id)
  //       setNotifications(prev => [
  //         // remove older notifications for the same task
  //         ...newNotifications,
  //         ...prev.filter(
  //           n =>
  //             n.payload?.taskId !== undefined &&
  //             !prevTaskIds.includes(n.payload?.task_id)
  //         ),
  //       ])
  //       setSince(newNotifications[newNotifications.length - 1].timestamp)
  //     }
  //   }, 10000)

  //   return () => clearInterval(intervalId)
  // }, [api, notifications, since])
  return (
    <>
      <OverlayToaster position="top-right">
        {user.notifications
          .filter(
            n =>
              !(
                n.payload.name === 'code_open_ends' &&
                surveyDialogOpen === 'CreateVariableDialog-Code Open Ends'
              )
          )
          .map(n => {
            return <Toast key={n.id} {...getNotificationProps(n)} />
          })}
      </OverlayToaster>
      {children}
    </>
  )
}
