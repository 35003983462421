import { MenuItem, Menu as BpMenu, Icon, Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import Columns from '../components/columns/Columns'
import DataTable from '../components/DataTable'
import LoadingSpinner from '../components/LoadingSpinner'
import Menu from '../components/Menu'
import ToolPage from '../components/ToolPage'
import { WindowContext } from '../contexts/WindowProvider'
import SidebarLayout from '../layouts/SidebarLayout'
import { useDocumentTitle } from '../utils/hooks'
import { SurveyContext } from '../contexts/SurveyProvider'

export default function Explorer() {
  const { currentSurvey } = useContext(WindowContext)
  const { setSurveyDialogOpen } = useContext(SurveyContext)
  const pageName = `${currentSurvey ? 'Data' : 'Loading - Data'}`
  useDocumentTitle(pageName)
  return (
    <ToolPage
      menu={
        <Menu pageName={pageName}>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text="Segmentation Explorer"
                  icon="inherited-group"
                  onClick={() =>
                    setSurveyDialogOpen('SegmentationExplorerDialog')
                  }
                />
              </BpMenu>
            }
          >
            <Button text="Window" />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text={
                    <Link to="/help/data" target="_blank">
                      Data Help Video
                      <Icon className="new-page-icon" icon="share" size={12} />
                    </Link>
                  }
                />
              </BpMenu>
            }
          >
            <Button text={'Help'} />
          </Popover2>
        </Menu>
      }
      tool={
        currentSurvey ? (
          <SidebarLayout sidebar={<Columns />} content={<DataTable />} />
        ) : (
          <LoadingSpinner />
        )
      }
    />
  )
}
