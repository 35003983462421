import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import {
  Classes,
  Colors,
  FormGroup,
  Icon,
  MenuItem,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core'
import { MultiSelect2 } from '@blueprintjs/select'
import { useContext, useEffect, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import highlightText from '../utils/highlightText'
import CrosstabTable from './crosstab/CrosstabTable'
import { useSearchParams } from 'react-router-dom'
import { WindowContext } from '../contexts/WindowProvider'
import { VizContext } from '../contexts/VizProvider'
import variableOrQuestionToString from '../utils/variableOrQuestionToString'
import { SurveyContext } from '../contexts/SurveyProvider'
import { AppToaster } from './toaster'
import LoadingSpinner from './LoadingSpinner'
import IndexTable from './crosstab/IndexTable'
import Chart from './crosstab/Chart'

const filterQuestion = (query, question, _index, exactMatch) => {
  const normalizedName = question.name.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedQuery === normalizedName
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0
  }
}

export const filterQuestions = (query, items, filter = items => items) => {
  const normalizedQueries = (
    query.trim()[query.trim().length - 1] === ','
      ? query.trim().slice(0, -1)
      : query
  )
    .trim()
    .toLowerCase()
    .split(',')
    .map(q => q.trim())
  if (!normalizedQueries.length) {
    return []
  }
  let filteredQuestions = items
    .filter(filter)
    .filter(
      q =>
        normalizedQueries.some(
          normalizedQuery => q.name.toLowerCase().indexOf(normalizedQuery) >= 0
        ) ||
        normalizedQueries.some(
          normalizedQuery =>
            q?.label?.toLowerCase()?.indexOf(normalizedQuery) >= 0
        )
    )
  const lastRecentQuestionIndex = filteredQuestions.findLastIndex(
    q => q.isRecent === true
  )
  const firstRecentQuestionIndex = filteredQuestions.findIndex(
    q => q.isRecent === true
  )
  filteredQuestions = filteredQuestions.map((q, i) => ({
    ...q,
    isFirstRecentQuestion: i === firstRecentQuestionIndex,
    isLastRecentQuestion: i === lastRecentQuestionIndex,
  }))
  return filteredQuestions
}

export default function VizUI() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const api = useApi()
  const [searchParams] = useSearchParams()
  const tableType = searchParams.get('tableType')
  const {
    settings: { statTesting, percentages, chart },
  } = useContext(WindowContext)
  const {
    banner,
    stub,
    setBanner,
    setStub,
    filter,
    setFilter,
    setTableN,
    questions,
  } = useContext(VizContext)
  const { weights, variables } = useContext(SurveyContext)

  // Don't allow numeric questions in cross tabs
  useEffect(() => {
    if (tableType === 'cross') {
      setStub(prev => prev.filter(q => q.type !== 'number'))
      setBanner(prev => prev.filter(q => q.type !== 'number'))
    }
  }, [tableType, setStub, setBanner])

  useEffect(
    function resetTableN() {
      if (setTableN) {
        setTableN()
      }
      return () => setTableN()
    },
    [setTableN]
  )

  useEffect(() => {
    ;(async function variableChangeEffect() {
      setTableN()
      if (!stub || !banner | !stub.length || !banner.length) {
        setData(undefined)
        return
      }
      setLoading(true)
      let url
      let body = {
        banner: banner.map(b => b.id),
        stub: stub.map(s => s.id),
        filter: filter.map(f => f.id),
        table_type: tableType,
      }
      if (tableType === 'index') {
        url = '/indextab'
      } else {
        url = '/crosstab3'
        body = {
          ...body,
          stat_testing: statTesting,
          filter: filter.map(f => f.id),
          percentages,
        }
      }
      const response = await api.post(url, body)
      if (response.ok) {
        const data = response.body
        setData(data)
        setTableN(data.n)
      } else {
        setData(null)
        setTableN(undefined)
        const schemaMessages = response.body.messages
        const message = schemaMessages
          ? Object.values(schemaMessages.json).join(', ')
          : `Error creating table (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    })()
  }, [
    banner,
    stub,
    api,
    statTesting,
    percentages,
    weights,
    filter,
    setTableN,
    tableType,
  ])

  const handleQuestionSelected = (question, setQuestions) =>
    setQuestions(prev =>
      isQuestionSelected(question, prev)
        ? prev.filter(q => q.id !== question.id)
        : [...prev, question]
    )

  const handleRemoveQuestion = (question, setQuestions) => {
    setQuestions(prev => prev.filter(q => q.id !== question.id))
  }

  const isQuestionSelected = (question, questionsSelected) => {
    return questionsSelected && questionsSelected.indexOf(question) !== -1
  }

  // const renderTag = question => question.name
  const renderTag = item => variableOrQuestionToString(item, undefined)

  const questionsEqual = (a, b) => a.id === b.id

  const bannerRenderer = (
    question,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = variableOrQuestionToString(question)
    return (
      <>
        {question?.isFirstRecentQuestion ? (
          <li key="header" className={Classes.MENU_HEADER}>
            <h6 className={`${Classes.HEADING}`}>Recent Questions</h6>
          </li>
        ) : null}
        <MenuItem
          selected={modifiers.active}
          icon={isQuestionSelected(question, banner) ? 'tick' : 'blank'}
          shouldDismissPopover={true}
          disabled={modifiers.disabled}
          label={question.type}
          key={question.id}
          onClick={handleClick}
          onFocus={handleFocus}
          text={highlightText(text, query)}
          className={
            question?.isLastRecentQuestion ? 'last-recent-question' : null
          }
        />
      </>
    )
  }
  const filterRenderer = (
    variable,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = variableOrQuestionToString(variable)
    return (
      <MenuItem
        selected={modifiers.active}
        icon={isQuestionSelected(variable, filter) ? 'tick' : 'blank'}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        label={variable.variable_type}
        key={variable.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(text, query)}
      />
    )
  }

  return (
    <>
      {questions === undefined ? (
        <LoadingSpinner intent="success" footer />
      ) : questions === null ? (
        <p>Could not retrieve questions</p>
      ) : questions.length === 0 ? (
        <p>There are no questions.</p>
      ) : (
        <div style={{ padding: '40px 60px' }}>
          <div id="viz-form" style={{ display: 'flex', columnGap: '32px' }}>
            <div
              id="stub-banner-form"
              style={{ width: '70%', padding: '8px 0px' }}
            >
              <FormGroup
                label={
                  <span>
                    <Icon
                      icon="people"
                      size={14}
                      className={Classes.TEXT_MUTED}
                      style={{ marginRight: 3 }}
                    />
                    Banner
                  </span>
                }
                labelFor="banner"
                style={{ paddingLeft: '24px' }}
              >
                <MultiSelect2
                  id="banner"
                  selectedItems={banner ?? []}
                  items={questions.filter(q => q.type !== 'number')}
                  itemsEqual={questionsEqual}
                  onItemSelect={banner =>
                    handleQuestionSelected(banner, setBanner)
                  }
                  onRemove={banner => handleRemoveQuestion(banner, setBanner)}
                  tagRenderer={renderTag}
                  // itemPredicate={filterQuestion}
                  itemListPredicate={filterQuestions}
                  itemRenderer={bannerRenderer}
                  noResults={<MenuItem disabled={true} text="No results." />}
                  popoverProps={{
                    minimal: true,
                    popoverClassName: 'scrollable-select-popover',
                  }}
                  placeholder="Create a banner"
                  resetOnSelect={true}
                />
              </FormGroup>
            </div>
            <div
              id="filter-form"
              style={{
                width: '30%',
                backgroundColor: Colors.LIGHT_GRAY4,
                padding: '8px 12px',
                border: `1px solid rgba(17,20,24,.2)`,
                borderRadius: 4,
              }}
            >
              <FormGroup
                label={
                  <span>
                    <Icon
                      icon="filter"
                      size={14}
                      className={Classes.TEXT_MUTED}
                      style={{ marginRight: 3 }}
                    />
                    Filter
                  </span>
                }
                labelFor="filter"
              >
                <MultiSelect2
                  id="filter"
                  selectedItems={filter ?? []}
                  items={variables}
                  itemsEqual={questionsEqual}
                  onItemSelect={filter =>
                    handleQuestionSelected(filter, setFilter)
                  }
                  onRemove={filter => handleRemoveQuestion(filter, setFilter)}
                  onClear={() => setFilter([])}
                  tagRenderer={renderTag}
                  itemPredicate={filterQuestion}
                  itemRenderer={filterRenderer}
                  noResults={<MenuItem disabled={true} text="No results." />}
                  popoverProps={{
                    minimal: true,
                    popoverClassName: 'scrollable-select-popover',
                  }}
                  placeholder="Create a filter"
                  resetOnSelect={true}
                  tagInputProps={{ tagProps: { minimal: true, round: true } }}
                />
              </FormGroup>
            </div>
          </div>
          <div id="chart-container">
            {loading ? (
              <Spinner size={SpinnerSize.STANDARD} />
            ) : data &&
              ((data?.stubs && tableType === 'index') ||
                (!data?.stubs && tableType === 'cross')) ? (
              // <div id="viz-container" style={{ height, width }}>
              <div id="viz-container">
                {chart ? (
                  <Chart data={data} />
                ) : tableType === 'cross' ? (
                  <CrosstabTable
                    data={data}
                    setBanner={setBanner}
                    setStub={setStub}
                  />
                ) : (
                  <IndexTable data={data} />
                )}
              </div>
            ) : (
              'Select a stub and a banner'
            )}
          </div>
        </div>
      )}
    </>
  )
}
