import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi } from './ApiProvider'
import { useSurvey } from './SurveyProvider'
import LoadingSpinner from '../components/LoadingSpinner'

export const MaxDiffContext = createContext()

/*
 * This component is used to check if the maxdiff ID in the URL is valid.
 * If it is, it renders the MaxDiffProvider component, which fetches the
 * MaxDiff data from the API and stores it in the context.
 * If it is not, it redirects to the survey page.
 */
export function ValidatedMaxDiffProvider(props) {
  const navigate = useNavigate()
  const { maxDiffs } = useSurvey()

  const { maxDiffId, surveyId } = useParams()

  const surveyFinishedLoadingMaxdiffs = maxDiffs !== undefined
  const requestedMaxDiff = maxDiffs?.find(md => md.id.toString() === maxDiffId)

  useEffect(() => {
    if (surveyFinishedLoadingMaxdiffs && requestedMaxDiff === undefined) {
      console.error(
        'Invalid maxDiffId ' +
          maxDiffId +
          ' provided. Redirecting to survey page.'
      )
      navigate('/survey/' + surveyId, { replace: true })
    }
  }, [
    requestedMaxDiff,
    navigate,
    surveyId,
    maxDiffId,
    surveyFinishedLoadingMaxdiffs,
  ])

  if (requestedMaxDiff !== undefined) {
    return <MaxDiffProvider {...props} />
  }
  return <LoadingSpinner />
}

export default function MaxDiffProvider({ children }) {
  const { maxDiffId } = useParams()
  const { weights } = useSurvey()
  const [maxDiffName, setMaxDiffName] = useState()
  const [maxdiffQuestion, setMaxdiffQuestion] = useState(null)
  const [subgroupQuestions, setSubgroupQuestions] = useState()
  const [selectedGroupVariables, setSelectedGroupVariables] = useState([])
  const [lowSampleWarningThreshold, setLowSampleWarningThreshold] = useState(0)
  const [availableTools, setAvailableTools] = useState([])
  const [enabledTools, setEnabledTools] = useState()
  // This state is used by the PreferenceProfiles component to inform about
  // preference profiles with low samples
  // null: no claim has been selected yet, so ignore sample size
  // []: no preference profiles with low samples
  const [
    preferenceProfilesWithLowSamples,
    setPreferenceProfilesWithLowSamples,
  ] = useState(null)

  // This state is used to toggle between SHOWING the weighted or unweighted
  // amount of respondents in the subgroup. This does not affect the calculations,
  // it's just for display purposes.
  const [displayWeighted, setDisplayWeighted] = useState(true)

  // This stores the weighted amount of respondents in the subgroup
  const [totalRespondentsInSubgroup, setTotalRespondentsInSubgroup] =
    useState(0)

  // This one stores the unwighted amount of respondents in the subgroup
  const [
    totalUnweightedRespondentsInSubgroup,
    setTotalUnweightedRespondentsInSubgroup,
  ] = useState(0)

  // This stores the weighted total respondents in the survey
  const [totalRespondentsInSurvey, setTotalRespondentsInSurvey] = useState(0)
  const [loading, setLoading] = useState(false)

  // This stores the export sets of the maxdiff
  const [exportSets, setExportSets] = useState([])

  const api = useApi()

  // Get the settings from the MaxDiff
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await api.get(`/maxdiffs/${maxDiffId}`)
      if (response.ok) {
        setExportSets(response.body.export_sets)
        setMaxdiffQuestion(response.body.question)
        setSubgroupQuestions(response.body.subgroup_questions)
        setMaxDiffName(response.body.name)
        setLowSampleWarningThreshold(response.body.low_sample_warning_threshold)
        setEnabledTools(response.body.enabled_tools)
        setAvailableTools(response.body.available_tools)
      } else {
        console.error(response)
      }
      setLoading(false)
    })()
  }, [maxDiffId, api, weights])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      let response

      const noSubgoupSelectedYet =
        selectedGroupVariables === undefined ||
        selectedGroupVariables.length === 0 ||
        selectedGroupVariables.some(v => v === undefined)

      // Fetch the total weighted respondents in the subgroup
      if (noSubgoupSelectedYet) {
        response = await api.post(`/maxdiffs/${maxDiffId}/total_respondents`)
      } else {
        response = await api.post(`/maxdiffs/${maxDiffId}/total_respondents`, {
          subgroup_variables_ids: selectedGroupVariables,
        })
      }

      if (response.ok) {
        setTotalRespondentsInSubgroup(response.body.total_respondents)
      } else {
        console.error(response)
      }

      // Fetch the total unweighted respondents in the subgroup
      if (noSubgoupSelectedYet) {
        response = await api.post(`/maxdiffs/${maxDiffId}/total_respondents`, {
          weight: false,
        })
      } else {
        response = await api.post(`/maxdiffs/${maxDiffId}/total_respondents`, {
          subgroup_variables_ids: selectedGroupVariables,
          weight: false,
        })
      }
      if (response.ok) {
        setTotalUnweightedRespondentsInSubgroup(response.body.total_respondents)
      } else {
        console.error(response)
      }

      // Fetch the total weighted respondents in the survey
      response = await api.post(`/maxdiffs/${maxDiffId}/total_respondents`)
      if (response.ok) {
        setTotalRespondentsInSurvey(response.body.total_respondents)
      } else {
        console.error(response)
      }

      setLoading(false)
    })()
  }, [maxDiffId, selectedGroupVariables, api, weights])

  // Remove export set
  const removeExportSet = useCallback(
    async id_to_delete => {
      const response = await api.delete(
        `/maxdiffs/${maxDiffId}/export_set/${id_to_delete}`
      )
      if (!response.ok) {
        console.error(response.error)
        return false
      }
      setExportSets(prev => prev.filter(tag => tag.id != id_to_delete))
      return true
    },
    [api, maxDiffId, exportSets, setExportSets]
  )

  // Create export set
  const createExportSet = useCallback(
    async (name, selectedClaimsIds, consideredClaimsIds) => {
      const response = await api.post(`/maxdiffs/${maxDiffId}/export_set`, {
        name: name,
        selected_variables_ids: selectedClaimsIds,
        considered_variables_ids: consideredClaimsIds.filter(
          id => !selectedClaimsIds.includes(id)
        ),
        subgroup_variables_ids: selectedGroupVariables,
      })
      if (!response.ok) {
        console.error(response.error)
        return
      }
      setExportSets(prev => [...prev, response.body])
      return response.body
    },
    [api, maxDiffId, selectedGroupVariables, setExportSets]
  )

  return (
    <MaxDiffContext.Provider
      value={{
        maxDiffId,
        maxDiffName,
        subgroupQuestions,
        maxdiffQuestion,
        loading,
        setLoading,
        selectedGroupVariables,
        setSelectedGroupVariables,
        totalRespondentsInSubgroup,
        totalRespondentsInSurvey,
        totalUnweightedRespondentsInSubgroup,
        displayWeighted,
        setDisplayWeighted,
        lowSampleWarningThreshold,
        preferenceProfilesWithLowSamples,
        setPreferenceProfilesWithLowSamples,
        enabledTools,
        availableTools,
        exportSets,
        removeExportSet,
        createExportSet,
      }}
    >
      {children}
    </MaxDiffContext.Provider>
  )
}

export function useMaxDiff() {
  const context = useContext(MaxDiffContext)
  if (context === undefined) {
    throw new Error('useMaxDiff must be used within a MaxDiffProvider')
  }
  return context
}
