// import ReactEChart from 'echarts-for-react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { useEffect } from 'react'
import { HeatmapChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  VisualMapContinuousComponent,
  DataZoomComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { useRef } from 'react'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'

import { CanvasRenderer } from 'echarts/renderers'

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const normalLabelStyle = {
  color: 'black',
  fontSize: 12,
  fontWeight: 'normal',
}

const highlightLabelStyle = {
  color: 'black',
  fontSize: 12,
  fontWeight: 'bold',
}

echarts.use([
  HeatmapChart,
  VisualMapContinuousComponent,
  GridComponent,
  ToolboxComponent,
  CanvasRenderer,
  DataZoomComponent,
])

export default function JointPreferencesGraph({
  banners,
  stubs,
  data,
  valueColorScale,
  percentageColorScale,
  showPercentages = false,
  useLabels = true,
  totalRespondentsInSubgroup,
}) {
  const chartRef = useRef(null)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { maxDiffName } = useMaxDiff()

  // Big mode is when there are two many items to display (either banners or stubs)
  // In big mode, the labels are not displayed
  // Each axis has its own big mode

  const maxItemsToDisplayWithLabels = 15

  const bannersInBigMode = banners.length > maxItemsToDisplayWithLabels
  const stubsInBigMode = stubs.length > maxItemsToDisplayWithLabels
  const oneInBigMode = bannersInBigMode || stubsInBigMode

  // Total respondents in subgroup is used to calculate the percentages
  const globalMax = Math.round(totalRespondentsInSubgroup)

  const valueColors = valueColorScale.domain([0, globalMax])
  const percentageColors = percentageColorScale.domain([0, 100])

  // Transform the data into a format that echarts can use: [x, y, value]
  const chartData = data
    .map((row, i) => {
      return row.map((value, j) => {
        const v = Math.round(value) || 0
        const percentage = globalMax !== 0 ? (v / globalMax) * 100 : 0

        return {
          value: [j, i, v, percentage],
          itemStyle: {
            color: showPercentages
              ? percentage === 100
                ? '#999'
                : percentageColors(percentage).hex()
              : valueColors(v).hex(),
          },
        }
      })
    })
    .flat()

  const inRange = {
    color: showPercentages
      ? [...Array(100).keys()].map(i => percentageColors(i).hex())
      : [...Array(globalMax).keys()].map(i => valueColors(i).hex()),
  }

  const options = {
    grid: {
      height: '80%',
      width: '80%',
      top: '10%',
      bottom: '10%',
      containLabel: true,
    },
    tooltip: {
      show: oneInBigMode,
      trigger: 'item',
      textStyle: {
        align: 'center',
      },
      confine: true,
      formatter: function (params) {
        if (!params.data) return ''

        const bannerIndex = params.data.value[0]
        const bannerLabel =
          useLabels && banners[bannerIndex].label
            ? banners[bannerIndex].label
            : banners[bannerIndex].name
        const stubIndex = params.data.value[1]
        const stubLabel =
          useLabels && stubs[stubIndex].label
            ? stubs[stubIndex].label
            : stubs[stubIndex].name
        const value = params.data.value[2]
        const percentage = params.data.value[3]
        return buildTooltip({
          bannerLabel,
          stubLabel,
          value,
          percentage,
          globalMax,
        })
      },
    },
    xAxis: {
      type: 'category',
      show: !bannersInBigMode,
      data: banners.map(b => {
        const label = useLabels && b.label ? b.label : b.name
        return { value: label, textStyle: normalLabelStyle }
      }),
      axisLabel: {
        show: !bannersInBigMode,
        interval: 0,
        rotate: 30,
        overflow: 'break',
        color: '#000',
      },
      axisPointer: {
        show: true,
        type: 'shadow',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      show: !stubsInBigMode,
      type: 'category',
      data: stubs.map(s => {
        const label = useLabels && s.label ? s.label : s.name
        return { value: label, textStyle: normalLabelStyle }
      }),
      axisLabel: {
        show: !stubsInBigMode,
        interval: 0,
        color: '#000',
      },
      axisPointer: {
        show: true,
        type: 'shadow',
        label: {
          show: false,
        },
      },
    },
    dataZoom: [
      {
        disabled: !bannersInBigMode,
        type: 'inside',
        xAxisIndex: [0],
      },
      {
        disabled: !stubsInBigMode,
        type: 'inside',
        yAxisIndex: [0],
      },
    ],

    visualMap: {
      show: true,
      left: 'right',
      top: 'middle',
      itemHeight: 200,
      itemWidth: 25,
      calculable: true,
      min: 0,
      max: showPercentages ? 100 : globalMax,
      range: [0, showPercentages ? 100 : globalMax],
      inRange: inRange,
      dimension: showPercentages ? 3 : 2,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          name: `${maxDiffName}-joint-preferences`,
          type: 'png',
        },
      },
    },
    animation: false,
    series: [
      {
        name: 'Joint preferences',
        type: 'heatmap',
        data: chartData,
        label: {
          show: !oneInBigMode,
          formatter: function (params) {
            return showPercentages
              ? percentageFormatter.format(params.data.value[3] / 100)
              : params.data.value[2]
          },
          fontSize: 13,
        },
      },
    ],
  }

  const onEvents = {
    dblclick: params => {
      bannersInBigMode && resetDataZoom(chartRef, 0)
      stubsInBigMode && resetDataZoom(chartRef, 1)
    },

    mouseover: params => {
      !bannersInBigMode &&
        highlightXAxisLabel(chartRef, options, params.data.value[0])
      !stubsInBigMode &&
        highlightYAxisLabel(chartRef, options, params.data.value[1])
    },
    mouseout: params => {
      !bannersInBigMode &&
        downplayXAxisLabel(chartRef, options, params.data.value[0])
      !stubsInBigMode &&
        downplayYAxisLabel(chartRef, options, params.data.value[1])
    },
  }

  function handleResize() {
    if (chartRef.current && chartRef.current.getEchartsInstance()) {
      const instance = chartRef.current.getEchartsInstance()
      const height = instance.getHeight()

      // Set the visual map length based on the height of the chart
      instance.setOption({
        visualMap: {
          itemHeight: height * 0.6,
        },
      })
    }
  }

  return (
    <div className="joint-preferences-graph">
      <ReactEChartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%', width: '100%' }}
        ref={chartRef}
        onEvents={onEvents}
      />
    </div>
  )
}

function highlightXAxisLabel(chartRef, options, index) {
  if (options.xAxis.data[index].textStyle.fontWeight === 'bold') return
  options.xAxis.data[index].textStyle = highlightLabelStyle
  chartRef.current.getEchartsInstance().setOption({
    xAxis: {
      data: options.xAxis.data,
    },
  })
}

function downplayXAxisLabel(chartRef, options, index) {
  if (options.xAxis.data[index].textStyle.fontWeight === 'normal') return
  options.xAxis.data[index].textStyle = normalLabelStyle
  chartRef.current.getEchartsInstance().setOption({
    xAxis: {
      data: options.xAxis.data,
    },
  })
}

function highlightYAxisLabel(chartRef, options, index) {
  if (options.yAxis.data[index].textStyle.fontWeight === 'bold') return
  options.yAxis.data[index].textStyle = highlightLabelStyle
  chartRef.current.getEchartsInstance().setOption({
    yAxis: {
      data: options.yAxis.data,
    },
  })
}

function downplayYAxisLabel(chartRef, options, index) {
  if (options.yAxis.data[index].textStyle.fontWeight === 'normal') return
  options.yAxis.data[index].textStyle = normalLabelStyle
  chartRef.current.getEchartsInstance().setOption({
    yAxis: {
      data: options.yAxis.data,
    },
  })
}

function resetDataZoom(chartRef, dataZoomIndex) {
  chartRef.current.getEchartsInstance().dispatchAction({
    type: 'dataZoom',
    dataZoomIndex: dataZoomIndex,
    start: 0,
    end: 100,
  })
}

function buildTooltip({
  bannerLabel,
  stubLabel,
  value,
  percentage,
  globalMax,
}) {
  const bannerSameAsStub = bannerLabel === stubLabel

  const stubCell = bannerSameAsStub
    ? ''
    : `<td style="text-align: left; padding-left: 10px;">${stubLabel}</td>`

  return `
    <table style="border-collapse: collapse;">
      <tr>
        <td rowspan="2" style="font-size: 24px; font-weight: bold; border-right: 1px solid black; padding: 5px;">
          ${value} <span style="font-size: 16px;">of</span> ${globalMax}<br>
          ${percentageFormatter.format(percentage / 100)}
        </td>
        <td style="${
          bannerSameAsStub
            ? 'text-align: left; padding-left: 10px;'
            : 'text-align: left; padding-left: 10px; border-bottom: 1px solid black;'
        }">
          ${bannerLabel}
        </td>
      </tr>
      <tr>
        ${stubCell}
      </tr>
    </table>
  `
}
