import Columns from '../components/columns/Columns'
import { CrosstabMenu } from '../components/crosstab/CrosstabMenu'
import CrosstabUI from '../components/crosstab/CrosstabUI2'
import ApiProvider from '../contexts/ApiProvider'
import CrosstabProvider from '../contexts/CrosstabProvider'
import DataProvider from '../contexts/DataProvider'
import SidebarLayout from '../layouts/SidebarLayout'

export default function Crosstab() {
  return (
    <ApiProvider>
      <DataProvider>
        <CrosstabProvider>
          <SidebarLayout
            sidebar={<Columns />}
            content={<CrosstabUI />}
            menu={<CrosstabMenu />}
          />
        </CrosstabProvider>
      </DataProvider>
    </ApiProvider>
  )
}
