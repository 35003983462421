import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useMaxDiff } from './MaxDiffProvider'
import { useSurvey } from './SurveyProvider'
import { useParams } from 'react-router-dom'
import { useApi } from './ApiProvider'

const TURFContext = createContext()

export function TURFProvider({ children }) {
  const { selectedGroupVariables } = useMaxDiff()
  const [loading, setLoading] = useState(false)

  const [claimIds, setClaimIds] = useState({
    selectedClaimsIds: [],
    consideredClaimsIds: [],
  })

  const [mode, setMode] = useState('normal')
  const [TURFResults, setTURFResults] = useState([])
  const [totals, setTotals] = useState(undefined)
  const { weights } = useSurvey()
  const { maxDiffId } = useParams()
  const api = useApi()

  // Get the claims from the maxdiff question

  useEffect(() => {
    let cancelled = false
    ;(async () => {
      setLoading(true)
      const { selectedClaimsIds, consideredClaimsIds } = claimIds
      const selected_claims_ids = selectedClaimsIds
      const considered_claims_ids = consideredClaimsIds.filter(
        c => !selected_claims_ids.includes(c)
      )

      const response = await api.post(
        `/maxdiffs/${maxDiffId}/compute_reaches`,
        {
          ...{
            selected_variables_ids: selected_claims_ids,
            considered_variables_ids: considered_claims_ids,
            weight: weights ? weights.id : true,
          },
          ...(selectedGroupVariables.length > 0 && {
            subgroup_variables_ids: selectedGroupVariables,
            weight: weights ? weights.id : true,
          }),
        }
      )
      if (cancelled) {
        return
      }
      if (response.ok) {
        setTURFResults(response.body.claims)
        setTotals({
          reach: response.body['total_reach'],
          liked: response.body['total_liked'],
          favourite: response.body['total_favourites'],
          respondentsReached: response.body['respondents_reached'],
          respondentsTotal: response.body['respondents_total'],
        })
      } else {
        console.error(response)
      }
      setLoading(false)
    })()
    return () => {
      console.log('cleanup')
      cancelled = true
    }
  }, [claimIds, weights, selectedGroupVariables, maxDiffId, api, setLoading])

  const selectClaims = useCallback(
    list => {
      const { selectedClaimsIds, consideredClaimsIds } = claimIds
      const new_claims = list.filter(
        c => consideredClaimsIds.includes(c) && !selectedClaimsIds.includes(c)
      )
      if (new_claims.length > 0) {
        setClaimIds(prev => ({
          ...prev,
          selectedClaimsIds: [...prev.selectedClaimsIds, ...new_claims],
        }))
      }
    },
    [claimIds, setClaimIds]
  )

  const deselectClaims = useCallback(
    list => {
      const { selectedClaimsIds } = claimIds
      const claims_to_deselect = list.filter(c => selectedClaimsIds.includes(c))
      if (claims_to_deselect.length > 0) {
        setClaimIds(prev => ({
          ...prev,
          selectedClaimsIds: prev.selectedClaimsIds.filter(
            c => !claims_to_deselect.includes(c)
          ),
        }))
      }
    },
    [claimIds, setClaimIds]
  )

  const considerClaims = useCallback(
    list => {
      const { consideredClaimsIds } = claimIds
      const claims_to_consider = list.filter(
        c => !consideredClaimsIds.includes(c)
      )
      if (claims_to_consider.length > 0) {
        setClaimIds(prev => ({
          ...prev,
          consideredClaimsIds: [
            ...prev.consideredClaimsIds,
            ...claims_to_consider,
          ],
        }))
      }
    },
    [claimIds, setClaimIds]
  )

  const deconsiderClaims = useCallback(
    list => {
      const { consideredClaimsIds } = claimIds
      deselectClaims(list)
      const claims_to_deconsider = list.filter(c =>
        consideredClaimsIds.includes(c)
      )
      if (claims_to_deconsider.length > 0) {
        setClaimIds(prev => ({
          ...prev,
          consideredClaimsIds: prev.consideredClaimsIds.filter(
            c => !claims_to_deconsider.includes(c)
          ),
        }))
      }
    },
    [deselectClaims, claimIds, setClaimIds]
  )

  return (
    <TURFContext.Provider
      value={{
        claimIds,
        setClaimIds,
        selectedGroupVariables,
        mode,
        setMode,
        selectClaims,
        deselectClaims,
        considerClaims,
        deconsiderClaims,
        TURFResults,
        totals,
        setLoading,
        loading,
      }}
    >
      {children}
    </TURFContext.Provider>
  )
}

export const useTURF = () => {
  const context = useContext(TURFContext)
  if (!context) {
    throw new Error('useTURF must be used within a TURFProvider')
  }
  return context
}
