function getTime(t = new Date(), military = false) {
  let hours = t.getHours()
  let mins = '0'.slice(0, +t.getMinutes() < 10) + t.getMinutes()
  if (military) {
    hours = '0'.slice(0, +hours < 10) + hours
    return `${hours}${mins}`
  }
  const suffix = hours >= 12 ? 'PM' : 'AM'
  hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours
  hours = '0'.slice(0, +hours < 10) + hours
  return `${hours}${mins}${suffix}`
}
export default function getTimestamp(t = new Date(), military = false) {
  const year = t.getFullYear()
  const month = '0'.slice(0, +t.getMonth() < 9) + (t.getMonth() + 1)
  const day = '0'.slice(0, +t.getDate() < 10) + t.getDate()
  const time = getTime(t, military)
  const stamp = `${year}-${month}${day}-${time}`
  return stamp
}
