import {
  Button,
  DialogStep,
  MultistepDialog,
  Menu as BpMenu,
  MenuItem,
  MenuDivider,
  Icon,
  FormGroup,
  InputGroup,
  Classes,
  TagInput,
  DialogBody,
  Text,
  Spinner,
} from '@blueprintjs/core'
import {
  SurveyContext,
  flattenApiVariables,
} from '../../contexts/SurveyProvider'
import { useContext, useRef, useState } from 'react'
import { recodeTypes } from '../../pages/VariablesPage'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import './ComputeVariableDialog.css'
import { functions, operatorMatrix } from './computeOperators'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import { Cell, Column, RenderMode, Table2 } from '@blueprintjs/table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { formatValue } from '../crosstab/CrosstabTable'
import { useMemo } from 'react'
import { zScores } from '../../utils/stats'
import { interpolatePRGn } from 'd3-scale-chromatic'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { errorObjectToString } from '../../ApiClient'
import { WindowContext } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'

export default function ComputeVariableDialog({ isOpen, onClose }) {
  const [prevRequest, setPrevRequest] = useState()
  const [error, setError] = useState()
  const { loading, setLoading } = useContext(WindowContext)
  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    variables,
    setQuestions,
    setVariables,
    prevVariables,
    setPrevVariables,
  } = useContext(SurveyContext)

  const type = surveyDialogOpen
    ? recodeTypes.find(
        r => r?.name === surveyDialogOpen.split('-').slice(-1)[0]
      )
    : undefined

  const [variableQuery, setVariableQuery] = useState('')
  const [functionQuery, setFunctionQuery] = useState('')
  const [variableName, setVariableName] = useState('')
  const [variableLabel, setVariableLabel] = useState('')
  const variableSelectRef = useRef()
  const functionSelectRef = useRef()

  const handleVariableSelected = e => {
    variableSelectRef.current.value = [e.target.value]
  }
  const handleFunctionSelected = e => {
    functionSelectRef.current.value = [e.target.value]
  }

  const handleRemoveFormulaItem = i => {
    setFormula(prev => prev.filter((_, j) => j !== i))
  }

  const handleOperatorClick = operator => {
    if (operator.id === 'backspace') {
      handleRemoveFormulaItem(formula.length - 1)
      return
    }
    setFormula(prev => [
      ...prev,
      {
        type: 'operator',
        id: operator.id,
        value: operator.value,
        text: operator.text,
        icon: operator.icon,
        kind: operator?.kind,
      },
    ])
  }

  const [formula, setFormula] = useState([])
  const [data, setData] = useState()

  const api = useApi()
  const { surveyId } = useParams()
  const hasChanged = useMemo(() => {
    const request = {
      name: variableName,
      label: variableLabel,
      formula: formula
        .map(f => (f.type !== 'variable' ? f.id : '`' + f.id + '`'))
        .join(''),
      survey_id: parseInt(surveyId),
    }
    return (
      !prevRequest || JSON.stringify(prevRequest) !== JSON.stringify(request)
    )
  }, [variableName, variableLabel, formula, surveyId, prevRequest])

  const handlePreviewRequest = async () => {
    const request = {
      name: variableName,
      label: variableLabel,
      formula: formula
        .map(f => (f.type !== 'variable' ? f.id : '`' + f.id + '`'))
        .join(''),
      survey_id: parseInt(surveyId),
    }
    if (!hasChanged) return
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/computed/preview`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData(response.body)
      setError()
    } else {
      setError(errorObjectToString(response.body.messages.json))
      setData()
    }
  }

  const handleCreateRequest = async () => {
    const request = {
      name: variableName,
      label: variableLabel,
      formula: formula
        .map(f => (f.type !== 'variable' ? f.id : '`' + f.id + '`'))
        .join(''),
      survey_id: parseInt(surveyId),
    }
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/computed/create`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData()
      setError()
      setVariableName('')
      setVariableLabel('')
      setFormula([])
      onClose()
      const variable = response.body
      const question = variable.question
      const newVariable = flattenApiVariables([variable])[0]
      const newVariables = [...prevVariables, newVariable]
      setQuestions(prev => [...prev, question])
      setVariables(prev => [...prev, newVariable])
      setPrevVariables(newVariables)
      setVariables(newVariables)
      AppToaster.show({
        message: 'Variable created',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  const summaryCellStyles = useMemo(() => {
    if (!data?.summaries) return {}
    return data?.summaries.map(summary => {
      const counts = summary.punches.flatMap(punch =>
        punch.count !== null ? [punch.count] : []
      )
      const countsZ = zScores(counts, true)
      return Object.fromEntries(
        summary.punches.map((punch, i) => {
          const zScore = countsZ?.[i]
          if (zScore === undefined) return [punch.punch, {}]
          const backgroundColor = lighten(interpolatePRGn(zScore), 0.25)
          const color = getContrastText(backgroundColor)
          return [punch.punch, { backgroundColor, color }]
        })
      )
    })
  }, [data?.summaries])

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <MultistepDialog
            transitionDuration={surveyDialogOpen ? 0 : 300}
            finalButtonProps={{
              text:
                'Create Variable' + (data?.summaries?.length !== 1 ? 's' : ''),
              intent: 'success',
              disabled: error !== undefined,
              onClick: handleCreateRequest,
            }}
            // style={{ width: '90vw', height: '70vh' }}
            style={{ width: width - 100, height: height - 100 }}
            onClose={onClose}
            onChange={(newStep, prevStep, ev) => {
              // ev.preventDefault()
              // ev.stopPropagation()
              if (newStep === 'preview' || newStep === 'summary') {
                handlePreviewRequest()
              }
            }}
            title={
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    {recodeTypes.map((recode, i) =>
                      recode !== null ? (
                        <MenuItem
                          key={i}
                          icon={recode.icon}
                          text={recode.name}
                          labelElement={
                            <Icon
                              icon={type === recode ? 'small-tick' : 'blank'}
                            />
                          }
                          onClick={() =>
                            setSurveyDialogOpen(
                              `CreateVariableDialog-${recode.name}`
                            )
                          }
                        />
                      ) : (
                        <MenuDivider key={i} />
                      )
                    )}
                  </BpMenu>
                }
              >
                <Button
                  minimal
                  text={type?.name}
                  rightIcon="caret-down"
                  icon={type?.icon}
                />
              </Popover2>
            }
            isOpen={isOpen}
            isCloseButtonShown={true}
          >
            <DialogStep
              id="options"
              title="Options"
              nextButtonProps={{
                disabled: formula.length < 2 || !variableName || !variableLabel,
              }}
              panel={
                <DialogBody
                  style={{ display: 'flex' }}
                  id="options-panel"
                  className="options-panel"
                >
                  <div
                    id="variable-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 150, minWidth: 150 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter variables"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={variableQuery}
                        onChange={e => setVariableQuery(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={variableQuery ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!variableQuery}
                              onClick={() => setVariableQuery('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="variables"
                        multiple
                        disabled={formula.slice(-1)[0]?.type === 'variable'}
                        ref={variableSelectRef}
                        onChange={ev => {
                          variableSelectRef.current.value = [
                            ...ev.target.selectedOptions,
                          ].slice(-1)[0].value
                        }}
                        style={{
                          userSelect: 'element',
                          width: '150px',
                          overflowX: 'auto',
                          height: '50vh',
                        }}
                      >
                        {variables
                          .filter(v => {
                            const normalizedQuery = variableQuery.toLowerCase()
                            const normalizedVariable =
                              variableOrQuestionToString(v).toLowerCase()
                            return normalizedVariable.includes(normalizedQuery)
                          })
                          .map((v, i) => (
                            <option
                              onClick={handleVariableSelected}
                              key={i}
                              value={v.id}
                              onDoubleClick={() => {
                                setFormula(prev => [
                                  ...prev,
                                  {
                                    id: v.id,
                                    name: v.name,
                                    label: v.label,
                                    type: 'variable',
                                  },
                                ])
                              }}
                            >
                              {variableOrQuestionToString(v)}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </div>
                  <div id="operations-container" style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 16px',
                        height: '100%',
                      }}
                    >
                      <TagInput
                        rightElement={
                          <Button
                            minimal
                            title="Clear formula"
                            icon="cross"
                            disabled={!formula.length}
                            onClick={() => setFormula([])}
                          />
                        }
                        values={formula.map(v =>
                          v?.icon ? (
                            <Icon icon={v.icon} size={13} />
                          ) : v?.type === 'variable' ? (
                            v.name
                          ) : (
                            v.value
                          )
                        )}
                        // tagProps={formula.map(e =>
                        //   e?.type === 'variable'
                        //     ? { intent: 'primary' }
                        //     : { minimal: true }
                        // )}
                        tagProps={(_, i) =>
                          formula[i]?.type === 'variable'
                            ? { intent: 'primary' }
                            : formula[i]?.id === '_'
                            ? { intent: 'danger' }
                            : {}
                        }
                        // tagProps={{ minimal: true }}
                        id="formula"
                        onRemove={(_, index) => handleRemoveFormulaItem(index)}
                        inputProps={{
                          disabled: true,
                          value: '',
                          onFocus: e => e.target.blur(),
                          id: 'formula-input',
                        }}
                        minimal
                        placeholder={
                          formula.length
                            ? ''
                            : 'Use the variables on the left and operators below to create a formula'
                        }
                      ></TagInput>
                      {/* </div> */}
                      <div id="formula-preview" className={Classes.TEXT_MUTED}>
                        {formula.map((e, i) => (
                          <span
                            key={i}
                            id={`${e?.id}-preview`}
                            className={[
                              'formula-element',
                              `${e?.type}-element`,
                              e?.kind !== 'numeric' ? 'non-numeric' : 'numeric',
                            ].join(' ')}
                          >
                            {(e?.icon ? e.value : false) || e?.name || e?.value}
                          </span>
                        ))}
                      </div>
                      <div id="operators" style={{ padding: '12px 0' }}>
                        <table id="operator-table">
                          <tbody>
                            {operatorMatrix.map((row, r) => (
                              <tr key={r}>
                                {row.map((op, c) =>
                                  op ? (
                                    <td colSpan={op?.colSpan} key={c}>
                                      <Button
                                        id={op?.id}
                                        fill
                                        text={op?.text}
                                        icon={
                                          <Icon icon={op?.icon} size={14} />
                                        }
                                        onClick={() => handleOperatorClick(op)}
                                      />
                                    </td>
                                  ) : (
                                    <td key={c} />
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                          }}
                        >
                          <FormGroup
                            label="Variable Name"
                            labelFor="variable-name"
                            helperText="Ex: S3_65+S9_50K+"
                            style={{ marginTop: 20 }}
                          >
                            <InputGroup
                              autoFocus
                              value={variableName}
                              onChange={e => setVariableName(e.target.value)}
                              id="variable-name"
                              placeholder="Enter a variable name.."
                            />
                          </FormGroup>
                          <FormGroup
                            label="Variable Label"
                            labelFor="variable-label"
                            helperText="Age 65+ Income 50K+"
                            style={{ marginTop: 20 }}
                          >
                            <InputGroup
                              autoFocus
                              value={variableLabel}
                              onChange={e => setVariableLabel(e.target.value)}
                              id="variable-name"
                              placeholder="Enter a variable name.."
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="function-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 150, minWidth: 150 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter functions"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={functionQuery}
                        onChange={e => setFunctionQuery(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={functionQuery ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!functionQuery}
                              onClick={() => setFunctionQuery('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="functions"
                        multiple
                        disabled={formula.slice(-1)[0]?.type !== 'variable'}
                        ref={functionSelectRef}
                        onChange={ev => {
                          functionSelectRef.current.value = [
                            ...ev.target.selectedOptions,
                          ].slice(-1)[0].value
                        }}
                        style={{
                          userSelect: 'element',
                          width: '150px',
                          overflowX: 'auto',
                          height: '50vh',
                        }}
                      >
                        {functions
                          .filter(f => {
                            const normalizedQuery = functionQuery.toLowerCase()
                            const normalizedFunction = f.text.toLowerCase()
                            return normalizedFunction.includes(normalizedQuery)
                          })
                          .map((f, i) => (
                            <option
                              onClick={handleFunctionSelected}
                              key={i}
                              value={f.id}
                              onDoubleClick={() => {
                                setFormula(prev => [
                                  ...prev,
                                  {
                                    id: f.id,
                                    name: f.name,
                                    value: f.value,
                                    type: 'function',
                                  },
                                ])
                              }}
                            >
                              {f.text}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </div>
                </DialogBody>
              }
            />
            <DialogStep
              id="summary"
              panel={
                <DialogBody id="summary-panel" className="summary-panel">
                  {!loading ? (
                    data?.summaries ? (
                      data.summaries.map((summary, s) => (
                        <div>
                          <h4>
                            {summary.name} -- {summary.label}
                          </h4>
                          {summary.punches ? (
                            <table
                              className={[
                                Classes.HTML_TABLE,
                                Classes.HTML_TABLE_CONDENSED,
                              ].join(' ')}
                            >
                              <thead>
                                <tr>
                                  <th />
                                  <th>Count</th>
                                  <th>Percent</th>
                                  <th>Valid Percent</th>
                                  <th>Cum. Percent</th>
                                </tr>
                              </thead>
                              <tbody>
                                {summary.punches.map((punch, p) => (
                                  <tr key={p}>
                                    <th className="numeric-cell">
                                      {punch.punch === null
                                        ? 'Missing'
                                        : punch.punch}
                                    </th>
                                    <td className="numeric-cell">
                                      {punch.count ?? '~'}
                                    </td>
                                    <td
                                      className="numeric-cell"
                                      style={summaryCellStyles[s][punch.punch]}
                                    >
                                      {formatValue(punch.percent, 1, true) ??
                                        '~'}
                                    </td>
                                    <td className="numeric-cell">
                                      {formatValue(
                                        punch.valid_percent,
                                        1,
                                        true
                                      ) ?? '~'}
                                    </td>
                                    <td className="numeric-cell">
                                      {formatValue(
                                        punch.cum_percent,
                                        1,
                                        true
                                      ) ?? '~'}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            'Summary cannot be generated for this variable. This is likely because it has too many unique values.'
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
              title="Summary"
            />
            <DialogStep
              id="preview"
              title="Finalize"
              onOpening={() => console.log('open preview')}
              nextButtonProps={{ disabled: error !== undefined }}
              panel={
                <DialogBody id="preview-panel" className="preview-panel">
                  {!loading ? (
                    data?.data ? (
                      <div style={{ height: height - 330, width: width - 440 }}>
                        <Table2
                          numFrozenColumns={1}
                          numRows={data.data.length}
                          cellRendererDependencies={[data]}
                          renderMode={RenderMode.BATCH}
                          getCellClipboardData={(row, col) =>
                            data.data[row][col]
                          }
                        >
                          {data?.headers.map((column, c) => (
                            <Column
                              key={column}
                              name={column}
                              cellRenderer={rowIndex => (
                                <Cell>{data.data[rowIndex][c]}</Cell>
                              )}
                            />
                          ))}
                        </Table2>
                      </div>
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
            />
          </MultistepDialog>
        )
      }}
    </AutoSizer>
  )
}
