import { createContext, useState } from 'react'

export const CrosstabContext = createContext()

export default function CrosstabProvider({ children }) {
  const [options, setOptions] = useState({
    viz: 'table',
    aggregation: 'counts',
    aggregationColumn: '',
    statTesting: false,
  })

  return (
    <CrosstabContext.Provider value={{ options, setOptions }}>
      {children}
    </CrosstabContext.Provider>
  )
}
