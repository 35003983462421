import { useContext, useEffect, useMemo } from 'react'
import { createContext, useCallback, useState } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { LoadingIcon } from '../components/LoadingSpinner'
import { AppToaster, ProgressToaster } from '../components/toaster'
import { filterQuestions } from '../components/VizUI'
import useFilteredMultiSelect from '../hooks/useFilteredMultiSelect'
import { downloadFile } from '../utils/downloadFile'
import { useApi } from './ApiProvider'
import { useWindow } from './WindowProvider'
import { useSurvey } from './SurveyProvider'

export const VizContext = createContext()

export default function VizProvider({ children }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tableType = searchParams.get('tableType')
  const { surveyId } = useParams()
  const api = useApi()
  const [banner, setBanner] = useState([])
  const [filter, setFilter] = useState([])
  const [tableN, setTableN] = useState()
  const [questions, setQuestions] = useState()
  const {
    setLoading,
    createReport,
    addSlideToReport,
    setSettings,
    currentSurvey,
    settings,
  } = useWindow()
  const [sidebarTab, setSidebarTab] = useState('stubs')
  const {
    questions: surveyQuestions,
    variables: surveyVariables,
    createComputedVariable,
    modifyVariables,
    assignNewQuestionToVariables,
  } = useSurvey()

  const setSlide = slide => {
    setBanner(slide.banner)
    setStub(slide.stub)
    setFilter(slide.filter)
    if (slide?.settings) {
      if (slide.settings?.chart === undefined) {
        setSettings(prev => ({ ...prev, ...slide.settings, chart: null }))
      } else {
        setSettings(prev => ({ ...prev, ...slide.settings }))
      }
    }
    navigate(`/survey/${surveyId}/viz?tableType=${slide.type}`)
  }

  const hasFieldReport = useMemo(
    () => currentSurvey?.reports?.find(r => r.name === 'Field Report'),
    [currentSurvey]
  )

  /**
   * Create a Field Report
   *
   * The Field Report will have slides for:
   * - Alls (clicks) for the current week
   * - Completes for the current week
   * - Termination reasons for the current week
   * - LOI (Length of Interview) for the current week
   *
   * Each slide has a requirements function and a build function.
   * The requirements function checks if the requirements for the slide are met,
   * and returns a map of variables that will be passed to the build function.
   *
   * The build function builds the slide.
   *
   * All the requirement functions are called first, and if any of them fails, the report is not created.
   * Then, all the build functions are called.
   *
   */
  async function createFieldReport() {
    let toastKey
    toastKey = ProgressToaster.show({
      message: 'Creating report...',
      isCloseButtonShown: false,
      timeout: 0,
      icon: <LoadingIcon />,
    })

    const requirementFunctions = []
    const buildFunctions = []

    // Create the dynamic_week question that will be used as banner for all the slides
    const [dynamicWeekRequirements, dynamicWeekBuild] =
      createDynamicWeekQuestion()
    requirementFunctions.push(dynamicWeekRequirements)

    const slideFunctions = [
      createAllsAndCompletesSlide,
      createTermsSlide,
      createLOISlide,
    ]

    for (const func of slideFunctions) {
      const [requirements, build] = func()
      requirementFunctions.push(requirements)
      buildFunctions.push(build)
    }

    // A map of variables that will be passed to all build functions
    let vars = {}

    // Check requirements
    for (const func of requirementFunctions) {
      let newVars
      try {
        newVars = func()
      } catch (e) {
        if (e instanceof RequirementError) {
          AppToaster.show({
            message: e.message,
            intent: 'danger',
            icon: 'error',
          })
          ProgressToaster.dismiss(toastKey)
          return
        } else {
          throw e
        }
      }
      vars = { ...vars, ...newVars }
    }

    // Build the dynamic_week question
    const { dynamicWeekQuestion } = await dynamicWeekBuild(vars)
    vars = { ...vars, dynamicWeekQuestion }

    // Build slides
    const slides = []

    for (const func of buildFunctions) {
      const result = func(vars)
      const newSlides = result instanceof Promise ? await result : result
      slides.push(...newSlides)
    }

    let report = await createReport({ name: 'Field Report' })

    // Add the slides to the report
    slides.forEach(async (s, index) => {
      try {
        const slideWithPosition = { ...s, position: index + 1 }
        await addSlideToReport(slideWithPosition, report.id)
      } catch (e) {
        AppToaster.show({
          message: e.message,
          intent: 'danger',
          icon: 'error',
        })
        ProgressToaster.dismiss(toastKey)
        return
      }
    })

    ProgressToaster.dismiss(toastKey)
    return report
  }

  /**
   * Adds a LOI (Length of Interview) slide to the report
   *
   * This slide will have the dynamic_week question as banner and as stubs
   * the TimeStamp question
   *
   * @returns {Array} An array with two elements: a requirements function and a build function
   *
   */
  function createLOISlide() {
    const requirements = () => {
      const timeStampQuestion = surveyQuestions.find(
        q => q.name === 'TimeStamp' && q.type === 'single-select'
      )

      if (!timeStampQuestion) {
        throw new RequirementError(
          'No TimeStamp question found. Is this a Confirmit Survey?.'
        )
      }
      return {
        timeStampQuestion,
      }
    }

    const build = ({ timeStampQuestion, dynamicWeekQuestion }) => {
      const slide = {
        name: 'LOI',
        type: 'cross',
        banner: [dynamicWeekQuestion.id],
        stub: [timeStampQuestion.id],
      }

      return [slide]
    }

    return [requirements, build]
  }

  /**
   * Add a terms slide to the report.
   * The terms slide serves to display the termination reasons crosstab
   * for the current week.
   *
   * This slide will have the dynamic_week question as banner and as stubs
   * the SurveyTerms question
   *
   * @returns {Array} An array with two elements: a requirements function and a build function
   */
  function createTermsSlide() {
    const requirements = () => {
      const surveyTermsQuestion = surveyQuestions.find(
        q => q.name === 'SurveyTerms' && q.type === 'single-select'
      )

      if (!surveyTermsQuestion) {
        throw new RequirementError(
          'No SurveyTerms question found. Is this a Confirmit Survey?.'
        )
      }

      const surveyTerminationVariable = surveyVariables.find(
        v => v.id === surveyTermsQuestion.variables[0].id
      )

      if (!surveyTerminationVariable) {
        throw new RequirementError(
          'No SurveyTerms variable found. Is this a Confirmit Survey?.'
        )
      }

      return {
        surveyTermsQuestion,
        surveyTerminationVariable,
      }
    }

    const build = ({ surveyTermsQuestion, dynamicWeekQuestion }) => {
      const slide = {
        name: 'Termination Reasons',
        type: 'cross',
        banner: [dynamicWeekQuestion.id],
        stub: [surveyTermsQuestion.id],
      }

      return [slide]
    }
    return [requirements, build]
  }

  /**
   * Create a dynamic_week question, used as banner for most of the slides.
   * It's a multi-select question with 7 options: "Interview Started Before Today", "Interview Started Before Today - 1", etc.
   *
   * @returns {Array} An array with two elements: a requirements function and a build function
   */
  function createDynamicWeekQuestion() {
    /**
     * Check if the requirements for the dynamic_week question are met.
     * @returns {Object} An object with all the objects needed to build the dynamic_week question
     * @raises {RequirementError} If the requirements are not met
     *
     */
    const requirements = () => {
      const interviewStartQuestion = surveyQuestions.find(
        q => q.name === 'interview_start' && q.type === 'date'
      )
      if (!interviewStartQuestion) {
        throw new RequirementError(
          'No interview_start question found. Is this a Confirmit Survey?.'
        )
      }

      const dynamicWeekQuestion = surveyQuestions.find(
        q => q.name === 'started_before_last_week' && q.type === 'multi-select'
      )

      if (dynamicWeekQuestion) {
        throw new RequirementError(
          'A dynamic_week question already exists. Please delete it before creating a new one.'
        )
      }

      return { interviewStartQuestion }
    }

    /**
     * Build the dynamic_week question.
     * @param {Object} interviewStartQuestion The interview_start question
     * @returns {Object} An object with the dynamic_week question created
     */
    const build = async ({ interviewStartQuestion }) => {
      const tokens = ['today']

      for (let i = 1; i < 7; i++) {
        tokens.push(`today-${i}d`)
      }

      const computedVariables = []

      for (const token of tokens) {
        const name = `iterview_started_${token}`
        const label =
          token === 'today'
            ? 'Interview Started Before Today'
            : `Interview Started Before ${token.slice(6, 7)} Days Ago`
        const formula = `\`${interviewStartQuestion.variables[0].id}\` <= \`d(0)\``
        const dates = [token]

        const result = await createComputedVariable(name, label, formula, dates)
        computedVariables.push(result)
      }

      // Make all those variables numeric
      const modifications = computedVariables.map(v => ({
        id: v.id,
        variable_type: 'number',
      }))

      await modifyVariables(modifications)

      // Group them all under the "started_before_last_week" multi-select question

      let dynamicWeekQuestion

      dynamicWeekQuestion = await assignNewQuestionToVariables(
        'started_before_last_week',
        'multi-select',
        computedVariables.map(v => v.id)
      )

      console.log({ dynamicWeekQuestion })

      return { dynamicWeekQuestion }
    }

    return [requirements, build]
  }

  /**
   * Create two slides: The Alls (clicks) and the Completes
   * The Alls slide will have the dynamic_week question as banner and all the screening questions as stubs.
   * The Completes slide will have the same, but with the is_complete question as filter.
   *
   * @returns {Array} An array with two elements: a requirements function and a build function
   *
   */
  function createAllsAndCompletesSlide() {
    const requirements = () => {
      const interviewStartQuestion = surveyQuestions.find(
        q => q.name === 'interview_start' && q.type === 'date'
      )
      if (!interviewStartQuestion) {
        throw new RequirementError(
          'No interview_start question found. Is this a Confirmit Survey?.'
        )
      }

      const statusQuestion = surveyQuestions.find(
        q => q.name === 'status' && q.type === 'single-select'
      )
      if (!statusQuestion) {
        throw new RequirementError(
          'No status question found. Is this a Confirmit Survey?.'
        )
      }

      const screeningQuestions = surveyQuestions.filter(
        q =>
          q.name.match(/^S\d+/) &&
          (q.type === 'multi-select' || q.type === 'single-select') &&
          q.variables.every(
            v =>
              v.variable_type === 'number' || v.variable_type === 'categorical'
          )
      )
      if (!screeningQuestions.length) {
        throw new RequirementError(
          'No screening questions found. They should start with "S" and a number.'
        )
      }

      return {
        interviewStartQuestion,
        statusQuestion,
        screeningQuestions,
      }
    }

    const build = async ({
      dynamicWeekQuestion,
      statusQuestion,
      screeningQuestions,
    }) => {
      // Create a variable for "status == 1" (complete) named "is_complete"
      const isCompleteVariable = await createComputedVariable(
        'is_complete',
        'Respondent completed the survey',
        `\`${statusQuestion.variables[0].id}\` == 1`
      )

      // Create a multi-select question for "is_complete"

      const isCompleteQuestion = await assignNewQuestionToVariables(
        'is_complete',
        'multi-select',
        [isCompleteVariable.id]
      )

      console.log({ isCompleteQuestion })

      // Add the new questions to the surveyQuestions (and set them as recent)

      setQuestions(prev => [
        ...prev,
        { ...dynamicWeekQuestion, isRecent: true },
        { ...isCompleteQuestion, isRecent: true },
      ])

      // Create a new Slide in that report with "started_before_last_week" as banner and all the "S(number(s))" questions as stubs
      // Create a new Slide exactly like the previous one, but with "is_complete" as filter

      const slides = [
        {
          name: "This week's ALLs (dynamic)",
          type: 'cross',
          banner: [dynamicWeekQuestion.id],
          stub: screeningQuestions.map(q => q.id),
        },
        {
          name: "This week's COMPLETES (dynamic)",
          type: 'cross',
          banner: [dynamicWeekQuestion.id],
          stub: screeningQuestions.map(q => q.id),
          filter: [isCompleteVariable.id],
        },
      ]

      return slides
    }
    return [requirements, build]
  }

  const validStubQuestions = useMemo(
    () =>
      questions
        ? tableType === 'index'
          ? questions
          : questions.filter(q => q.type !== 'number')
        : [],
    [questions, tableType]
  )

  const filters = useMemo(
    () => [
      {
        name: 'Original',
        filter: q => !q.variables.some(v => v.has_formula || v.is_solution),
      },
      { name: 'Recodes', filter: q => q.variables.some(v => v.has_formula) },
      { name: 'Solutions', filter: q => q.variables.some(v => v.is_solution) },
    ],
    []
  )

  const [
    {
      selected: stub,
      query: stubQuery,
      filteredSelection: filteredStubSelection,
      filteredOptions: filteredStubOptions,
      filterName: stubFilterName,
    },
    {
      select: selectStub,
      reset: resetStub,
      queryChange: stubQueryChange,
      set: setStub,
      setFilterName: setStubFilterName,
    },
  ] = useFilteredMultiSelect(
    { selected: [], query: '' },
    filterQuestions,
    validStubQuestions,
    filters
  )

  useEffect(() => {
    if (
      (stub.length !== 1 || banner.length !== 1) &&
      settings?.chart !== null
    ) {
      setSettings(prev => ({ ...prev, chart: null }))
    }
  }, [stub, banner, settings?.chart, setSettings])

  useEffect(() => {
    ;(async () => {
      const response = await api.get(
        `/survey/${surveyId}/questions?types=single-select&types=multi-select&types=segmentation&types=number`
      )
      if (response.ok) {
        let { questions: newQuestions, recent_questions } = response.body

        const not_recent_questions = newQuestions.filter(
          q => !recent_questions.find(recent_q => recent_q.id === q.id)
        )
        recent_questions = recent_questions.map(q => ({
          ...q,
          isRecent: true,
        }))
        setQuestions([...recent_questions, ...not_recent_questions])
        if (Array.isArray(response.body) && response.body.length > 0) {
          // setBanner(response.body[0])
          // setStub(response.body[0])
        }
      } else {
        setQuestions(null)
      }
    })()
  }, [api, surveyId])

  const canExportToExcel = useMemo(
    () => banner.length && stub.length,
    [banner, stub]
  )

  const requestVizExport = useCallback(async () => {
    if (!canExportToExcel) {
      return
    }
    const toastId = ProgressToaster.show({
      message: 'Exporting...',
      isCloseButtonShown: false,
      timeout: 0,
      icon: <LoadingIcon />,
    })
    setLoading(true)
    const response = await api.post('/table_export?type=' + tableType, {
      banner: banner.map(b => b.id),
      stub: stub.map(s => s.id),
      filter: filter.map(f => f.id),
      table_type: tableType,
    })
    ProgressToaster.dismiss(toastId)
    setLoading(false)
    if (response.ok) {
      const data = await response.body.blob()
      console.log({ banner, stub, filter })
      let filename = `${banner.map(v => v.name.slice(0, 15)).join(',')} x ${stub
        .map(v => v.name.slice(0, 5))
        .join(',')}`
      let filterText = '(TOTAL)'
      if (filter.length) {
        filterText = filter
          .map(v => v.name)
          .join(',')
          .slice(0, 40)
      }
      filename =
        filename.slice(0, 207 - 16 - 6 - filterText.length) + filterText
      downloadFile(data, filename + '.xlsx')
      ProgressToaster.show({
        message: 'Download Successful',
        isCloseButtonShown: false,
        icon: 'tick-circle',
        intent: 'success',
      })
    } else {
      console.log({ response })
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [canExportToExcel, setLoading, api, banner, stub, filter, tableType])

  return (
    <VizContext.Provider
      value={{
        questions,
        banner,
        filter,
        tableN,
        setBanner,
        setFilter,
        setTableN,
        requestVizExport,
        canExportToExcel,
        sidebarTab,
        setSidebarTab,
        stub,
        setStub,
        resetStub,
        selectStub,
        stubQuery,
        stubQueryChange,
        filteredStubSelection,
        validStubQuestions,
        filteredStubOptions,
        setSlide,
        createFieldReport,
        hasFieldReport,
        stubFilterName,
        setStubFilterName,
      }}
    >
      {children}
    </VizContext.Provider>
  )
}

export function useViz() {
  const context = useContext(VizContext)
  if (context === undefined) {
    throw new Error('useViz must be used within a VizProvider')
  }
  return context
}

/**
 * Error thrown when a requirement for creating a slide or
 * a report is not met
 */
class RequirementError extends Error {
  constructor(message) {
    super(message)
    this.name = 'RequirementError'
  }
}
