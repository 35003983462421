import { useRef } from 'react'
import { useDrop } from 'react-dnd'

export default function VariableArea({
  areaName,
  changeVariableLocation,
  children,
  id,
}) {
  const ref = useRef()
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'variable',
    drop(variable) {
      changeVariableLocation(variable.name, areaName)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  let border
  if (isActive) {
    border = '1px solid gray'
  }
  drop(ref)
  return (
    <div
      id={id}
      ref={ref}
      style={border ? { border } : {}}
      className="variable-area"
    >
      <div className={'variable-container'}>{children}</div>
    </div>
  )
}
