import { Button, Collapse } from '@blueprintjs/core'
import { useState } from 'react'
import './SidebarLayout.css'

export default function SidebarLayout({
  sidebar,
  content,
  footer = false,
  menu = true,
}) {
  const [showSidebar, setShowSidebar] = useState(true)
  return (
    // <div style={{ paddingTop: 24, height: '100vh' }} id="container">
    // <div id="page-container">
    //   {menu}
    <main
      style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
      id="main"
    >
      {/* <SimpleBar id={'sidebar'}>{sidebar}</SimpleBar> */}
      <div
        id="sidebar"
        style={{
          height: `calc(100vh - ${24 * (!!menu + !!footer)}px)`,
          ...(showSidebar ? {} : { display: 'none' }),
        }}
      >
        {sidebar}
        <div
          style={{
            display: 'flex',
            flexShrink: 'initial',
            flexBasis: 'initial',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        />
      </div>
      <div
        id="content-container-parent"
        style={{
          width: `calc(100vw - ${showSidebar ? 270 : 0}px)`,
          backgroundColor: 'rgb(252,252,252)',
          overflow: 'hidden',
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
        }}
      >
        <div
          id="content-container-child"
          style={{
            // padding: '1.75rem',
            borderTopLeftRadius: '2rem',
            height: '100%',
          }}
        >
          <Button
            style={{
              position: 'absolute',
              left: showSidebar ? 270 : 0,
              zIndex: 2,
            }}
            minimal
            // text="Toggle"
            // large
            icon="list"
            onClick={() => setShowSidebar(!showSidebar)}
          />
          <div id="content-container">{content}</div>
        </div>
      </div>
      {/* <Upload /> */}
    </main>
    // </div>
  )
}
