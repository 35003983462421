import React from 'react'

const SaveIcon = () => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    style={{ verticalAlign: 'top', padding: '0 0.2rem' }}
  >
    <path
      d="M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0"
      fill="none"
      stroke="black"
      stroke-width="3"
    />
  </svg>
)

const RestoreIcon = () => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    style={{ verticalAlign: 'top', padding: '0 0.2rem' }}
  >
    <path
      d="M3.8,33.4 M47,18.9h9.8V8.7 M56.3,20.1 C52.1,9,40.5,0.6,26.8,2.1C12.6,3.7,1.6,16.2,2.1,30.6 M13,41.1H3.1v10.2 M3.7,39.9c4.2,11.1,15.8,19.5,29.5,18 c14.2-1.6,25.2-14.1,24.7-28.5"
      fill="none"
      stroke="black"
      stroke-width="3"
    />
  </svg>
)

export const AverageUtilitiesInstructions = () => (
  <div className="instructions">
    <section>
      <h2>Overview</h2>
      <p>
        On this page, you are able to view the average Maxdiff scores of all
        items tested. Items above the average score across all respondents are
        shaded in green displayed on the right side, and those below the average
        are shaded in purple displayed on the left.
      </p>
    </section>
    <section>
      <h2>Buttons:</h2>
      <ul>
        <li>
          <strong>Subgroups:</strong> This drop-down menu allows you to change
          the subgroups you are simulating. The default is the entire sample.
        </li>
        <li>
          <strong>Save Image:</strong> The average score graph can be saved as
          an image by clicking the <SaveIcon /> icon.
        </li>
      </ul>
    </section>
    <section>
      <h2>View Preference</h2>
      <p>
        The default view of the items is the order in which they were entered
        into the tool. You can change this by clicking "Sorted" under the "View"
        tab. This allows you to view the items from highest to lowest MaxDiff
        score.
      </p>
    </section>
    <section>
      <h2>Exporting to Excel</h2>
      <p>
        To export the results, click on the "File" menu and select "Export to
        Excel." This will download an Excel file detailing the average scores
        for the selected audience.
      </p>
    </section>
  </div>
)

export const PreferenceProfilesInstructions = () => (
  <div className="instructions">
    <section>
      <h2>Overview</h2>
      <p>
        The Preference Profiles page allows you to understand an item’s reach
        across different audiences. Note that you are not able to select any
        subgroup on this page since all subgroups are displayed as you
        investigate the reach profile of different items.
      </p>
    </section>
    <section>
      <h2>Saving Images</h2>
      <p>
        The donut charts can be saved by clicking the <SaveIcon /> icon.
      </p>
    </section>
    <section>
      <h2>Exporting to Excel</h2>
      <p>
        To export the results, click on the "File" menu and select "Export to
        Excel." This will download an Excel file detailing the counts of
        respondents reached across all audiences and MaxDiff items.
      </p>
    </section>
  </div>
)

export const JointPreferencesInstructions = () => (
  <div className="instructions">
    <section>
      <h2>Overview</h2>
      <p>
        The Joint Preferences page allows you to build out any crosstabulation
        with items tested to understand the overlap in reach. Choose as many or
        as few in the "Banners" and "Stubs" sections. Note that you need to have
        at least one banner and one stub.
      </p>
    </section>
    <section>
      <h2>How it works</h2>
      <ul>
        <li>
          <strong>Hovering:</strong> As you hover over the cells, you’ll get a
          more detailed view. Each cell reveals the number and percentage of
          respondents reached by both claims based on the subgroup you have
          selected.
        </li>
        <li>
          <strong>Shading:</strong> The crosstabulation formed will be displayed
          as cells in various shades of green. The darker shades represent a
          greater overlap in reach while lighter shades represent less overlap.
        </li>
        <li>
          <strong>Filtering:</strong> You can use the value scale to the right
          of the chart to filter the results. For example, if you want to see
          only the cells where at least 10% of respondents were reached by both
          items, you can slide the value indicator from the bottom until it
          reaches 10%. The cells that do not meet this threshold will be hidden
          from the chart. Sliding from the top will also filter the results, but
          will set an upper bound instead. <br />
          By default, the scale is based on percentages. You can change this to
          raw counts by switching from "Show percentages" to "Show counts" in
          the "View" menu.
        </li>
        <li>
          <strong>Zoom In/Out:</strong> You can zoom in and out of the chart
          using your mouse wheel or trackpad. To reset the zoom, double click
          anywhere on the chart.
        </li>
      </ul>
    </section>
    <section>
      <h2>Buttons</h2>
      <ul>
        <li>
          <strong>Subgroups:</strong> This drop-down menu allows you to change
          the subgroups you are simulating. The default is the entire sample.
        </li>
        <li>
          <strong>Save Image:</strong> The crosstab can be saved as an image by
          clicking the <SaveIcon /> icon to the right of the chart.
        </li>
      </ul>
    </section>
    <section>
      <h2>Exporting to Excel</h2>
      <p>
        To export the results, click on the "File" menu and select "Export to
        Excel." This will download the complete crosstabulation for all items in
        this MaxDiff, with sheets for percentages and counts.
      </p>
    </section>
  </div>
)

export const DecisionTreeInstructions = () => (
  <div className="instructions">
    <section>
      <h2>Overview</h2>
      <p>
        Decision Routes is a visual version of the TURF page. It allows you to
        play out different scenarios of adding items to your bundle and
        understand the cumulative reach of each item.
      </p>
    </section>
    <section>
      <h2>How it works</h2>
      <ul>
        <li>
          <strong>Icons:</strong> Each Maxdiff item is uniquely represented by a
          different icon with its shape and color. The first level of icons
          displays the top ten items ordered by reach from left to right. You
          can hover over them to view a table displaying the total and
          cumulative number of respondents reached, as well as the individual
          and total reach proportions.
        </li>
        <li>
          <strong>Build your bundle: </strong>To create your bundle, click the
          item you want to include. A new row of 10 items will appear,
          displaying the next 10 items which would maximize your reach given
          your previous item selection. You continue this process until
          satisfied or when you hit 100% reach. <br />
          <br />
          Note that if you would like to recreate the optimized reach scenario
          from the TURF page, you would just click the left most icon on each
          level of icons until you hit 100% reach.
        </li>
        <li>
          <strong>Selecting/deselecting a bundle: </strong>
          To select a bundle for exporting or for comparison with other bundles,
          double click its last item. The path will be outlined in red, and a
          stacked bar chart will appear at the bottom of the chart, detailing
          the contribution of each item to the total reach of your bundle. You
          can deselect a bundle by double clicking on the last item again.
        </li>
        <li>
          <strong>Panning and Zooming:</strong> You can use your mouse wheel or
          scroll with your trackpad to zoom in and out of the graph. You can
          also move the tree chart around the screen by dragging it with your
          mouse or trackpad. To reset the tree to its default position, you can
          click the <RestoreIcon /> icon in the top right
        </li>
        <li>
          <strong>Viewing all items:</strong> Each level shows by default the 10
          items that would contribute the most to the total reach of this
          bundle. If you'd like to explore all other items for this level,
          hovering over the parent for this level will display a table with a
          plus sign on it. Clicking that sign will show all possible items for
          this level. You can get back to displaying the original 10 items by
          clicking the minus button
        </li>
      </ul>
    </section>
    <section>
      <h2>Buttons</h2>
      <ul>
        <li>
          <strong>Subgroups:</strong> This drop-down menu allows you to change
          the subgroups you are simulating. The default is the entire sample.
        </li>
        <li>
          <strong>Save Image:</strong> The current Decision Route and bar charts
          can be saved as an image by clicking the <SaveIcon /> icon to the
          right of the chart.
        </li>
        <li>
          <strong>Restore:</strong> To reset to the default zoom state, click on
          the <RestoreIcon /> icon.
        </li>
      </ul>
    </section>
    <section>
      <h2>Exporting to Excel</h2>
      <p>
        To export the results, click on the "File" menu and select "Export to
        Excel." This will download an Excel file detailing the reach
        contribution of each item for each of the selected bundles.
      </p>
    </section>
  </div>
)

export const TURFInstructions = () => (
  <div className="instructions">
    <section>
      <h2>Overview</h2>
      <p>
        Below are descriptions of the analytic metrics and the buttons and
        columns in the TURF Simulator.
      </p>
    </section>

    <section>
      <h2>Metrics:</h2>
      <ul>
        <li>
          <strong>Reach:</strong> The proportion of the audience who consider
          the particular item appealing.
        </li>
        <li>
          <strong>Total Reach:</strong> The percent of unique (unduplicated)
          respondents that are reached by at least one of the statements that
          are turned on.
        </li>
        <li>
          <strong>Total Favorites:</strong> The proportion of the audience who
          have their favorite item in the bundle. This is used to build/assess
          depth of liking for an item or bundle of items.
        </li>
        <li>
          <strong>Total liked:</strong> Of the items in the ‘current offering’,
          the number of items that members consider appealing.
        </li>
      </ul>
    </section>

    <section>
      <h2>Columns:</h2>
      <ul>
        <li>
          <strong>Considered:</strong> The items that are being considered for
          inclusion in the bundle.
        </li>
        <li>
          <strong>Selected:</strong> The items that are currently in the bundle.
          We can only select items that are checked in the "considered" column.
        </li>
        <li>
          <strong>Reach:</strong> The proportion of the audience who consider
          the particular item appealing.
        </li>
        <li>
          <strong>Favorite:</strong> The proportion of the audience who have
          this item as their favorite.
        </li>
      </ul>
    </section>

    <section>
      <h2>Buttons:</h2>
      <ul>
        <li>
          <strong>Subgroups:</strong> This drop-down menu allows you to change
          the subgroups you are simulating. The default is the entire sample.
        </li>
        <li>
          <strong>Check Boxes:</strong> Check or uncheck for as many items as
          you would like to include in your 'consideration set' and 'current
          offerings.'
        </li>
        <li>
          <strong>"Consider/Select All" and "Consider/Select None":</strong>{' '}
          These buttons will turn all check boxes "on" or "off" for all of the
          items in the considered and selected columns.
        </li>
        <li>
          <strong>Next:</strong> This button will automatically select the next
          item that maximizes reach, based on the considered items and the
          current selection. To build an optimized bundle from scratch, click
          "Next" until you have reached your desired metrics.
        </li>
        <li>
          <strong>Back:</strong> This button will deselect the last selected
          item.
        </li>
        <li>
          <strong>Saved scenarios:</strong> This text box allows you to name and
          save your bundle scenarios. To do this, write a name and hit the
          <span class="key">Enter</span> key. The name will appear as a tag, and
          you can click on the tag to load the scenario. To remove a tag, click
          on the "x" next to its name. The bundle scenarios store: the
          considered items, the selected items, and the selected subgroups.
        </li>
      </ul>
    </section>

    <section>
      <h2>Exporting to Excel</h2>
      <p>
        Once there is at least one saved scenario, you can export the data to
        Excel. To do this, click on the "File" menu and select "Export to
        Excel." This will download an Excel file detailing the items in the
        consideration, the items in the current offering, and the metrics for
        each saved scenario.
      </p>
    </section>
    <section>
      <h2>Modes</h2>
      <p>
        There are two modes in the TURF Simulator, which can be changed from the
        View menu
      </p>
      <ul>
        <li>
          <strong>Normal</strong> The default mode. In this mode, selecting an
          item will display the reach and favorite metrics for that item.
        </li>
        <li>
          <strong>Build</strong> In this mode, the columns of reach and favorite
          show how much this item would increase the total reach and favorite
          were it to be added to the current offering. Note that as you add
          items to the current offering, the contribution of each next item will
          diminish.
        </li>
      </ul>
    </section>
  </div>
)
