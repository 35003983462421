import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import React, { useEffect } from 'react'
import Crosstab from './pages/Crosstab'
import Explorer from './pages/ExplorerPage'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import './App.css'
import './components/maxdiff/MaxDiffUI.css'
import ApiProvider from './contexts/ApiProvider'
import '@fontsource/inter'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import '@fontsource/roboto-mono/variable.css'
import VariablesPage from './pages/VariablesPage'
import SurveyProvider from './contexts/SurveyProvider'
import WindowProvider from './contexts/WindowProvider'
import DataProvider from './contexts/DataProvider'
import PublicRoute from './components/PublicRoute'
import InstructionsDialogProvider from './contexts/InstructionsDialogProvider'
import LoginPage from './pages/LoginPage'
import UserProvider from './contexts/UserProvider'
import PrivateRoute from './components/PrivateRoute'
import MagicRequestPage from './pages/MagicRequestPage'
import SurveysPage from './pages/SurveysPage'
import UploadPage from './pages/UploadPage'
import { ValidatedMaxDiffProvider } from './contexts/MaxDiffProvider'
import MaxDiffMenuProvider from './contexts/MaxDiffMenuProvider'
import VizProvider from './contexts/VizProvider'
import SurveyHomePage from './pages/SurveyHomePage'
import MaxDiffPage from './pages/MaxDiffPage'
import SurveyPage from './pages/NewSurveyPage'
import ModulesPage from './pages/ModulesPage'
import VizPage from './pages/VizPage'
import HelpVideoPage from './pages/HelpVideoPage'
import SuperGrouperPage from './pages/SuperGrouperPage'
import RequireRole from './components/RequireRole'
import UsersPage from './pages/UsersPage'
import RestorePage from './pages/RestorePage'
import NotificationWrapper from './components/NotificationWrapper'
import TasksPage from './pages/TasksPage'
import RacecarDriversPage from './pages/RacecarDriversPage'
// import DataProvider from './contexts/DataProvider'

import Sortable from 'sortablejs'
import { MultiDrag } from 'sortablejs'

Sortable.mount(new MultiDrag())

function App() {
  useEffect(() => {
    const errorListener = e => {
      console.log(e.message)
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }
    window.addEventListener('error', errorListener)
    return () => {
      window.removeEventListener('error', errorListener)
    }
  }, [])
  return (
    <BrowserRouter>
      <ApiProvider>
        <UserProvider>
          <Routes>
            <Route
              path="/help/:videoId"
              element={
                <PrivateRoute>
                  <RequireRole roles={['analyst', 'researcher']}>
                    <HelpVideoPage />
                  </RequireRole>
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <MagicRequestPage />
                </PublicRoute>
              }
            />
            <Route
              path="/verify"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <WindowProvider>
                          <SurveyProvider>
                            <InstructionsDialogProvider>
                              <NotificationWrapper>
                                <Outlet />
                              </NotificationWrapper>
                            </InstructionsDialogProvider>
                          </SurveyProvider>
                        </WindowProvider>
                      }
                    >
                      <Route
                        path="survey/:surveyId/*"
                        element={
                          <Routes>
                            <Route
                              path="/explorer"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <DataProvider>
                                    <Explorer />
                                  </DataProvider>
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/variables"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <VariablesPage />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/viz"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <VizProvider>
                                    <VizPage />
                                  </VizProvider>
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/grouper"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <SuperGrouperPage />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/drivers"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <RacecarDriversPage />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/crosstab"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <Crosstab />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/upload"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <UploadPage />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/restore"
                              element={
                                <RequireRole roles={['analyst', 'researcher']}>
                                  <RestorePage />
                                </RequireRole>
                              }
                            />
                            <Route
                              path="/maxdiffs/*"
                              element={
                                <Routes>
                                  <Route
                                    path="/:maxDiffId/*"
                                    element={
                                      <ValidatedMaxDiffProvider>
                                        <MaxDiffMenuProvider>
                                          <Routes>
                                            <Route
                                              path="/:toolId?"
                                              element={<MaxDiffPage />}
                                            />
                                          </Routes>
                                        </MaxDiffMenuProvider>
                                      </ValidatedMaxDiffProvider>
                                    }
                                  />
                                  <Route
                                    path="*"
                                    element={<Navigate to=".." replace />}
                                  />
                                </Routes>
                              }
                            />

                            <Route path="/" element={<SurveyHomePage />} />
                          </Routes>
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <RequireRole roles={['analyst', 'researcher']}>
                            <SurveyPage />
                          </RequireRole>
                        }
                      />
                      <Route path="/" element={<SurveysPage />} />
                      <Route path="/modules" element={<ModulesPage />} />
                      <Route
                        path="/users"
                        element={
                          <RequireRole roles={['analyst']}>
                            <UsersPage />
                          </RequireRole>
                        }
                      />
                      <Route path="/tasks" element={<TasksPage />} />
                    </Route>
                  </Routes>
                </PrivateRoute>
              }
            />
          </Routes>
        </UserProvider>
      </ApiProvider>
    </BrowserRouter>
  )
}

export default App
