import { Position, Toaster } from '@blueprintjs/core'

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  className: 'toaster',
  position: Position.BOTTOM,
})

export const ProgressToaster = Toaster.create({
  className: 'loading-toaster',
  position: Position.BOTTOM_RIGHT,
})
