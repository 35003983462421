import {
  Button,
  DialogStep,
  MultistepDialog,
  Menu as BpMenu,
  MenuItem,
  MenuDivider,
  Icon,
  FormGroup,
  InputGroup,
  Classes,
  TagInput,
  DialogBody,
  Text,
  Colors,
  Intent,
  Tag,
  Divider,
} from '@blueprintjs/core'
import { SurveyContext } from '../../contexts/SurveyProvider'
import { useContext, useState } from 'react'
import { recodeTypes } from '../../pages/VariablesPage'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import './RecodeVariablesDialog.css'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useMemo } from 'react'
import { errorObjectToString } from '../../ApiClient'
import { WindowContext } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'
import useFilteredMultiSelect from '../../hooks/useFilteredMultiSelect'
import { filterQuestions } from '../VizUI'
import { NUMERIC_VARIABLE_TYPES } from './VariablesUI'
import { IconNames } from '@blueprintjs/icons'

export default function ImputeVariablesDialog({ isOpen, onClose }) {
  const [prevRequest, setPrevRequest] = useState()
  const [error, setError] = useState()
  const { setLoading } = useContext(WindowContext)
  const { surveyDialogOpen, setSurveyDialogOpen, variables } =
    useContext(SurveyContext)

  const type = surveyDialogOpen
    ? recodeTypes.find(
        r => r?.name === surveyDialogOpen.split('-').slice(-1)[0]
      )
    : undefined

  const [suffix, setSuffix] = useState('_impute')

  const numericVariables = useMemo(
    () =>
      variables.filter(v => NUMERIC_VARIABLE_TYPES.includes(v.variable_type)),
    [variables]
  )

  const [
    { selected, query, filteredSelection, filteredOptions },
    { select, reset, queryChange },
  ] = useFilteredMultiSelect(
    { selected: [], query: '' },
    filterQuestions,
    numericVariables
  )

  const api = useApi()
  const { surveyId } = useParams()
  const hasChanged = useMemo(() => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      survey_id: parseInt(surveyId),
    }
    return (
      !prevRequest || JSON.stringify(prevRequest) !== JSON.stringify(request)
    )
  }, [selected, suffix, surveyId, prevRequest])

  const handlePreviewRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      survey_id: parseInt(surveyId),
    }
    if (!hasChanged) return
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/impute/preview`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setError()
    } else {
      setError(errorObjectToString(response.body.messages.json))
    }
  }

  const handleCreateRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      survey_id: parseInt(surveyId),
    }
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/impute/queue`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setError()
      setSuffix('')
      reset()
      onClose()
      AppToaster.show({
        message:
          "Imputation Queued. You'll receive notifications about its progress.",
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  // const [showAlert, setShowAlert] = useState(false)

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <MultistepDialog
            transitionDuration={surveyDialogOpen ? 0 : 300}
            finalButtonProps={{
              text: 'Request Imputation',
              intent: 'success',
              disabled: error !== undefined,
              onClick: handleCreateRequest,
            }}
            // style={{ width: '90vw', height: '70vh' }}
            style={{ width: width - 100, height: height - 100 }}
            onClose={onClose}
            onChange={(newStep, prevStep, ev) => {
              // ev.preventDefault()
              // ev.stopPropagation()
              if (newStep === 'preview' || newStep === 'summary') {
                handlePreviewRequest()
              }
            }}
            title={
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    {recodeTypes.map((recode, i) =>
                      recode !== null ? (
                        <MenuItem
                          key={i}
                          icon={recode.icon}
                          text={recode.name}
                          labelElement={
                            <Icon
                              icon={type === recode ? 'small-tick' : 'blank'}
                            />
                          }
                          onClick={() =>
                            setSurveyDialogOpen(
                              `CreateVariableDialog-${recode.name}`
                            )
                          }
                        />
                      ) : (
                        <MenuDivider key={i} />
                      )
                    )}
                  </BpMenu>
                }
              >
                <Button
                  minimal
                  text={type?.name}
                  rightIcon="caret-down"
                  icon={type?.icon}
                />
              </Popover2>
            }
            isOpen={isOpen}
            isCloseButtonShown={true}
          >
            <DialogStep
              id="variables"
              title="Variables"
              nextButtonProps={{
                disabled: !suffix,
              }}
              panel={
                <DialogBody
                  style={{ display: 'flex' }}
                  id="options-panel"
                  className="options-panel"
                >
                  <div
                    id="variable-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 300, minWidth: 300 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter variables"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={query}
                        onChange={e => queryChange(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={query ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!query}
                              onClick={() => queryChange('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="variables"
                        multiple
                        value={filteredSelection.map(o => o.id)}
                        onChange={e => {
                          // const selectedOption =
                          //   e.target.selectedIndex !== -1
                          //     ? numericVariables[e.target.selectedIndex]
                          //     : null
                          const selected = [...e.target.selectedOptions].map(
                            o => {
                              return filteredOptions.find(
                                v => `${v.id}` === `${o.value}`
                              )
                            }
                          )
                          select(selected)
                        }}
                        style={{
                          // width: '300px',
                          width: '100%',
                          overflowX: 'auto',
                          height: 'calc(50vh - 80px)',
                        }}
                      >
                        {filteredOptions.map(v => {
                          const text = variableOrQuestionToString(v, null)
                          return (
                            <option value={v.id} key={v.id}>
                              {text}
                            </option>
                          )
                        })}
                      </select>
                    </FormGroup>
                    <FormGroup
                      helperText={
                        !selected.length ? (
                          <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                            Select at least 2 variables
                          </Text>
                        ) : null
                      }
                      label="Inputs Selected"
                      labelInfo="(above)"
                      labelFor="inputs-selected"
                    >
                      <TagInput
                        id="inputs-selected"
                        leftIcon={IconNames.PROPERTIES}
                        // values={selected.map(v => v.name)}
                        values={[]}
                        onRemove={(_, index) =>
                          select([
                            ...selected.slice(0, index),
                            ...selected.slice(index + 1),
                          ])
                        }
                        inputProps={{
                          disabled: true,
                          value: '',
                          onFocus: e => e.target.blur(),
                        }}
                        disabled={selected.length === 0}
                        tagProps={{ minimal: true }}
                        intent={!selected.length ? Intent.DANGER : null}
                        placeholder={
                          selected.length ? '' : 'Select inputs above'
                        }
                        rightElement={
                          <div
                            style={{
                              padding: '5px 6px',
                            }}
                          >
                            <Tag
                              minimal={selected.length === 0}
                              icon={
                                <Icon icon={IconNames.PROPERTY} size="10" />
                              }
                              intent={
                                selected.length
                                  ? !selected.length
                                    ? Intent.DANGER
                                    : Intent.PRIMARY
                                  : Intent.NONE
                              }
                              onRemove={() => reset()}
                            >
                              {selected.length} variable
                              {selected.length !== 1 ? 's' : ''}
                            </Tag>
                          </div>
                        }
                      >
                        {selected.map((v, i) => (
                          <Tooltip2
                            key={v.id}
                            popoverClassName="input-tooltip"
                            className="input-tooltip"
                            content={
                              v?.label ? (
                                <span style={{ fontSize: 12 }}>{v.label}</span>
                              ) : null
                            }
                            minimal
                          >
                            <Tag
                              key={v.id}
                              minimal
                              onRemove={() =>
                                select([
                                  ...selected.slice(0, i),
                                  ...selected.slice(i + 1),
                                ])
                              }
                            >
                              {v.name}
                            </Tag>
                          </Tooltip2>
                        ))}
                      </TagInput>
                    </FormGroup>
                  </div>
                  <div id="operations-container" style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        padding: '0 16px',
                        height: '100%',
                      }}
                    >
                      <div id="operators">
                        <FormGroup
                          label="Variable Name Suffix"
                          labelFor="variable-name-suffix"
                          helperText="Ex: _impute"
                        >
                          <InputGroup
                            autoFocus
                            value={suffix}
                            onChange={e => setSuffix(e.target.value)}
                            id="variable-name-suffix"
                            placeholder="Enter a suffix.."
                          />
                        </FormGroup>
                        Select all variables you'd like to use as inputs for
                        imputation. A new variable will be created for each
                        input that originally contained missing data.
                        {/* </div> */}
                        <Divider />
                        {/* <h2>Preview</h2> */}
                        <div
                          style={{
                            maxHeight: 'calc(70vh - 157px)',
                            maxWidth: 350,
                            overflow: 'auto',
                            // alignSelf: 'center',
                          }}
                        >
                          {selected.length ? (
                            <table>
                              <tbody>
                                {selected.map(v => (
                                  <tr key={v.id}>
                                    <td
                                      style={{
                                        textAlign: 'right',
                                        color: Colors.GRAY1,
                                      }}
                                    >
                                      {v.name}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        width: 30,
                                      }}
                                    >
                                      {/* ➡ */}
                                      {/* {'-->'} */}
                                      <Icon icon="arrow-right" />
                                    </td>
                                    {/* <td style={{ fontWeight: 500 }}> */}
                                    <td>{v.name + suffix}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div style={{ marginTop: 32, textAlign: 'center' }}>
                              <h4>
                                Select variables to preview new variable
                                names...
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogBody>
              }
            />
          </MultistepDialog>
        )
      }}
    </AutoSizer>
  )
}
