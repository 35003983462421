import { MultiSelect2 } from '@blueprintjs/select'
import { Button, FormGroup } from '@blueprintjs/core'
import { MenuItem } from '@blueprintjs/core'
import highlightText from '../../utils/highlightText'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'

const filterQuestion = (query, question, _index, exactMatch) => {
  const normalizedName = question.name.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedQuery === normalizedName
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0
  }
}

export default function JointPreferencesClaimsSelector({
  useLabels = true,
  ...props
}) {
  const { selectedClaims, setSelectedClaims, label, ...rest } = props
  const { maxdiffQuestion } = useMaxDiff()
  const items = [{ name: 'Select All' }, ...maxdiffQuestion.variables]

  const maxTagsToDisplay = 5

  const multiSelectItemRenderer = (
    item,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = useLabels && item.label ? `${item.label}` : `${item.name}`

    if (item.name === 'Select All') {
      return (
        <Button
          selected={modifiers.active}
          shouldDismissPopover={true}
          disabled={modifiers.disabled}
          key={item.id}
          onClick={() => {
            if (selectedClaims.length === maxdiffQuestion.variables.length) {
              setSelectedClaims([])
            } else {
              setSelectedClaims(maxdiffQuestion.variables)
            }
          }}
          onFocus={handleFocus}
          text={
            selectedClaims.length === maxdiffQuestion.variables.length
              ? 'Select None'
              : 'Select All'
          }
        />
      )
    }

    return (
      <MenuItem
        selected={modifiers.active}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(text, query)}
        icon={selectedClaims.includes(item) ? 'tick' : 'blank'}
      />
    )
  }
  return (
    <FormGroup label={label} {...rest}>
      {maxdiffQuestion && maxdiffQuestion.variables && (
        <MultiSelect2
          key={maxdiffQuestion.id}
          selectedItems={selectedClaims}
          items={items}
          itemsEqual={(a, b) => a.id === b.id}
          onItemSelect={item => {
            if (item.name === 'Select All') {
              setSelectedClaims(maxdiffQuestion.variables)
            } else {
              setSelectedClaims(prev =>
                !prev.includes(item)
                  ? [...prev, item]
                  : prev.filter(c => c !== item)
              )
            }
          }}
          // onRemove={item =>
          //   setSelectedClaims(prev => prev.filter(c => c.id !== item.id))
          // }
          onRemove={item => {
            if (item.name === 'Select All') {
              console.log('Clearing all claims')
              setSelectedClaims([])
            } else {
              setSelectedClaims(prev => prev.filter(c => c !== item))
            }
          }}
          tagRenderer={
            // The first three tags are shown, the rest are hidden
            (item, index) =>
              index < maxTagsToDisplay
                ? useLabels && item.label
                  ? item.label
                  : item.name
                : index === maxTagsToDisplay
                ? `+${selectedClaims.length - maxTagsToDisplay} more`
                : ``
          }
          itemPredicate={filterQuestion}
          itemRenderer={multiSelectItemRenderer}
          onClear={() => setSelectedClaims([])}
          noResults={<MenuItem disabled={true} text="No results." />}
          popoverProps={{
            minimal: true,
            popoverClassName: 'joint-preferences-claims-selector-popover',
          }}
          placeholder="Choose the items to analyze..."
          resetOnQuery={true}
        />
      )}
    </FormGroup>
  )
}
