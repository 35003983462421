import { useContext, useEffect, useState } from 'react'
import { SurveyContext } from '../contexts/SurveyProvider'
import { Link, useParams } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  HotkeysProvider,
} from '@blueprintjs/core'
import { Cell, Column, JSONFormat2, Table2 } from '@blueprintjs/table'
import { AppToaster } from './toaster'
import { errorObjectToString } from '../ApiClient'
import getContrastText, { lighten } from '../utils/getContrastRatio'
import { interpolatePRGn } from 'd3-scale-chromatic'
import { DIALOG_FOOTER_ACTIONS } from '@blueprintjs/core/lib/esm/common/classes'

export default function SegmentationExplorerDialog() {
  const { setSurveyDialogOpen, surveyDialogOpen } = useContext(SurveyContext)
  const { surveyId } = useParams()
  const [segmentations, setSegmentations] = useState()
  const api = useApi()

  const onClose = () => setSurveyDialogOpen()
  const isOpen = surveyDialogOpen === 'SegmentationExplorerDialog'

  useEffect(() => {
    ;(async () => {
      if (api === undefined || surveyId === undefined || !isOpen) return
      const response = await api.get(`/survey/${surveyId}/segmentations`)
      if (response.ok) {
        console.log({ response })
        setSegmentations(response.body)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({ message, intent: 'danger', icon: 'error' })
      }
    })()
  }, [api, surveyId, isOpen])

  const silhouetteCellRenderer = r => {
    const score = segmentations[r].silhouette_score
    const backgroundColor = lighten(interpolatePRGn(score), 0.25)
    const color = getContrastText(backgroundColor)
    return (
      <Cell
        style={{
          backgroundColor,
          color,
        }}
      >
        {segmentations[r].silhouette_score?.toFixed(2)}
      </Cell>
    )
  }
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Segmentation Explorer"
      icon="inherited-group"
      style={{ width: '80vw' }}
    >
      <DialogBody>
        {segmentations ? (
          segmentations.length ? (
            <HotkeysProvider>
              <Table2 numRows={segmentations.length}>
                <Column
                  name="Name"
                  cellRenderer={r => (
                    <Cell>{segmentations[r].variable.name}</Cell>
                  )}
                />
                <Column
                  name="Algo"
                  cellRenderer={r => <Cell>{segmentations[r].algo}</Cell>}
                />
                <Column
                  name="Size"
                  cellRenderer={r => <Cell>{segmentations[r].size}</Cell>}
                />
                <Column
                  name="Silhouette"
                  cellRenderer={silhouetteCellRenderer}
                />
                <Column
                  name="Notes"
                  cellRenderer={r => (
                    <Cell>
                      <JSONFormat2>{segmentations[r]?.notes}</JSONFormat2>
                    </Cell>
                  )}
                />
                <Column
                  name="Inputs"
                  cellRenderer={r => (
                    <Cell>
                      {segmentations[r].inputs.map(v => v.name).join(', ')}
                    </Cell>
                  )}
                />
                <Column
                  name="Seed"
                  cellRenderer={r => <Cell>{segmentations[r].seed}</Cell>}
                />
                <Column
                  name="NMF Runs"
                  cellRenderer={r => <Cell>{segmentations[r].nmf_runs}</Cell>}
                />
                <Column
                  name="Is Imported"
                  cellRenderer={r => (
                    <Cell>
                      {segmentations[r].is_imported === true ? '✔' : ''}
                    </Cell>
                  )}
                />
              </Table2>
            </HotkeysProvider>
          ) : (
            <div>
              No segmentations yet.{' '}
              <Link
                to={`/survey/${surveyId}/grouper`}
                style={{ display: 'inline' }}
                onClick={onClose}
              >
                Create some.
              </Link>
            </div>
          )
        ) : (
          'Loading...'
        )}
      </DialogBody>
      <DialogFooter>
        <div className={DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} intent="success">
            Done
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  )
}
