import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { Popover2 } from '@blueprintjs/popover2'
import {
  Button,
  Radio,
  RadioGroup,
  NumericInput,
  ControlGroup,
  Menu,
  MenuItem,
} from '@blueprintjs/core'
import { Icon, Label, Spinner } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'
import { useApi } from '../../contexts/ApiProvider'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { useTURF } from '../../contexts/TURFProvider'
import ReactEcharts from 'echarts-for-react'
import chroma from 'chroma-js'
import { IconNames } from '@blueprintjs/icons'
import ClaimsSelector from './ClaimsSelector'
import { downloadFile } from '../../utils/downloadFile'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'

import { LoadingIcon } from '../LoadingSpinner'
import { AppToaster, ProgressToaster } from '../toaster'

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

function calculateMean(array) {
  const sum = array.reduce((acc, val) => acc + val, 0)
  return sum / array.length
}

function rgbToYiq(r, g, b) {
  return 0.299 * r + 0.587 * g + 0.114 * b
}

function getTextColorForBackground(backgroundColor) {
  const [r, g, b] = chroma(backgroundColor).rgb()
  const yiq = rgbToYiq(r, g, b)

  return yiq >= 128 ? 'black' : 'white'
}

const WithPopover = WrappedComponent =>
  function Comp(props) {
    const [showPopover, setShowPopover] = useState(false)
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
    const [menuCallbacks, setMenuCallbacks] = useState({})

    const handleRightClick = useCallback(event => {
      event.preventDefault()
      const x = event.pageX
      const y = event.pageY
      const offset = { x: 0, y: 27 }
      setPopoverPosition({ top: y - offset.y, left: x - offset.x })
    }, [])

    const handleClickOutside = useCallback(event => {
      setShowPopover(false)
    }, [])

    useEffect(() => {
      if (showPopover) {
        document.addEventListener('click', handleClickOutside)
      } else {
        document.removeEventListener('click', handleClickOutside)
      }

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }, [showPopover])

    const defaultMenuContent = [
      <MenuItem
        text="Default Item"
        onClick={() => {
          console.log('Default item clicked')
        }}
      />,
    ]

    const menuContentWithCallbacks = ({ closePopover, additionalArgs }) => {
      const defaultContent = props.menuContent
        ? props.menuContent
        : defaultMenuContent

      return React.Children.map(defaultContent, child => {
        return React.cloneElement(child, {
          onClick: () => {
            console.log('Calling callback for', child.props.text)
            closePopover()
            if (menuCallbacks && menuCallbacks[child.props.text]) {
              menuCallbacks[child.props.text](additionalArgs)
            }
          },
        })
      })
    }

    return (
      <div
        onContextMenu={handleRightClick}
        style={{ position: 'relative', height: '100%' }}
      >
        <WrappedComponent
          setShowPopover={setShowPopover}
          setMenuCallbacks={setMenuCallbacks}
          {...props}
        />
        {showPopover && props.menuContent && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              pointerEvents: 'none',
            }}
          >
            <div
              style={{
                position: 'fixed', // position relative to the viewport
                top: `${popoverPosition.top}px`,
                left: `${popoverPosition.left}px`,
                zIndex: 1000, // set a high z-index to make sure it's on top
              }}
            >
              <Popover2
                isOpen={showPopover}
                content={
                  <Menu>
                    {menuContentWithCallbacks({
                      closePopover: () => setShowPopover(false),
                    })}
                  </Menu>
                }
              >
                <Button minimal={true}></Button>
              </Popover2>
            </div>
          </div>
        )}
      </div>
    )
  }

const EnhancedTURFGraph = WithPopover(TURFGraph)

export function TURFSolver({ exportFileName }) {
  const { considerClaims } = useTURF()
  const { maxdiffQuestion, maxDiffName, createExportSet, exportSets } =
    useMaxDiff()
  const [excludedClaims, setExcludedClaims] = useState([])
  const [forcedClaims, setForcedClaims] = useState([])

  // The number of expected items in the bundle. If zero, we'll try to find the smallest bundle that reaches everyone.
  // If non-zero, we'll try to find the best bundles of this size.
  const [bundleSize, setBundleSize] = useState(0)

  // The state for the numeric input for the custom bundle size
  const [customBundleSize, setCustomBundleSize] = useState(5) // Default value for custom bundle size

  const setBundleChoice = useCallback(
    newChoice => {
      if (newChoice === 'smallest') {
        setBundleSize(0)
      } else if (newChoice === 'custom') {
        setBundleSize(customBundleSize)
      }
    },
    [customBundleSize]
  )

  const bundleChoice = bundleSize === 0 ? 'smallest' : 'custom'

  // The value of the current objective. If bundleChoice is 'smallest', this is the number of items in the bundle.
  // If bundleChoice is 'custom', this is the number of people reached by the bundle.
  const [objectiveValue, setObjectiveValue] = useState(0)

  // The bundles returned by the TURF solver
  const [bundles, setBundles] = useState(null)

  // The ID of the current TURF job
  const [jobId, setJobId] = useState(null)

  const fetchingSolutions = jobId !== null

  const api = useApi()
  const { maxDiffId } = useParams()
  const { selectedGroupVariables } = useTURF()

  const handleBundleChoiceChange = e => {
    const newChoice = e.currentTarget.value
    setBundleChoice(newChoice)
    setBundles(null) // Empty results when bundle choice changes
    if (newChoice === 'smallest') {
      setBundleSize(0)
    } else if (newChoice === 'custom') {
      setBundleSize(customBundleSize) // Update bundleSize when 'custom' is chosen
    }
  }

  const handleCustomBundleSizeChange = valueAsNumber => {
    setCustomBundleSize(valueAsNumber)
    if (bundleChoice === 'custom') {
      // Update bundleSize only if 'custom' is the chosen option
      setBundleSize(valueAsNumber)
    }
  }

  const consideredClaims = useMemo(
    () =>
      maxdiffQuestion?.variables?.filter(
        c => !excludedClaims.map(c => c.id).includes(c.id)
      ),
    [maxdiffQuestion, excludedClaims]
  )

  const exportToExcel = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    const response = await api.get(`/maxdiffs/${maxDiffId}/export_to_excel`)
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [maxDiffId, api, maxDiffName, exportFileName])

  const menu = useMemo(
    () => [
      {
        order: 1,
        label: 'File',
        items: [
          {
            label: 'Export to Excel',
            icon: fetchingSolutions ? (
              <LoadingIcon type="menu" />
            ) : (
              IconNames.EXPORT
            ),
            onClick: exportToExcel,
            disabled: fetchingSolutions || exportSets?.length === 0,
          },
        ],
      },
    ],
    [exportToExcel, fetchingSolutions, exportSets?.length]
  )
  useRegisterMaxDiffMenu('TURFBundlerMenu', menu)

  // Update the considered claims when the excluded claims change
  useEffect(() => {
    if (!maxdiffQuestion) return
    if (!considerClaims) return
    considerClaims(consideredClaims.map(c => c.id))
  }, [consideredClaims, maxdiffQuestion, considerClaims])

  // Empty results if selected group variables change or considered claims change
  useEffect(() => {
    setBundles(null)
  }, [selectedGroupVariables, consideredClaims, setBundles, forcedClaims])

  const cancelJob = useCallback(
    async jobToCancel => {
      const response = await api.delete(
        `/maxdiffs/${maxDiffId}/turf_optimizer/${jobToCancel}`
      )
      if (response.ok) {
        console.log('Cancelled TURF job', jobToCancel)
        setJobId(null)
      } else {
        console.log('Failed to cancel TURF job', jobToCancel, response)
      }
    },
    [maxDiffId, api]
  )

  const startJob = useCallback(async () => {
    const payload = {
      considered_variables_ids: consideredClaims.map(c => c.id),
      subgroup_variables_ids: selectedGroupVariables,
      forced_variables_ids: forcedClaims.map(c => c.id),
      bundle_size: bundleSize,
      max_solutions: 15,
    }

    const response = await api.post(
      `/maxdiffs/${maxDiffId}/turf_optimizer/start_job`,
      payload
    )

    if (response.ok) {
      setJobId(() => response.body.job_id)
      return response.body.job_id
    } else {
      console.error('Here:', { response })
      return null
    }
  }, [
    api,
    consideredClaims,
    forcedClaims,
    maxDiffId,
    selectedGroupVariables,
    bundleSize,
  ])

  const handleClick = useCallback(() => {
    if (jobId) {
      cancelJob(jobId)
      setJobId(null)
    } else {
      startJob()
    }
  }, [jobId, cancelJob, startJob])

  // Start fetching results when jobId changes
  useEffect(() => {
    if (!jobId) return

    const intervalId = setInterval(async () => {
      if (!jobId) {
        clearInterval(intervalId)
        return
      }

      const response = await api.get(
        `/maxdiffs/${maxDiffId}/turf_optimizer/${jobId}/results`
      )

      if (response.ok) {
        const { status, results, objective } = response.body

        if (status === 'finished') {
          console.log('Finished fetching TURF results')
          clearInterval(intervalId)
          setJobId(null)
        }
        setBundles(results)
        setObjectiveValue(objective)
      } else if (response.status === 403) {
        clearInterval(intervalId)
        console.error('Job does not exist', response)
        setJobId(null)
      } else {
        clearInterval(intervalId)
        console.error('Unknown error while fetching TURF results', response)
        setJobId(null)
      }
    }, 999) // Poll every 1 second

    return () => clearInterval(intervalId)
  }, [
    api,
    jobId,
    maxDiffId,
    consideredClaims,
    forcedClaims,
    selectedGroupVariables,
  ])

  // Cancel job when unmounting
  useEffect(() => {
    return () => {
      if (jobId) {
        cancelJob(jobId)
      }
    }
  }, [jobId, cancelJob])

  const menuContent = [<MenuItem text="Save as scenario..." />]

  const saveBundleAsScenario = useCallback(
    (name, bundleIds) => {
      const newSet = createExportSet(
        name,
        bundleIds,
        consideredClaims.map(c => c.id)
      )
      return newSet !== undefined ? true : false
    },
    [createExportSet, consideredClaims]
  )

  const graph = (
    <EnhancedTURFGraph
      menuContent={menuContent}
      bundles={bundles}
      fetchingSolutions={fetchingSolutions}
      saveBundleAsScenario={saveBundleAsScenario}
    />
  )

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <SettingsPanel
          {...{
            setExcludedClaims,
            excludedClaims,
            setForcedClaims,
            forcedClaims,
            fetchingSolutions,
            handleClick,
            handleBundleChoiceChange,
            bundleChoice,
            handleCustomBundleSizeChange,
            customBundleSize,
            bundles,
            objectiveValue,
            consideredClaims,
          }}
        />
        <div style={{ width: '100%' }}>{graph}</div>
      </div>
    </>
  )
}

function SettingsPanel({
  setExcludedClaims,
  excludedClaims,
  setForcedClaims,
  forcedClaims,
  fetchingSolutions,
  handleClick,
  handleBundleChoiceChange,
  bundleChoice,
  handleCustomBundleSizeChange,
  customBundleSize,
  bundles,
  objectiveValue,
  consideredClaims,
}) {
  const forcedClaimsTagRenderer = (_, index) =>
    index === 0 ? `${forcedClaims.length} items` : null
  const excludedClaimsTagRenderer = (_, index) =>
    index === 0 ? `${excludedClaims.length} items` : null
  return (
    <div
      style={{
        flexDirection: 'column',
        marginRight: '20px',
        padding: '10px',
        border: '2px solid #ccc',
        borderRadius: '8px',
        boxSizing: 'border-box',
        width: '250px',
      }}
    >
      <ControlGroup vertical style={{ marginBottom: '10px' }}>
        <ClaimsSelector
          setSelectedClaims={setExcludedClaims}
          selectedClaims={excludedClaims}
          singleSelect={false}
          useLabels={true}
          formGroupLabel="Excluded items"
          selectProps={{
            disabled: fetchingSolutions,
            tagRenderer: excludedClaimsTagRenderer,
          }}
          formGroupProps={{
            inline: false,
            style: { paddingLeft: '0px' },
          }}
          style={{ marginBottom: '10px' }}
          containerProps={{
            style: {
              marginBottom: '10px',
              width: '100%',
            },
          }}
        />
        <ClaimsSelector
          setSelectedClaims={setForcedClaims}
          selectedClaims={forcedClaims}
          singleSelect={false}
          useLabels={true}
          formGroupLabel="Forced items"
          formGroupProps={{
            inline: false,
            style: { paddingLeft: '0px' },
          }}
          selectProps={{
            disabled: fetchingSolutions,
            tagRenderer: forcedClaimsTagRenderer,
          }}
          containerProps={{
            style: { marginBottom: '10px', width: '100%' },
          }}
        />
        <RadioGroup
          inline={true}
          label="Pick a strategy"
          onChange={handleBundleChoiceChange}
          selectedValue={bundleChoice}
          disabled={fetchingSolutions}
        >
          <Radio
            label="Smallest bundle that reaches everyone"
            value="smallest"
          />
          <Radio label="Best bundle of X items" value="custom" />
        </RadioGroup>
        {bundleChoice === 'custom' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NumericInput
                value={customBundleSize}
                onValueChange={valueAsNumber =>
                  handleCustomBundleSizeChange(valueAsNumber)
                }
                style={{ width: '50px' }}
                disabled={fetchingSolutions}
                min={forcedClaims.length + 1}
                max={consideredClaims.length}
              />
              <div style={{ marginLeft: '10px' }}>
                {customBundleSize > 1 ? '     items' : '     item'}
              </div>
            </div>
          </div>
        )}
        <StartStopButton
          fetchingSolutions={fetchingSolutions}
          handleClick={handleClick}
        />
        <LoadingLabel
          loading={fetchingSolutions}
          bundles={bundles}
          objectiveValue={objectiveValue}
          bundleChoice={bundleChoice}
        />
      </ControlGroup>
    </div>
  )
}

function StartStopButton({ fetchingSolutions, handleClick, ...props }) {
  const icon = fetchingSolutions ? IconNames.STOP : IconNames.PLAY
  return (
    <Button
      onClick={handleClick}
      style={{ marginBottom: '30px', marginTop: '30px' }}
      icon={icon}
      {...props}
    >
      {fetchingSolutions ? 'Stop' : 'Start'}
    </Button>
  )
}

function LoadingLabel({ loading, bundles, objectiveValue, bundleChoice }) {
  const {
    totalRespondentsInSubgroup,
    displayWeighted,
    totalUnweightedRespondentsInSubgroup,
  } = useMaxDiff()

  const totalInSubgroup = !displayWeighted
    ? totalUnweightedRespondentsInSubgroup
    : totalRespondentsInSubgroup

  const nBundles = bundles?.length

  const ofMessage =
    bundleChoice === 'smallest'
      ? ' of ' + objectiveValue + ' items each.'
      : ' that reach ' +
        objectiveValue +
        ' (' +
        percentageFormatter.format(objectiveValue / totalInSubgroup) +
        ')' +
        ' people each.'

  return (
    <Label>
      {loading && (
        <>
          <Spinner
            size={16}
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          />{' '}
          Looking for solutions...
        </>
      )}
      {!loading && nBundles !== undefined && <Icon icon={IconNames.TICK} />}
      {nBundles !== undefined &&
        nBundles > 0 &&
        ` Found ${nBundles} bundle${nBundles > 1 ? 's' : ''} ${ofMessage}`}
      {nBundles !== undefined &&
        nBundles === 0 &&
        !loading &&
        ' No bundles found.'}
    </Label>
  )
}

function TURFGraph({ bundles, graphProps, useLabels = true, ...props }) {
  const { claimIds, selectedGroupVariables } = useTURF()
  const { maxDiffId } = useParams()
  const [baseTURFResults, setBaseTURFResults] = useState(null)
  const chartRef = useRef()
  const api = useApi()
  const bundlesRef = useRef(bundles)

  useEffect(() => {
    bundlesRef.current = bundles
  }, [bundles])

  const saveBundle = useCallback(
    async params => {
      const currentBundles = bundlesRef.current
      if (!currentBundles) return

      const bundle = currentBundles[params.dataIndex].map(item =>
        parseInt(item)
      )

      const name = prompt('Enter a name for the scenario')
      if (!name) return

      const result = props.saveBundleAsScenario(name, bundle)
      console.log('Result:', result)

      if (result === true) {
        AppToaster.show({
          message: 'Scenario saved',
          intent: 'success',
          icon: 'tick',
        })
      } else {
        AppToaster.show({
          message: 'Error saving scenario',
          intent: 'danger',
          icon: 'error',
        })
      }
    },
    [
      props.saveBundleAsScenario,
      claimIds.consideredClaimsIds,
      selectedGroupVariables,
      baseTURFResults,
    ]
  )

  const handleRightClick = useCallback(
    params => {
      if (props.setShowPopover) {
        props.setShowPopover(true)
        props.setMenuCallbacks(prevState => ({
          ...prevState,
          'Save as scenario...': () => saveBundle(params),
        }))
      }
    },
    [props.setShowPopover, saveBundle]
  )

  const onEvents = useMemo(
    () => ({
      contextmenu: handleRightClick,
    }),
    [handleRightClick]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          selected_variables_ids: [],
          considered_variables_ids: claimIds.consideredClaimsIds,
          weight: true,
        }

        if (selectedGroupVariables.length > 0) {
          payload.subgroup_variables_ids = selectedGroupVariables
          payload.weight = true
        }

        const response = await api.post(
          `/maxdiffs/${maxDiffId}/compute_reaches`,
          payload
        )

        if (response.ok) {
          setBaseTURFResults(() => response.body)
        } else {
          console.error(response)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }

    fetchData()
  }, [api, claimIds, maxDiffId, selectedGroupVariables])

  const max_reach = useMemo(() => {
    return baseTURFResults !== null
      ? Math.max(
          ...baseTURFResults?.claims
            .filter(claim => claim.reach !== undefined)
            .map(claim => claim.reach)
        ) || 1
      : 1
  }, [baseTURFResults])

  const reach_color_scale = useMemo(() => {
    return chroma.scale(['red', 'white', 'green']).domain([0, max_reach])
  }, [max_reach])

  const values = useMemo(
    () =>
      bundles?.map((bundle, i) => {
        const bundleVariables = bundle.map(item => parseInt(item))
        const bundleTURFResults = baseTURFResults?.claims.filter(claim =>
          bundleVariables.includes(claim.variable.id)
        )

        const reachMean = bundleTURFResults
          ? calculateMean(bundleTURFResults.map(claim => claim.reach))
          : 0
        const favouriteSum = bundleTURFResults
          ? bundleTURFResults.reduce((acc, claim) => acc + claim.favourite, 0)
          : 0
        const claimsInBundle = bundleTURFResults
          ? bundleTURFResults.map(claim => claim)
          : []

        claimsInBundle.forEach(element => {
          element.reach_color = reach_color_scale(element.reach).hex()
          element.text_color = getTextColorForBackground(element.reach_color)
        })

        claimsInBundle.sort((a, b) => b.reach - a.reach)

        return {
          reachMean,
          favouriteSum,
          claimsInBundle: claimsInBundle,
        }
      }),
    [bundles, baseTURFResults, max_reach, reach_color_scale]
  )

  const option = useMemo(
    () => ({
      brush: {
        toolbox: ['rect', 'keep', 'clear'], // Choose which brushes to use
        xAxisIndex: 0, // The xAxis to which the brush component is coordinated
        yAxisIndex: 0, // The yAxis to which the brush component is coordinated
      },
      toolbox: {
        feature: {
          brush: {
            type: ['rect', 'polygon', 'clear'], // Rectangular selection, polygon selection, and clear selection
          },
        },
      },
      xAxis: {
        type: 'value',
        name: 'More item reach',
        scale: true,
      },
      yAxis: {
        type: 'value',
        name: 'More favorites',
        scale: true,
      },
      emphasis: {
        show: true,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const dataPoint = values[params.dataIndex]

          const overlappingPoints = values.filter(
            item =>
              item.reachMean === dataPoint.reachMean &&
              item.favouriteSum === dataPoint.favouriteSum
          )

          const toolTipContent = overlappingPoints.map((item, i) => {
            const claims = item.claimsInBundle
            const claimElements = claims
              .map(claim => {
                const name =
                  useLabels && claim.variable.label
                    ? claim.variable.label
                    : claim.variable.name
                return `<div style="background-color: ${claim.reach_color}; color: ${claim.text_color};">${name}</div>`
              })
              .join('')

            return `
          <strong>Items:</strong><br />
          ${claimElements}
      `
          })
          if (overlappingPoints.length > 1) {
            console.log(overlappingPoints)
          }
          return (
            '<div style="background-color: white; color: black;">' +
            toolTipContent +
            '</div>'
          )
        },
      },
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0],
          filterMode: 'none',
        },
        {
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'none',
        },
      ],
      series: [
        {
          // Existing Configuration
          data: values?.map((item, i) => {
            return {
              value: [item.reachMean * 100, item.favouriteSum * 100],
              symbolSize: 15,
              itemStyle: {
                color: `hsl(${(i * 360) / values.length}, 100%, 40%)`, // Unique HSL color
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'black',
                },
              },
            }
          }),
          type: 'scatter',
          selectMode: 'single',
        },
      ],
      ...graphProps,
    }),
    [values, graphProps]
  )
  return (
    <div style={{ height: '100%' }}>
      <ReactEcharts
        option={option}
        ref={chartRef}
        style={{ height: '100%', width: '100%' }}
        onEvents={onEvents}
      />
    </div>
  )
}
