import { Button, Checkbox, Classes, Dialog } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { useEffect, useRef, useState } from 'react'
import { useUser } from '../contexts/UserProvider'

export default function WelcomeDialog() {
  const [width, setWidth] = useState(560)

  const [isOpen, setIsOpen] = useState()
  const hideWelcomeCheckbox = useRef()
  const { user } = useUser()

  useEffect(() => {
    if (user === undefined) return
    setWidth(window.parent.innerWidth)
    if (user && user.role !== 'client') {
      setIsOpen(['true', null].includes(localStorage.getItem('showWelcome')))
    }
  }, [user])

  const handleClose = () => {
    setIsOpen(false)
    if (hideWelcomeCheckbox.current.checked) {
      localStorage.setItem('showWelcome', false)
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title="Welcome to the Toolkit"
      icon="lifesaver"
      style={{
        width: width * 0.6 + 200,
        height: (width * 0.6) / (1 + 7 / 9) + 250,
      }}
    >
      <div className={Classes.DIALOG_BODY} style={{ padding: '0 10px' }}>
        <p>
          <strong>Toolkit Tutorial</strong> (10 minutes)
        </p>
        <p>
          Below is a series of tutorial videos. Watch to learn how to use the
          toolkit.
        </p>
        <p>
          Every page in the toolkit has its own help video. You can always
          access it by clicking on <b>Help</b> in the menu at the top of the
          page.
        </p>
        <div>
          <iframe
            width={width * 0.6}
            height={(width * 0.6) / (1 + 7 / 9)}
            src="https://www.youtube-nocookie.com/embed/videoseries?list=PLFPg9mPYfIDqPUP9PcQFhKUvdTTcYN0tJ&rel=0"
            title="Toolkit Tutorial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            allowFullScreen
          />
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <div style={{ float: 'right' }}>
            <Tooltip2
              content={
                <span>
                  You can always access help videos by clicking on <b>Help</b>{' '}
                  in the menu at the top of the page.
                </span>
              }
            >
              <Checkbox
                style={{ paddingTop: 10 }}
                label="Don't show this Welcome Dialog again"
                inputRef={hideWelcomeCheckbox}
              />
            </Tooltip2>
          </div>
          <Button onClick={handleClose}>Dismiss</Button>
        </div>
      </div>
    </Dialog>
  )
}
