import './HelpVideo.css'

export default function HelpVideo({ src, title }) {
  return (
    <div>
      <h1>{title}</h1>
      <div className="embed-container">
        <iframe
          // width="560"
          // height="315"
          src={src + (src.includes('?') ? '' : '?') + `&autoplay=1&rel=0`}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
