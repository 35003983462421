import React from 'react'
import { Spinner } from '@blueprintjs/core'
import { useSurvey } from '../../contexts/SurveyProvider'
import { useTURF } from '../../contexts/TURFProvider'

const percentageFormatter = new Intl.NumberFormat('en', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export default function TURFTotals() {
  const { totals, loading } = useTURF()
  const { weights } = useSurvey()

  if (loading || totals === undefined) {
    return (
      <div className="turf-totals">
        <Spinner size={50} />
      </div>
    )
  }

  return (
    <div className="turf-totals">
      <table>
        <tbody>
          <tr className="turf-totals-total-reach-row">
            <th>Total Reach</th>
            <td className={totals.reach >= 1 ? 'highlighted' : ''}>
              {percentageFormatter.format(totals.reach)}
            </td>
          </tr>
          <tr className="turf-totals-total-liked-row">
            <th>Total Liked:</th>
            <td>{totals.liked.toFixed(1)}</td>
          </tr>
          <tr className="turf-totals-total-favourites-row">
            <th>Total Favorites:</th>
            <td>{percentageFormatter.format(totals.favourite)}</td>
          </tr>
          <tr className="turf-totals-total-respondents-reached-row">
            <th>
              Respondents reached{' '}
              {weights && <span className="weighted">(weighted)</span>}:
            </th>
            <td>
              {Math.round(totals.respondentsReached)} of{' '}
              {Math.round(totals.respondentsTotal)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
