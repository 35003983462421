import {
  Button,
  Checkbox,
  FormGroup,
  InputGroup,
  MenuItem,
  Dialog,
} from '@blueprintjs/core'

import { Tooltip2 } from '@blueprintjs/popover2'
import { MultiSelect2, Select2 } from '@blueprintjs/select'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { useSurvey } from '../contexts/SurveyProvider'
import highlightText from '../utils/highlightText'
import { LoadingIcon } from './LoadingSpinner'
import UploadClaimsDialog from './maxdiff/UploadClaimsDialog'
import { AppToaster } from './toaster'

export default function NewMaxdiffDialog() {
  const api = useApi()
  const forceExhaustiveCheckboxRef = useRef()
  const [loading, setLoading] = useState(false)
  const [selectedMaxDiffQuestion, setSelectedMaxDiffQuestion] = useState(null)
  const [selectedSubgroupQuestions, setSelectedSubgroupQuestions] = useState([])
  const [reachThreshold, setReachThreshold] = useState(75)
  const [lowSampleWarningThreshold, setLowSampleWarningThreshold] =
    useState(125)
  const [maxDiffName, setMaxDiffName] = useState('')

  const {
    setMaxDiffs,
    questions,
    surveyId,
    surveyDialogOpen,
    setSurveyDialogOpen,
  } = useSurvey()

  const handleSubmit = async ev => {
    ev.preventDefault()
    setLoading(true)
    AppToaster.clear()
    const data = {
      ...{
        name: maxDiffName,
        survey_id: surveyId,
        question_id: selectedMaxDiffQuestion.id,
        reach_threshold: reachThreshold,
        low_sample_warning_threshold: lowSampleWarningThreshold,
        force_exhaustive_subgroups:
          !forceExhaustiveCheckboxRef.current.disabled &&
          forceExhaustiveCheckboxRef.current.checked,
      },
      ...(selectedSubgroupQuestions.length > 0 && {
        subgroup_questions_ids: selectedSubgroupQuestions.map(q => q.id),
      }),
    }
    const response = await api.post('/maxdiffs/new', data)
    if (response.ok) {
      const maxDiff = response.body
      setMaxDiffs(prev => {
        return prev ? [...prev, maxDiff] : [maxDiff]
      })
      setSurveyDialogOpen(null)
      AppToaster.show({
        message: 'MaxDiff created successfully',
        intent: 'success',
        icon: 'tick-circle',
      })
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
    }
    setLoading(false)
  }

  const clearAll = () => {
    setSelectedMaxDiffQuestion(null)
    setSelectedSubgroupQuestions([])
    setMaxDiffName('')
    setReachThreshold(75)
    setLowSampleWarningThreshold(125)
    forceExhaustiveCheckboxRef.current.checked = false
  }

  return (
    <Dialog
      isOpen={surveyDialogOpen === 'NewMaxDiffDialog'}
      onClose={() => {
        clearAll()
        setSurveyDialogOpen(null)
      }}
      title="New MaxDiff"
      icon="style"
      style={{ width: '30%' }}
    >
      <form style={{ maxWidth: 600, padding: 32 }} onSubmit={handleSubmit}>
        <FormGroup
          label="MaxDiff name"
          labelFor="maxdiff-name"
          helperText={
            <>
              Choose a unique name for your MaxDiff.
              <i style={{ paddingTop: 8 }}>Example: weighted MaxDiff</i>
            </>
          }
        >
          <InputGroup
            placeholder="maxdiff1"
            id="maxdiff-name"
            value={maxDiffName}
            onChange={ev => setMaxDiffName(ev.target.value)}
          />
        </FormGroup>

        <NewMaxDiffQuestionSelector
          loading={loading}
          questions={questions}
          setSelectedMaxDiffQuestion={setSelectedMaxDiffQuestion}
          selectedMaxDiffQuestion={selectedMaxDiffQuestion}
        />
        <NewMaxDiffSubgroupQuestionsSelector
          loading={loading}
          questions={questions}
          setSelectedSubgroupQuestions={setSelectedSubgroupQuestions}
          forceExhaustiveCheckboxRef={forceExhaustiveCheckboxRef}
          selectedSubgroupQuestions={selectedSubgroupQuestions}
        />
        <NewMaxDiffReachThresholdSelector
          loading={loading}
          setReachThreshold={setReachThreshold}
          reachThreshold={reachThreshold}
        />
        <NewMaxDiffLowSampleWarningThresholdSelector
          loading={loading}
          setLowSampleWarningThreshold={setLowSampleWarningThreshold}
          lowSampleWarningThreshold={lowSampleWarningThreshold}
        />
        <MaxDiffSubmitButton
          loading={loading}
          selectedMaxDiffQuestion={selectedMaxDiffQuestion}
          maxDiffName={maxDiffName}
        />
      </form>
    </Dialog>
  )
}

function NewMaxDiffLowSampleWarningThresholdSelector({
  loading,
  setLowSampleWarningThreshold,
  lowSampleWarningThreshold,
}) {
  return (
    <FormGroup
      labelFor="low-sample-warning-threshold"
      helperText="If a subgroup question has less than this number of respondents, a warning will be displayed."
      label="Low sample warning threshold"
    >
      <div style={{ display: 'flex' }}>
        <InputGroup
          id="low-sample-warning-threshold"
          value={lowSampleWarningThreshold}
          onChange={ev => setLowSampleWarningThreshold(ev.target.value)}
          type="number"
          min="1"
          step="1"
          disabled={loading}
        />
      </div>
    </FormGroup>
  )
}

function NewMaxDiffReachThresholdSelector({
  loading,
  setReachThreshold,
  reachThreshold,
}) {
  return (
    <FormGroup
      labelFor="reach-threshold"
      helperText="Respondents whose score is above this threshold will be considered as reached."
      label="Reach"
    >
      <div style={{ display: 'flex' }}>
        <InputGroup
          id="reach-threshold"
          value={reachThreshold}
          onChange={ev => setReachThreshold(ev.target.value)}
          type="number"
          min="1"
          max="100"
          step="1"
          disabled={loading}
        />
        <div
          style={{
            verticalAlign: 'middle',
            textAlign: 'center',
            padding: '0 8px',
          }}
        >
          %
        </div>
      </div>
    </FormGroup>
  )
}

function MaxDiffSubmitButton({
  loading,
  selectedMaxDiffQuestion,
  maxDiffName,
}) {
  const disabled =
    loading ||
    !selectedMaxDiffQuestion ||
    maxDiffName === '' ||
    maxDiffName === null

  return (
    <FormGroup>
      <Button
        type="submit"
        intent="success"
        loading={loading}
        disabled={disabled}
      >
        Save
      </Button>
    </FormGroup>
  )
}

function NewMaxDiffQuestionSelector({
  loading,
  questions,
  selectedMaxDiffQuestion,
  setSelectedMaxDiffQuestion,
}) {
  const [showUploadDialog, setShowUploadDialog] = useState(false)

  const maxdiffQuestions = questions?.filter(q => q.type === 'maxdiff')
  const areThereMaxDiffQuestions = maxdiffQuestions?.length > 0

  const buttonPlaceholderText = areThereMaxDiffQuestions
    ? 'Choose a MaxDiff question'
    : 'No MaxDiff questions available'

  return (
    <FormGroup
      labelFor="maxdiff-question"
      helperText="Choose the question that will be used to create the MaxDiff."
      inline={true}
    >
      <div style={{ display: 'flex' }}>
        <Select2
          id="maxdiff-question"
          items={maxdiffQuestions}
          itemRenderer={singleSelectItemRenderer}
          onItemSelect={setSelectedMaxDiffQuestion}
          itemsEqual={(a, b) => a.id === b.id}
          noResults={
            <MenuItem
              disabled={true}
              text={loading ? 'Loading...' : 'No results.'}
              icon={loading ? <LoadingIcon /> : undefined}
            />
          }
        >
          <Button
            text={
              selectedMaxDiffQuestion
                ? selectedMaxDiffQuestion.name
                : buttonPlaceholderText
            }
            disabled={!areThereMaxDiffQuestions}
          />
        </Select2>
        <div
          style={{
            verticalAlign: 'middle',
            textAlign: 'center',
            padding: '0 8px',
          }}
        >
          or
        </div>
        <Button onClick={() => setShowUploadDialog(true)}>
          Upload a new one
        </Button>
        <UploadClaimsDialog
          afterDialogClose={question => setSelectedMaxDiffQuestion(question)}
          showUploadDialog={showUploadDialog}
          setShowUploadDialog={setShowUploadDialog}
        />
      </div>
    </FormGroup>
  )
}

function NewMaxDiffEnabledToolsSelector({
  availableTools,
  loading,
  enabledTools,
  setEnabledTools,
  areThereSubgroupQuestions,
}) {
  // Build a list of checkboxes for each tool

  // If "Preference Profiles" is in enabledTools and there are no subgroup questions, remove it
  useEffect(() => {
    if (
      !areThereSubgroupQuestions &&
      enabledTools.includes('Preference Profiles')
    ) {
      setEnabledTools(prev => prev.filter(t => t !== 'Preference Profiles'))
    }
  }, [areThereSubgroupQuestions, enabledTools, setEnabledTools])

  const toolCheckboxes = availableTools.map(toolName => {
    const isPreferenceProfiles = toolName === 'Preference Profiles'
    const disabled =
      (isPreferenceProfiles && !areThereSubgroupQuestions) || loading

    const checkboxElement = (
      <Checkbox
        disabled={disabled}
        inline={true}
        label={toolName}
        key={toolName}
        defaultChecked={enabledTools.includes(toolName) && !disabled}
        onChange={ev => {
          if (isPreferenceProfiles && !areThereSubgroupQuestions) {
            return
          }

          if (ev.target.checked) {
            setEnabledTools(prev => [...prev, toolName])
          } else {
            setEnabledTools(prev => prev.filter(t => t !== toolName))
          }
        }}
      />
    )

    if (isPreferenceProfiles && disabled && !loading) {
      return (
        <Tooltip2
          content="Preference Profiles is disabled because there are no subgroup questions."
          key={toolName}
        >
          {checkboxElement}
        </Tooltip2>
      )
    }

    return checkboxElement
  })

  return (
    <FormGroup
      labelFor="enabled-tools"
      helperText="Choose the tools that will be enabled for this MaxDiff."
    >
      <div>{toolCheckboxes}</div>
    </FormGroup>
  )
}

function NewMaxDiffSubgroupQuestionsSelector({
  loading,
  questions,
  selectedSubgroupQuestions,
  setSelectedSubgroupQuestions,
  forceExhaustiveCheckboxRef = null,
}) {
  const possibleSubgroupQuestions = questions?.filter(
    q => q.type === 'multi-select'
  )

  const dontSubmitOnEnter = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
    }
  }

  const buttonPlaceholderText =
    !possibleSubgroupQuestions || possibleSubgroupQuestions.length === 0
      ? 'No multi-select questions available'
      : 'Choose subgroup questions for this MaxDiff'

  const multiSelectItemRenderer = useCallback(
    (item, { handleClick, handleFocus, modifiers, query }) => {
      if (!modifiers.matchesPredicate) {
        return null
      }

      const text = `${item.name}`

      const isQuestionSelected = () =>
        selectedSubgroupQuestions.some(c => c.id === item.id)

      return (
        <MenuItem
          selected={modifiers.active}
          icon={isQuestionSelected() ? 'tick' : 'blank'}
          shouldDismissPopover={true}
          disabled={modifiers.disabled}
          label={item.variables.length + ' variables'}
          key={item.id}
          onClick={handleClick}
          onFocus={handleFocus}
          text={highlightText(text, query)}
        />
      )
    },
    [selectedSubgroupQuestions]
  )

  return (
    <FormGroup>
      <MultiSelect2
        id="Subgroup questions"
        selectedItems={selectedSubgroupQuestions}
        disabled={
          !possibleSubgroupQuestions || possibleSubgroupQuestions.length === 0
        }
        onKeyDown={dontSubmitOnEnter}
        items={possibleSubgroupQuestions}
        itemsEqual={(a, b) => a.id === b.id}
        onItemSelect={item =>
          setSelectedSubgroupQuestions(prev =>
            !prev.includes(item)
              ? [...prev, item]
              : prev.filter(c => c !== item)
          )
        }
        onRemove={item =>
          setSelectedSubgroupQuestions(prev =>
            prev.filter(c => c.id !== item.id)
          )
        }
        tagRenderer={q => q.name}
        itemPredicate={filterQuestion}
        itemRenderer={multiSelectItemRenderer}
        noResults={
          <MenuItem
            disabled={true}
            text={loading ? 'Loading...' : 'No results'}
            icon={loading ? <LoadingIcon /> : 'search'}
          />
        }
        popoverProps={{
          minimal: true,
          popoverClassName: 'newmaxdiff-subgroup-selector-popover',
        }}
        placeholder={buttonPlaceholderText}
        resetOnSelect={true}
      />

      {forceExhaustiveCheckboxRef && (
        <Tooltip2
          content={
            <>
              If checked, an 'Others' variable will be added to every <br />
              subgroup question that doesn't cover all respondents.
            </>
          }
        >
          <Checkbox
            label="Make subgroup questions exhaustive"
            inputRef={forceExhaustiveCheckboxRef}
            defaultChecked={false}
            disabled={
              !selectedSubgroupQuestions ||
              selectedSubgroupQuestions.length === 0
            }
          />
        </Tooltip2>
      )}
    </FormGroup>
  )
}

function singleSelectItemRenderer(item, { handleClick, modifiers, query }) {
  if (!modifiers.matchesPredicate) {
    return null
  }

  const text = `${item.name}`
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      text={highlightText(text, query)}
      shouldDismissPopover={true}
    />
  )
}

function filterQuestion(query, question, _index, exactMatch) {
  const normalizedName = question.name.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedQuery === normalizedName
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0
  }
}

export function UpdateMaxdiffDialog() {
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [selectedMaxDiffQuestion, setSelectedMaxDiffQuestion] = useState(null)
  const [selectedSubgroupQuestions, setSelectedSubgroupQuestions] = useState([])
  const [reachThreshold, setReachThreshold] = useState(75)
  const [lowSampleWarningThreshold, setLowSampleWarningThreshold] =
    useState(125)
  const [maxDiffName, setMaxDiffName] = useState('')
  const [enabledTools, setEnabledTools] = useState([])

  const {
    setMaxDiffs,
    questions,
    setSurveyDialogOpen,
    surveyDialogOpen,
    maxDiffs,
  } = useSurvey()

  const display = surveyDialogOpen?.startsWith('UpdateMaxdiffDialog:')
  const maxDiffId = parseInt(surveyDialogOpen?.split(':')[1])

  const maxDiff = maxDiffs?.find(m => m.id === maxDiffId)
  const availableTools = maxDiff?.available_tools

  //Populate fields with maxdiff data
  useEffect(() => {
    if (maxDiff) {
      setMaxDiffName(maxDiff.name)
      setSelectedMaxDiffQuestion(
        questions.find(q => q.id === maxDiff.question.id)
      )
      const subgroup_questions_ids = maxDiff.subgroup_questions.map(q => q.id)
      setSelectedSubgroupQuestions(
        questions.filter(q => subgroup_questions_ids.includes(q.id))
      )
      setReachThreshold(maxDiff.reach_threshold)
      setLowSampleWarningThreshold(maxDiff.low_sample_warning_threshold)
      setEnabledTools(maxDiff.enabled_tools)
    }
  }, [maxDiff, questions])

  const handleSubmit = async ev => {
    ev.preventDefault()
    setLoading(true)
    AppToaster.clear()
    const data = {
      name: maxDiffName,
      question_id: selectedMaxDiffQuestion.id,
      subgroup_questions_ids: selectedSubgroupQuestions.map(q => q.id),
      reach_threshold: reachThreshold,
      low_sample_warning_threshold: lowSampleWarningThreshold,
      enabled_tools: enabledTools,
    }
    const response = await api.put('/maxdiffs/' + maxDiff.id, data)
    if (response.ok) {
      const maxDiff = response.body
      // Update MaxDiff data in the survey context
      setMaxDiffs(prev => {
        return prev.map(m => (m.id === maxDiff.id ? maxDiff : m))
      })
      setSurveyDialogOpen(null)
      AppToaster.show({
        message: 'MaxDiff updated successfully',
        intent: 'success',
        icon: 'tick-circle',
      })
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
    }
    setLoading(false)
  }

  return (
    <Dialog
      isOpen={display}
      onClose={() => {
        setSurveyDialogOpen(null)
      }}
      title="Modify MaxDiff"
      icon="style"
      style={{ width: '40%' }}
    >
      <form style={{ width: '100%', padding: 32 }} onSubmit={handleSubmit}>
        <FormGroup
          label="MaxDiff name"
          labelFor="maxdiff-name"
          helperText={
            <>
              Choose a unique name for your MaxDiff.
              <i style={{ paddingTop: 8 }}>Example: weighted MaxDiff</i>
            </>
          }
        >
          <InputGroup
            placeholder="maxdiff1"
            id="maxdiff-name"
            value={maxDiffName}
            onChange={ev => setMaxDiffName(ev.target.value)}
          />
        </FormGroup>

        <NewMaxDiffQuestionSelector
          loading={loading}
          questions={questions}
          setSelectedMaxDiffQuestion={setSelectedMaxDiffQuestion}
          selectedMaxDiffQuestion={selectedMaxDiffQuestion}
        />
        <NewMaxDiffSubgroupQuestionsSelector
          loading={loading}
          questions={questions}
          setSelectedSubgroupQuestions={setSelectedSubgroupQuestions}
          selectedSubgroupQuestions={selectedSubgroupQuestions}
        />
        <NewMaxDiffReachThresholdSelector
          loading={loading}
          setReachThreshold={setReachThreshold}
          reachThreshold={reachThreshold}
        />
        <NewMaxDiffLowSampleWarningThresholdSelector
          loading={loading}
          setLowSampleWarningThreshold={setLowSampleWarningThreshold}
          lowSampleWarningThreshold={lowSampleWarningThreshold}
        />
        <NewMaxDiffEnabledToolsSelector
          loading={loading}
          availableTools={availableTools}
          enabledTools={enabledTools}
          setEnabledTools={setEnabledTools}
          areThereSubgroupQuestions={selectedSubgroupQuestions.length > 0}
        />
        <MaxDiffSubmitButton
          loading={loading}
          selectedMaxDiffQuestion={selectedMaxDiffQuestion}
          maxDiffName={maxDiffName}
        />
      </form>
    </Dialog>
  )
}
