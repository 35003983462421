import { useUser } from '../contexts/UserProvider'
import SegmentationExplorerDialog from './SegmentationExplorerDialog'
import SetWeightsDialog from './SetWeightsDialog'

export default function ToolPage({ tool, menu, footer }) {
  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const isResearcher = user.role === 'researcher'
  const isAnalystOrResearcher = isAnalyst || isResearcher

  return (
    <div id="page-container">
      {isAnalystOrResearcher && (
        <>
          <SetWeightsDialog />
          <SegmentationExplorerDialog />
        </>
      )}
      {menu && menu}
      <main
        style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
        id="main"
      >
        <div
          id="content-container-parent"
          style={{
            width: '100vw',
            backgroundColor: 'rgb(252,252,252)',
            overflow: 'hidden',
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            height: `calc(100vh - ${24 * (!!menu + !!footer) + 2}px)`,
            // height: `calc(100vh - 48px)`,
          }}
        >
          <div
            id="content-container-child"
            style={{
              height: '100%',
            }}
          >
            {/* <div id="content-container">{children}</div> */}
            <div
              id="content-container"
              // style={{ height: `calc(100vh - ${24 * (menu + true)}px)` }}
            >
              {tool}
            </div>
          </div>
        </div>
      </main>
      {footer ? <footer id="status-footer">{footer}</footer> : null}
    </div>
  )
}
