import { Button, Classes, Icon, Popover } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { memo } from 'react'
import { parentIcon } from '../icons/customIcons'
export default function sourceColumn(options) {
  return {
    component: SourceComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) => (rowData ? `${rowData}` : ''),
    pasteValue: ({ value }) =>
      value
        ? ['true', 'false'].includes(value.toLowerCase())
          ? value.toLowerCase() === 'true'
            ? true
            : false
          : null
        : null,
  }
}

const SourceComponent = memo(
  /**
   *
   * @param {CellProps} param0
   * @returns
   */
  ({ focus, columnData, rowData, setRowData }) => {
    return rowData.dependents.length || rowData.sources.length ? (
      <div className="source-buttons-container">
        {rowData.sources?.length ? (
          <div className="source-button-container">
            <Popover2
              content={
                <div className="popover-table-container">
                  <table className="popover-table">
                    <thead>
                      <tr>
                        <th>Source{rowData.sources.length !== 1 ? 's' : ''}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData.sources.map(source => (
                        <tr>
                          <td>{source}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              interactionKind="hover"
            >
              <Button
                icon={<Icon icon="crown" size={10} />}
                small
                className="source-button"
              />
            </Popover2>
          </div>
        ) : (
          ''
        )}
        {rowData.dependents?.length ? (
          <div className="source-button-container">
            <Popover2
              content={
                <div className="popover-table-container">
                  <table className="popover-table">
                    <thead>
                      <tr>
                        <th>
                          Dependent{rowData.dependents.length !== 1 ? 's' : ''}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData.dependents.map(dependent => (
                        <tr>
                          <td>{dependent}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              interactionKind="hover"
            >
              <Button
                icon={<Icon icon="one-to-many" size={10} />}
                small
                className="source-button"
              />
            </Popover2>
          </div>
        ) : (
          ''
        )}
      </div>
    ) : (
      ''
    )
  }
)
