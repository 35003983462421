import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useUser } from '../contexts/UserProvider'

export default function LoginPage() {
  const [searchParams] = useSearchParams()
  const magicRefreshToken = searchParams.get('t')

  const { login } = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      if (!magicRefreshToken) {
        console.log('no token found')
        return
      }
      const result = await login(magicRefreshToken)
      if (result === 'fail') {
        alert('Invalid magic link, try requesting another one')
      } else if (result === 'ok') {
        let next = '/'
        if (location.state && location.state.next) {
          next = location.state.next
        }
        navigate(next)
      }
    })()
  })

  return 'Logging in...'
}
