import React, { createContext, useContext, useState } from 'react'
import { Dialog, Button, Classes } from '@blueprintjs/core'

const InstructionsDialogContext = createContext()

export default function InstructionsDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState(null)

  const value = {
    openDialog: (newContent, newTitle = null) => {
      setContent(newContent)
      setTitle(newTitle)
      setIsOpen(true)
    },
    closeDialog: () => {
      setIsOpen(false)
      setContent(null)
      setTitle(null)
    },
  }

  return (
    <InstructionsDialogContext.Provider value={value}>
      {children}

      {/* The Dialog with Instructions */}
      <Dialog
        icon="info-sign"
        title={title || 'Instructions'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ maxWidth: '80vw', maxHeight: '80vh', width: 'auto' }}
      >
        <div className={Classes.DIALOG_BODY} style={{ overflow: 'auto' }}>
          {content}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </div>
        </div>
      </Dialog>
    </InstructionsDialogContext.Provider>
  )
}

export function useInstructionsDialog() {
  const context = useContext(InstructionsDialogContext)
  if (!context) {
    throw new Error(
      `useInstructionsDialog must be used within a InstructionsDialogProvider`
    )
  }
  return context
}
