import { isString } from './utils/strings'

// https://github.com/miguelgrinberg/react-microblog/blob/main/src/MicroblogApiClient.js
export default class ApiClient {
  constructor(onError) {
    this.onError = onError
  }

  async request(options) {
    let response = this.requestInternal(options)
    if (response.status === 401 && options.url !== '/tokens') {
      const refreshResponse = await this.put('/tokens', {
        token: localStorage.getItem('accessToken'),
      })
      if (refreshResponse.ok) {
        localStorage.setItem('accessToken', refreshResponse.body.token)
        response = this.requestInternal(options)
      }
    }
    if (response.status >= 500 && this.onError) {
      this.onError(response)
    }
    return response
  }

  async requestInternal(options) {
    let query = new URLSearchParams(options.query || {}).toString()
    if (query !== '') {
      query = '?' + query
    }

    let response
    let formData
    if (options?.body instanceof File) {
      formData = new FormData()
      formData.append('file', options.body)
    }
    try {
      response = await fetch('/api' + options.url + query, {
        method: options.method,
        headers: {
          ...(formData ? {} : { 'Content-Type': 'application/json' }),
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          'ngrok-skip-browser-warning': 'true',
          ...options.headers,
        },
        credentials: options.url === '/tokens' ? 'include' : 'omit',
        body: options.body
          ? formData
            ? formData
            : JSON.stringify(options.body)
          : null,
      })
    } catch (error) {
      response = {
        ok: false,
        status: 500,
        json: async () => {
          return {
            code: 500,
            message: 'The server is unresponsive',
            description: error.toString(),
          }
        },
      }
    }

    const isJSON = response.headers.get('content-type') === 'application/json'
    return {
      ok: response.ok,
      status: response.status,
      body:
        response.status !== 204
          ? isJSON
            ? await response.json()
            : response
          : null,
    }
  }

  async get(url, query, options) {
    return this.request({ method: 'GET', url, query, ...options })
  }

  async post(url, body, options) {
    return this.request({ method: 'POST', url, body, ...options })
  }

  async put(url, body, options) {
    return this.request({ method: 'PUT', url, body, ...options })
  }

  async delete(url, options) {
    return this.request({ method: 'DELETE', url, ...options })
  }

  async verifyMagic(magicToken) {
    const response = await this.post('/verify_magic_token', {
      token: magicToken,
    })
    if (!response.ok) {
      return response.status === 401 ? 'fail' : 'error'
    }
    localStorage.setItem('accessToken', response.body.token)
    return 'ok'
  }

  logout() {
    localStorage.setItem('accessToken', null)
  }

  isAuthenticated() {
    return localStorage.getItem('accessToken') !== null
  }
}

export const errorObjectToString = obj => {
  return Object.entries(obj)
    .map(
      ([field, errors]) =>
        `${field}: ${
          Array.isArray(errors)
            ? errors.join(', ')
            : isString(errors)
            ? errors
            : Object.values(errors).join(', ')
        }`
    )
    .join(', ')
}
