import { useContext } from 'react'
import { DataContext } from '../../contexts/DataProvider'
import { SurveyContext } from '../../contexts/SurveyProvider'
import './columns.css'
import ContextMenuOption from './ContextMenuOption'
// import PopoverOption from './PopoverOption'

const limit = 50

function cleanLabel(label, name) {
  return label
    .replace(name, '')
    .replace('(' + name.split('_')[0].toLowerCase() + ')', '')
    .replace('(' + name.split('_')[0].toUpperCase() + ')', '')
}

export default function Columns() {
  const { selectedColumns, setSelectedColumns } = useContext(DataContext)
  const { variables: columns } = useContext(SurveyContext)

  const handleColumnsSelected = e => {
    const { selectedOptions } = e.target
    if (selectedOptions.length > limit) {
      alert(
        `Please limit selection to ${limit} columns (${selectedOptions.length} attempted.)`
      )
      return
    }
    let newSelectedColumns = Array.from(selectedOptions).map(
      option => option.value
    )
    const { href } = window.location
    if (href.includes('/explorer')) {
      // include responseid column in variable explorer
      if (!newSelectedColumns.includes(`${columns[0].id}`)) {
        newSelectedColumns = [`${columns[0].id}`, ...newSelectedColumns]
      }
    }
    setSelectedColumns(newSelectedColumns)
  }

  return (
    <>
      {columns === undefined ? (
        'loading...'
      ) : columns === null ? (
        <p>Could not retrieve columns.</p>
      ) : (
        <nav className="hide-multiselect-scrollbar">
          {columns.length === 0 ? (
            <p>There are no columns.</p>
          ) : (
            <select
              multiple
              onChange={handleColumnsSelected}
              size={columns.length}
              value={selectedColumns}
            >
              {columns.map((column, i) => (
                <ContextMenuOption
                  key={i}
                  column={column.id}
                  offset={[225, -17]}
                >
                  {column.name}
                  {column.label &&
                  column.label.toLowerCase() !== column.name.toLowerCase()
                    ? ` : ${cleanLabel(column.label, column.name)}`
                    : ''}
                </ContextMenuOption>
                // <PopoverOption
                //   key={i}
                //   offset={[225, -17]}
                //   column={column.name}
                //   isOpen={tooltip === column.name}
                //   onInteraction={isOpen => {
                //     if (tooltipTimeout) clearTimeout(tooltipTimeout)
                //     tooltipTimeout = setTimeout(
                //       () =>
                //         setTooltip(prev =>
                //           isOpen
                //             ? prev !== column.name
                //               ? column.name
                //               : prev
                //             : prev === column.name
                //             ? null
                //             : prev
                //         ),
                //       100
                //     )
                //   }}
                //   renderTarget={props => (
                //     <option value={column.name} {...props}>
                //       {column.name}{' '}
                //       {column.label &&
                //       column.label.toLowerCase() !== column.name.toLowerCase()
                //         ? ` : ${cleanLabel(column.label, column.name)}`
                //         : ''}
                //     </option>
                //   )}
                // />
              ))}
            </select>
          )}
        </nav>
      )}
    </>
  )
}
