import Menu from '../components/Menu'
import ToolPage from '../components/ToolPage'
import Tasks from '../components/Tasks'
import { useDocumentTitle } from '../utils/hooks'

export default function TasksPage() {
  const pageName = 'Tasks'
  useDocumentTitle(pageName)
  return <ToolPage menu={<Menu pageName={pageName} />} tool={<Tasks />} />
}
