import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { useEffect, useCallback, useMemo } from 'react'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import AverageUtilitiesGraph from './AverageUtilitiesGraph'
import chroma from 'chroma-js'
import { H2 } from '@blueprintjs/core'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'
import { ProgressToaster, AppToaster } from '../toaster'
import LoadingSpinner, { LoadingIcon } from '../LoadingSpinner'
import { downloadFile } from '../../utils/downloadFile'
import { useSurvey } from '../../contexts/SurveyProvider'
import { useInstructionsDialog } from '../../contexts/InstructionsDialogProvider'
import { AverageUtilitiesInstructions } from './Instructions'

export default function AverageUtilities({
  exportFileName = 'AverageUtilities',
}) {
  const { maxDiffName, selectedGroupVariables, loading } = useMaxDiff()
  const { maxDiffId } = useParams()
  const api = useApi()
  const { weights } = useSurvey()
  const { openDialog } = useInstructionsDialog()

  const [averageUtilities, setAverageUtilities] = useState()
  const [doSort, setDoSort] = useState(false)

  const exportToExcel = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    const response = await api.post(
      `/maxdiffs/${maxDiffId}/utilities/export_to_excel`,
      { subgroup_variables_ids: selectedGroupVariables }
    )
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [maxDiffId, api, maxDiffName, selectedGroupVariables, exportFileName])

  const menu = useMemo(
    () => [
      {
        label: 'File',
        items: [
          {
            label: 'Export to Excel',
            onClick: exportToExcel,
            icon: 'export',
            disabled: averageUtilities && !averageUtilities.length,
          },
        ],
      },
      {
        label: 'View',
        items: [
          {
            label: 'Sorted',
            onClick: () => setDoSort(!doSort),
            icon: 'sort',
            selected: doSort,
            shouldDismissPopover: false,
          },
        ],
      },
      {
        label: 'Help',
        items: [
          {
            label: 'Instructions',
            shouldDismissPopover: true,
            icon: loading ? <LoadingIcon type="menu" /> : 'help',
            onClick: () => {
              openDialog(<AverageUtilitiesInstructions />, 'Average Scores')
            },
          },
        ],
      },
    ],
    [doSort, exportToExcel, averageUtilities, openDialog]
  )

  useRegisterMaxDiffMenu('AverageUtilities', menu)

  useEffect(() => {
    ;(async () => {
      const response = await api.post(`/maxdiffs/${maxDiffId}/utilities`, {
        subgroup_variables_ids: selectedGroupVariables,
        sort: doSort,
      })
      if (response.ok) {
        setAverageUtilities(response.body.utilities.reverse())
      } else {
        console.error(response)
      }
    })()
  }, [maxDiffId, api, selectedGroupVariables, doSort, weights])

  const colorScale = chroma.scale('PRGn').padding(-2).domain([-50, 50])

  return (
    <div className="average-utilities">
      {averageUtilities === undefined ? (
        <LoadingSpinner />
      ) : averageUtilities.length ? (
        <AverageUtilitiesGraph
          series={averageUtilities}
          colorScale={colorScale}
        />
      ) : (
        <H2>No data to display</H2>
      )}
    </div>
  )
}
