import { useEffect, useState } from 'react'
import { useWindow } from '../contexts/WindowProvider'
import {
  Button,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  Icon,
  Spinner,
  Text,
  HTMLTable,
  ControlGroup,
} from '@blueprintjs/core'
import { AppToaster } from '../components/toaster'
import { Select2 } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'
import { useApi } from '../contexts/ApiProvider'
import highlightText from '../utils/highlightText'
import './NewConfirmitSurveyDialog.css'

/**
 * Dialog to create a new survey from the Confirmit API
 * @returns {JSX.Element} - JSX element
 */
export default function NewConfirmitSurveyDialog() {
  const {
    dialogOpen,
    setDialogOpen,
    loading,
    setLoading,
    setSurveys,
    surveys,
  } = useWindow()

  const [confirmitSurveys, setConfirmitSurveys] = useState([])
  const [selectedConfirmitSurvey, setSelectedConfirmitSurvey] = useState(null)

  const api = useApi()

  const onDialogClose = () => {
    setDialogOpen(null)
    setSelectedConfirmitSurvey(null)
  }

  const fetchConfirmitSurveys = async () => {
    setLoading(true)
    const response = await api.get('/confirmit/surveys')
    if (response.ok) {
      setConfirmitSurveys(response.body)
    } else {
      const message = response.body.messages.json
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  const createNewConfirmitSurvey = async () => {
    setLoading(true)
    // If there's a survey with that same name, append and index
    // between parentheses to the name
    let name = selectedConfirmitSurvey.name
    let i = 1
    while (surveys?.find(survey => survey.name === name)) {
      name = name.replace(/\(\d+\)$/, '') + `(${i++})`
    }

    const response = await api.post('/confirmit/new', {
      confirmit_survey_id: selectedConfirmitSurvey.id,
      survey_name: name,
    })
    if (response.ok) {
      const survey = response.body.survey
      setSurveys(prev => {
        return prev ? [...prev, survey] : [survey]
      })
      AppToaster.show({
        message: 'Survey created',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
        timeout: 3000,
      })
    }
    setLoading(false)
    setSelectedConfirmitSurvey(null)
    setDialogOpen(null)
  }

  return (
    <Dialog
      isOpen={dialogOpen === 'NewConfirmitSurveyDialog'}
      onClose={onDialogClose}
      canOutsideClickClose={false}
      title="New Confirmit Survey"
      icon="style"
      onOpening={fetchConfirmitSurveys}
      style={{ width: '60%' }}
    >
      <form
        onSubmit={ev => {
          ev.preventDefault()
          createNewConfirmitSurvey()
        }}
        className={Classes.DIALOG_BODY}
      >
        <ControlGroup
          fill={true}
          vertical={false}
          style={{ marginBottom: '10px' }}
        >
          <ConfirmitSurveySelector
            confirmitSurvey={confirmitSurveys}
            setSelectedConfirmitSurvey={setSelectedConfirmitSurvey}
            selectedConfirmitSurvey={selectedConfirmitSurvey}
            loading={loading}
          />
          <Button
            type="submit"
            disabled={loading || selectedConfirmitSurvey === null}
            loading={loading}
            text="Sync..."
            style={{ marginLeft: '10px' }}
          />
        </ControlGroup>
        {selectedConfirmitSurvey && (
          <ConfirmitSurveyStats
            confirmitSurveyId={selectedConfirmitSurvey.id}
          />
        )}
      </form>
    </Dialog>
  )
}

/**
 * A dialog to display stats about a Confirmit survey
 * @param {string} confirmitSurveyId - Confirmit survey ID
 * @returns {JSX.Element} - JSX element
 */
export function ConfirmitSurveyStatsDialog() {
  const { dialogOpen, setDialogOpen } = useWindow()

  const isOpen = dialogOpen
    ? dialogOpen.startsWith('ConfirmitSurveyStatsDialog - ')
    : false
  const confirmitSurveyId = isOpen
    ? dialogOpen.replace('ConfirmitSurveyStatsDialog - ', '')
    : null

  const handleClose = () => {
    setDialogOpen(null)
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} style={{ width: '60%' }}>
      {confirmitSurveyId && (
        <ConfirmitSurveyStats
          confirmitSurveyId={confirmitSurveyId}
          showSurveyName={true}
        />
      )}
    </Dialog>
  )
}

/**
 * Display stats about a Confirmit survey
 * @param {string} confirmitSurveyId - Confirmit survey ID
 * @returns {JSX.Element} - JSX element
 */
export function ConfirmitSurveyStats({
  confirmitSurveyId,
  width = '100%',
  showSurveyName = false,
}) {
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [surveyData, setSurveyData] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (!confirmitSurveyId) return
      setLoading(true)
      const response = await api.get(`/confirmit/surveys/${confirmitSurveyId}`)
      if (response.ok) {
        setSurveyData(response.body)
      } else {
        console.log(response)
        const message = response.body.messages.json
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    })()
  }, [confirmitSurveyId])

  if (loading || surveyData === null) {
    return <Spinner />
  }

  const {
    name,
    status,
    creatorFullName,
    created,
    lastModified,
    languages,
    defaultLanguage,
    description,
  } = surveyData

  return (
    <HTMLTable striped style={{ width }} className="confirmit-stats-table">
      <tbody>
        {showSurveyName && (
          <tr>
            <td>Survey name</td>
            <td>{name}</td>
          </tr>
        )}
        <tr>
          <td>Confirmit ID</td>
          <td>{confirmitSurveyId}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td style={{ color: statusColour(status) }}>{status}</td>
        </tr>
        <tr>
          <td>Creator</td>
          <td>{creatorFullName}</td>
        </tr>
        <tr>
          <td>Created</td>
          <td>{new Date(created).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Last modified</td>
          <td>{new Date(lastModified).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Languages</td>
          <td>{languages.join(', ')}</td>
        </tr>
        <tr>
          <td>Default language</td>
          <td>{defaultLanguage}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{description}</td>
        </tr>
      </tbody>
    </HTMLTable>
  )
}

/**
 * Select a Confirmit survey
 * @param {Object[]} confirmitSurvey - Confirmit survey data from the API
 * @param {function} setSelectedConfirmitSurvey - Set the selected Confirmit survey
 * @param {Object} selectedConfirmitSurvey - The selected Confirmit survey
 * @returns {JSX.Element} - JSX element
 *
 */
function ConfirmitSurveySelector({
  confirmitSurvey,
  setSelectedConfirmitSurvey,
  selectedConfirmitSurvey,
  ...props
}) {
  const handleSelect = item => {
    setSelectedConfirmitSurvey(item)
  }

  const renderSurvey = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    const created = new Date(item.created)

    const color = statusColour(item.status)

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        icon={<Icon icon="document" color={color} />}
        key={item.id}
        onClick={handleClick}
        text={highlightText(item.name, query)}
        title={item.name}
        label={
          // Make both texts appear on the same line
          <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
            <Text className={Classes.TEXT_SMALL}>
              {created.toLocaleDateString()}
            </Text>
            <Text className={Classes.TEXT_SMALL} style={{ color: color }}>
              {item.status}
            </Text>
          </FormGroup>
        }
      />
    )
  }

  const filterSurvey = (query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
  }

  return (
    <Select2
      items={confirmitSurvey}
      itemRenderer={renderSurvey}
      itemPredicate={filterSurvey}
      onItemSelect={handleSelect}
      noResults={<MenuItem disabled text="No results." />}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      fill={true}
    >
      <Button
        text={
          selectedConfirmitSurvey
            ? selectedConfirmitSurvey.name
            : 'Select a survey...'
        }
        rightIcon="double-caret-vertical"
        fill={true}
        loading={props.loading}
      />
    </Select2>
  )
}

function statusColour(status) {
  switch (status) {
    case 'production':
      return Colors.GREEN3
    case 'closed':
      return Colors.RED3
    case 'design':
      return Colors.ORANGE3
    default:
      return Colors.GRAY3
  }
}
