import React, { useCallback } from 'react'
import {
  MenuDivider,
  MenuItem,
  Menu as BpMenu,
  Button,
  Icon,
  Tag,
  NavbarGroup,
  Tabs,
  Tab,
} from '@blueprintjs/core'
import '../components/VizPage.css'
import { IconNames } from '@blueprintjs/icons'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Link, useSearchParams } from 'react-router-dom'
import LoadingSpinner, { LoadingIcon } from '../components/LoadingSpinner'
import Menu from '../components/Menu'
import StatusBar from '../components/StatusBar'
import ToolPage from '../components/ToolPage'
import VizUI from '../components/VizUI'
import { useSurvey } from '../contexts/SurveyProvider'
import { useViz } from '../contexts/VizProvider'
import { useWindow } from '../contexts/WindowProvider'
import SidebarLayout from '../layouts/SidebarLayout'
import { useDocumentTitle } from '../utils/hooks'
import StubPanel from '../components/StubPanel'
import SlidesPanel from '../components/SlidesPanel'
import { AppToaster } from '../components/toaster'

export default function VizPage() {
  const [searchParams] = useSearchParams()
  const tableType = searchParams.get('tableType')
  const {
    filter,
    tableN,
    stub,
    resetStub,
    banner,
    sidebarTab,
    setSidebarTab,
    questions,
    stubFilterName,
    setStubFilterName,
  } = useViz()
  const { currentSurvey, createReport } = useWindow()
  const pageName = `${currentSurvey ? 'Viz' : 'Loading - Viz'}`
  const { currentReportId, setCurrentReportId } = useSurvey()
  useDocumentTitle(pageName)

  const handleAddReport = async () => {
    const name = prompt('Enter a name for the report')
    const report = await createReport({ name })
    if (report) {
      setCurrentReportId(report.id)
    }
  }

  return (
    <ToolPage
      menu={
        <Menu
          pageName={tableType[0].toUpperCase() + tableType.slice(1) + 'tab'}
        >
          <FileMenu tableType={tableType} />
          <EditMenu />
          <ViewMenu tableType={tableType} />
          <WindowMenu />
          <HelpMenu />
        </Menu>
      }
      tool={
        currentSurvey ? (
          <SidebarLayout
            footer
            sidebar={
              questions ? (
                <div style={{ padding: 6 }} id="viz-tabs-container">
                  <Tabs
                    id="viz-tabs"
                    selectedTabId={sidebarTab}
                    fill
                    className="tabs"
                    onChange={newTabId => setSidebarTab(newTabId)}
                  >
                    <Tab
                      id="stubs"
                      icon={
                        !stubFilterName
                          ? IconNames.LIST
                          : stubFilterName === 'Recodes'
                          ? IconNames.SWAP_HORIZONTAL
                          : stubFilterName === 'Original'
                          ? IconNames.FloatingPoint
                          : IconNames.INHERITED_GROUP
                      }
                      panel={<StubPanel banner={banner} />}
                    >
                      <Popover2
                        minimal
                        placement="bottom-start"
                        content={
                          <BpMenu>
                            <MenuItem
                              text={'All'}
                              icon="list"
                              onClick={() => setStubFilterName()}
                              labelElement={
                                <Icon
                                  icon={
                                    !stubFilterName ? 'small-tick' : 'blank'
                                  }
                                />
                              }
                            />
                            <MenuItem
                              text={'Original'}
                              icon="floating-point"
                              onClick={() => setStubFilterName('Original')}
                              labelElement={
                                <Icon
                                  icon={
                                    stubFilterName === 'Original'
                                      ? 'small-tick'
                                      : 'blank'
                                  }
                                />
                              }
                            />
                            <MenuItem
                              text={'Recodes'}
                              icon="swap-horizontal"
                              onClick={() => setStubFilterName('Recodes')}
                              labelElement={
                                <Icon
                                  icon={
                                    stubFilterName === 'Recodes'
                                      ? 'small-tick'
                                      : 'blank'
                                  }
                                />
                              }
                            />
                            <MenuItem
                              text={'Solutions'}
                              icon="inherited-group"
                              onClick={() => setStubFilterName('Solutions')}
                              labelElement={
                                <Icon
                                  icon={
                                    stubFilterName === 'Solutions'
                                      ? 'small-tick'
                                      : 'blank'
                                  }
                                />
                              }
                            />
                          </BpMenu>
                        }
                      >
                        <Button
                          alignText="left"
                          rightIcon="caret-down"
                          text={'Stubs'}
                          className="tab-menu-button"
                          minimal
                        />
                      </Popover2>
                    </Tab>
                    <Tab
                      // title="Slides"
                      id="slides"
                      panel={<SlidesPanel />}
                    >
                      <Popover2
                        minimal
                        placement="bottom-start"
                        content={
                          <BpMenu>
                            {currentSurvey.reports.map((report, i) => (
                              <MenuItem
                                key={i}
                                text={report.name}
                                onClick={() => setCurrentReportId(report.id)}
                                labelElement={
                                  <Icon
                                    icon={
                                      currentReportId === report.id
                                        ? 'small-tick'
                                        : 'blank'
                                    }
                                  />
                                }
                              />
                            ))}
                            {currentSurvey?.reports?.length ? (
                              <MenuDivider />
                            ) : null}
                            <MenuItem
                              icon="plus"
                              text="New Report"
                              onClick={handleAddReport}
                            />
                          </BpMenu>
                        }
                      >
                        <Button
                          alignText="left"
                          rightIcon="caret-down"
                          text={'Report'}
                          className="tab-menu-button"
                          minimal
                        />
                      </Popover2>
                    </Tab>
                  </Tabs>
                </div>
              ) : null
            }
            content={<VizUI />}
          />
        ) : (
          <LoadingSpinner footer />
        )
      }
      footer={
        <StatusBar filter={filter} tableN={tableN} showFilter={true}>
          <NavbarGroup>
            <Tag
              minimal
              icon={
                <Icon
                  icon="property"
                  size={12}
                  // className={stub ? 'enabled-status-bar-element' : null}
                />
              }
              onRemove={() => resetStub()}
            >
              {stub.length} stub
              {stub.length !== 1 ? 's' : ''}
            </Tag>
          </NavbarGroup>
        </StatusBar>
      }
    />
  )
}

function FileMenu({ tableType }) {
  const {
    requestVizExport,
    canExportToExcel,
    createFieldReport,
    setSidebarTab,
    hasFieldReport,
  } = useViz()
  const { setCurrentReportId } = useSurvey()
  const { loading, setLoading } = useWindow()

  // If this is a crosstab, add the option to create a new Field Crosstab report

  const isCrosstab = tableType === 'cross'

  const createFieldReportClick = useCallback(async () => {
    setLoading(true)
    const report = await createFieldReport()
    if (report) {
      setSidebarTab('slides')
      setCurrentReportId(report.id)

      AppToaster.show({
        message: 'Field Report Created',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      AppToaster.show({
        message: 'Error creating Field Report',
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }, [createFieldReport, setCurrentReportId, setSidebarTab, setLoading])

  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          <MenuItem
            text="Download as Excel"
            icon={!loading ? 'download' : <LoadingIcon type="menu" />}
            onClick={requestVizExport}
            disabled={!canExportToExcel || loading}
          />
          {isCrosstab && <MenuDivider />}
          {isCrosstab && (
            <Tooltip2
              content={'There is already a Field Report in this Survey'}
              disabled={!hasFieldReport}
            >
              <MenuItem
                text="Add Field Report..."
                icon={!loading ? 'add' : <LoadingIcon type="menu" />}
                onClick={createFieldReportClick}
                disabled={loading || hasFieldReport}
              />
            </Tooltip2>
          )}
        </BpMenu>
      }
    >
      <Button text={'File'} />
    </Popover2>
  )
}

function EditMenu() {
  const { setSurveyDialogOpen } = useSurvey()
  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          <MenuItem
            text="Subgroup"
            icon="filter"
            onClick={() => setSurveyDialogOpen('SetSubgroupDialog')}
          />
          <MenuItem
            text="Weights"
            icon="database"
            onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
          />
        </BpMenu>
      }
    >
      <Button text={'Edit'} />
    </Popover2>
  )
}

function ViewMenu({ tableType }) {
  const { settings, setSettings } = useWindow()
  const { stub, banner } = useViz()

  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          {tableType === 'cross'
            ? [
                <MenuItem
                  key="graph"
                  disabled={banner.length > 1 || stub.length > 1}
                  text={
                    <span className={settings?.chart ? 'active-submenu' : null}>
                      Graph
                    </span>
                  }
                  shouldDismissPopover={false}
                >
                  <MenuItem
                    icon="grouped-bar-chart"
                    text={'Bar Chart'}
                    shouldDismissPopover={false}
                    disabled={banner.length > 1 || stub.length > 1}
                    onClick={() =>
                      setSettings(prev => ({
                        ...prev,
                        chart: prev?.chart !== 'bar' ? 'bar' : null,
                      }))
                    }
                    labelElement={
                      <Icon
                        icon={
                          settings?.chart === 'bar' ? 'small-tick' : 'blank'
                        }
                      />
                    }
                  />
                  <MenuItem
                    icon="pie-chart"
                    text={'Pie Chart'}
                    disabled={banner.length > 1 || stub.length > 1}
                    shouldDismissPopover={false}
                    onClick={() =>
                      setSettings(prev => ({
                        ...prev,
                        chart: prev?.chart !== 'pie' ? 'pie' : null,
                      }))
                    }
                    labelElement={
                      <Icon
                        icon={
                          settings?.chart === 'pie' ? 'small-tick' : 'blank'
                        }
                      />
                    }
                  />
                </MenuItem>,
                <MenuDivider key="div1" />,
                <MenuItem
                  key="counts"
                  text={'Counts'}
                  icon="numerical"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      percentages: false,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={!settings.percentages ? 'small-tick' : 'blank'}
                    />
                  }
                />,
                <MenuItem
                  key="percentages"
                  text="Percentages"
                  icon="percentage"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      percentages: true,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={settings.percentages ? 'small-tick' : 'blank'}
                    />
                  }
                />,
                <MenuDivider key="div2" />,
                <MenuItem
                  key="highlighting"
                  text="Cell Highlighting"
                  icon="color-fill"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      highlighting: !prev.highlighting,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={settings.highlighting ? 'small-tick' : 'blank'}
                    />
                  }
                />,
                <MenuItem
                  key="stat-testing"
                  text="Stat Testing"
                  icon="lab-test"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      statTesting: !prev.statTesting,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={settings.statTesting ? 'small-tick' : 'blank'}
                    />
                  }
                />,
              ]
            : [
                <MenuItem
                  key="indices"
                  text="Show Index Tables"
                  // icon="numerical"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      showIndexTabs: !prev.showIndexTabs,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={settings.showIndexTabs ? 'small-tick' : 'blank'}
                    />
                  }
                />,
                <MenuItem
                  key="means"
                  text="Show Mean Tables"
                  // icon="numerical"
                  shouldDismissPopover={false}
                  onClick={() =>
                    setSettings(prev => ({
                      ...prev,
                      showMeanTabs: !prev.showMeanTabs,
                    }))
                  }
                  labelElement={
                    <Icon
                      icon={settings.showMeanTabs ? 'small-tick' : 'blank'}
                    />
                  }
                />,
              ]}
        </BpMenu>
      }
    >
      <Button text={'View'} />
    </Popover2>
  )
}

function WindowMenu() {
  const { setSurveyDialogOpen } = useSurvey()
  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          <MenuItem
            text="Segmentation Explorer"
            icon="inherited-group"
            onClick={() => setSurveyDialogOpen('SegmentationExplorerDialog')}
          />
        </BpMenu>
      }
    >
      <Button text="Window" />
    </Popover2>
  )
}

function HelpMenu() {
  const { loading } = useWindow()
  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          <MenuItem
            text={
              <Link to="/help/viz" target="_blank">
                Viz Page Overview
                <Icon className="new-page-icon" icon="share" size={12} />
              </Link>
            }
            disabled={loading}
          />
          <MenuItem
            text={
              <Link to="/help/netting" target="_blank">
                Creating Nets
                <Icon className="new-page-icon" icon="share" size={12} />
              </Link>
            }
            disabled={loading}
          />
        </BpMenu>
      }
    >
      <Button text={'Help'} />
    </Popover2>
  )
}
