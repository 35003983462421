import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from '@blueprintjs/core'
import { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from '../../contexts/ApiProvider'
import {
  flattenApiVariables,
  SurveyContext,
} from '../../contexts/SurveyProvider'
import { AppToaster } from '../toaster'
import { QUESTION_TYPES } from './VariablesUI'

export default function SetQuestionDialog({ isOpen, onClose }) {
  const {
    selectedVariables,
    setVariables,
    prevVariables,
    setPrevVariables,
    getVariables,
    setQuestions,
  } = useContext(SurveyContext)
  const api = useApi()
  const typeRef = useRef()
  const nameRef = useRef()
  const [loading, setLoading] = useState(false)
  const { surveyId } = useParams()

  const handleSubmit = async () => {
    const data = {
      name: nameRef.current.value,
      type: typeRef.current.value,
      variables: selectedVariables,
    }
    setLoading(true)
    const response = await api.put(`/survey/${surveyId}/set_question`, data)
    if (response.ok) {
      const question = response.body.question
      const deletedQuestions = response.body.deleted_questions.map(q => q.id)
      const isNewQuestion = response.body.is_new_question
      console.log({ question, deletedQuestions })
      const newVariables = prevVariables.map(old_v => {
        const new_v = question.variables.find(({ id }) => old_v.id === id)
        if (new_v) {
          new_v.question = {
            name: question.name,
            type: question.type,
          }
          return flattenApiVariables([new_v])[0]
        }
        return old_v
      })
      setVariables(newVariables)
      setPrevVariables(newVariables)
      // The new questions are the previous ones minus the deleted ones plus the new one
      if (isNewQuestion) {
        setQuestions(prev => [
          ...prev.filter(q => !deletedQuestions.includes(q.id)),
          question,
        ])
      } else {
        setQuestions(prev =>
          prev.map(q => {
            if (q.id === question.id) {
              return question
            }
            return q
          })
        )
      }
      onClose()
    } else {
      console.log(response)
      const message = Object.entries(response.body.messages.json)
        .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
        .join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   // ;(async () => {
  //   //   const data = {
  //   //     name: nameRef.current,
  //   //     type: typeRef.current,
  //   //     variables: selectedVariables,
  //   //   }
  //   //   const response = await api.post('/set_question', data)
  //   //   if (response.ok) {
  //   //     setVariables(prev => {
  //   //       setPrevVariables(prev)
  //   //       return response.body
  //   //     })
  //   //   }
  //   //   // setVariables(response.ok ? response.body : null)
  //   //   // setPrevVariables(response.ok ? response.body : null)
  //   // })()
  // }, [api, selectedVariables, setPrevVariables, setVariables])
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <div>Set Question</div>
          <div
            className={[
              Classes.TEXT_MUTED,
              Classes.TEXT_OVERFLOW_ELLIPSIS,
              'set-question-dialog-subheader',
            ].join(' ')}
          >
            {getVariables(selectedVariables)
              .map(v => v.name)
              .join(', ')}
          </div>
        </>
      }
      icon="add-to-artifact"
    >
      <form
        onSubmit={ev => {
          ev.preventDefault()
          handleSubmit()
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Question Name"
            labelFor="question-name"
            helperText={
              <>
                Choose a unique question name. This will appear in tables and in
                the UI. Spaces and special characters are allowed. (
                <i style={{ paddingTop: 8 }}>Example: S5 - Gender</i>)
              </>
            }
          >
            <InputGroup
              autoFocus
              inputRef={nameRef}
              id="question-name"
              placeholder="Enter question name..."
            />
          </FormGroup>
          <FormGroup
            label="Question Type"
            labelFor="question-type"
            helperText={
              <>
                Choose a question type. This will be used to determine how to
                show the question in tables. (
                <i>Percentage Selected or Average</i>)
              </>
            }
          >
            <HTMLSelect
              defaultValue={
                QUESTION_TYPES.find(([v]) => v === 'multi-select')[0]
              }
              elementRef={typeRef}
              options={QUESTION_TYPES.map(([value, label]) => ({
                value,
                label,
              }))}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onClose} minimal intent="danger">
              Cancel
            </Button>
            <Button type="submit" intent="success" loading={loading}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  )
}
