import {
  MenuItem,
  Menu as BpMenu,
  Button,
  Icon,
  Navbar,
  Classes,
  NavbarGroup,
  NavbarDivider,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useContext } from 'react'
import ToolPage from '../components/ToolPage'
import Menu from '../components/Menu'
import { WindowContext } from '../contexts/WindowProvider'
import { useDocumentTitle } from '../utils/hooks'
import { SurveyContext } from '../contexts/SurveyProvider'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../components/LoadingSpinner'
import GrouperUI from '../components/GrouperUI'
import RacecarDrivers from '../components/RacecarDrivers'

export default function RacecarDriversPage() {
  const { currentSurvey, loading } = useContext(WindowContext)
  const pageName = `${
    currentSurvey ? 'Racecar Drivers' : 'Loading - Racecar Drivers'
  }`
  const { setSurveyDialogOpen, weights } = useContext(SurveyContext)
  useDocumentTitle(pageName)
  return (
    <ToolPage
      menu={
        <Menu pageName={pageName}>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem text="Download as Excel" />
              </BpMenu>
            }
          >
            <Button text={'File'} />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text="Weights"
                  icon="database"
                  onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
                />
              </BpMenu>
            }
          >
            <Button text={'Edit'} />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  icon="inherited-group"
                  text="Segmentation Explorer"
                  onClick={() =>
                    setSurveyDialogOpen('SegmentationExplorerDialog')
                  }
                />
              </BpMenu>
            }
          >
            <Button text="Window" />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text={
                    <Link to="/help/viz" target="_blank">
                      Viz Page Overview
                      <Icon className="new-page-icon" icon="share" size={12} />
                    </Link>
                  }
                  disabled={loading}
                />
                <MenuItem
                  text={
                    <Link to="/help/netting" target="_blank">
                      Creating Nets
                      <Icon className="new-page-icon" icon="share" size={12} />
                    </Link>
                  }
                  disabled={loading}
                />
              </BpMenu>
            }
          >
            <Button text={'Help'} />
          </Popover2>
        </Menu>
      }
      tool={currentSurvey ? <RacecarDrivers /> : <LoadingSpinner footer />}
      footer={
        <Navbar
          id="app-status-menu"
          className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
        >
          <NavbarGroup
            align="right"
            onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
          >
            <NavbarDivider />
            <Button
              small
              minimal
              icon={
                <Icon
                  icon="database"
                  size={12}
                  className={weights ? 'enabled-status-bar-element' : null}
                />
              }
              className={weights ? 'enabled-status-bar-element' : null}
              onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
              text={
                weights
                  ? 'Weights Applied'
                  : weights === null
                  ? 'Unweighted'
                  : '...'
              }
            />
          </NavbarGroup>
        </Navbar>
      }
    />
  )
}
