// import EChartsReact from 'echarts-for-react'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components'

import { CanvasRenderer } from 'echarts/renderers'
import * as echarts from 'echarts/core'

echarts.use([
  BarChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  CanvasRenderer,
])

export default function AverageUtilitiesGraph({
  series,
  colorScale,
  useLabels = true,
}) {
  const valueFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })

  const { maxDiffName } = useMaxDiff()

  const processedData = series.map(value => {
    const v = value.mean - 50
    const position = value.mean > 50 ? 'left' : 'right'
    return {
      value: v,
      variable_label: useLabels && value.label ? value.label : value.name,
      label: {
        position: position,
        align: value.mean > 50 ? 'right' : 'left',
        overflow: 'break',
      },
      itemStyle: {
        color: colorScale(v).hex(),
      },
    }
  })

  const dummySeriesJustForLabels = processedData.map(value => {
    const ret = { ...value }
    ret.label = {
      ...ret.label,
      position: ret.label.position === 'left' ? 'right' : 'left',
      align: ret.label.align === 'left' ? 'right' : 'left',
    }
    return ret
  })

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: params => {
        return ''
      },
    },
    series: [
      {
        type: 'bar',
        data: processedData,
        label: {
          show: true,
          formatter: value => {
            return value.data.variable_label
          },
        },
      },
      {
        type: 'bar',
        barGap: '-100%',
        data: dummySeriesJustForLabels,
        label: {
          show: true,
          formatter: value => {
            return `${valueFormatter.format(value.value + 50)}`
          },
          fontWeight: 'bold',
        },
      },
    ],
    xAxis: {
      type: 'value',
      min: -50,
      max: 50,
      axisLabel: {
        formatter: value => {
          return `${value + 50}`
        },
      },
    },
    yAxis: {
      type: 'category',
      show: false,
      data: series.map(value => value.name),
      axisLabel: {
        show: false,
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: 'Save as image',
          type: 'png',
          name: `${maxDiffName}-average-utilities`,
        },
      },
    },
  }

  return (
    <div className="average-utilities-graph">
      <ReactEChartsCore
        echarts={echarts}
        option={options}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  )
}
