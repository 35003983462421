import { createContext, useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useApi } from './ApiProvider'

export const DataContext = createContext()

export default function DataProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams()
  let searchVariables = searchParams.get('vids')
  if (searchVariables) {
    searchVariables = searchVariables.split(',')
    searchParams.delete('vids')
    setSearchParams(searchParams)
  }

  const [data, setData] = useState()
  const [numRows, setNumRows] = useState()
  const [selectedColumns, setSelectedColumns] = useState(searchVariables || [])
  const { surveyId } = useParams()
  const api = useApi()

  useEffect(() => {
    ;(async () => {
      if (selectedColumns && selectedColumns.length > 0) {
        const response = await api.post(`/survey/${surveyId}/data`, {
          ids: selectedColumns,
        })
        setData(response.ok ? response.body.data : null)
        setNumRows(response.ok ? response.body.num_rows : null)
      } else {
        setData({})
      }
    })()
  }, [api, selectedColumns, surveyId])

  return (
    <DataContext.Provider
      value={{ selectedColumns, setSelectedColumns, data, numRows }}
    >
      {children}
    </DataContext.Provider>
  )
}
