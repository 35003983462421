import {
  Button,
  DialogStep,
  MultistepDialog,
  Menu as BpMenu,
  MenuItem,
  MenuDivider,
  Icon,
  FormGroup,
  InputGroup,
  Classes,
  TagInput,
  DialogBody,
  Text,
  Spinner,
  Colors,
  Intent,
  Tag,
} from '@blueprintjs/core'
import {
  SurveyContext,
  flattenApiVariables,
} from '../../contexts/SurveyProvider'
import { useContext, useState } from 'react'
import { recodeTypes } from '../../pages/VariablesPage'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import './RecodeVariablesDialog.css'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import { Cell, Column, RenderMode, Table2 } from '@blueprintjs/table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { formatValue } from '../crosstab/CrosstabTable'
import { useMemo } from 'react'
import { zScores } from '../../utils/stats'
import { interpolatePRGn } from 'd3-scale-chromatic'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { errorObjectToString } from '../../ApiClient'
import { WindowContext } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'
import useFilteredMultiSelect from '../../hooks/useFilteredMultiSelect'
import { filterQuestions } from '../VizUI'
import { IconNames } from '@blueprintjs/icons'

export default function DummyVariablesDialog({ isOpen, onClose }) {
  const [prevRequest, setPrevRequest] = useState()
  const [error, setError] = useState()
  const { loading, setLoading } = useContext(WindowContext)
  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    variables,
    setQuestions,
    setVariables,
    prevVariables,
    setPrevVariables,
  } = useContext(SurveyContext)

  const type = surveyDialogOpen
    ? recodeTypes.find(
        r => r?.name === surveyDialogOpen.split('-').slice(-1)[0]
      )
    : undefined

  const singleSelectVariables = useMemo(
    () => variables.filter(v => v.question_type === 'single-select'),
    [variables]
  )

  const [
    { selected, query, filteredSelection, filteredOptions },
    { select, reset, queryChange },
  ] = useFilteredMultiSelect(
    { selected: [], query: '' },
    filterQuestions,
    singleSelectVariables
  )

  const [data, setData] = useState()

  const api = useApi()
  const { surveyId } = useParams()
  const hasChanged = useMemo(() => {
    const request = {
      variables: selected.map(v => v.id),
      survey_id: parseInt(surveyId),
    }
    return (
      !prevRequest || JSON.stringify(prevRequest) !== JSON.stringify(request)
    )
  }, [selected, surveyId, prevRequest])

  const handlePreviewRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      survey_id: parseInt(surveyId),
    }
    if (!hasChanged) return
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/dummy/preview`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData(response.body)
      setError()
    } else {
      setError(errorObjectToString(response.body.messages.json))
      setData()
    }
  }

  const handleCreateRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      survey_id: parseInt(surveyId),
    }
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/dummy/create`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData()
      setError()
      reset()
      onClose()
      const variables = response.body
      const questions = variables.map(v => v.question)
      const newFlatVariables = flattenApiVariables(variables)
      const newVariables = [...prevVariables, ...newFlatVariables]
      setQuestions(prev => [...prev, ...questions])
      // setVariables(prev => [...prev, ...newVariables])
      setPrevVariables(newVariables)
      setVariables(newVariables)
      AppToaster.show({
        message: 'Variables created',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  const summaryCellStyles = useMemo(() => {
    if (!data?.summaries) return {}
    return data?.summaries.map(summary => {
      const counts = summary.punches.flatMap(punch =>
        punch.count !== null ? [punch.count] : []
      )
      const countsZ = zScores(counts, true)
      return Object.fromEntries(
        summary.punches.map((punch, i) => {
          const zScore = countsZ?.[i]
          if (zScore === undefined) return [punch.punch, {}]
          const backgroundColor = lighten(interpolatePRGn(zScore), 0.25)
          const color = getContrastText(backgroundColor)
          return [punch.punch, { backgroundColor, color }]
        })
      )
    })
  }, [data?.summaries])

  // const [showAlert, setShowAlert] = useState(false)

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <MultistepDialog
            transitionDuration={surveyDialogOpen ? 0 : 300}
            finalButtonProps={{
              text:
                'Create Variable' + (data?.summaries?.length !== 1 ? 's' : ''),
              intent: 'success',
              disabled: error !== undefined,
              onClick: handleCreateRequest,
            }}
            // style={{ width: '90vw', height: '70vh' }}
            style={{ width: width - 100, height: height - 100 }}
            onClose={onClose}
            onChange={(newStep, prevStep, ev) => {
              // ev.preventDefault()
              // ev.stopPropagation()
              if (newStep === 'preview' || newStep === 'summary') {
                handlePreviewRequest()
              }
            }}
            title={
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    {recodeTypes.map((recode, i) =>
                      recode !== null ? (
                        <MenuItem
                          key={i}
                          icon={recode.icon}
                          text={recode.name}
                          labelElement={
                            <Icon
                              icon={type === recode ? 'small-tick' : 'blank'}
                            />
                          }
                          onClick={() =>
                            setSurveyDialogOpen(
                              `CreateVariableDialog-${recode.name}`
                            )
                          }
                        />
                      ) : (
                        <MenuDivider key={i} />
                      )
                    )}
                  </BpMenu>
                }
              >
                <Button
                  minimal
                  text={type?.name}
                  rightIcon="caret-down"
                  icon={type?.icon}
                />
              </Popover2>
            }
            isOpen={isOpen}
            isCloseButtonShown={true}
          >
            <DialogStep
              id="variables"
              title="Variables"
              panel={
                <DialogBody
                  style={{ display: 'flex' }}
                  id="options-panel"
                  className="options-panel"
                >
                  <div
                    id="variable-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 300, minWidth: 300 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter variables"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={query}
                        onChange={e => queryChange(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={query ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!query}
                              onClick={() => queryChange('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="variables"
                        multiple
                        value={filteredSelection.map(o => o.id)}
                        onChange={e => {
                          // const selectedOption =
                          //   e.target.selectedIndex !== -1
                          //     ? numericVariables[e.target.selectedIndex]
                          //     : null
                          const selected = [...e.target.selectedOptions].map(
                            o => {
                              return filteredOptions.find(
                                v => `${v.id}` === `${o.value}`
                              )
                            }
                          )
                          select(selected)
                        }}
                        style={{
                          // width: '300px',
                          width: '100%',
                          overflowX: 'auto',
                          height: 'calc(50vh - 80px)',
                        }}
                      >
                        {filteredOptions.map(v => {
                          const text = variableOrQuestionToString(v, null)
                          return (
                            <option value={v.id} key={v.id}>
                              {text}
                            </option>
                          )
                        })}
                      </select>
                    </FormGroup>
                    <FormGroup
                      helperText={
                        !selected.length ? (
                          <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                            Select at least 2 variables
                          </Text>
                        ) : null
                      }
                      label="Inputs Selected"
                      labelInfo="(above)"
                      labelFor="inputs-selected"
                    >
                      <TagInput
                        id="inputs-selected"
                        leftIcon={IconNames.PROPERTIES}
                        // values={selected.map(v => v.name)}
                        values={[]}
                        onRemove={(_, index) =>
                          select([
                            ...selected.slice(0, index),
                            ...selected.slice(index + 1),
                          ])
                        }
                        inputProps={{
                          disabled: true,
                          value: '',
                          onFocus: e => e.target.blur(),
                        }}
                        disabled={selected.length === 0}
                        tagProps={{ minimal: true }}
                        intent={!selected.length ? Intent.DANGER : null}
                        placeholder={
                          selected.length ? '' : 'Select inputs above'
                        }
                        rightElement={
                          <div
                            style={{
                              padding: '5px 6px',
                            }}
                          >
                            <Tag
                              minimal={selected.length === 0}
                              icon={
                                <Icon icon={IconNames.PROPERTY} size="10" />
                              }
                              intent={
                                selected.length
                                  ? !selected.length
                                    ? Intent.DANGER
                                    : Intent.PRIMARY
                                  : Intent.NONE
                              }
                              onRemove={() => reset()}
                            >
                              {selected.length} variable
                              {selected.length !== 1 ? 's' : ''}
                            </Tag>
                          </div>
                        }
                      >
                        {selected.map((v, i) => (
                          <Tooltip2
                            key={v.id}
                            popoverClassName="input-tooltip"
                            className="input-tooltip"
                            content={
                              v?.label ? (
                                <span style={{ fontSize: 12 }}>{v.label}</span>
                              ) : null
                            }
                            minimal
                          >
                            <Tag
                              key={v.id}
                              minimal
                              onRemove={() =>
                                select([
                                  ...selected.slice(0, i),
                                  ...selected.slice(i + 1),
                                ])
                              }
                            >
                              {v.name}
                            </Tag>
                          </Tooltip2>
                        ))}
                      </TagInput>
                    </FormGroup>
                  </div>
                  <div id="operations-container" style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        padding: '0 16px',
                        height: '100%',
                      }}
                    >
                      <div id="operators" className={Classes.TEXT_MUTED}>
                        Please note only Single Selects can be dummied. If you
                        want to dummy a variable but it is not available, please
                        change the question type to Single Select.
                      </div>
                    </div>
                  </div>
                </DialogBody>
              }
            />
            <DialogStep
              id="summary"
              panel={
                <DialogBody id="summary-panel" className="summary-panel">
                  {!loading ? (
                    data?.summaries ? (
                      data.summaries.map((summary, s) => (
                        <div className="summary">
                          <h4>
                            {summary.name} — {summary.label}
                          </h4>
                          {summary.punches ? (
                            <table
                              className={[
                                Classes.HTML_TABLE,
                                Classes.HTML_TABLE_CONDENSED,
                              ].join(' ')}
                            >
                              <thead>
                                <tr>
                                  <th />
                                  <th>Count</th>
                                  <th>Percent</th>
                                  <th>Valid Percent</th>
                                  <th>Cum. Percent</th>
                                </tr>
                              </thead>
                              <tbody>
                                {summary.punches.map((punch, p) => {
                                  return (
                                    <tr key={p}>
                                      <th className="numeric-cell">
                                        {punch.punch === null
                                          ? 'Missing'
                                          : punch.punch}
                                      </th>
                                      <td className="numeric-cell">
                                        {punch.count ?? '~'}
                                      </td>
                                      <td
                                        className="numeric-cell"
                                        style={
                                          summaryCellStyles[s][punch.punch]
                                        }
                                      >
                                        {formatValue(punch.percent, 1, true) ??
                                          '~'}
                                      </td>
                                      <td className="numeric-cell">
                                        {formatValue(
                                          punch.valid_percent,
                                          1,
                                          true
                                        ) ?? '~'}
                                      </td>
                                      <td className="numeric-cell">
                                        {formatValue(
                                          punch.cum_percent,
                                          1,
                                          true
                                        ) ?? '~'}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          ) : (
                            'Summary cannot be generated for this variable. This is likely because it has too many unique values.'
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
              title="Summary"
            />
            <DialogStep
              id="preview"
              title="Finalize"
              nextButtonProps={{ disabled: error !== undefined }}
              panel={
                <DialogBody id="preview-panel" className="preview-panel">
                  {!loading ? (
                    data?.data ? (
                      <div style={{ height: height - 330, width: width - 440 }}>
                        <Table2
                          numFrozenColumns={1}
                          numRows={data.data.length}
                          cellRendererDependencies={[data]}
                          renderMode={RenderMode.BATCH}
                          getCellClipboardData={(row, col) =>
                            data.data[row][col]
                          }
                        >
                          {data?.headers.map((column, c) => (
                            <Column
                              key={column}
                              name={column}
                              cellRenderer={rowIndex => (
                                <Cell>{data.data[rowIndex][c]}</Cell>
                              )}
                            />
                          ))}
                        </Table2>
                      </div>
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
            />
          </MultistepDialog>
        )
      }}
    </AutoSizer>
  )
}
