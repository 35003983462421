// Compute the width of a text in pixels
export function textSizeInPixels(text, font) {
  // re-use canvas object for better performance
  const canvas =
    textSizeInPixels.canvas ||
    (textSizeInPixels.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  const height = Math.ceil(
    metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
  )

  return [metrics.width, height]
}
