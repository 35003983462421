import React from 'react'
import { useDocumentTitle } from '../utils/hooks'
import RestoreBackup from '../components/RestoreBackup'
import ToolPage from '../components/ToolPage'
import Menu from '../components/Menu'

export default function RestorePage() {
  const pageName = 'New From Backup'
  useDocumentTitle(pageName)
  return (
    <ToolPage menu={<Menu pageName={pageName} />} tool={<RestoreBackup />} />
  )
}
