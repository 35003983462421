import React from 'react'
import { useParams } from 'react-router-dom'
import ToolPage from '../components/ToolPage'
import MaxDiffUI, { MaxDiffHome } from '../components/maxdiff/MaxDiffUI'
import { useMaxDiffMenu } from '../contexts/MaxDiffMenuProvider'
import { useMaxDiff } from '../contexts/MaxDiffProvider'
import { useSurvey } from '../contexts/SurveyProvider'
import { useUser } from '../contexts/UserProvider'

import {
  Menu as BpMenu,
  Button,
  Classes,
  Icon,
  MenuDivider,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
} from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { useCallback } from 'react'
import Menu from '../components/Menu'
import SubgroupSelector from '../components/maxdiff/SubgroupSelector'

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export default function MaxDiffPage() {
  const { menu } = useMaxDiffMenu()
  const { maxDiffName } = useMaxDiff()

  const { toolId } = useParams()

  const displayingATool = toolId !== undefined

  const buildMenu = useCallback(
    menu => (
      <Menu pageName={maxDiffName}>
        {menu.map((level, i) => (
          <Popover2
            key={i}
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                {level.items.map((item, j) => {
                  if (item === 'divider') {
                    return <MenuDivider key={j} />
                  }
                  return (
                    <MenuItem
                      key={j}
                      text={item.label}
                      icon={item.icon}
                      onClick={item.onClick}
                      disabled={item.disabled}
                      shouldDismissPopover={item.shouldDismissPopover}
                      labelElement={
                        <Icon icon={item.selected ? 'small-tick' : 'blank'} />
                      }
                    />
                  )
                })}
              </BpMenu>
            }
          >
            <Button text={level.label} />
          </Popover2>
        ))}
      </Menu>
    ),
    [maxDiffName, menu]
  )

  return (
    <ToolPage
      menu={buildMenu(menu)}
      tool={displayingATool ? <MaxDiffUI /> : <MaxDiffHome />}
      footer={displayingATool && <MaxDiffFooter />}
    />
  )
}

function MaxDiffStatus() {
  const {
    totalRespondentsInSubgroup,
    totalUnweightedRespondentsInSubgroup,
    totalRespondentsInSurvey,
    displayWeighted,
    setDisplayWeighted,
  } = useMaxDiff()

  const { nRespondents: totalUnweightedRespondentsInSurvey, weights } =
    useSurvey()

  const { user } = useUser()
  const { toolId } = useParams()

  const isClient = user.role === 'client'

  const offerWeights = weights !== null

  const totalInSurvey = !displayWeighted
    ? totalUnweightedRespondentsInSurvey
    : totalRespondentsInSurvey

  const totalInSubgroup = !displayWeighted
    ? totalUnweightedRespondentsInSubgroup
    : totalRespondentsInSubgroup

  const percentage = totalInSurvey === 0 ? 0 : totalInSubgroup / totalInSurvey

  // Just show the number of the survey's respondents
  // if we're on the preference profiles tab
  const text =
    toolId !== 'preference-profiles'
      ? `n=${Math.round(totalInSubgroup)} of ${Math.round(
          totalInSurvey
        )} (${percentageFormatter.format(percentage)})`
      : `n=${Math.round(totalInSurvey)}`

  return (
    <span>
      <Popover2
        content={
          <BpMenu>
            <MenuItem
              text="Weighted totals"
              onClick={() => setDisplayWeighted(true)}
              active={displayWeighted}
            />
            <MenuItem
              text="Unweighted totals"
              onClick={() => setDisplayWeighted(false)}
              active={!displayWeighted}
            />
          </BpMenu>
        }
        placement="bottom"
        minimal
        disabled={!offerWeights || isClient}
      >
        <Button minimal small>
          <Icon icon="gantt-chart" size={12} />
          {text}
        </Button>
      </Popover2>
    </span>
  )
}

function MaxDiffFooter() {
  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const isResearcher = user.role === 'researcher'
  const isAnalystOrResearcher = isAnalyst || isResearcher

  return (
    <Navbar
      id="app-status-menu"
      className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
    >
      <NavbarGroup align="right">
        <NavbarDivider />
        <LowSampleWarningLabel />
        <SubgroupSelector />
        <NavbarDivider />
        <MaxDiffStatus />
        <NavbarDivider />
        {isAnalystOrResearcher && <WeightsButton />}
        {!isAnalystOrResearcher && <WeightsLabel />}
      </NavbarGroup>
    </Navbar>
  )
}

function LowSampleWarningLabel() {
  const {
    totalRespondentsInSubgroup,
    lowSampleWarningThreshold,
    preferenceProfilesWithLowSamples,
  } = useMaxDiff()
  const { toolId } = useParams()

  const inPreferenceProfilesTab = toolId === 'preference-profiles'

  const displaySimpleWarning =
    !inPreferenceProfilesTab &&
    totalRespondentsInSubgroup <= lowSampleWarningThreshold

  const displayPreferenceProfileWarning =
    inPreferenceProfilesTab &&
    preferenceProfilesWithLowSamples !== null &&
    preferenceProfilesWithLowSamples.length > 0

  if (!(displaySimpleWarning || displayPreferenceProfileWarning)) {
    return null
  }

  let message

  if (displaySimpleWarning) {
    message = `The sample size is lower than ${lowSampleWarningThreshold} respondents. Interpret with caution.`
  } else if (displayPreferenceProfileWarning) {
    message = `The sample size is lower than ${lowSampleWarningThreshold} respondents for all samples marked in red. Interpret with caution.`
  }

  return (
    <Tooltip2 content={message}>
      <span
        style={{
          backgroundColor: '#fbb360',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          padding: '0 5px',
          color: 'black',
          fontWeight: '700',
        }}
      >
        WARNING: Low sample size
      </span>
    </Tooltip2>
  )
}

function WeightsButton() {
  const { setSurveyDialogOpen, weights } = useSurvey()
  return (
    <Button
      small
      minimal
      icon={
        <Icon
          icon="database"
          size={12}
          className={weights ? 'enabled-status-bar-element' : null}
        />
      }
      className={weights ? 'enabled-status-bar-element' : null}
      onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
      text={
        weights ? 'Weights Applied' : weights === null ? 'Unweighted' : '...'
      }
    />
  )
}

function WeightsLabel() {
  const { weights } = useSurvey()
  return (
    <span className={weights ? 'enabled-status-bar-element' : null}>
      <Icon
        icon="database"
        size={12}
        className={weights ? 'enabled-status-bar-element' : null}
      />
      {weights ? 'Weights Applied' : weights === null ? 'Unweighted' : '...'}
    </span>
  )
}
