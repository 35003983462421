import { useParams } from 'react-router-dom'
import HelpVideo from '../components/HelpVideo'
import { useDocumentTitle } from '../utils/hooks'

const helpVideos = [
  {
    title: 'Netting',
    id: 'netting',
    src: 'https://www.youtube-nocookie.com/embed/jCHVNCHPHT0',
  },
  {
    title: 'Viz Page Overview',
    id: 'viz',
    src: 'https://www.youtube-nocookie.com/embed/dyA7dHYqyFQ',
  },
  {
    title: 'Surveys / Toolkit Homepage',
    id: 'home',
    src: 'https://www.youtube-nocookie.com/embed/NohlQD3SNFc',
  },
  {
    title: 'Survey Home',
    id: 'survey-home',
    src: 'https://www.youtube-nocookie.com/embed/G9gBIqvNx2M',
  },
  {
    title: 'Variables',
    id: 'variables',
    src: 'https://www.youtube-nocookie.com/embed/wdCkNJXftVk',
  },
  {
    title: 'Data Explorer',
    id: 'data',
    src: 'https://www.youtube-nocookie.com/embed/yGsXjRZZKIg',
  },
  {
    title: 'Toolkit Tutorial',
    id: 'tutorial',
    src: 'https://www.youtube-nocookie.com/embed/videoseries?list=PLFPg9mPYfIDqPUP9PcQFhKUvdTTcYN0tJ',
  },
]

export default function HelpVideoPage() {
  const { videoId } = useParams()
  const video = helpVideos.find(v => v.id === videoId)
  useDocumentTitle(video.title)
  return (
    <div style={{ padding: '0 60px' }}>
      <HelpVideo title={video.title} src={video.src} />
    </div>
  )
}
