import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import { useWindow } from '../contexts/WindowProvider'

export default function Survey() {
  const api = useApi()
  const surveyNameRef = useRef()
  const { setSurveys } = useWindow()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async ev => {
    ev.preventDefault()
    setLoading(true)
    const data = {
      name: surveyNameRef.current.value,
    }

    const response = await api.post(`/survey`, data)
    if (response.ok) {
      const survey = response.body
      setSurveys(prev => {
        console.log({ prev })
        return prev ? [...prev, survey] : [survey]
      })
      navigate('/survey/' + survey.id + '/upload')
    } else {
      console.log(response)
      alert(Object.values(response.body.messages.json).join(', '))
    }
    setLoading(false)
  }

  return (
    <form style={{ maxWidth: 600, padding: 32 }} onSubmit={handleSubmit}>
      <FormGroup
        label="Survey Name"
        labelFor="survey-name"
        helperText={
          <>
            Choose a unique survey name.{' '}
            <i style={{ paddingTop: 8 }}>Example: 22-17100 Loreal Skin Care</i>
          </>
        }
      >
        <InputGroup
          placeholder="22-41789 Future Motion Onewheel"
          inputRef={surveyNameRef}
          id="survey-name"
        />
      </FormGroup>
      <Button type="submit" intent="success" loading={loading}>
        Submit
      </Button>
    </form>
  )
}
