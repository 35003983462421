function isInt(value) {
  return /^\d+$/.test(value)
}
export function isFloat(value) {
  return /^\d+\.\d+$/.test(value)
}
function isNull(value) {
  return value === 'null'
}

export default function tryConvertString(
  value,
  { tryInt = true, tryFloat = true, tryNull = true } = {}
) {
  if (tryInt && isInt(value)) {
    return parseInt(value)
  }
  if (tryFloat && isFloat(value)) {
    return parseFloat(value)
  }
  if (tryNull && isNull(value)) {
    return null
  }
  return value
}
