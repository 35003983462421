import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
} from 'echarts/components'

import { CanvasRenderer } from 'echarts/renderers'
import { useWindow } from '../../contexts/WindowProvider'
import { transpose } from '../../utils/stats'
import chroma from 'chroma-js'
echarts.use([
  BarChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  CanvasRenderer,
  TitleComponent,
])

const bpQualPallette = [
  '#147EB3',
  '#29A634',
  '#D1980B',
  '#D33D17',
  '#9D3F9D',
  '#00A396',
  '#DB2C6F',
  '#8EB125',
  '#946638',
  '#7961DB',
]

const pallette = bpQualPallette.map(c => chroma(c))
export default function Chart({ data }) {
  const {
    settings: { percentages, chart },
  } = useWindow()

  const formatValue = (value, digits = 1) =>
    percentages ? `${(value * 100).toFixed(digits)}%` : value

  const groupNames = [
    data.headers.columns[0].slice(-1)[0][0][0],
    ...data.headers.rows
      .slice(-1)[0]
      .slice(-1)[0]
      .map(([varName]) => varName),
  ]
  const groupedData = data.headers.columns
    .slice(-1)[0]
    .slice(-1)[0]
    .map(([bannerName], c) => [bannerName, ...data.values.map(row => row[c])])

  const barDatasetSource = [groupNames, ...groupedData]
  const pieDatasetSource = transpose(barDatasetSource)
  console.log({ pieDatasetSource, barDatasetSource })

  const labelOption = {
    show: true,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    rotate: 90,
    formatter: o => {
      // console.log(o)
      const value = formatValue(o.data[o.seriesIndex + 1])
      // const value = formatValue(o.value)

      return `${value} - ${o.seriesName}`
      // return `${value}`
    },
    fontSize: 13,
    rich: {
      name: {},
    },
  }

  const defaultSeriesOptions = {
    tooltip: {
      valueFormatter: value => formatValue(value, 2),
    },
  }
  const datasetSource = chart === 'bar' ? barDatasetSource : pieDatasetSource
  console.log({ chart, datasetSource })
  const series =
    chart === 'bar'
      ? Array(datasetSource[0].length - 1)
          .fill()
          .map((_, i) => ({
            type: 'bar',
            label: labelOption,
            ...defaultSeriesOptions,
            name: datasetSource[0][i + 1],
          }))
      : Array(datasetSource.length - 1)
          .fill()
          .map((_, i) => ({
            type: 'pie',
            radius: 50,
            center: [
              `${((i + 1) * 100) / (datasetSource.length - 1) + 10}%`,
              '50%',
            ],
            encode: {
              itemName: datasetSource[0][0],
              value: datasetSource[0][i + 1],
            },
            name: datasetSource[0][i + 1],
            // label: labelOption,
            ...defaultSeriesOptions,
          }))

  const title =
    chart === 'bar'
      ? []
      : Array(datasetSource.length - 1)
          .fill()
          .map((_, i) => ({
            text: datasetSource[0][i + 1],
            left: `${((i + 1) * 100) / (datasetSource.length - 1) + 6}%`,
            top: '65%',
          }))

  const options = {
    toolbox: {
      feature: {
        saveAsImage: {
          title: 'Save as image',
          type: 'png',
          name: `graph`,
        },
      },
    },
    legend: {},
    tooltip: {},
    dataset: {
      source: datasetSource,
    },
    title,
    xAxis: { type: 'category' },
    yAxis: {},
    series,
    color: bpQualPallette,
  }
  // return 'Chart'
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      key={chart}
      style={{
        height: '100%',
        width: '100%',
        minHeight: 500,
      }}
    />
  )
}
