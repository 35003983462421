/**
 * id: Representation for pandas.eval
 * text: Display text for button
 * value: Display text for formula preview
 */
const DECIMAL = { id: '.', text: '.', value: '.', kind: 'numeric' }
const ZERO = { id: '0', text: '0', value: '0', kind: 'numeric', colSpan: 2 }
const ONE = { id: '1', text: '1', value: '1', kind: 'numeric' }
const TWO = { id: '2', text: '2', value: '2', kind: 'numeric' }
const THREE = { id: '3', text: '3', value: '3', kind: 'numeric' }
const FOUR = { id: '4', text: '4', value: '4', kind: 'numeric' }
const FIVE = { id: '5', text: '5', value: '5', kind: 'numeric' }
const SIX = { id: '6', text: '6', value: '6', kind: 'numeric' }
const SEVEN = { id: '7', text: '7', value: '7', kind: 'numeric' }
const EIGHT = { id: '8', text: '8', value: '8', kind: 'numeric' }
const NINE = { id: '9', text: '9', value: '9', kind: 'numeric' }

const ADD = { id: '+', icon: 'plus', value: '+' }
const MINUS = { id: '-', icon: 'minus', value: '-' }
const MULTIPLY = { id: '*', icon: 'small-cross', value: '*' }
const DIVIDE = { id: '/', icon: 'divide', value: '/' }
const EXPONENTIATE = { id: '**', icon: 'key-control', value: '**' }

const LT = { id: '<', icon: 'less-than', value: '<' }
const LTE = { id: '<=', icon: 'less-than-or-equal-to', value: '<=' }
const GT = { id: '>', icon: 'greater-than', value: '>' }
const GTE = { id: '>=', icon: 'greater-than-or-equal-to', value: '>=' }
const EQ = { id: '==', icon: 'equals', value: '=' }
const NEQ = { id: '!=', icon: 'not-equal-to', value: '!=' }

const OPEN_PARENS = { id: '(', text: '(', value: '(' }
const CLOSE_PARENS = { id: ')', text: ')', value: ')' }

const AND = { id: '&', text: '&', value: '&' }
const OR = { id: '|', text: '|', value: '|' }
const NOT = { id: '~', text: '!', value: '!' }
const BACKSPACE = { id: 'backspace', icon: 'key-backspace' }

export const functions = [
  { id: '.isnull()', text: 'isnull()', value: '.isnull()' },
  { id: '.notnull()', text: 'notnull()', value: '.notnull()' },
  { id: '.abs()', text: 'abs()', value: '.abs()' },
  { id: '.exp()', text: 'exp()', value: '.exp()' },
  { id: '.sqrt()', text: 'sqrt()', value: '.sqrt()' },
]

const operators = {
  DECIMAL,
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  ADD,
  MINUS,
  MULTIPLY,
  DIVIDE,
  EXPONENTIATE,
  LT,
  LTE,
  GT,
  GTE,
  EQ,
  NEQ,
  OPEN_PARENS,
  CLOSE_PARENS,
  AND,
  OR,
  NOT,
  BACKSPACE,
}

export const operatorMatrix = [
  [
    operators.ADD,
    operators.LT,
    operators.GT,
    operators.SEVEN,
    operators.EIGHT,
    operators.NINE,
  ],
  [
    operators.MINUS,
    operators.LTE,
    operators.GTE,
    operators.FOUR,
    operators.FIVE,
    operators.SIX,
  ],
  [
    operators.MULTIPLY,
    operators.EQ,
    operators.NEQ,
    operators.ONE,
    operators.TWO,
    operators.THREE,
  ],
  [
    operators.DIVIDE,
    operators.AND,
    operators.OR,
    operators.ZERO,
    operators.DECIMAL,
  ],
  [
    operators.EXPONENTIATE,
    operators.NOT,
    operators.BLANK,
    operators.OPEN_PARENS,
    operators.CLOSE_PARENS,
    operators.BACKSPACE,
  ],
]
