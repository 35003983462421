import ReactEChartsCore from 'echarts-for-react/lib/core'
import { PieChart } from 'echarts/charts'
import {
  GraphicComponent,
  GridComponent,
  ToolboxComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import React from 'react'

import { useRef } from 'react'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { textSizeInPixels } from '../../utils/textSizeInPixels'
echarts.use([
  PieChart,
  GridComponent,
  ToolboxComponent,
  CanvasRenderer,
  GraphicComponent,
])

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

/**
 * Genearates a echarts graph with a pie chart for each subgroup question
 */
export default function PreferenceProfilesGraph({
  selectedClaim,
  subgroupQuestions,
  useLabels = true,
  subtitlesOffset = 60,
  pieWidth = 500,
  padding = { x: 0, y: 0 },
  spacing = { x: 0, y: -50 },
  margin = { left: 100, right: 100, top: 10, bottom: 10 },
}) {
  const chartRef = useRef(null)

  const selectedItem = selectedClaim[0]
  const { maxDiffName, lowSampleWarningThreshold } = useMaxDiff()

  const maxCol = Math.min(3, subgroupQuestions.length) // Max 3 columns
  const maxRow = Math.ceil(subgroupQuestions.length / maxCol)

  const width =
    pieWidth * maxCol +
    padding.x +
    (maxCol - 1) * spacing.x +
    margin.left +
    margin.right

  const height =
    pieWidth * maxRow +
    padding.y +
    (maxRow - 1) * spacing.y +
    margin.top +
    margin.bottom

  const innerRadius = 65 * (pieWidth / 500)
  const outerRadius = 105 * (pieWidth / 500)
  const radii = [innerRadius, outerRadius]

  // Calculate the center of each pie chart in pixel
  const centers = []
  for (let i = 0; i < subgroupQuestions.length; i++) {
    const left = (i % maxCol) * (pieWidth + spacing.x) + padding.x + margin.left
    const top =
      Math.floor(i / maxCol) * (pieWidth + spacing.y) + padding.y + margin.top
    centers.push([left + pieWidth / 2, top + pieWidth / 2])
  }

  const series = subgroupQuestions.map((subgroup, i) => {
    const totalRespondentsReached = subgroup.variables.reduce(
      (acc, cur) => acc + cur.respondents,
      0
    )

    return {
      name: subgroup.name,
      type: 'pie',
      radius: radii,
      center: centers[i],
      itemStyle: {
        borderRadius: 5,
        borderColor: '#fff',
      },
      labelLayout: {
        draggable: true,
        moveOverlap: 'shiftY',
      },
      labelLine: {
        showAbove: false,
        length: 30,
        length2: 20,
      },
      label: {
        position: 'outside',
        overflow: 'break',
        show: true,
        rich: {
          name: {
            fontSize: 14,
            fontWeight: 'normal',
          },
          numbers: {
            fontSize: 14,
            color: '#999',
          },
        },
      },
      emphasis: {
        label: {
          show: true,
          rich: {
            name: {
              fontSize: 14,
              fontWeight: 'normal',
            },
            numbers: {
              fontSize: 14,
              color: 'black',
              fontWeight: 'bold',
            },
          },
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      data: subgroup.variables.map(claim => {
        if (claim.respondents === 0) return null

        claim.percentage = percentageFormatter.format(
          claim.respondents / totalRespondentsReached
        )

        const variableLabel =
          useLabels && claim.label ? claim.label : claim.name

        return {
          value: claim.respondents,
          name: variableLabel,

          label: {
            formatter: () =>
              `{name|${variableLabel}}\n {numbers|${
                claim.percentage
              } (${Math.round(claim.respondents)})}`,
          },
        }
      }),
    }
  })

  const subtitles = subgroupQuestions.map((s, i) => {
    const totalRespondents = s.variables.reduce(
      (acc, cur) => acc + cur.respondents,
      0
    )

    const totalRespondentsInSubgroup = s.total_respondents_in_subgroup

    const percentage = percentageFormatter.format(
      totalRespondents / totalRespondentsInSubgroup
    )

    const top = centers[i][1] + outerRadius + subtitlesOffset
    const text = s.name + ` (${percentage})`

    // Center the text (center-half of the text width)
    const left = centers[i][0] - textSizeInPixels(text, '14px Arial')[0] / 2

    return {
      type: 'text',
      left: left,
      top: top,
      style: {
        font: '14px Arial',
        text: text,
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 14,
      },
    }
  })

  const totalRespondentsLabels = subgroupQuestions.map((s, i) => {
    const totalRespondentsReached = Math.round(
      s.variables.reduce((acc, cur) => acc + cur.respondents, 0)
    )

    const totalRespondentsInSubgroup = s.total_respondents_in_subgroup

    const totalRespondentsText =
      totalRespondentsReached + ' of ' + totalRespondentsInSubgroup

    // Make the text orange if the subgroup has low samples
    const lowSample = totalRespondentsReached <= lowSampleWarningThreshold
    const color = lowSample ? 'red' : 'black'

    const [textWidth] = textSizeInPixels(totalRespondentsText, '14px Arial')

    const left = centers[i][0] - textWidth / 1.7
    const top = centers[i][1]

    return {
      type: 'text',
      left: left,
      top: top,
      style: {
        font: '14px Arial',
        text: totalRespondentsText,
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 14,
        fill: color,
      },
    }
  })

  const options = {
    graphic: subtitles.concat(totalRespondentsLabels),
    series: series,
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: 'Save as image',
          name: `${maxDiffName} - ${selectedItem.name} `,
          type: 'png',
          backgroundColor: '#fff',
          excludeComponents: ['toolbox'],
        },
      },
    },
  }
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      style={{ height: height, width: width }}
      ref={chartRef}
    />
  )
}
