import { Button, Classes, Menu, MenuItem } from '@blueprintjs/core'
import { ContextMenu2 } from '@blueprintjs/popover2'
import { interpolatePRGn } from 'd3-scale-chromatic'
import { uniqBy } from 'lodash'
import { useMemo, useState } from 'react'
import { useApi } from '../../contexts/ApiProvider'
import { useWindow } from '../../contexts/WindowProvider'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { zScores } from '../../utils/stats'
import tryConvertString from '../../utils/tryConvertString'
import { LoadingIcon } from '../LoadingSpinner'
import { AppToaster, ProgressToaster } from '../toaster'
import '../VizUI.css'

export default function CrosstabTable({ data, setBanner, setStub }) {
  console.log('CrosstabTable', data)
  const api = useApi()
  const {
    settings: { percentages, highlighting },
    setLoading,
  } = useWindow()

  const { rowHeaders, rowHeaderNames } = data
    ? getRowHeaders(data.headers.rows)
    : { rowheaders: undefined, rowHeaderNames: undefined }

  const roundTo = percentages ? 2 : 0
  const showStatTesting = Boolean(
    data?.stat_testing && data.stat_testing.values
  )

  const cellStyles = useMemo(
    () => computeCellStyles(data),
    [data?.banner_slices, data?.values]
  )

  const [selected, setSelected] = useState([])
  const isSelected = node => selected.includes(JSON.stringify(node))

  const handleClick = (ev, node) => {
    setSelected(prev => {
      console.log({ prev, node })
      const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform)
      const multiClick = isMac ? ev.metaKey : ev.ctrlKey
      const nodeString = JSON.stringify(node)
      const [axis] = node
      if (prev.includes(nodeString)) {
        return prev.filter(s => s !== nodeString)
      } else if (prev.length && multiClick && JSON.parse(prev[0])[0] === axis) {
        return [...prev, nodeString]
      } else return [nodeString]
    })
  }

  const handleSetNetClick = async () => {
    let punches = selected.map(nodeString => JSON.parse(nodeString))
    const [axis] = punches[0]
    punches = punches.map(([axis, node]) => ({
      question_id: node.question_id,
      variable_id: node.variable_id,
      punch: node.punch,
      parent_id:
        axis === 'stub'
          ? findParent(node, data.headers._meta.rows)?.variable_id
          : findParent(node, data.headers._meta.cols)?.variable_id,
    }))
    const compositeQuestions = uniqBy(punches, 'question_id')
    const name = prompt('Name of Net?', 'NET: ')
    const toastId = ProgressToaster.show({
      message: 'Netting...',
      isCloseButtonShown: false,
      timeout: 0,
      icon: <LoadingIcon />,
    })

    setLoading(true)
    const response = await api.post('/net', { name, punches })
    ProgressToaster.dismiss(toastId)
    setLoading(false)

    if (response.ok) {
      const questionWithNet = response.body
      setSelected([])
      let newStateCallback
      if (compositeQuestions.length > 1) {
        // multiple composites, append new net question
        newStateCallback = prev => [...prev, questionWithNet]
      } else {
        // if single composite, replace with new net question
        newStateCallback = prev => {
          const prevQuestionIndex = prev.findIndex(
            question => question.id === compositeQuestions[0].question_id
          )
          return [
            ...prev.slice(0, prevQuestionIndex),
            questionWithNet,
            ...prev.slice(prevQuestionIndex + 1),
          ]
        }
      }
      if (axis === 'banner') {
        setBanner(newStateCallback)
      } else {
        setStub(newStateCallback)
      }
    } else {
      const message = Object.entries(response.body.messages.json)
        .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
        .join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    // console.log({ axis, response })
  }

  return (
    <table
      style={{ '#r0_c0': { backgroundColor: '#c6aad1' } }}
      className={[
        Classes.HTML_TABLE,
        Classes.INTERACTIVE,
        Classes.HTML_TABLE_CONDENSED,
        ...(showStatTesting ? ['stat-testing'] : ['striped']),
      ].join(' ')}
      id="crosstab-table"
    >
      <CrosstabTableHeader
        data={data}
        showStatTesting={showStatTesting}
        rowHeaderNames={rowHeaderNames}
      />
      <tbody>
        <TotalsRow data={data} colSpan={rowHeaders[0].length} />
        {data.values.map((row, r) => (
          <TableRow
            row={row}
            r={r}
            rowHeaders={rowHeaders}
            data={data}
            isSelected={isSelected}
            handleClick={handleClick}
            handleSetNetClick={handleSetNetClick}
            selected={selected}
            roundTo={roundTo}
            highlighting={highlighting}
            cellStyles={cellStyles}
            percentages={percentages}
            showStatTesting={showStatTesting}
          />
        ))}
      </tbody>
      {data?.end_metrics && <TableFooter data={data} rowHeaders={rowHeaders} />}
    </table>
  )
}

function getRowHeaders(rows) {
  const overallLength = rows[0][1]
    .map(([_, span]) => span)
    .reduce((x, y) => x + y)
  const emptyHeaders = Array(overallLength)
    .fill(null)
    .map(() => Array(rows.length).fill([null, null]))
  const names = []
  rows.forEach(([name, level], l) => {
    let totalSpan = 0
    names.push(name)
    level.forEach(([col, span], r) => {
      emptyHeaders[r + totalSpan][l] = [col, span]
      totalSpan += span - 1
    })
  })
  return { rowHeaders: emptyHeaders, rowHeaderNames: names }
}

function computeCellStyles(data) {
  if (!data?.banner_slices || !data?.values) return
  return data.values.map(row =>
    data.banner_slices.flatMap(([start, stop]) =>
      zScores(row.slice(start, stop), true).map(z => {
        const backgroundColor = lighten(interpolatePRGn(z), 0.25)
        const color = getContrastText(backgroundColor)
        return { color, backgroundColor }
      })
    )
  )
}

function findParent(node, nodes) {
  let parentPath = node.path.slice(0, -1)
  if (!parentPath.length) return
  parentPath = JSON.stringify(parentPath)
  return nodes.find(({ path }) => JSON.stringify(path) === parentPath)
}

/**
 * The row containing the column (banner) headers.
 * It starts with an empty cell, then a "TOTAL" cell,
 * then the column headers.
 */
function CrosstabTableHeader({ data, rowHeaderNames, showStatTesting }) {
  return (
    <thead>
      {data.headers.columns.map(([name, row], r) => {
        return (
          <tr key={r}>
            {Array(
              Math.max(
                data.headers.rows.length - data.headers.columns.length,
                0
              ) + 1
            )
              .fill(1)
              .map((i, c) => {
                if (c === 0) {
                  return <th key={c} />
                }
              })}
            <th className="banner" key={name}>
              {name}
            </th>
            {r === 0 && (
              <th
                rowSpan={data.headers.columns.length}
                key="total-sample"
                id="total-sample-header"
                className="banner total"
              >
                TOTAL
              </th>
            )}
            {row.map(([header, span], c) => {
              return (
                <th className="banner" key={c} colSpan={span > 1 ? span : null}>
                  {header}
                </th>
              )
            })}
          </tr>
        )
      })}
      <tr>
        {rowHeaderNames.map((rowHeaderName, c) => {
          return (
            <th key={c} className="banner">
              {rowHeaderName}
            </th>
          )
        })}
        {showStatTesting
          ? [
              <th key="total-column-placeholder" />,
              ...data.stat_testing.header.map((c, i) => (
                <th className="stat-testing" key={i}>
                  {c}
                </th>
              )),
            ]
          : Array(
              data.headers.columns[0][1]
                .map(([_, span]) => span)
                .reduce((x, y) => x + y) + 1
            )
              .fill(1)
              .map((_, i) => <th key={i} />)}
      </tr>
    </thead>
  )
}

/**
 * The totals row is actually the first row of the table
 * after the header
 */
function TotalsRow({ data, colSpan }) {
  return (
    <tr key="total-row">
      {' '}
      <TotalsRowHeader data={data} colSpan={colSpan} />
      {data['totals']['row'].map((v, c) => {
        const isStartOfBanner =
          c !== 1 && data?.banner_slices.find(([start]) => start === c - 1)
        return <TotalsRowCell v={v} c={c} isStartOfBanner={isStartOfBanner} />
      })}
    </tr>
  )
}

/**
 * Just the first header cell for the totals row.
 * The one that reads "Base: Total Respondents"
 */
function TotalsRowHeader({ data, colSpan }) {
  const leftPadding =
    (data['headers']['_meta']['rows'][0]['path'].length - 1) * 20 + 'px'
  return (
    <th
      className="variable-name-header stub total total-row"
      style={{
        paddingLeft: leftPadding,
        textAlign: 'right',
      }}
      colSpan={colSpan}
    >
      Base: Total Respondents
    </th>
  )
}

function TotalsRowCell({ v, c, isStartOfBanner }) {
  const isStartOfBannerStyle = {
    borderLeft: '15px solid white',
    boxShadow: '0 0 15px rgba(0,0,0,0.75)',
    clipPath: 'inset(0px -15px 0px 0px)',
  }
  return (
    <td
      style={{
        ...(isStartOfBanner ? isStartOfBannerStyle : {}),
      }}
      key={c}
      className={'total total-row' + (c === 0 ? ' total-column' : '')}
    >
      {formatValue(v, 0, false)}
    </td>
  )
}

function TableRow({
  row,
  r,
  rowHeaders,
  isSelected,
  selected,
  handleClick,
  handleSetNetClick,
  data,
  showStatTesting,
  roundTo,
  highlighting,
  percentages,
  cellStyles,
}) {
  const rowAsNums = row.map(v => tryConvertString(v))
  const isNetRow = data['headers']['_meta']['rows'][r]['has_children']
  return [
    <tr key={r}>
      {rowHeaders[r].flatMap(([header, span], c) =>
        header !== null ? (
          c === rowHeaders[r].length - 1 ? (
            <LastHeaderColumn
              handleSetNetClick={handleSetNetClick}
              handleClick={handleClick}
              isNetRow={isNetRow}
              data={data}
              r={r}
              c={c}
              header={header}
              isSelected={isSelected}
              selected={selected}
              showStatTesting={showStatTesting}
              span={span}
            />
          ) : (
            <FirstHeaderColumn
              c={c}
              header={header}
              span={span}
              showStatTesting={showStatTesting}
            />
          )
        ) : (
          []
        )
      )}
      <TotalsColumn
        data={data}
        r={r}
        roundTo={roundTo}
        percentages={percentages}
      />
      {row.map((val, c) => {
        const isStartOfBanner =
          c !== 0 && data?.banner_slices.find(([start]) => start === c)

        return (
          <DataColumn
            c={c}
            r={r}
            highlighting={highlighting}
            cellStyles={cellStyles}
            value={rowAsNums[c]}
            isStartOfBanner={isStartOfBanner}
            roundTo={roundTo}
            percentages={percentages}
          />
        )
      })}
    </tr>,
    ...(showStatTesting && data?.stat_testing.values[r]
      ? [<StatTestRow data={data} r={r} />]
      : []),
  ]
}

function StatTestRow({ data, r }) {
  return (
    <tr key={'stat-' + r}>
      <th key={'total-column placeholder'} className="total-column" />
      {data.stat_testing.values[r].map((col, c) => (
        <td className="stat-testing" key={c}>
          {col !== '' ? col : ' '}
        </td>
      ))}
    </tr>
  )
}

function TableFooter({ data, rowHeaders }) {
  return (
    <tfoot id="end-metrics">
      {data.end_metrics.values.map((row, r) => {
        const showStatRow =
          data.end_metrics.headers[r] === 'Mean' &&
          data.end_metrics?.stat_symbols
        return [
          <tr key={r}>
            <th
              className={`variable-name-header stub total`}
              style={{
                paddingLeft:
                  (data['headers']['_meta']['rows'][0]['path'].length - 1) *
                    20 +
                  'px',
                textAlign: 'right',
              }}
              colSpan={rowHeaders[0].length}
            >
              {data.end_metrics.headers[r]}
            </th>
            {row.map((col, c) => (
              <td key={c}>{formatValue(col, 2, false)}</td>
            ))}
          </tr>,
          showStatRow ? (
            <tr key="stat">
              <th
                className="variable-name-header stub total"
                colSpan={rowHeaders[0].length}
              />
              {data.end_metrics.stat_symbols.map((s, c) => (
                <td key={c} className="stat-testing">
                  {s}
                </td>
              ))}
            </tr>
          ) : null,
        ]
      })}
    </tfoot>
  )
}

function LastHeaderColumn({
  handleSetNetClick,
  handleClick,
  isNetRow,
  data,
  r,
  c,
  header,
  isSelected,
  selected,
  showStatTesting,
  span,
}) {
  // console.log('Second header column', c, header)

  const headerLeftPadding =
    (data['headers']['_meta']['rows'][r]['path'].length - 1) * 20 + 'px'

  return (
    <ContextMenu2
      key={c}
      content={
        <Menu>
          <MenuItem
            text="Net"
            onClick={handleSetNetClick}
            disabled={selected.length < 2}
          />
        </Menu>
      }
    >
      {ctxMenuProps => (
        <th
          onContextMenu={ctxMenuProps.onContextMenu}
          ref={ctxMenuProps.ref}
          className={`${ctxMenuProps.className} ${
            isNetRow ? 'net-cell ' : ''
          }variable-name-header stub${
            isSelected(['stub', data['headers']['_meta']['rows'][r]])
              ? ' selected'
              : ''
          }`}
          style={{
            paddingLeft: headerLeftPadding,
          }}
          onClick={ev =>
            handleClick(ev, ['stub', data['headers']['_meta']['rows'][r]])
          }
          rowSpan={
            span > 1 || showStatTesting
              ? showStatTesting
                ? span * 2
                : span
              : null
          }
        >
          {ctxMenuProps.popover}
          <span>{header}</span>
          {isNetRow ? (
            <NetCaretDownButton />
          ) : (
            // Just a placeholder to keep the column width consistent
            <span style={{ width: 32, display: 'inline-block' }} />
          )}
        </th>
      )}
    </ContextMenu2>
  )
}

function NetCaretDownButton() {
  return (
    <Button
      small
      minimal
      icon="caret-down"
      className="tree-icon"
      onClick={ev => ev.stopPropagation()}
    />
  )
}

function FirstHeaderColumn({ c, header, span, showStatTesting }) {
  // console.log('First header column', c, header)
  return (
    <th
      key={c}
      rowSpan={
        span > 1 || showStatTesting ? (showStatTesting ? span * 2 : span) : null
      }
    >
      {header}
    </th>
  )
}

function TotalsColumn({ data, r, roundTo, percentages }) {
  return (
    <td className="total total-column">
      {formatValue(data['totals']['column'][r], roundTo, percentages)}
    </td>
  )
}

function DataColumn({
  c,
  r,
  highlighting,
  isStartOfBanner,
  cellStyles,
  value,
  roundTo,
  percentages,
}) {
  return (
    <td
      key={c}
      style={{
        ...(highlighting ? cellStyles[r][c] : {}),
        ...(isStartOfBanner ? { borderLeft: '15px solid white' } : {}),
      }}
    >
      {formatValue(value, roundTo, percentages)}
    </td>
  )
}

export function formatValue(v, roundTo, percentages) {
  const valAsNum = percentages && v ? v * 100 : v
  const valFormatted = valAsNum ? valAsNum.toFixed(roundTo) : valAsNum
  return valFormatted
}
