export default function variableOrQuestionToString(
  { name, label },
  maxLen = 80,
  asObject = false
) {
  let labelToUse =
    label && label?.toLowerCase() !== name?.toLowerCase()
      ? maxLen && label.length + name.length > maxLen
        ? label.slice(0, maxLen - 3) + '...'
        : label
      : ''
  return asObject
    ? { name, label: labelToUse ? ` - ${labelToUse}` : '' }
    : labelToUse
    ? `${name} - ${labelToUse}`
    : name
}

export function entityListToIdString(entities, preserveOrder = false) {
  if (!entities) return ''
  if (preserveOrder) {
    return entities.map(e => e.id).join(',')
  }
  return entities
    .map(e => e.id)
    .sort()
    .join(',')
}
