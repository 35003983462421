import { Button, Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { useParams } from 'react-router-dom'

export default function SubgroupSelector({ useLabels = true }) {
  const { toolId } = useParams()
  const {
    subgroupQuestions,
    selectedGroupVariables,
    setSelectedGroupVariables,
  } = useMaxDiff()

  const divisor = ' | '

  let selectedVariablesString = ''

  subgroupQuestions?.forEach((q, index) => {
    const selectedVariables = q.variables.filter(v =>
      selectedGroupVariables.includes(v.id)
    )
    if (selectedVariables.length === 0) {
      return
    }
    const selectedString = `${selectedVariables
      .map(v => (useLabels && v.label ? v.label : v.name))
      .join(', ')}`

    if (selectedVariablesString === '') {
      selectedVariablesString += selectedString
    } else {
      selectedVariablesString += divisor + selectedString
    }
  })

  const maxLength = 150

  if (selectedVariablesString?.length > maxLength) {
    selectedVariablesString =
      selectedVariablesString.slice(0, maxLength) + '...'
  }

  const buttonText =
    toolId === 'preference-profiles'
      ? '-'
      : selectedVariablesString === ''
      ? 'Subgroups'
      : selectedVariablesString?.length > maxLength
      ? selectedVariablesString?.slice(0, maxLength) + '...'
      : selectedVariablesString

  const onClick = id =>
    selectedGroupVariables.includes(id)
      ? setSelectedGroupVariables(selectedGroupVariables.filter(i => i !== id))
      : setSelectedGroupVariables([...selectedGroupVariables, id])

  const menu = (
    <Menu>
      {subgroupQuestions === undefined ? (
        <MenuItem key="loading" text="Loading..." />
      ) : subgroupQuestions === null ? (
        <MenuItem key="problem-no-groups" text="Problem getting groups" />
      ) : subgroupQuestions.length === 0 ? (
        <MenuItem key="no-groups" text="No groups" />
      ) : (
        subgroupQuestions.map((question, q_index) => (
          <div key={question.name + '_' + q_index}>
            <MenuItem
              text={question.name}
              intent={
                question.variables.some(
                  v =>
                    selectedGroupVariables &&
                    selectedGroupVariables.includes(v.id)
                )
                  ? 'primary'
                  : 'none'
              }
              selected={question.selected}
            >
              {question.variables.map((variable, v_index) => {
                const text =
                  useLabels && variable.label ? variable.label : variable.name
                return (
                  <MenuItem
                    selected={selectedGroupVariables.includes(variable.id)}
                    intent={
                      selectedGroupVariables.includes(variable.id)
                        ? 'success'
                        : 'none'
                    }
                    key={question.name + '_' + q_index + '_' + v_index}
                    text={text}
                    onClick={() => onClick(variable.id)}
                    shouldDismissPopover={false}
                  />
                )
              })}
            </MenuItem>
            {q_index < subgroupQuestions.length - 1 && <MenuDivider />}
          </div>
        ))
      )}
      <MenuDivider />
      <Button
        icon={'filter-remove'}
        text="Clear"
        onClick={() => setSelectedGroupVariables([])}
        disabled={selectedGroupVariables.length === 0}
        fill={true}
      />
    </Menu>
  )

  const shouldDisable =
    subgroupQuestions === undefined ||
    subgroupQuestions.length === 0 ||
    toolId === 'preference-profiles'

  const activeIntent =
    selectedGroupVariables.length > 0 && toolId !== 'preference-profiles'

  return (
    <Popover2 content={menu} disabled={shouldDisable}>
      <Button
        key="subgroup-selector"
        icon={<Icon icon="filter" size={12} />}
        text={buttonText}
        minimal
        small
        className={activeIntent ? 'enabled-status-bar-element' : null}
        disabled={shouldDisable}
        intent={activeIntent ? 'primary' : 'none'}
      />
    </Popover2>
  )
}
