import React from 'react'
export default function Logo() {
  return (
    <svg
      style={{
        width: 96,
        height: 96,
      }}
      viewBox="0 0 200 200"
    >
      <defs>
        <radialGradient
          gradientUnits="userSpaceOnUse"
          cx="208.438"
          cy="-98.829"
          r="92.79"
          id="gradient-2"
          gradientTransform="matrix(1.877375, 1.9155, -0.830539, 0.814009, -458.272535, -308.706746)"
        >
          <stop offset="0" style={{ stopColor: '#FBD38D', stopOpacity: 1 }} />
          <stop offset="1" style={{ stopColor: '#DD6B20', stopOpacity: 1 }} />
          <animate
            attributeName="cy"
            from="-98.829"
            to="-140"
            dur="5.5s"
            repeatCount="indefinite"
            fill="freeze"
            values="-98.829; -140; -110; -98.829; -90; -90; -95; -98.829"
            keyTimes="0; 0.2; 0.4; 0.5; 0.6; 0.85; 0.95; 1"
            keySplines=".42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;"
          />
        </radialGradient>
      </defs>
      {/*<path*/}
      {/*  fill="url(#gradient-2)"*/}
      {/*  d="M110.86 137.081H73.758l-.004 34.059H30.187v-69.092l80.683.354s35.614-.53 35.245-28.585c-.603-30.271-35.245-30.271-35.245-30.271s-29.977-1.237-38.265-1.237l-42.418.177L7.21 5h102.049c16.082.008 30.16 2.702 42.038 8.078 11.892 5.382 21.164 13.117 27.601 23.033 6.438 9.918 9.69 21.781 9.69 35.317 0 13.536-3.297 25.361-9.824 35.196-6.524 9.829-15.378 16.999-26.88 22.205L192.791 195h-47.102l-34.829-57.919Z"*/}
      {/*/>*/}
      <clipPath id="p.0">
        <path
          d="m0 0l424.24146 0l0 424.24146l-424.24146 0l0 -424.24146z"
          clipRule="nonzero"
        ></path>
      </clipPath>
      <g
        clipPath="url(#p.0)"
        transform="matrix(0.47143, 0, 0, 0.468912, -0.000082, 0.534044)"
      >
        <path
          // fill="#f06537"
          fill="url(#gradient-2)"
          d="m0 212.29921l0 0c0 -117.24961 95.0496 -212.29921 212.29921 -212.29921l0 0c56.305252 0 110.3044 22.367174 150.11821 62.181c39.813843 39.813828 62.181 93.81296 62.181 150.11821l0 0c0 117.24962 -95.04959 212.29921 -212.29921 212.29921l0 0c-117.24961 0 -212.29921 -95.04959 -212.29921 -212.29921z"
          fillRule="evenodd"
        />
        <path
          fill="#ffffff"
          d="m139.01686 359.34903l0 -259.20312l36.265625 0l3.734375 61.34375l1.609375 0q20.265625 -31.46875 49.859375 -49.59375q29.60936 -18.140625 65.874985 -18.140625q14.390625 0 26.65625 2.40625q12.265625 2.390625 24.53125 8.25l-10.125 37.875q-13.875 -4.796875 -23.203125 -6.921875q-9.328125 -2.140625 -24.796875 -2.140625q-29.328125 0 -56.26561 16.796875q-26.9375 16.796875 -50.40625 57.328125l0 151.99998l-43.734375 0z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}
