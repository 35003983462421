import {
  Button,
  Classes,
  Icon,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  Text,
} from '@blueprintjs/core'
import { useContext } from 'react'
import { SurveyContext } from '../contexts/SurveyProvider'
import { useParams } from 'react-router-dom'

export default function StatusBar({
  filter,
  tableN,
  children,
  showFilter = false,
}) {
  const { surveyId } = useParams()
  const { setSurveyDialogOpen, weights } = useContext(SurveyContext)
  const filterApplied = Array.isArray(filter) && filter.length > 0
  return (
    <Navbar
      id="app-status-menu"
      className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
    >
      {children}
      {surveyId !== undefined ? (
        <>
          <NavbarGroup
            align="right"
            onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
          >
            <NavbarDivider />
            <Button
              small
              minimal
              icon={
                <Icon
                  icon="database"
                  size={12}
                  className={weights ? 'enabled-status-bar-element' : null}
                />
              }
              className={weights ? 'enabled-status-bar-element' : null}
              onClick={() => setSurveyDialogOpen('SetWeightsDialog')}
              text={
                weights
                  ? 'Weights Applied'
                  : weights === null
                  ? 'Unweighted'
                  : '...'
              }
            />
          </NavbarGroup>
          {showFilter ? (
            <NavbarGroup align="right">
              <NavbarDivider />
              <Icon
                icon="filter"
                size={12}
                className={filterApplied ? 'enabled-status-bar-element' : null}
              />
              <Text
                className={filterApplied ? 'enabled-status-bar-element' : null}
              >
                {Array.isArray(filter)
                  ? filter.length
                    ? 'Filter Applied'
                    : 'Total Sample'
                  : '...'}
              </Text>
              {tableN !== undefined ? (
                <Text id="n-text"> {` (n=${tableN.toFixed(1)})`}</Text>
              ) : null}
            </NavbarGroup>
          ) : null}
        </>
      ) : null}
    </Navbar>
  )
}
