import {
  Button,
  Classes,
  Collapse,
  Colors,
  Divider,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  NumericInput,
  ProgressBar,
  RangeSlider,
  Tag,
  TagInput,
  Text,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Tooltip2 } from '@blueprintjs/popover2'
import { MultiSelect2 } from '@blueprintjs/select'
import { useContext, useMemo, useRef, useState } from 'react'
import { SurveyContext } from '../contexts/SurveyProvider'
import useFilteredMultiSelect from '../hooks/useFilteredMultiSelect'
import highlightText from '../utils/highlightText'
import variableOrQuestionToString from '../utils/variableOrQuestionToString'
import { filterQuestions } from './VizUI'
import './GrouperUI.css'
import { NUMERIC_VARIABLE_TYPES } from './variables/VariablesUI'
import { useApi } from '../contexts/ApiProvider'
import { range, sum } from '../utils/stats'
import { AppToaster } from './toaster'

const allAlgorithms = ['NMF', 'kmeans', 'LC']
const TIMING_EST = { NMF: 18, LC: 1, kmeans: 1 }

export default function GrouperUI() {
  const { variables } = useContext(SurveyContext)
  const [segSizes, setSegSizes] = useState([3, 7])
  const [algorithmsSelected, setAlgorithmsSelected] = useState(allAlgorithms)
  const [segName, setSegName] = useState('')
  const seedInputRef = useRef()
  const nmfRunsInputRef = useRef()
  const numericVariables = useMemo(
    () =>
      variables.filter(v => NUMERIC_VARIABLE_TYPES.includes(v.variable_type)),
    [variables]
  )
  const [
    { selected, query, filteredSelection, filteredOptions },
    { select, reset, queryChange },
  ] = useFilteredMultiSelect(
    { selected: [], query: '' },
    filterQuestions,
    numericVariables
  )
  const [loading, setLoading] = useState(false)
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false)
  const validInputs = selected.length && selected.length >= segSizes[1]
  const api = useApi()
  const handleSubmitSegmentation = async ev => {
    ev.preventDefault()
    setLoading(true)
    AppToaster.clear()
    const data = {
      sizes: segSizes,
      algos: algorithmsSelected,
      inputs: selected.map(v => v.id),
      name: segName,
      seed: parseInt(seedInputRef.current.value),
      nmf_runs: parseInt(nmfRunsInputRef.current.value),
    }

    const response = await api.post('/segment', data)
    console.log(response)
    if (response.ok) {
      AppToaster.show({
        message:
          "Segmentation has been queued. You'll receive notifications about its progress.",
        intent: 'success',
        icon: 'tick-circle',
      })
    } else {
      const message = Object.entries(response.body.messages.json)
        .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
        .join(', ')
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
    }
    setLoading(false)
  }
  // const isVariableSelected = (variable, variablesSelected) => {
  //   return variablesSelected && variablesSelected.indexOf(variable) !== -1
  // }
  // const handleRemoveVariable = (variable, setVariables) => {
  //   setVariables(prev => prev.filter(q => q.id !== variable.id))
  // }
  // const handleVariableSelected = (variable, setVariables) => {
  //   setVariables(prev => {
  //     console.log({ variable, prev })
  //     return isVariableSelected(variable, prev)
  //       ? prev.filter(q => q.id !== variable.id)
  //       : [...prev, variable]
  //   })
  // }
  // const variablesEqual = (a, b) => a.id === b.id
  // const renderTag = item => variableOrQuestionToString(item, undefined)

  const isItemSelected = (item, itemsSelected) => {
    return itemsSelected && itemsSelected.indexOf(item) !== -1
  }
  const handleRemoveItem = (item, setItems) => {
    setItems(prev => prev.filter(i => i !== item))
  }
  const itemRenderer = (
    item,
    isSelected,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        selected={modifiers.active}
        icon={isSelected ? 'tick' : 'blank'}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        // label={item}
        key={item}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(item, query)}
      />
    )
  }
  // const inputRenderer = (
  //   variable,
  //   { handleClick, handleFocus, modifiers, query }
  // ) => {
  //   if (!modifiers.matchesPredicate) {
  //     return null
  //   }
  //   const text = variableOrQuestionToString(variable)
  //   return (
  //     <MenuItem
  //       selected={modifiers.active}
  //       icon={isVariableSelected(variable, inputs) ? 'tick' : 'blank'}
  //       shouldDismissPopover={true}
  //       disabled={modifiers.disabled}
  //       label={variable.variable_type}
  //       key={variable.id}
  //       onClick={handleClick}
  //       onFocus={handleFocus}
  //       text={highlightText(text, query)}
  //     />
  //   )
  // }

  return (
    <div
      style={{
        padding: '40px 60px',
        height: '100%',
      }}
    >
      <form
        onSubmit={handleSubmitSegmentation}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // height: '100%',
          gap: 75,
        }}
      >
        <div
          id="input-select-container"
          style={{ width: '100%', height: '60vh' }}
        >
          <FormGroup
            label={
              <span style={{ fontSize: 13 }}>
                <Icon
                  icon={IconNames.PROPERTIES}
                  size={12}
                  style={{ marginRight: '5px' }}
                />
                Inputs
              </span>
            }
            labelFor="inputs"
            style={{ flexGrow: 2 }}
          >
            <InputGroup
              leftIcon="filter"
              placeholder="Filter input list"
              rightElement={
                <>
                  {filteredOptions.length !== numericVariables.length ? (
                    <Tag minimal>
                      {filteredOptions.length}/{numericVariables.length}
                    </Tag>
                  ) : null}
                  <Tooltip2
                    content={query ? 'Clear filter' : null}
                    minimal
                    placement="right"
                  >
                    <Button
                      minimal
                      title="Clear filter"
                      icon="cross"
                      disabled={!query}
                      onClick={() => queryChange('')}
                    />
                  </Tooltip2>
                </>
              }
              style={{ marginBottom: 10 }}
              value={query}
              onChange={e => {
                queryChange(e.target.value)
              }}
            />
            <select
              className={Classes.INPUT}
              id="inputs"
              multiple
              style={{ width: '100%', height: 'calc(60vh - 80px)' }}
              value={filteredSelection.map(o => o.id)}
              onChange={e => {
                // const selectedOption =
                //   e.target.selectedIndex !== -1
                //     ? numericVariables[e.target.selectedIndex]
                //     : null
                const selected = [...e.target.selectedOptions].map(o => {
                  return filteredOptions.find(v => `${v.id}` === `${o.value}`)
                })
                select(selected)
              }}
            >
              {filteredOptions.map(v => {
                const text = variableOrQuestionToString(v, null)
                return (
                  <option value={v.id} key={v.id}>
                    {text}
                  </option>
                )
              })}
            </select>
          </FormGroup>
          <FormGroup
            helperText={
              !validInputs ? (
                <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                  Select at least {segSizes[1]} inputs
                </Text>
              ) : null
            }
            label="Inputs Selected"
            labelInfo="(above)"
            labelFor="inputs-selected"
          >
            <TagInput
              id="inputs-selected"
              leftIcon={IconNames.PROPERTIES}
              // values={selected.map(v => v.name)}
              values={[]}
              onRemove={(_, index) =>
                select([
                  ...selected.slice(0, index),
                  ...selected.slice(index + 1),
                ])
              }
              inputProps={{
                disabled: true,
                value: '',
                onFocus: e => e.target.blur(),
              }}
              disabled={selected.length === 0}
              tagProps={{ minimal: true }}
              intent={segSizes[1] > selected.length ? Intent.DANGER : null}
              placeholder={selected.length ? '' : 'Select inputs above'}
              rightElement={
                <div
                  style={{
                    padding: '5px 6px',
                  }}
                >
                  <Tag
                    minimal={selected.length === 0}
                    icon={<Icon icon={IconNames.PROPERTY} size="10" />}
                    intent={
                      selected.length
                        ? segSizes[1] > selected.length
                          ? Intent.DANGER
                          : Intent.PRIMARY
                        : Intent.NONE
                    }
                    onRemove={() => reset()}
                  >
                    {selected.length} input{selected.length !== 1 ? 's' : ''}
                  </Tag>
                </div>
              }
            >
              {selected.map((v, i) => (
                <Tooltip2
                  key={v.id}
                  popoverClassName="input-tooltip"
                  className="input-tooltip"
                  content={
                    v?.label ? (
                      <span style={{ fontSize: 12 }}>{v.label}</span>
                    ) : null
                  }
                  minimal
                >
                  <Tag
                    key={v.id}
                    minimal
                    onRemove={() =>
                      select([
                        ...selected.slice(0, i),
                        ...selected.slice(i + 1),
                      ])
                    }
                  >
                    {v.name}
                  </Tag>
                </Tooltip2>
              ))}
            </TagInput>
          </FormGroup>
        </div>
        <div>
          <FormGroup
            label={
              <span style={{ fontSize: 13 }}>
                <Icon
                  iconSize={14}
                  icon="function"
                  style={{ marginRight: 3 }}
                  className={Classes.TEXT_MUTED}
                />
                Algorithms
              </span>
            }
            helperText={
              algorithmsSelected.length === 0 ? (
                <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                  Select at least 1 algorithm
                </Text>
              ) : null
            }
            labelFor="algorithms"
          >
            <MultiSelect2
              fill
              id="algorithms"
              selectedItems={algorithmsSelected ?? []}
              items={allAlgorithms}
              // itemsEqual={variablesEqual}
              onItemSelect={item =>
                setAlgorithmsSelected(prev =>
                  isItemSelected(item, prev)
                    ? prev.filter(i => i !== item)
                    : [...prev, item]
                )
              }
              onRemove={item => handleRemoveItem(item, setAlgorithmsSelected)}
              onClear={() => setAlgorithmsSelected([])}
              tagRenderer={item => item}
              // itemListPredicate={filterQuestions}
              itemRenderer={(item, props) =>
                itemRenderer(
                  item,
                  isItemSelected(item, algorithmsSelected),
                  props
                )
              }
              noResults={<MenuItem disabled={true} text="No results." />}
              popoverProps={{
                minimal: true,
                popoverClassName: 'scrollable-select-popover',
              }}
              placeholder="Select Algorithms"
              resetOnSelect={true}
              tagInputProps={{ tagProps: { minimal: true, round: true } }}
            />
          </FormGroup>
          <FormGroup
            style={{ width: 325 }}
            label={
              <span style={{ fontSize: 13 }}>
                <Icon
                  iconSize={14}
                  icon="layout-sorted-clusters"
                  style={{ marginRight: 3 }}
                  className={Classes.TEXT_MUTED}
                />
                Segment Sizes
              </span>
            }
            labelFor="sizes"
            helperText={
              selected.length && segSizes[1] > selected.length ? (
                <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                  Select at least {segSizes[1]} inputs
                </Text>
              ) : null
            }
          >
            <RangeSlider
              intent={
                segSizes[1] > selected.length ? Intent.DANGER : Intent.PRIMARY
              }
              id="sizes"
              min={2}
              max={10}
              value={segSizes}
              onChange={range => setSegSizes(range)}
            />
          </FormGroup>
          <Divider style={{ margin: '26px 0px' }} />
          <FormGroup
            helperText={`Preview: ${segName ? segName : 'q8ef-demos'} (${
              algorithmsSelected?.[0] ? algorithmsSelected[0] : 'NMF'
            }${segSizes[0]})`}
          >
            <InputGroup
              value={segName}
              onChange={ev => setSegName(ev.target.value)}
              placeholder="Segmentation Name"
              id="seg-name"
            />
          </FormGroup>
          <Button
            icon="inherited-group"
            text="Queue Segmentation"
            type="submit"
            fill
            intent={validInputs ? 'primary' : 'danger'}
            loading={loading}
            disabled={!validInputs || loading}
            style={{ marginBottom: 13, marginTop: 13 }}
          />

          <Button
            minimal
            text="Advanced Settings"
            rightIcon="settings"
            icon={advancedSettingsOpen ? 'caret-down' : 'caret-right'}
            onClick={() => setAdvancedSettingsOpen(prev => !prev)}
          />
          <Collapse isOpen={advancedSettingsOpen} keepChildrenMounted={true}>
            <FormGroup
              style={{ marginLeft: 34, marginTop: 8 }}
              labelFor="seed"
              label="Random Seed"
              helperText="Change the seed if you want to generate new solutions for the same inputs"
            >
              <NumericInput
                id="seed"
                leftIcon="random"
                min={0}
                defaultValue={0}
                inputRef={seedInputRef}
              />
            </FormGroup>
            <FormGroup
              style={{ marginLeft: 34, marginTop: 8 }}
              labelFor="nmf-runs"
              label="NMF Runs"
              helperText="How many NMF potential solutions to run for each segmentation size. By default, 40 are run and the one with the most even segment sizes is chosen. Lowering this number will speed up the segmentation process but may result in less even segments."
            >
              <NumericInput
                id="nmf-runs"
                leftIcon="social-media"
                min={1}
                max={100}
                defaultValue={40}
                inputRef={nmfRunsInputRef}
              />
            </FormGroup>
          </Collapse>
        </div>
      </form>
    </div>
  )
}
