import {
  MenuItem,
  Menu as BpMenu,
  Icon,
  Button,
  MenuDivider,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Link } from 'react-router-dom'
import Menu from '../components/Menu'
import Surveys from '../components/Surveys'
import ToolPage from '../components/ToolPage'
import React from 'react'
import { useDocumentTitle } from '../utils/hooks'
import { useWindow } from '../contexts/WindowProvider'
import { useUser } from '../contexts/UserProvider'
import { archiveIcon, excelIcon } from '../icons/customIcons'

export default function SurveysPage() {
  const pageName = 'Surveys'
  useDocumentTitle(pageName)
  const {
    setDialogOpen,
    settings: { surveysView },
    setSettings,
  } = useWindow()
  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const isResearcher = user.role === 'researcher'
  const isAnalystOrResearcher = isAnalyst || isResearcher
  return (
    <ToolPage
      menu={
        <Menu pageName={pageName}>
          {isAnalystOrResearcher && <FileMenu />}
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  text={'Table'}
                  icon="th-list"
                  onClick={() =>
                    setSettings(prev => ({ ...prev, surveysView: 'table' }))
                  }
                  labelElement={
                    <Icon
                      icon={surveysView === 'table' ? 'small-tick' : 'blank'}
                    />
                  }
                />
                <MenuItem
                  text={'Grid'}
                  icon="grid-view"
                  onClick={() =>
                    setSettings(prev => ({ ...prev, surveysView: 'grid' }))
                  }
                  labelElement={
                    <Icon
                      icon={surveysView === 'grid' ? 'small-tick' : 'blank'}
                    />
                  }
                />
              </BpMenu>
            }
          >
            <Button text={'View'} />
          </Popover2>
          {isAnalyst && (
            <Popover2
              minimal
              placement="bottom-start"
              content={
                <BpMenu>
                  {isAnalyst && (
                    <MenuItem text="AA">
                      <MenuItem
                        text={'Augmented MaxDiff Designer'}
                        icon="style"
                        onClick={() => setDialogOpen('AugMdDesigner')}
                      />
                    </MenuItem>
                  )}
                  <MenuItem text="Tabs">
                    <MenuItem
                      text={'Create Consolidated AXES file'}
                      onClick={() => setDialogOpen('CreateAxesDialog')}
                    />
                  </MenuItem>
                </BpMenu>
              }
            >
              <Button text={'Extras'} />
            </Popover2>
          )}
          {isAnalystOrResearcher && (
            <Popover2
              minimal
              placement="bottom-start"
              content={
                <BpMenu>
                  <MenuItem
                    text={
                      <Link to="/help/tutorial" target="_blank">
                        Toolkit Tutorial Playlist
                        <Icon
                          className="new-page-icon"
                          icon="share"
                          size={12}
                        />
                      </Link>
                    }
                  />
                  <MenuDivider />
                  <MenuItem
                    text={
                      <Link to="/help/home" target="_blank">
                        Toolkit Homepage Help Video
                        <Icon
                          className="new-page-icon"
                          icon="share"
                          size={12}
                        />
                      </Link>
                    }
                  />
                </BpMenu>
              }
            >
              <Button text={'Help'} />
            </Popover2>
          )}
        </Menu>
      }
      tool={<Surveys />}
    />
  )
}

function FileMenu() {
  const { setDialogOpen } = useWindow()
  return (
    <Popover2
      minimal
      placement="bottom-start"
      content={
        <BpMenu>
          <MenuItem
            text={
              <Link className="unstyled-link" to="/new">
                New from .xlsx or .sav
              </Link>
            }
            icon={excelIcon}
          />
          <MenuItem
            text={
              <Link className="unstyled-link" to="/survey/new/restore">
                New from .backup
              </Link>
            }
            icon={archiveIcon}
          />
          <MenuDivider />
          <MenuItem
            text={'New from Confirmit'}
            icon="cloud-upload"
            onClick={() => setDialogOpen('NewConfirmitSurveyDialog')}
          />
        </BpMenu>
      }
    >
      <Button text={'File'} />
    </Popover2>
  )
}
