import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useApi } from './ApiProvider'

const UserContext = createContext()

export default function UserProvider({ children }) {
  const [user, setUser] = useState()
  const api = useApi()
  const [notificationsSince, setNotificationsSince] = useState(0)

  useEffect(() => {
    ;(async () => {
      if (api.isAuthenticated()) {
        const response = await api.get('/me')
        setUser(response.ok ? response.body : null)
        setNotificationsSince(
          response.body.notifications?.slice(-1)[0]?.timestamp || 0
        )
      } else {
        setUser(null)
      }
    })()
  }, [api])

  useEffect(() => {
    if (!user || !api) return
    // Notification polling
    let intervalId = null
    intervalId = setInterval(async () => {
      const response = await api.get(
        '/notifications?since=' + notificationsSince
      )
      if (response.ok) {
        if (response.body.length === 0) return
        if (response.body?.bodyUsed === false) return
        const newNotifications = response.body
        const prevTaskIds = user.notifications.map(n => n.payload?.task_id)
        setUser(prev => ({
          ...prev,
          notifications: [
            // remove older notifications for the same task
            ...newNotifications,
            ...prev.notifications.filter(
              n =>
                n.payload?.taskId !== undefined &&
                !prevTaskIds.includes(n.payload?.task_id)
            ),
          ],
        }))
        setNotificationsSince(
          newNotifications[newNotifications.length - 1].timestamp
        )
      }
    }, 10000)

    return () => clearInterval(intervalId)
  }, [api, notificationsSince, user])

  const login = useCallback(
    async magicRefreshToken => {
      const result = await api.verifyMagic(magicRefreshToken)
      if (result === 'ok') {
        const response = await api.get('/me')
        setUser(response.ok ? response.body : null)
      }
      return result
    },
    [api]
  )

  const logout = useCallback(() => {
    api.logout()
    setUser(null)
  }, [api])

  return (
    <UserContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}
