import { Button, Classes, Dialog, FormGroup, MenuItem } from '@blueprintjs/core'
import { Suggest2 } from '@blueprintjs/select'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import { SurveyContext } from '../contexts/SurveyProvider'
import highlightText from '../utils/highlightText'
import variableToString from '../utils/variableOrQuestionToString'
import { AppToaster } from './toaster'

export default function SetWeightsDialog() {
  const {
    variables,
    weights,
    setWeights,
    setSurveyDialogOpen,
    surveyDialogOpen,
  } = useContext(SurveyContext)
  const { surveyId } = useParams()
  const [loading, setLoading] = useState(false)

  const api = useApi()
  const [selectedVariable, setSelectedVariable] = useState(weights)
  useEffect(
    function updateWeightsAfterApiCall() {
      setSelectedVariable(weights)
    },
    [weights]
  )

  const onClose = () => setSurveyDialogOpen()
  const isOpen = surveyDialogOpen === 'SetWeightsDialog'
  const changeMade = selectedVariable?.id !== weights?.id

  const handleSubmit = async () => {
    setLoading(true)
    const response = await api.put(`/survey`, {
      id: surveyId,
      weight_id: selectedVariable?.id || null,
    })
    if (response.ok) {
      setWeights(selectedVariable)
      const message = selectedVariable?.id
        ? `Weights applied successfully (${selectedVariable.name})`
        : 'Data is now unweighted'
      AppToaster.show({
        message,
        intent: 'success',
        icon: 'error',
      })
      onClose()
    } else {
      // alert(Object.values(response.body.messages.json).join(', '))
      const message = Object.values(response.body.messages.json).join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  const filterVariable = (query, variable, _index, exactMatch) => {
    const normalizedName = variable.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedQuery === normalizedName
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0
    }
  }
  const variableRenderer = (
    variable,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = variableToString(variable)
    return (
      <MenuItem
        selected={modifiers.active}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        label={variable.type}
        key={variable.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(text, query)}
      />
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      // isOpen={true}
      onClose={onClose}
      title="Set Weights"
      icon="database"
      // enforceFocus={false}
      // usePortal={false}
    >
      <form
        onSubmit={ev => {
          ev.preventDefault()
          if (changeMade) {
            handleSubmit()
          }
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Weight Variable"
            labelFor="weight-variable"
            helperText="Choose the variable to use for weighing"
          >
            <Suggest2
              id="weight-variable"
              items={variables?.filter(v => v.variable_type === 'number')}
              disabled={loading}
              noResults={
                <MenuItem
                  disabled={true}
                  text="No results."
                  roleStructure="listoption"
                />
              }
              inputValueRenderer={variableToString}
              placeholder="Select a weight variable"
              itemRenderer={variableRenderer}
              itemPredicate={filterVariable}
              onItemSelect={setSelectedVariable}
              popoverProps={{
                popoverClassName: 'scrollable-select-popover',
              }}
              selectedItem={selectedVariable}
            />
            {/* <Button
              text={weightVariable?.name ?? 'Select a weight variable'}
              rightIcon="double-caret-vertical"
              placeholder="Select a film"
            /> */}
          </FormGroup>
          <Button
            text="Remove Weights"
            intent="warning"
            outlined
            icon="remove"
            disabled={loading || selectedVariable === null}
            onClick={() => {
              setSelectedVariable(null)
            }}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={onClose}
              minimal
              intent="danger"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              // onClick={handleSubmit}
              intent="success"
              loading={loading}
              disabled={!changeMade}
              type="submit"
            >
              {changeMade ? 'Submit' : 'No Change'}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  )
}
