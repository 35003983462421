import React, { useRef } from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { TreeChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

import * as echarts from 'echarts/core'
import { DecisionTree as DTree } from '../../utils/treeFunctions'

echarts.use([
  TreeChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  CanvasRenderer,
])

export default function DecisionTreeGraph({
  pallette,
  shapes,
  totalRespondents,
  fetchNode,
  exportLeaves,
  useLabels = true,
}) {
  const clickNumber = useRef(0)

  const decisionTree = new DTree({
    clickNumber: clickNumber,
    pallette: pallette,
    symbols: shapes,
    mostRespondents: totalRespondents,
    fetchNode: fetchNode,
    exportLeaves: exportLeaves,
    useLabels: useLabels,
  })
  const chartRef = useRef(null)
  decisionTree.chartRef = chartRef

  return (
    <div className="decision-tree-graph">
      <ReactEChartsCore
        echarts={echarts}
        option={decisionTree.getOption()}
        style={{ height: '100%', width: '100%' }}
        onEvents={decisionTree.getOnEvents()}
        onChartReady={() => decisionTree.onChartReady()}
        ref={decisionTree.chartRef}
      />
    </div>
  )
}
