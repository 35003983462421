import { AnchorButton, ButtonGroup } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

import { useTURF } from '../../contexts/TURFProvider'

export default function TURFNextBestClaimButton({ showTooltip = true }) {
  const { TURFResults, claimIds, setClaimIds, loading } = useTURF()
  const { consideredClaimsIds, selectedClaimsIds } = claimIds

  const nextBestClaim = () => {
    if (consideredClaimsIds.length === 0) {
      return null
    }
    const cla = TURFResults.filter(
      c =>
        consideredClaimsIds.includes(c.variable.id) &&
        !selectedClaimsIds.includes(c.variable.id)
    )
    const max = Math.max(...cla.map(c => c.deduplicated_reach))
    const max_reaches = cla.filter(c => c.deduplicated_reach === max)
    if (max_reaches.length === 1) {
      return max_reaches[0]
    } else {
      console.log(
        "Can't decide which claim to select next. Breaking tie with favourite."
      )
      const max_fav = Math.max(...max_reaches.map(c => c.favourite))
      const max_favourites = max_reaches.filter(c => c.favourite === max_fav)
      return max_favourites[0]
    }
  }

  const removeLastClaim = () => {
    setClaimIds({
      ...claimIds,
      selectedClaimsIds: claimIds.selectedClaimsIds.slice(0, -1),
    })
  }

  const getNextBest = () => {
    const nextBest = nextBestClaim()
    if (nextBest) {
      setClaimIds({
        ...claimIds,
        selectedClaimsIds: [
          ...claimIds.selectedClaimsIds,
          nextBest.variable.id,
        ],
      })
    } else {
      console.error("Can't find next best claim")
    }
  }

  const disabledNext =
    consideredClaimsIds.length === 0 ||
    consideredClaimsIds.length === selectedClaimsIds.length ||
    loading

  const disableBack = selectedClaimsIds.length === 0 || loading

  return (
    <ButtonGroup className="bp4-inline turf-nextBestClaim">
      <Tooltip2 content="Deselect last selected claim" hoverOpenDelay={1000}>
        <AnchorButton
          onClick={removeLastClaim}
          loading={loading}
          disabled={disableBack}
          icon="arrow-left"
        >
          Back
        </AnchorButton>
      </Tooltip2>
      <Tooltip2
        content={`Select next best claim based on reach. Break ties with favorite`}
        hoverOpenDelay={1000}
      >
        <AnchorButton
          onClick={getNextBest}
          loading={loading}
          disabled={disabledNext}
          rightIcon="arrow-right"
          className="turf-nextBestClaim-button"
        >
          Next
        </AnchorButton>
      </Tooltip2>
    </ButtonGroup>
  )
}
