import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import { useApi } from '../../contexts/ApiProvider'

export default function DraggableVariable({
  name: column,
  children,
  handleTogglePunch,
  filterOutPunches,
}) {
  const ref = useRef()
  const [{ isDragging }, drag] = useDrag({
    item: { name: column },
    type: 'variable',
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  const opacity = isDragging ? 0 : 1

  const api = useApi()
  const [labels, setLabels] = useState()

  useEffect(() => {
    ;(async () => {
      const response = await api.post('/value_labels', { column })
      console.log('fetch ' + column)
      if (response.ok) {
        setLabels(response.body)
      } else {
        setLabels(null)
      }
    })()
  }, [api, column])

  drag(ref)
  return (
    <Popover2
      content={
        <Menu className="variable-menu">
          <MenuDivider title="Punches" />
          {labels
            ? labels.map((row, r) => (
                <MenuItem
                  key={r}
                  text={row.join(' - ')}
                  shouldDismissPopover={false}
                  selected={!filterOutPunches.includes(row[0])}
                  onClick={() => handleTogglePunch(column, row[0])}
                />
              ))
            : 'loading...'}
        </Menu>
      }
    >
      <Button
        elementRef={ref}
        style={{ opacity }}
        rightIcon="caret-down"
        className="draggable-variable"
      >
        {children}
      </Button>
    </Popover2>
  )
}
