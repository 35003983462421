import { useEffect, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { AppToaster } from './toaster'
import { errorObjectToString } from '../ApiClient'
import { Classes } from '@blueprintjs/core'
import TimeAgo, { Duration } from './TimeAgo'

export default function Tasks() {
  const api = useApi()
  const [tasks, setTasks] = useState()
  useEffect(() => {
    ;(async () => {
      const response = await api.get('/tasks')
      if (response.ok) {
        const tasks = response.body
        setTasks(tasks)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    })()
  }, [api])
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {tasks ? (
        <table
          className={Classes.HTML_TABLE}
          style={{ marginTop: 24, padding: 24 }}
        >
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Name</th>
              <th>Description</th>
              <th>Duration</th>
              <th>Last Update</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(task => {
              const secs_since_last_update = task.last_updated
                ? new Date().getTime() -
                  new Date(Date.parse(task.last_updated)).getTime()
                : undefined
              const has_likely_failed =
                secs_since_last_update === undefined ||
                secs_since_last_update > 60 * 30
              const status = task?.started_at
                ? task.complete
                  ? task.succeeded
                    ? 'success ✅'
                    : 'failed ❌'
                  : 'running 🏃'
                : !has_likely_failed
                ? 'queued 🕒'
                : 'timeout ❌'

              return (
                <tr
                  className={
                    task.complete || has_likely_failed ? 'muted-row' : null
                  }
                  key={task.id}
                >
                  {/* <td>{task.id}</td> */}
                  <td>{task.name}</td>
                  <td>{task.description}</td>
                  <td>
                    {task.last_updated && task.started_at ? (
                      <Duration
                        isoDateStart={task.started_at}
                        isoDateEnd={task.last_updated}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {task.last_updated ? (
                      <TimeAgo isoDate={task.last_updated} />
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="no-text-wrap">{status}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
