import { useCallback, useReducer } from 'react'

const RESET = 'RESET'
const SELECT = 'SELECT'
const QUERY_CHANGE = 'QUERY_CHANGE'
const SET = 'SET'
const SET_FILTER = 'SET_FILTER'

function filteredMultiSelectReducer(state, action) {
  const { selected, filteredSelection, filteredOptions, query } = state

  const { type, generateState, newQuery, newSelection, newFilterName } = action

  switch (type) {
    case SELECT: {
      const selectedButNotInFilter = [
        ...new Set([...selected, ...newSelection]),
      ].filter(({ id }) => !filteredOptions.find(o => o.id === id))
      const previouslySelected = selected.filter(({ id }) =>
        newSelection.some(o => o.id === id)
      )
      const newlySelected = newSelection.filter(
        ({ id }) => !previouslySelected.some(o => o.id === id)
      )
      return generateState({
        ...state,
        // selected: [...selectedButNotInFilter, ...newSelection],
        selected: [
          ...selectedButNotInFilter,
          ...previouslySelected,
          ...newlySelected,
        ],
      })
    }
    case SET: {
      if (typeof newSelection === 'function') {
        return generateState({ ...state, selected: newSelection(selected) })
      }
      return generateState({ ...state, selected: newSelection })
    }
    case QUERY_CHANGE: {
      return generateState({ ...state, query: newQuery })
    }
    case SET_FILTER: {
      return generateState({ ...state, filterName: newFilterName })
    }
    case RESET: {
      return generateState(action.state)
    }
    default: {
      throw new Error(`Unsupported action: ${type}`)
    }
  }
}

export default function useFilteredMultiSelect(
  initialState = {
    selected: [],
    query: '',
    newSelection: [],
    filterName: null,
  },
  filterOptions,
  options,
  filters = []
) {
  const generateState = useCallback(
    partialState => {
      const { query, selected, filterName } = partialState
      const filter = filters.find(f => f.name === filterName)?.filter
      const filteredOptions = filterOptions(query, options, filter)
      const filteredSelection = filterOptions(query, selected, filter)
      const newState = {
        options,
        selected,
        query,
        filterName,
        filteredSelection,
        filteredOptions,
      }
      return newState
    },
    [filterOptions, filters, options]
  )
  const [state, dispatch] = useReducer(
    filteredMultiSelectReducer,
    initialState,
    generateState
  )
  const select = useCallback(
    newSelection => dispatch({ type: SELECT, newSelection, generateState }),
    [generateState]
  )
  const set = useCallback(
    newSelection => dispatch({ type: SET, newSelection, generateState }),
    [generateState]
  )
  const setFilterName = useCallback(
    newFilterName =>
      dispatch({ type: SET_FILTER, newFilterName, generateState }),
    [generateState]
  )
  const queryChange = useCallback(
    newQuery => dispatch({ type: QUERY_CHANGE, newQuery, generateState }),
    [generateState]
  )
  const reset = useCallback(() => {
    dispatch({
      type: RESET,
      generateState,
      state: initialState,
    })
  }, [generateState, initialState])
  return [state, { select, reset, queryChange, set, setFilterName }]
}
