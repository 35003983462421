import {
  Button,
  DialogStep,
  MultistepDialog,
  Menu as BpMenu,
  MenuItem,
  MenuDivider,
  Icon,
  FormGroup,
  InputGroup,
  Classes,
  TagInput,
  DialogBody,
  Text,
  Colors,
  Intent,
  Tag,
} from '@blueprintjs/core'
import {
  SurveyContext,
  flattenApiVariables,
} from '../../contexts/SurveyProvider'
import { useContext, useState } from 'react'
import { recodeTypes } from '../../pages/VariablesPage'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import './RecodeVariablesDialog.css'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useMemo } from 'react'
import { errorObjectToString } from '../../ApiClient'
import { WindowContext } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'
import useFilteredMultiSelect from '../../hooks/useFilteredMultiSelect'
import { filterQuestions } from '../VizUI'
import { IconNames } from '@blueprintjs/icons'

export default function DeleteVariablesDialog({ isOpen, onClose }) {
  const [prevRequest, setPrevRequest] = useState()
  const [error, setError] = useState()
  const { loading, setLoading } = useContext(WindowContext)
  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    variables,
    setQuestions,
    setVariables,
    setPrevVariables,
    selectedVariables,
  } = useContext(SurveyContext)

  const type = surveyDialogOpen
    ? recodeTypes.find(
        r => r?.name === surveyDialogOpen.split('-').slice(-1)[0]
      )
    : undefined

  const unconflictedVariables = useMemo(
    () => variables.filter(v => !v.is_weight && v.sources.length === 0),
    [variables]
  )
  // const passedVariables = surveyDialogOpen?.includes('-')
  //   ? surveyDialogOpen
  //       .split('-')[1]
  //       .split(',')
  //       .map(id => variables.find(v => v.id === parseInt(id)))
  //   : []
  const passedVariables = selectedVariables.map(vId =>
    variables.find(v => v.id === vId)
  )

  const [
    { selected, query, filteredSelection, filteredOptions },
    { select, reset, queryChange },
  ] = useFilteredMultiSelect(
    {
      selected: passedVariables,
      query: '',
    },
    filterQuestions,
    unconflictedVariables
  )

  const api = useApi()
  const { surveyId } = useParams()
  const hasChanged = useMemo(() => {
    const request = {
      variables: selected.map(v => v.id),
      survey_id: parseInt(surveyId),
    }
    return (
      !prevRequest || JSON.stringify(prevRequest) !== JSON.stringify(request)
    )
  }, [selected, surveyId, prevRequest])

  const handleDeleteRequest = async () => {
    const variableIds = selected.map(v => v.id)
    const request = {
      variables: variableIds,
    }
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/delete`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setError()
      reset()
      onClose()
      const { variables: deletedVariableIds, questions: deletedQuestionIds } =
        response.body
      const newVariables = variables.filter(
        v => !deletedVariableIds.includes(v.id)
      )
      setQuestions(prev => prev.filter(q => !deletedQuestionIds.includes(q.id)))
      setVariables(prev => prev.filter(v => !deletedVariableIds.includes(v.id)))
      setPrevVariables(newVariables)
      setVariables(newVariables)
      AppToaster.show({
        message: `Variable${
          deletedVariableIds.length !== 1 ? 's' : ''
        } deleted successfully`,
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <MultistepDialog
            transitionDuration={surveyDialogOpen ? 0 : 300}
            finalButtonProps={{
              text: 'Delete Variable' + (selected.length !== 1 ? 's' : ''),
              intent: 'success',
              disabled: error !== undefined || selected.length === 0,
              onClick: handleDeleteRequest,
            }}
            // style={{ width: '90vw', height: '70vh' }}
            style={{ width: width - 100, height: height - 100 }}
            onClose={onClose}
            title={
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    {recodeTypes.map((recode, i) =>
                      recode !== null ? (
                        <MenuItem
                          key={i}
                          icon={recode.icon}
                          text={recode.name}
                          labelElement={
                            <Icon
                              icon={type === recode ? 'small-tick' : 'blank'}
                            />
                          }
                          onClick={() =>
                            setSurveyDialogOpen(
                              `CreateVariableDialog-${recode.name}`
                            )
                          }
                        />
                      ) : (
                        <MenuDivider key={i} />
                      )
                    )}
                  </BpMenu>
                }
              >
                <Button
                  minimal
                  text={type?.name}
                  rightIcon="caret-down"
                  icon={type?.icon}
                />
              </Popover2>
            }
            isOpen={isOpen}
            isCloseButtonShown={true}
          >
            <DialogStep
              id="variables"
              title="Variables"
              panel={
                <DialogBody
                  style={{ display: 'flex' }}
                  id="options-panel"
                  className="options-panel"
                >
                  <div
                    id="variable-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 300, minWidth: 300 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter variables"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={query}
                        onChange={e => queryChange(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={query ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!query}
                              onClick={() => queryChange('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="variables"
                        multiple
                        value={filteredSelection.map(o => o.id)}
                        onChange={e => {
                          // const selectedOption =
                          //   e.target.selectedIndex !== -1
                          //     ? numericVariables[e.target.selectedIndex]
                          //     : null
                          const selected = [...e.target.selectedOptions].map(
                            o => {
                              return filteredOptions.find(
                                v => `${v.id}` === `${o.value}`
                              )
                            }
                          )
                          select(selected)
                        }}
                        style={{
                          // width: '300px',
                          width: '100%',
                          overflowX: 'auto',
                          height: 'calc(50vh - 80px)',
                        }}
                      >
                        {filteredOptions.map(v => {
                          const text = variableOrQuestionToString(v, null)
                          return (
                            <option value={v.id} key={v.id}>
                              {text}
                            </option>
                          )
                        })}
                      </select>
                    </FormGroup>
                    <FormGroup
                      helperText={
                        !selected.length ? (
                          <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                            Select at least 1 variable
                          </Text>
                        ) : null
                      }
                      label="Inputs Selected"
                      labelInfo="(above)"
                      labelFor="inputs-selected"
                    >
                      <TagInput
                        id="inputs-selected"
                        leftIcon={IconNames.PROPERTIES}
                        // values={selected.map(v => v.name)}
                        values={[]}
                        onRemove={(_, index) =>
                          select([
                            ...selected.slice(0, index),
                            ...selected.slice(index + 1),
                          ])
                        }
                        inputProps={{
                          disabled: true,
                          value: '',
                          onFocus: e => e.target.blur(),
                        }}
                        disabled={selected.length === 0}
                        tagProps={{ minimal: true }}
                        intent={!selected.length ? Intent.DANGER : null}
                        placeholder={
                          selected.length ? '' : 'Select inputs above'
                        }
                        rightElement={
                          <div
                            style={{
                              padding: '5px 6px',
                            }}
                          >
                            <Tag
                              minimal={selected.length === 0}
                              icon={
                                <Icon icon={IconNames.PROPERTY} size="10" />
                              }
                              intent={
                                selected.length
                                  ? !selected.length
                                    ? Intent.DANGER
                                    : Intent.PRIMARY
                                  : Intent.NONE
                              }
                              onRemove={() => reset()}
                            >
                              {selected.length} variable
                              {selected.length !== 1 ? 's' : ''}
                            </Tag>
                          </div>
                        }
                      >
                        {selected.map((v, i) => (
                          <Tooltip2
                            key={v.id}
                            popoverClassName="input-tooltip"
                            className="input-tooltip"
                            content={
                              v?.label ? (
                                <span style={{ fontSize: 12 }}>{v.label}</span>
                              ) : null
                            }
                            minimal
                          >
                            <Tag
                              key={v.id}
                              minimal
                              onRemove={() =>
                                select([
                                  ...selected.slice(0, i),
                                  ...selected.slice(i + 1),
                                ])
                              }
                            >
                              {v.name}
                            </Tag>
                          </Tooltip2>
                        ))}
                      </TagInput>
                    </FormGroup>
                  </div>
                  <div id="operations-container" style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        padding: '0 16px',
                        height: '100%',
                      }}
                    >
                      <div id="operators" className={Classes.TEXT_MUTED}>
                        <p>
                          Please note only variables that are both not used as a
                          weight and not used to create recoded variables can be
                          deleted.
                        </p>
                        <p>
                          If you would like to delete a variable that is used as
                          a weight or used to create recoded/dependent
                          variables, please delete the weight or recoded
                          variables first.
                        </p>
                        <p>
                          To see which variables are dependent on a variable,
                          hover over <Icon icon="one-to-many" /> in the Calcs
                          column in the Variables table.
                        </p>
                      </div>
                    </div>
                  </div>
                </DialogBody>
              }
            />
            <DialogStep
              id="confirm"
              title="Confirm"
              panel={
                <DialogBody>
                  <h3>Variables to Delete:</h3>
                  <table>
                    <tbody>
                      {selected.map((variable, v) => (
                        <tr key={v}>
                          <td>{variable.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </DialogBody>
              }
            />
          </MultistepDialog>
        )
      }}
    </AutoSizer>
  )
}
