import {
  Button,
  DialogStep,
  MultistepDialog,
  Menu as BpMenu,
  MenuItem,
  MenuDivider,
  Icon,
  FormGroup,
  InputGroup,
  Classes,
  TagInput,
  DialogBody,
  Text,
  Spinner,
  Colors,
  Intent,
  Tag,
  Divider,
  HTMLSelect,
  NumericInput,
  Alert,
} from '@blueprintjs/core'
import {
  SurveyContext,
  flattenApiVariables,
} from '../../contexts/SurveyProvider'
import { useContext, useState } from 'react'
import { recodeTypes } from '../../pages/VariablesPage'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import './RecodeVariablesDialog.css'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import { Cell, Column, RenderMode, Table2 } from '@blueprintjs/table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { formatValue } from '../crosstab/CrosstabTable'
import { useMemo } from 'react'
import { zScores } from '../../utils/stats'
import { interpolatePRGn } from 'd3-scale-chromatic'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { errorObjectToString } from '../../ApiClient'
import { WindowContext } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'
import useFilteredMultiSelect from '../../hooks/useFilteredMultiSelect'
import { filterQuestions } from '../VizUI'
import { NUMERIC_VARIABLE_TYPES } from './VariablesUI'
import { IconNames } from '@blueprintjs/icons'
import { isEqual } from 'lodash'

export default function RecodeVariablesDialog({ isOpen, onClose }) {
  const [prevRequest, setPrevRequest] = useState()
  const [error, setError] = useState()
  const { loading, setLoading } = useContext(WindowContext)
  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    variables,
    setQuestions,
    setVariables,
    prevVariables,
    setPrevVariables,
  } = useContext(SurveyContext)

  const type = surveyDialogOpen
    ? recodeTypes.find(
        r => r?.name === surveyDialogOpen.split('-').slice(-1)[0]
      )
    : undefined

  const [suffix, setSuffix] = useState('')
  const [labelPrefix, setLabelPrefix] = useState('')
  const [recodes, setRecodes] = useState([])

  const numericVariables = useMemo(
    () =>
      variables.filter(v => NUMERIC_VARIABLE_TYPES.includes(v.variable_type)),
    [variables]
  )

  const [
    { selected, query, filteredSelection, filteredOptions },
    { select, reset, queryChange },
  ] = useFilteredMultiSelect(
    { selected: [], query: '' },
    filterQuestions,
    numericVariables
  )

  const [data, setData] = useState()

  const api = useApi()
  const { surveyId } = useParams()
  const hasChanged = useMemo(() => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      label_prefix: labelPrefix,
      survey_id: parseInt(surveyId),
      recodes,
    }
    return (
      !prevRequest || JSON.stringify(prevRequest) !== JSON.stringify(request)
    )
  }, [selected, suffix, labelPrefix, surveyId, recodes, prevRequest])

  const handlePreviewRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      label_prefix: labelPrefix,
      survey_id: parseInt(surveyId),
      recodes,
    }
    if (!hasChanged) return
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/recode/preview`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData(response.body)
      setError()
    } else {
      setError(errorObjectToString(response.body.messages.json))
      setData()
    }
  }

  const handleCreateRequest = async () => {
    const request = {
      variables: selected.map(v => v.id),
      suffix,
      label_prefix: labelPrefix,
      survey_id: parseInt(surveyId),
      recodes,
    }
    setLoading(true)
    const response = await api.post(
      `/survey/${surveyId}/variables/recode/create`,
      request
    )
    setLoading(false)
    setPrevRequest(request)
    if (response.ok) {
      setData()
      setError()
      setSuffix('')
      setLabelPrefix('')
      reset()
      setRecodes([])
      onClose()
      const variables = response.body
      const questions = variables.map(v => v.question)
      const newFlatVariables = flattenApiVariables(variables)
      const newVariables = [...prevVariables, ...newFlatVariables]
      setQuestions(prev => [...prev, ...questions])
      // setVariables(prev => [...prev, ...newVariables])
      setPrevVariables(newVariables)
      setVariables(newVariables)
      AppToaster.show({
        message: 'Variable created',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  const summaryCellStyles = useMemo(() => {
    if (!data?.summaries) return {}
    return data?.summaries.map(summary => {
      const counts = summary.punches.flatMap(punch =>
        punch.count !== null ? [punch.count] : []
      )
      const countsZ = zScores(counts, true)
      return Object.fromEntries(
        summary.punches.map((punch, i) => {
          const zScore = countsZ?.[i]
          if (zScore === undefined) return [punch.punch, {}]
          const backgroundColor = lighten(interpolatePRGn(zScore), 0.25)
          const color = getContrastText(backgroundColor)
          return [punch.punch, { backgroundColor, color }]
        })
      )
    })
  }, [data?.summaries])

  const [oldValueType, setOldValueType] = useState('value')
  const [oldValueValue, setOldValueValue] = useState()
  const [oldValueRangeStart, setOldValueRangeStart] = useState()
  const [oldValueRangeEnd, setOldValueRangeEnd] = useState()

  const [newValueType, setNewValueType] = useState('value')
  const [newValueValue, setNewValueValue] = useState()
  const [selectedRecodes, setSelectedRecodes] = useState([])
  const [showAlert, setShowAlert] = useState(false)

  const oldValueOptionsValid =
    oldValueType === 'other-values' ||
    oldValueType === 'blank' ||
    (oldValueType === 'value' && Number.isInteger(oldValueValue)) ||
    (oldValueType === 'range' &&
      Number.isInteger(oldValueRangeStart) &&
      Number.isInteger(oldValueRangeEnd)) ||
    (oldValueType === 'range-lowest-thru-value' &&
      Number.isInteger(oldValueRangeEnd)) ||
    (oldValueType === 'range-value-thru-highest' &&
      Number.isInteger(oldValueRangeStart))

  const newValueOptionsValid =
    newValueType === 'blank' ||
    newValueType === 'copy' ||
    (newValueType === 'value' && Number.isInteger(newValueValue))

  const recodeValid =
    selected.length && oldValueOptionsValid && newValueOptionsValid

  const resetRecodeForm = () => {
    setOldValueType('value')
    setOldValueValue('')
    setOldValueRangeStart('')
    setOldValueRangeEnd('')
    setNewValueType('value')
    setNewValueValue('')
  }
  const handleRecodeAdd = () => {
    let newRecode = {
      old_value_type: oldValueType,
      new_value_type: newValueType,
    }

    if (oldValueType === 'value') {
      newRecode.old_value_value = oldValueValue
    } else if (oldValueType === 'range') {
      newRecode.old_value_range_start = oldValueRangeStart
      newRecode.old_value_range_end = oldValueRangeEnd
    } else if (oldValueType === 'range-lowest-thru-value') {
      newRecode.old_value_range_end = oldValueRangeEnd
    } else if (oldValueType === 'range-value-thru-highest') {
      newRecode.old_value_range_start = oldValueRangeStart
    }

    if (newValueType === 'value') {
      newRecode.new_value_value = newValueValue
    }

    if (recodes.some(r => isEqual(r, newRecode))) {
      setShowAlert(true)
      return
    }
    setRecodes(prev => {
      const newRecodes = [...prev, newRecode]
      return newRecodes
    })
    resetRecodeForm()
  }

  const handleRecodeDelete = () => {
    setRecodes(prev => prev.filter((_, i) => !selectedRecodes.includes(`${i}`)))
    setSelectedRecodes([])
  }

  const handleRecodeChange = () => {
    const selectedRecodeIndex = parseInt(selectedRecodes[0])
    let newRecode = {
      old_value_type: oldValueType,
      new_value_type: newValueType,
    }

    if (oldValueType === 'value') {
      newRecode.old_value_value = oldValueValue
    } else if (oldValueType === 'range') {
      newRecode.old_value_range_start = oldValueRangeStart
      newRecode.old_value_range_end = oldValueRangeEnd
    } else if (oldValueType === 'range-lowest-thru-value') {
      newRecode.old_value_range_end = oldValueRangeEnd
    } else if (oldValueType === 'range-value-thru-highest') {
      newRecode.old_value_range_start = oldValueRangeStart
    }

    if (newValueType === 'value') {
      newRecode.new_value_value = newValueValue
    }

    if (recodes.some(r => isEqual(r, newRecode))) {
      setShowAlert(true)
      return
    }

    if (recodes.some(r => isEqual(r, newRecode))) {
      setShowAlert(true)
      return
    }

    setRecodes(prev => [
      ...prev.slice(0, selectedRecodeIndex),
      newRecode,
      ...prev.slice(selectedRecodeIndex + 1),
    ])
  }

  const recodeAsStr = recode => {
    const recodeElements = []
    if (recode.old_value_type === 'value') {
      recodeElements.push(`${recode.old_value_value}`)
    } else if (recode.old_value_type === 'range') {
      recodeElements.push(
        `${recode.old_value_range_start} thru ${recode.old_value_range_end}`
      )
    } else if (recode.old_value_type === 'range-lowest-thru-value') {
      recodeElements.push(`Lowest thru ${recode.old_value_range_end}`)
    } else if (recode.old_value_type === 'range-value-thru-highest') {
      recodeElements.push(`${recode.old_value_range_start} thru Highest`)
    } else if (recode.old_value_type === 'other-values') {
      recodeElements.push('ELSE')
    } else if (recode.old_value_type === 'blank') {
      recodeElements.push('BLANK')
    }
    recodeElements.push('—>')

    if (recode.new_value_type === 'value') {
      recodeElements.push(`${recode.new_value_value}`)
    } else if (recode.new_value_type === 'copy') {
      recodeElements.push('COPY')
    } else if (recode.new_value_type === 'blank') {
      recodeElements.push('BLANK')
    }
    return recodeElements.join(' ')
  }

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <MultistepDialog
            transitionDuration={surveyDialogOpen ? 0 : 300}
            finalButtonProps={{
              text:
                'Create Variable' + (data?.summaries?.length !== 1 ? 's' : ''),
              intent: 'success',
              disabled: error !== undefined,
              onClick: handleCreateRequest,
            }}
            // style={{ width: '90vw', height: '70vh' }}
            style={{ width: width - 100, height: height - 100 }}
            onClose={onClose}
            onChange={(newStep, prevStep, ev) => {
              // ev.preventDefault()
              // ev.stopPropagation()
              if (newStep === 'preview' || newStep === 'summary') {
                handlePreviewRequest()
              }
            }}
            title={
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    {recodeTypes.map((recode, i) =>
                      recode !== null ? (
                        <MenuItem
                          key={i}
                          icon={recode.icon}
                          text={recode.name}
                          labelElement={
                            <Icon
                              icon={type === recode ? 'small-tick' : 'blank'}
                            />
                          }
                          onClick={() =>
                            setSurveyDialogOpen(
                              `CreateVariableDialog-${recode.name}`
                            )
                          }
                        />
                      ) : (
                        <MenuDivider key={i} />
                      )
                    )}
                  </BpMenu>
                }
              >
                <Button
                  minimal
                  text={type?.name}
                  rightIcon="caret-down"
                  icon={type?.icon}
                />
              </Popover2>
            }
            isOpen={isOpen}
            isCloseButtonShown={true}
          >
            <DialogStep
              id="variables"
              title="Variables"
              nextButtonProps={{
                disabled: !suffix || !labelPrefix,
              }}
              panel={
                <DialogBody
                  style={{ display: 'flex' }}
                  id="options-panel"
                  className="options-panel"
                >
                  <div
                    id="variable-select-container"
                    className="select-container"
                    style={{ overflow: 'auto', width: 300, minWidth: 300 }}
                  >
                    <FormGroup style={{ flexGrow: 2 }}>
                      <InputGroup
                        leftIcon="filter"
                        placeholder="Filter variables"
                        style={{ marginBottom: 10, width: '100%' }}
                        value={query}
                        onChange={e => queryChange(e.target.value)}
                        rightElement={
                          <Tooltip2
                            content={query ? 'Clear filter' : null}
                            minimal
                            placement="right"
                          >
                            <Button
                              minimal
                              title="Clear filter"
                              icon="cross"
                              disabled={!query}
                              onClick={() => queryChange('')}
                            />
                          </Tooltip2>
                        }
                      />
                      <select
                        className={Classes.INPUT}
                        id="variables"
                        multiple
                        value={filteredSelection.map(o => o.id)}
                        onChange={e => {
                          // const selectedOption =
                          //   e.target.selectedIndex !== -1
                          //     ? numericVariables[e.target.selectedIndex]
                          //     : null
                          const selected = [...e.target.selectedOptions].map(
                            o => {
                              return filteredOptions.find(
                                v => `${v.id}` === `${o.value}`
                              )
                            }
                          )
                          select(selected)
                        }}
                        style={{
                          // width: '300px',
                          width: '100%',
                          overflowX: 'auto',
                          height: 'calc(50vh - 80px)',
                        }}
                      >
                        {filteredOptions.map(v => {
                          const text = variableOrQuestionToString(v, null)
                          return (
                            <option value={v.id} key={v.id}>
                              {text}
                            </option>
                          )
                        })}
                      </select>
                    </FormGroup>
                    <FormGroup
                      helperText={
                        !selected.length ? (
                          <Text style={{ color: Colors.RED3, fontWeight: 500 }}>
                            Select at least 1 variable
                          </Text>
                        ) : null
                      }
                      label="Inputs Selected"
                      labelInfo="(above)"
                      labelFor="inputs-selected"
                    >
                      <TagInput
                        id="inputs-selected"
                        leftIcon={IconNames.PROPERTIES}
                        // values={selected.map(v => v.name)}
                        values={[]}
                        onRemove={(_, index) =>
                          select([
                            ...selected.slice(0, index),
                            ...selected.slice(index + 1),
                          ])
                        }
                        inputProps={{
                          disabled: true,
                          value: '',
                          onFocus: e => e.target.blur(),
                        }}
                        disabled={selected.length === 0}
                        tagProps={{ minimal: true }}
                        intent={!selected.length ? Intent.DANGER : null}
                        placeholder={
                          selected.length ? '' : 'Select inputs above'
                        }
                        rightElement={
                          <div
                            style={{
                              padding: '5px 6px',
                            }}
                          >
                            <Tag
                              minimal={selected.length === 0}
                              icon={
                                <Icon icon={IconNames.PROPERTY} size="10" />
                              }
                              intent={
                                selected.length
                                  ? !selected.length
                                    ? Intent.DANGER
                                    : Intent.PRIMARY
                                  : Intent.NONE
                              }
                              onRemove={() => reset()}
                            >
                              {selected.length} variable
                              {selected.length !== 1 ? 's' : ''}
                            </Tag>
                          </div>
                        }
                      >
                        {selected.map((v, i) => (
                          <Tooltip2
                            key={v.id}
                            popoverClassName="input-tooltip"
                            className="input-tooltip"
                            content={
                              v?.label ? (
                                <span style={{ fontSize: 12 }}>{v.label}</span>
                              ) : null
                            }
                            minimal
                          >
                            <Tag
                              key={v.id}
                              minimal
                              onRemove={() =>
                                select([
                                  ...selected.slice(0, i),
                                  ...selected.slice(i + 1),
                                ])
                              }
                            >
                              {v.name}
                            </Tag>
                          </Tooltip2>
                        ))}
                      </TagInput>
                    </FormGroup>
                  </div>
                  <div id="operations-container" style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        padding: '0 16px',
                        height: '100%',
                      }}
                    >
                      <div id="operators" style={{ padding: '12px 0' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                          }}
                        >
                          <FormGroup
                            label="Variable Suffix"
                            labelFor="variable-suffix"
                            helperText="Ex: _T2B"
                            style={{ marginTop: 20 }}
                          >
                            <InputGroup
                              autoFocus
                              value={suffix}
                              onChange={e => setSuffix(e.target.value)}
                              id="variable-name-suffix"
                              placeholder="Enter a suffix.."
                            />
                          </FormGroup>
                          <FormGroup
                            label="Label Prefix"
                            labelFor="variable-label"
                            helperText="If Prefix = T2B then Label = T2B : Satisfaction 12 oz Lotion"
                            style={{ marginTop: 20 }}
                          >
                            <InputGroup
                              autoFocus
                              value={labelPrefix}
                              onChange={e => setLabelPrefix(e.target.value)}
                              id="variable-label"
                              placeholder="Enter a label prefix.."
                            />
                          </FormGroup>
                        </div>
                        <Divider />
                        {/* <h2>Preview</h2> */}
                        <div
                          style={{
                            maxHeight: 'calc(70vh - 157px)',
                            maxWidth: 350,
                            overflow: 'auto',
                            alignSelf: 'center',
                          }}
                        >
                          {selected.length ? (
                            <table>
                              <tbody>
                                {selected.map(v => (
                                  <tr key={v.id}>
                                    <td
                                      style={{
                                        textAlign: 'right',
                                        color: Colors.GRAY1,
                                      }}
                                    >
                                      {v.name}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        width: 30,
                                      }}
                                    >
                                      {/* ➡ */}
                                      {/* {'-->'} */}
                                      <Icon icon="arrow-right" />
                                    </td>
                                    {/* <td style={{ fontWeight: 500 }}> */}
                                    <td>{v.name + suffix}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            'Select variables to preview new variable names'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogBody>
              }
            />
            <DialogStep
              id="recodes"
              title="Recodes"
              nextButtonProps={{ disabled: !recodes.length }}
              panel={
                <DialogBody>
                  <Alert
                    intent={Intent.WARNING}
                    icon={IconNames.WARNING_SIGN}
                    isOpen={showAlert}
                    onClose={() => setShowAlert(false)}
                  >
                    This recode already exists
                  </Alert>
                  <div
                    id="recodes-container"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 36,
                      alignItems: 'center',
                    }}
                  >
                    <div
                      id="values-container"
                      style={{
                        display: 'flex',
                        gap: 32,
                        justifyContent: 'center',
                      }}
                    >
                      <fieldset
                        id="old-value-fieldset"
                        className="value-fieldset"
                      >
                        <legend>Old Value</legend>
                        <FormGroup labelFor="old-value-type" label="Type">
                          <HTMLSelect
                            id="old-value-type"
                            value={oldValueType}
                            onChange={e => setOldValueType(e.target.value)}
                          >
                            <option value="value">Value</option>
                            <option value="blank">Blank</option>
                            <option value="range">Range</option>
                            <option value="range-lowest-thru-value">
                              Range: Lowest Thru Value
                            </option>
                            <option value="range-value-thru-highest">
                              Range: Value Thru Highest
                            </option>
                            <option value="other-values">
                              All Other Values
                            </option>
                          </HTMLSelect>
                        </FormGroup>
                        <div className="range-inputs">
                          {oldValueType === 'value' ? (
                            <FormGroup
                              labelFor="old-value-value"
                              label="Old Value"
                            >
                              <NumericInput
                                id="old-value-value"
                                inputClassName="numeric-input"
                                placeholder="Old Value"
                                value={oldValueValue}
                                onValueChange={v => setOldValueValue(v)}
                              />
                            </FormGroup>
                          ) : oldValueType === 'range' ? (
                            <>
                              <NumericInput
                                placeholder="Start"
                                inputClassName="numeric-input"
                                value={oldValueRangeStart}
                                onValueChange={v => setOldValueRangeStart(v)}
                              />
                              <Icon
                                className="through-icon"
                                icon="arrow-down"
                              />
                              <NumericInput
                                placeholder="End"
                                inputClassName="numeric-input"
                                value={oldValueRangeEnd}
                                onValueChange={v => setOldValueRangeEnd(v)}
                              />
                            </>
                          ) : oldValueType === 'range-lowest-thru-value' ? (
                            <>
                              <div className={`fake-input numeric-input`}>
                                Lowest
                              </div>
                              <Icon
                                className="through-icon"
                                icon="arrow-down"
                              />
                              <NumericInput
                                inputClassName="numeric-input"
                                placeholder="Value"
                                value={oldValueRangeEnd}
                                onValueChange={v => setOldValueRangeEnd(v)}
                              />
                            </>
                          ) : oldValueType === 'range-value-thru-highest' ? (
                            <>
                              <NumericInput
                                inputClassName="numeric-input"
                                placeholder="Value"
                                value={oldValueRangeStart}
                                onValueChange={v => setOldValueRangeStart(v)}
                              />
                              <Icon
                                className="through-icon"
                                icon="arrow-down"
                              />
                              <div className={`fake-input numeric-input`}>
                                Highest
                              </div>
                            </>
                          ) : null}
                        </div>
                      </fieldset>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="direction-right" size={28} />
                      </div>
                      <fieldset
                        id="new-value-fieldset"
                        className="value-fieldset"
                      >
                        <legend>New Value</legend>
                        <FormGroup labelFor="new-value-type" label="Type">
                          <HTMLSelect
                            id="new-value-type"
                            value={newValueType}
                            onChange={e => setNewValueType(e.target.value)}
                          >
                            <option value="value">Value</option>
                            <option value="blank">Blank</option>
                            <option value="copy">Copy Old Value</option>
                          </HTMLSelect>
                        </FormGroup>
                        {newValueType === 'value' ? (
                          <FormGroup labelFor="new-value" label="New Value">
                            <NumericInput
                              placeholder="New Value"
                              value={newValueValue}
                              onValueChange={v => setNewValueValue(v)}
                            />
                          </FormGroup>
                        ) : null}
                      </fieldset>
                    </div>
                    <Button
                      rightIcon="arrow-down"
                      large
                      text="Add"
                      disabled={!recodeValid}
                      onClick={handleRecodeAdd}
                    />
                    <div
                      id="recode-list-container"
                      style={{ display: 'flex', flexDirection: 'row', gap: 4 }}
                    >
                      <fieldset
                        id="recode-list-fieldset"
                        style={{
                          width: 'fit-content',
                          minWidth: 350,
                          display: 'flex',
                          gap: 4,
                        }}
                      >
                        <legend>Recode List</legend>
                        <select
                          className={Classes.INPUT}
                          multiple
                          id="recode-list"
                          style={{ width: '100%', minHeight: 100 }}
                          value={selectedRecodes}
                          onChange={e => {
                            const selectedRecodes = Array.from(
                              e.target.selectedOptions
                            ).map(o => o.value)
                            if (selectedRecodes.length === 1) {
                              const selectedRecode =
                                recodes[parseInt(selectedRecodes[0])]
                              setOldValueType(selectedRecode.old_value_type)
                              setOldValueValue(selectedRecode.old_value_value)
                              setOldValueRangeStart(
                                selectedRecode.old_value_range_start
                              )
                              setOldValueRangeEnd(
                                selectedRecode.old_value_range_end
                              )
                              setNewValueType(selectedRecode.new_value_type)
                              setNewValueValue(selectedRecode.new_value_value)
                            }
                            setSelectedRecodes(selectedRecodes)
                          }}
                        >
                          {recodes.map((recode, r) => (
                            <option key={r} value={r}>
                              {recodeAsStr(recode)}
                            </option>
                          ))}
                        </select>
                        <div
                          id="recode-list-buttons"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 100,
                            gap: 4,
                          }}
                        >
                          <Button
                            text="Change"
                            icon="edit"
                            onClick={handleRecodeChange}
                            disabled={selectedRecodes.length !== 1}
                          />
                          <Button
                            text="Remove"
                            icon="trash"
                            minimal
                            intent="danger"
                            onClick={handleRecodeDelete}
                            disabled={selectedRecodes.length === 0}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </DialogBody>
              }
            />
            <DialogStep
              id="summary"
              panel={
                <DialogBody id="summary-panel" className="summary-panel">
                  {!loading ? (
                    data?.summaries ? (
                      data.summaries.map((summary, s) => (
                        <div className="summary">
                          <h4>
                            {summary.name} — {summary.label}
                          </h4>
                          {summary.punches ? (
                            <table
                              className={[
                                Classes.HTML_TABLE,
                                Classes.HTML_TABLE_CONDENSED,
                              ].join(' ')}
                            >
                              <thead>
                                <tr>
                                  <th />
                                  <th>Count</th>
                                  <th>Percent</th>
                                  <th>Valid Percent</th>
                                  <th>Cum. Percent</th>
                                </tr>
                              </thead>
                              <tbody>
                                {summary.punches.map((punch, p) => {
                                  const recodeStr = recodes
                                    .filter(
                                      ({ new_value_value, new_value_type }) =>
                                        new_value_value === punch.punch ||
                                        (new_value_type === 'blank' &&
                                          punch.punch === null)
                                    )
                                    .map(
                                      recode =>
                                        `   ${
                                          recodeAsStr(recode).split(' —> ')[0]
                                        }`
                                    )
                                    .join(', ')

                                  return (
                                    <tr key={p}>
                                      <th className="numeric-cell">
                                        {punch.punch === null
                                          ? 'Missing'
                                          : punch.punch}
                                        <span
                                          className={
                                            Classes.TEXT_MUTED +
                                            ' ' +
                                            Classes.TEXT_SMALL
                                          }
                                        >
                                          {recodeStr}
                                        </span>
                                      </th>
                                      <td className="numeric-cell">
                                        {punch.count ?? '~'}
                                      </td>
                                      <td
                                        className="numeric-cell"
                                        style={
                                          summaryCellStyles[s][punch.punch]
                                        }
                                      >
                                        {formatValue(punch.percent, 1, true) ??
                                          '~'}
                                      </td>
                                      <td className="numeric-cell">
                                        {formatValue(
                                          punch.valid_percent,
                                          1,
                                          true
                                        ) ?? '~'}
                                      </td>
                                      <td className="numeric-cell">
                                        {formatValue(
                                          punch.cum_percent,
                                          1,
                                          true
                                        ) ?? '~'}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          ) : (
                            'Summary cannot be generated for this variable. This is likely because it has too many unique values.'
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
              title="Summary"
            />
            <DialogStep
              id="preview"
              title="Finalize"
              nextButtonProps={{ disabled: error !== undefined }}
              panel={
                <DialogBody id="preview-panel" className="preview-panel">
                  {!loading ? (
                    data?.data ? (
                      <div style={{ height: height - 330, width: width - 440 }}>
                        <Table2
                          numFrozenColumns={1}
                          numRows={data.data.length}
                          cellRendererDependencies={[data]}
                          renderMode={RenderMode.BATCH}
                          getCellClipboardData={(row, col) =>
                            data.data[row][col]
                          }
                        >
                          {data?.headers.map((column, c) => (
                            <Column
                              key={column}
                              name={column}
                              cellRenderer={rowIndex => (
                                <Cell>{data.data[rowIndex][c]}</Cell>
                              )}
                            />
                          ))}
                        </Table2>
                      </div>
                    ) : (
                      <div className="error-text">
                        <h3>Error</h3>
                        <Text className="error-text">{error}</Text>
                      </div>
                    )
                  ) : (
                    <Spinner />
                  )}
                </DialogBody>
              }
            />
          </MultistepDialog>
        )
      }}
    </AutoSizer>
  )
}
