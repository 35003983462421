import { FileInput, H1, Spinner } from '@blueprintjs/core'

import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import { useApi } from '../contexts/ApiProvider'
import { useContext, useState } from 'react'
import { WindowContext } from '../contexts/WindowProvider'
import { useNavigate } from 'react-router-dom'
import { TEXT_LARGE } from '@blueprintjs/core/lib/esm/common/classes'
import './Upload.css'
import { flattenApiVariables, SurveyContext } from '../contexts/SurveyProvider'
function Upload() {
  const api = useApi()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [fileInputText, setFileInputText] = useState('Choose a file...')
  const { setSurveys, setCurrentSurvey } = useContext(WindowContext)
  const { setVariables, setPrevVariables } = useContext(SurveyContext)
  const navigate = useNavigate()

  const uploadFile = async file => {
    if (!file) return
    setFileInputText(file.name)
    document.body.style.cursor = 'progress'
    setLoading('loading')
    setError()

    const response = await api.post('/survey/restore', file)
    if (response.ok) {
      setLoading('success')
      const survey = response.body
      setSurveys(prev => [survey, ...prev])
      const variables = flattenApiVariables(survey.variables)
      setVariables(variables)
      setPrevVariables(variables)
      setCurrentSurvey(
        Object.fromEntries(
          Object.keys(survey)
            .filter(k => k !== 'variables')
            .map(k => [k, survey[k]])
        )
      )
      navigate('/survey/' + survey.id + '/variables')
    } else {
      setFileInputText('Choose a file...')
      if (response.status === 415) {
        setError('Unsupported file format.')
      } else if (response.status === 400) {
        // setError(
        //   'Number of respondents in uploaded data does not match existing survey data.'
        // )
      }
      setLoading('error')
    }
    document.body.style.cursor = 'default'
  }

  return (
    <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          marginTop: '64px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {['error', undefined].includes(loading) ? (
          <>
            <H1 style={{ marginBottom: '16px' }}>Create Survey From Backup</H1>
            <div id="upload-info-text">
              Creates a new survey from a .backup file. This can be used to
              restore a survey that has been deleted or to create a copy of an
              existing survey.
            </div>
            <FileInput
              inputProps={{
                accept: '.backup,.gz,.tgz',
                multiple: false,
              }}
              large
              text={fileInputText}
              buttonText={loading !== 'loading' ? 'Browse' : 'Uploading...'}
              disabled={['loading', 'success'].includes(loading)}
              onInputChange={ev => {
                uploadFile(ev.target.files[0])
                ev.target.value = ''
              }}
            />
            {error !== undefined ? (
              <p
                style={{
                  color: '#AC2F33',
                  marginTop: '8px',
                  fontWeight: 'bold',
                }}
              >
                {error}
              </p>
            ) : null}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '12px' }} className={TEXT_LARGE}>
              Uploading {fileInputText}...
            </div>
            <Spinner />
          </>
        )}
      </div>
    </div>
  )
}

export default Upload
