import { memo, useLayoutEffect, useRef } from 'react'
import Select from 'react-select'
import { CellProps } from 'react-datasheet-grid'

export default function selectColumn(options) {
  return {
    component: SelectComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }) =>
      options.choices.find(choice => choice.value === rowData)?.label,
    pasteValue: ({ value }) =>
      options.choices.find(choice => choice.label === value)?.value ?? null,
  }
}

const SelectComponent = memo(
  /**
   *
   * @param {CellProps} param0
   * @returns
   */
  ({ focus, active, stopEditing, columnData, rowData, setRowData }) => {
    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])
    const ref = useRef()
    return (
      <Select
        className="select-column-select"
        ref={ref}
        styles={{
          container: provided => ({
            ...provided,
            flex: 1,
            alignSelf: 'stretch',
            pointerEvents: focus ? undefined : 'none',
          }),
          singleValue: provided => ({
            ...provided,
            color: 'inherit',
          }),
          control: provided => ({
            ...provided,
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            background: 'none',
            minHeight: 25,
          }),
          indicatorSeparator: provided => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
            height: 25,
          }),
          clearIndicator: provided => ({
            ...provided,
            padding: 4,
          }),
          dropdownIndicator: provided => ({
            ...provided,
            padding: 4,
          }),
          menu: provided => ({ ...provided, marginTop: 0 }),
          option: (provided, { isDisabled, isSelected, isFocused }) => ({
            ...provided,
            fontSize: 11,
            letterSpacing: '0.005em',
            padding: 4,
            backgroundColor: !isDisabled
              ? isSelected
                ? '#2c72d2'
                : isFocused
                ? 'rgba(45,113,210, 0.15)'
                : 'inherit'
              : undefined,
          }),
          placeholder: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
        }}
        isDisabled={columnData.disabled}
        value={
          columnData.choices.find(({ value }) => value === rowData) ?? null
        }
        menuPortalTarget={document.body}
        menuIsOpen={focus}
        onChange={({ value }) => {
          setRowData(value)
          setTimeout(stopEditing, 0)
        }}
        onMenuClose={() => stopEditing({ nextRow: false })}
        options={columnData.choices}
      />
    )
  }
)
