import { useEffect, useState } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { AppToaster } from './toaster'
import { errorObjectToString } from '../ApiClient'
import { Classes } from '@blueprintjs/core'
import TimeAgo from './TimeAgo'

export default function Users() {
  const api = useApi()
  const [users, setUsers] = useState()
  useEffect(() => {
    ;(async () => {
      const response = await api.get('/users')
      if (response.ok) {
        const users = response.body
        console.log(users)
        setUsers(users)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    })()
  }, [api])
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {users ? (
        <table
          className={Classes.HTML_TABLE}
          style={{ marginTop: 24, padding: 24 }}
        >
          <thead>
            <th>ID</th>
            <th>Email</th>
            <th>Role</th>
            <th>Requests</th>
            <th>Last Endpoint</th>
            <th>Last Seen</th>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.num_requests}</td>
                <td>{user.last_endpoint}</td>
                <td>
                  {user.last_seen ? <TimeAgo isoDate={user.last_seen} /> : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        'Loading...'
      )}
    </div>
  )
}
