import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SurveyContext } from '../../contexts/SurveyProvider'
import { Link, useParams } from 'react-router-dom'
import { useApi } from '../../contexts/ApiProvider'
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  InputGroup,
} from '@blueprintjs/core'
import { AppToaster } from '../toaster'
import { errorObjectToString } from '../../ApiClient'
import { DIALOG_FOOTER_ACTIONS } from '@blueprintjs/core/lib/esm/common/classes'
import { Keyframes } from '../Keyframes'
import { WindowContext } from '../../contexts/WindowProvider'
import { ReactSortable } from 'react-sortablejs'
import Sortable from 'sortablejs'
import './QuestionReorderDialog.css'
import { matchSorter } from 'match-sorter'
import { Tooltip2 } from '@blueprintjs/popover2'
import { getIsMac } from '../../utils/isMac'
import { set } from 'lodash'

export default function QuestionReorderDialog() {
  const { surveyId } = useParams()
  const api = useApi()

  const {
    surveyDialogOpen,
    setSurveyDialogOpen,
    questions,
    setQuestions,
    setVariables,
  } = useContext(SurveyContext)
  const onClose = () => setSurveyDialogOpen()
  const isOpen = surveyDialogOpen === 'QuestionReorderDialog'

  const [loading, setLoading] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [selected, setSelected] = useState([])
  const { currentSurvey } = useContext(WindowContext)

  const [prevOrder, setPrevOrder] = useState()
  const [order, setOrder] = useState()
  const dialogBodyRef = useRef()

  useEffect(() => {
    // initialize order once questions are loaded
    if (questions === undefined || order !== undefined) return
    setOrder(questions)
    setPrevOrder(questions)
  }, [questions, setOrder, order, prevOrder])

  const hasChanged = useMemo(() => {
    if (order === undefined || prevOrder === undefined) return false
    return order.some((q, i) => q.id !== prevOrder[i].id)
  }, [order, prevOrder])

  const searchRef = useRef()
  const handleSearch = () => {
    const search = searchRef.current.value
    if (!search) {
      AppToaster.show({
        message: `Please enter a search term`,
        intent: 'warning',
        timeout: 1500,
        isCloseButtonShown: false,
      })
      return
    }
    const matches = matchSorter(questions, search, {
      keys: ['name', 'question', 'label', 'id'],
    })
    if (!matches.length) {
      AppToaster.show({
        message: `No matches found for "${search}"`,
        intent: 'warning',
        timeout: 1500,
        isCloseButtonShown: false,
      })
      return
    }
    const firstMatch = matches[0]
    // const firstMatchIndex = order.findIndex(v => v.id === firstMatch.id)
    const e = document.querySelector(`[data-id="${firstMatch.id}"]`)
    e.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const moveQuestions = toTop => {
    const selectedIds = selected.map(q => q.id)
    const dialogBody = document.querySelector('.reorder-dialog-body')
    if (toTop) {
      setOrder(prev => [
        ...selected,
        ...prev.filter(q => !selectedIds.includes(q.id)),
      ])
      // dialogBody.scrollTop = 0
    } else {
      setOrder(prev => {
        const unselected = prev.filter(q => !selectedIds.includes(q.id))
        console.log(unselected.slice(-1)[0])

        // document
        //   .querySelector(`[data-id="${unselected.slice(-1)[0].id}"]`)
        //   .scrollIntoView({ block: 'start', behavior: 'instant' })
        return [...unselected, ...selected]
      })
      dialogBody.scrollTop = dialogBody.scrollHeight
    }
  }

  const handleSubmit = async () => {
    const data = { ids: order.map(q => q.id), survey_id: surveyId }
    setLoading(true)
    const response = await api.put(
      `/survey/${surveyId}/questions/reorder`,
      data
    )
    if (response.ok) {
      const { q_ids, v_ids } = response.body
      let newOrder
      setQuestions(prev => {
        newOrder = [...prev].sort(
          (a, b) => q_ids.indexOf(a.id) - q_ids.indexOf(b.id)
        )
        return newOrder
      })
      setVariables(prev =>
        [...prev].sort((a, b) => v_ids.indexOf(a.id) - v_ids.indexOf(b.id))
      )
      setOrder(newOrder)
      setPrevOrder(newOrder)
      onClose()
      AppToaster.show({
        message: 'Questions reordered successfully',
        intent: 'success',
        icon: 'tick',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
    setLoading(false)
  }

  const isMac = getIsMac()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Reorder Questions"
      icon="swap-vertical"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 4,
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSearch()
          }}
        >
          <InputGroup
            type="search"
            placeholder="Search..."
            // leftIcon="search"
            small
            // rightElement={
            //   <Button icon="cross" minimal={true} intent="danger" />
            // }
            // value={search}
            // onChange={e => setSearch(e.target.value)}
            inputRef={searchRef}
            rightElement={
              <Button
                type="submit"
                icon="search"
                style={{ borderRadius: '50%' }}
                minimal
              />
            }
          />
        </form>
        <ButtonGroup>
          <Tooltip2
            content={hasChanged ? 'Reset to previous order' : ''}
            intent="danger"
            minimal
          >
            <Button
              small
              rightIcon="undo"
              intent="danger"
              minimal
              disabled={!hasChanged}
              onClick={() => {
                setOrder(prevOrder)
              }}
            />
          </Tooltip2>
          <Tooltip2
            content={selected.length !== 0 ? 'Move to top' : ''}
            minimal
          >
            <Button
              // text="To Top"
              // minimal
              small
              onClick={() => moveQuestions(true)}
              disabled={selected.length === 0}
              rightIcon={
                <Icon
                  icon="bring-data"
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
            />
          </Tooltip2>
          <Tooltip2 content="Move to bottom" minimal>
            <Button
              // text="To Bottom"
              // minimal
              small
              onClick={() => moveQuestions(false)}
              disabled={selected.length === 0}
              rightIcon="bring-data"
            />
          </Tooltip2>
        </ButtonGroup>
      </div>
      <DialogBody className="reorder-dialog-body">
        {order === undefined || currentSurvey === undefined ? (
          <p>loading...</p>
        ) : order === null ? (
          <p>Couldn't retrieve variables</p>
        ) : order.length === 0 ? (
          'No Questions found.'
        ) : (
          <>
            <Keyframes
              name="DraggedAnimation"
              _0={{ backgroundPosition: '0% 50%', opacity: 0.9 }}
              _50={{ backgroundPosition: '100% 50%', opacity: 0.5 }}
              _100={{ backgroundPosition: '0% 50%', opacity: 0.9 }}
            />
            <table
              className={[
                // Classes.HTML_TABLE_STRIPED,
                Classes.HTML_TABLE,
                Classes.HTML_TABLE + '-condensed',
                Classes.HTML_TABLE_BORDERED,
              ].join(' ')}
              // style={{ borderCollapse: 'collapse' }}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>old #</th>
                  <th>Name</th>
                  {/* <th>Label</th>
                <th>Question</th> */}
                </tr>
              </thead>
              {/* <tbody> */}
              <ReactSortable
                className="sortable-table"
                multiDrag
                list={order}
                setList={setOrder}
                // list={order}
                // setList={newState => {
                //   console.log({ newState })
                //   setOrder(newState)
                // }}
                tag={'tbody'}
                multiDragKey={isMac ? 'meta' : 'ctrl'}
                avoidImplicitDeselect
                forceFallback
                style={{ cursor: dragging ? 'grabbing' : 'move' }}
                onSelect={evt => {
                  const selectedIds = evt.items.map(e =>
                    parseInt(e.getAttribute('data-id'))
                  )
                  const selectedQuestions = order.filter(q =>
                    selectedIds.includes(q.id)
                  )
                  setSelected(selectedQuestions)
                }}
                onDeselect={evt => {
                  const selectedIds = evt.items.map(e =>
                    parseInt(e.getAttribute('data-id'))
                  )
                  const selectedQuestions = order.filter(q =>
                    selectedIds.includes(q.id)
                  )
                  setSelected(selectedQuestions)
                }}
                // onSelect={evt => {
                //   const getRanges = integers => {
                //     const validRanges = []

                //     let startIndex = 0
                //     while (startIndex < integers.length - 1) {
                //       const startInteger = integers[startIndex]
                //       let lookAhead = 1
                //       let nextLookIndex = startIndex + lookAhead
                //       let expectedValue = startInteger + lookAhead
                //       while (
                //         lookAhead < integers.length &&
                //         integers[nextLookIndex] === expectedValue
                //       ) {
                //         lookAhead += 1
                //         nextLookIndex = startIndex + lookAhead
                //         expectedValue = startInteger + lookAhead
                //       }
                //       const endLevelNum = integers[nextLookIndex - 1]
                //       validRanges.push([startInteger, endLevelNum])
                //       startIndex = nextLookIndex
                //     }
                //     return validRanges
                //   }
                //   const indices = evt.newIndicies.map(({ index }) => index)
                //   const sortedIndices = indices.sort((a, b) => a - b)
                //   const items = evt.newIndicies.map(
                //     ({ multiDragElement: item }) => item.children[1].innerText
                //   )
                //   console.log({ indices, sortedIndices, items, evt })

                //   const ranges = getRanges(sortedIndices)
                //   ranges.forEach(([start, end]) => {
                //     console.log({ start, end })
                //     const startRow = evt.items[indices.indexOf(start)]
                //     const endRow = evt.items[indices.indexOf(end)]
                //     const startName = startRow.children[1].innerText
                //     const endName = endRow.children[1].innerText
                //     console.log({ startName, endName })
                //     startRow.classList.add('start-selected-row')
                //     endRow.classList.add('end-selected-row')
                //   })
                // }}
                onStart={evt => {
                  setDragging(true)
                  if (evt.items.length > 1) {
                    Sortable.ghost.children[0].colSpan = 3
                    Sortable.ghost.children[0].innerText = `${evt.items.length} questions...`
                    Sortable.ghost.children[1].style.display = 'none'
                    Sortable.ghost.children[2].style.display = 'none'

                    Sortable.dragged.children[0].colSpan = 3
                    Sortable.dragged.children[0].innerText = `${evt.items.length} questions...`
                    Sortable.dragged.children[1].style.display = 'none'
                    Sortable.dragged.children[2].style.display = 'none'
                  }
                  // Sortable.dragged.style.opacity = 0.6
                  Sortable.dragged.className += ' sortable-drag'
                  Sortable.dragged.style.fontStyle = 'italic'
                  Sortable.dragged.style.background =
                    'linear-gradient(315deg, rgba(45,114,210,.2), rgba(45,114,210,.4)'
                  Sortable.dragged.style.backgroundSize = '400% 400%'
                  Sortable.dragged.style.animation =
                    'DraggedAnimation 0.75s ease infinite'
                  Sortable.dragged.style.border = '1px solid #2a77cd'
                  Sortable.ghost.style.fontStyle = 'italic'
                }}
                onEnd={evt => {
                  setDragging(false)
                  if (evt.items.length > 1) {
                    evt.item.children[0].colSpan = null
                    evt.item.children[0].innerText = evt.newIndex + 1
                    evt.item.children[1].style.display = null
                    evt.item.children[2].style.display = null
                  }
                  Sortable.dragged.style.opacity = null
                  Sortable.dragged.style.fontStyle = null
                  Sortable.dragged.style.background = null
                  Sortable.dragged.style.backgroundSize = null
                  Sortable.dragged.style.animation = null
                  Sortable.dragged.style.border = null
                }}
                // animation={150}
                // fallbackOnBody
                // swapThreshold={0.65}
              >
                {order.map((question, i) => {
                  const oldIndex = prevOrder.findIndex(
                    q => q.id === question.id
                  )
                  const isReordered = i !== oldIndex
                  return (
                    <tr
                      key={question.id}
                      className={isReordered ? 'sortable-reordered' : ''}
                    >
                      {/* <td style={dragging ? { opacity: 0 } : {}}>{i + 1}</td> */}
                      <td>{i + 1}</td>
                      <td className={'old-index'}>
                        {isReordered ? oldIndex + 1 : ''}
                      </td>

                      <td>{question.name}</td>
                      {/* <td>{variable.label}</td>
                  <td>{variable.question}</td> */}
                    </tr>
                  )
                })}
              </ReactSortable>
              {/* </tbody> */}
            </table>
          </>
        )}
      </DialogBody>
      <DialogFooter>
        <div className={DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} intent="danger" minimal>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            intent="success"
            disabled={!hasChanged}
            rightIcon="floppy-disk"
            loading={loading}
          >
            Commit
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  )
}
