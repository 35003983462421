import React, { useState, useEffect, useCallback } from 'react'
import { MultiSelect2 } from '@blueprintjs/select'
import { FormGroup, MenuItem } from '@blueprintjs/core'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import { useApi } from '../contexts/ApiProvider'
import { useSurvey } from '../contexts/SurveyProvider'
import { AppToaster } from './toaster'
import highlightText from '../utils/highlightText'

function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

const EmailMultiSelect = () => {
  const api = useApi()
  const { surveyId } = useSurvey()

  const [allRegisteredEmails, setAllRegisteredEmails] = useState([])
  const [surveyRegisteredEmails, setSurveyRegisteredEmails] = useState([])

  const fetchAllRegisteredEmails = useCallback(async () => {
    const response = await api.get('/surveys/all_registered_users')
    if (response.ok) {
      console.log(response.body)
      setAllRegisteredEmails(response.body.map(user => user.email))
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
    }
  }, [api, setAllRegisteredEmails])

  const fetchSurveyRegisteredEmails = useCallback(async () => {
    const response = await api.get(`/survey/${surveyId}/registered_users`)
    if (response.ok) {
      setSurveyRegisteredEmails(response.body.map(user => user.email))
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
    }
  }, [api, surveyId, setSurveyRegisteredEmails])

  useEffect(() => {
    fetchAllRegisteredEmails()
    fetchSurveyRegisteredEmails()
  }, [surveyId, fetchAllRegisteredEmails, fetchSurveyRegisteredEmails])

  const registerNewEmail = async email => {
    const response = await api.post(`/survey/${surveyId}/register_users`, [
      {
        email: email,
      },
    ])
    if (response.ok) {
      AppToaster.show({
        message: `Email ${email} registered`,
        intent: 'success',
        icon: 'tick',
      })
      return true
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
      return false
    }
  }

  const unregisterEmail = async email => {
    const response = await api.post(`/survey/${surveyId}/unregister_users`, [
      {
        email: email,
      },
    ])
    if (response.ok) {
      AppToaster.show({
        message: `Email ${email} unregistered`,
        intent: 'success',
        icon: 'tick',
      })
      return true
    } else {
      const [field, error] = Object.entries(response.body.messages.json)[0]
      const message = `${field}: ${error}`
      AppToaster.show({
        message: message,
        intent: 'danger',
        icon: 'error',
        timeout: 0,
      })
      return false
    }
  }

  const emailRenderer = (
    email,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    if (surveyRegisteredEmails.includes(email)) {
      return
    }
    return (
      <MenuItem
        selected={modifiers.active}
        active={modifiers.active}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        // label={item.name}
        key={email.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(email, query)}
      />
    )
  }

  const handleEmailSelect = item => {
    console.log('handleItemSelect', item)
    if (registerNewEmail(item)) {
      setSurveyRegisteredEmails([...surveyRegisteredEmails, item])
      setAllRegisteredEmails([...allRegisteredEmails, item])
    }
  }

  const handleEmailRemove = (tag, index) => {
    console.log('handleTagRemove', tag, index)
    if (unregisterEmail(tag)) {
      setSurveyRegisteredEmails(prev => prev.filter((_, i) => i !== index))
    }
  }

  const filterPredicate = (query, email, _index, exactMatch) => {
    const normalizedEmail = email.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedQuery === normalizedEmail
    } else {
      return normalizedEmail.indexOf(normalizedQuery) >= 0
    }
  }

  return (
    <FormGroup label="Add to the list the emails you would want to have access to this survey">
      <MultiSelect2
        items={allRegisteredEmails}
        itemRenderer={emailRenderer}
        onItemSelect={handleEmailSelect}
        onRemove={handleEmailRemove}
        itemPredicate={filterPredicate}
        tagRenderer={email => email}
        noResults={<MenuItem disabled={true} text="No results." />}
        resetOnSelect={true}
        selectedItems={surveyRegisteredEmails}
        createNewItemFromQuery={query => {
          if (isValidEmail(query)) return query
        }}
      />
    </FormGroup>
  )
}

export default EmailMultiSelect
