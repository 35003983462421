import {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react'
import { WindowContext } from '../../contexts/WindowProvider'
import { SurveyContext } from '../../contexts/SurveyProvider'
import { recodeTypes } from '../../pages/VariablesPage'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  Button,
  Classes,
  DialogBody,
  DialogStep,
  FormGroup,
  Icon,
  Menu as BpMenu,
  MenuDivider,
  MenuItem,
  MultistepDialog,
  TextArea,
  NumericInput,
  ControlGroup,
  ProgressBar,
  Tag,
  Text,
  OverflowList,
  Divider,
  Alert,
  EditableText,
  Collapse,
} from '@blueprintjs/core'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Select2 } from '@blueprintjs/select'
import highlightText from '../../utils/highlightText'
import './CodeOpenEndDialog.css'
import { useApi } from '../../contexts/ApiProvider'
import { useParams } from 'react-router-dom'
import { AppToaster, ProgressToaster } from '../toaster'
import { errorObjectToString } from '../../ApiClient'
import { useUser } from '../../contexts/UserProvider'
import { LoadingIcon } from '../LoadingSpinner'
import { downloadFile } from '../../utils/downloadFile'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { interpolatePRGn } from 'd3-scale-chromatic'
import {
  TEXT_MUTED,
  TEXT_SMALL,
} from '@blueprintjs/core/lib/esm/common/classes'
import { Duration } from '../TimeAgo'

export default function CodeOpenEndDialog({ isOpen, onClose }) {
  const { setLoading } = useContext(WindowContext)
  const {
    user: { notifications },
  } = useUser()
  const [taskId, setTaskId] = useState()
  const [previewData, setPreviewData] = useState()
  const task = notifications?.find(n => n.payload.task_id === taskId)
  const progress = task ? task.payload.progress / 100 : undefined
  const status = task ? task.payload.progress_message : undefined
  const audienceInput = useRef()
  const topicInput = useRef()
  const api = useApi()
  const { surveyId } = useParams()
  const { surveyDialogOpen, setSurveyDialogOpen, variables, nRespondents } =
    useContext(SurveyContext)
  const type = surveyDialogOpen
    ? recodeTypes.find(r => r?.name === surveyDialogOpen.split('-')[1])
    : undefined

  const [confirmAlertOpen, setConfirmAlertOpen] = useState(false)

  const [numRespondents, setNumRespondents] = useState(2)
  const [startRespondent, setStartRespondent] = useState(1)
  const [selectedVariable, setSelectedVariable] = useState()
  const [codes, setCodes] = useState()
  // const [instructions, setInstructions] = useState(
  //   "I'm a market researcher and I'm studying how people think about Augmented reality and Augmented reality Glasses You'll be given a comment from a person along with a list of categories. The categories also indicate what sentiment the comment has. If you are unsure on the sentiment, please use a neutral code. Do not select the same code with two different sentiments."
  // )
  const [instructions, setInstructions] = useState(
    'You will receive comments from property owners about their experience with the rental platform VRBO'
  )
  const [topic, setTopic] = useState(
    'their experience with the rental platform VRBO'
  )
  const [audience, setAudience] = useState('property owners')
  const [prevSubmission, setPrevSubmission] = useState()
  const [currentRespondent, setCurrentRespondent] = useState()
  const textVariables = variables.filter(v => v.variable_type === 'text')
  const filterVariable = (query, variable, _index, exactMatch) => {
    const normalizedName = variable.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedQuery === normalizedName
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0
    }
  }
  const variableRenderer = (
    variable,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = variableOrQuestionToString(variable)
    return (
      <MenuItem
        selected={modifiers.active}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        label={variable.type}
        key={variable.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(text, query)}
      />
    )
  }

  const errors = useMemo(() => {
    const request = {
      variable: selectedVariable,
      instructions,
      codes: codes
        ? codes
            .split('\n')
            .map(code => code.trim())
            .filter(code => code.length > 0)
        : [],
      num_respondents: numRespondents,
      start_respondent: startRespondent,
      survey_id: surveyId,
    }
    const errors = {}
    if (request.variable === undefined) {
      errors.variable = 'No variable selected'
    }
    if (request.instructions.length < 10) {
      errors.instructions = 'Instructions are too short'
    }
    if (!request.codes || request.codes.length < 2) {
      errors.codes = 'Not enough codes'
    }
    if (request.numRespondents < 1) {
      errors.numRespondents = 'Not enough respondents'
    } else if (
      request.numRespondents > nRespondents ||
      request.startRespondent + request.numRespondents > nRespondents
    ) {
      errors.numRespondents = `Too many respondents. Only ${nRespondents} in survey.`
    }
    return errors
  }, [
    codes,
    instructions,
    nRespondents,
    numRespondents,
    selectedVariable,
    startRespondent,
    surveyId,
  ])
  const getOpenEndRequest = (preview = true) => ({
    variable: selectedVariable?.id,
    instructions,
    codes: codes
      ? codes
          .split('\n')
          .map(code => code.trim())
          .filter(code => code.length > 0)
      : [],
    survey_id: surveyId,
    ...(!preview
      ? { threshold: cutoff }
      : { num_respondents: numRespondents, start_respondent: startRespondent }),
  })
  const hasChanged =
    prevSubmission === undefined ||
    JSON.stringify(getOpenEndRequest()) !== prevSubmission

  const handlePreviewRequest = async () => {
    const request = getOpenEndRequest()
    setLoading(true)
    setTaskId()
    setHasDownloaded(false)
    setCurrentRespondent()
    setPrevSubmission(JSON.stringify(request))
    const response = await api.post(
      `/survey/${surveyId}/variables/code_oes/queue/preview`,
      request
    )
    if (response.ok) {
      setTaskId(response.body.id)
      setCurrentRespondent(0)
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
    setLoading(false)
  }
  const handleFullRunRequest = async () => {
    const request = getOpenEndRequest(false)
    // setLoading(true)
    // setTaskId()
    // setHasDownloaded(false)
    // setCurrentRespondent()
    // setPrevSubmission(JSON.stringify(request))
    const response = await api.post(
      `/survey/${surveyId}/variables/code_oes/queue/full_run`,
      request
    )
    setConfirmAlertOpen(false)
    setSurveyDialogOpen()
    if (response.ok) {
      // setTaskId(response.body.id)
      // setCurrentRespondent(0)
      AppToaster.show({
        message:
          "Open End Coding Requested. You'll receive an email with the results when completed.",
        intent: 'success',
        icon: 'tick-circle',
      })
    } else {
      const message = errorObjectToString(response.body.messages.json)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
    setLoading(false)
  }
  const downloadTaskData = useCallback(async () => {
    if (!taskId) return
    const toasterId = ProgressToaster.show({
      message: 'Downloading task data...',
      intent: 'primary',
      icon: <LoadingIcon />,
      timeout: 0,
    })
    const response = await api.get(`/task/${taskId}/download`, undefined, {
      cache: 'no-store',
    })
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      console.log({ data })
      downloadFile(data, `${taskId}.zip`)
      ProgressToaster.show({
        message: 'Download Successful',
        isCloseButtonShown: false,
        icon: 'tick-circle',
        intent: 'success',
      })
      setHasDownloaded(true)
    } else {
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [api, taskId])

  const getPreviewData = useCallback(async () => {
    if (!taskId) return
    const toasterId = ProgressToaster.show({
      message: 'Downloading task data...',
      intent: 'primary',
      icon: <LoadingIcon />,
      timeout: 0,
    })
    const response = await api.get(
      `/survey/${surveyId}/variables/code_oes/preview/${taskId}`
    )
    ProgressToaster.dismiss(toasterId)
    console.log({ response })
    if (response.ok) {
      return response.body
    } else {
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [api, surveyId, taskId])

  useEffect(() => {
    ;(async () => {
      if (previewData || progress !== 1) {
        return
      }
      const data = await getPreviewData()
      console.log(data)
      setPreviewData(data)
      setCurrentRespondent(0)
    })()
  }, [getPreviewData, previewData, progress])

  const respondentData = previewData
    ? (previewData?.respondents?.[currentRespondent]?.probabilities || [])
        .map((prob, i) => ({ prob, code: previewData.codes[i] }))
        .sort((a, b) => b.prob - a.prob)
    : undefined

  const respHasError = previewData
    ? previewData?.respondents?.[currentRespondent]?.error || false
    : false

  const [cutoff, setCutoff] = useState(0.7)
  const [hasDownload, setHasDownloaded] = useState(false)

  const codeRenderer = ({ prob, code }, i) => {
    let backgroundColor, color
    backgroundColor = lighten(interpolatePRGn(prob), 0)
    color = getContrastText(backgroundColor, 3)
    return (
      <Tooltip2 content={(prob * 100).toFixed(1) + '%'} minimal compact key={i}>
        <Tag
          className="code-tag"
          style={{
            color,
            backgroundColor,
            maxWidth: 400,
          }}
        >
          {code}
        </Tag>
      </Tooltip2>
    )
  }

  const [customPromptOpen, setCustomPromptOpen] = useState(false)

  return (
    <>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <MultistepDialog
              transitionDuration={surveyDialogOpen ? 0 : 300}
              finalButtonProps={{
                text: 'Code Open End',
                intent: 'success',
                onClick: () => {
                  setConfirmAlertOpen(true)
                },
                // disabled: error !== undefined,
                // onClick: handleCreateRequest,
              }}
              // style={{ width: '90vw', height: '70vh' }}
              style={{ width: width - 100, height: height - 100 }}
              onClose={onClose}
              onOpened={() => {
                audienceInput.current.handleFocus()
              }}
              onChange={(newStep, prevStep, ev) => {
                // ev.preventDefault()
                // ev.stopPropagation()
                if (newStep === 'preview' && hasChanged) {
                  handlePreviewRequest()
                }
              }}
              title={
                <Popover2
                  minimal
                  placement="bottom-start"
                  content={
                    <BpMenu>
                      {recodeTypes.map((recode, i) =>
                        recode !== null ? (
                          <MenuItem
                            key={i}
                            icon={recode.icon}
                            text={recode.name}
                            labelElement={
                              <Icon
                                icon={type === recode ? 'small-tick' : 'blank'}
                              />
                            }
                            onClick={() =>
                              setSurveyDialogOpen(
                                `CreateVariableDialog-${recode.name}`
                              )
                            }
                          />
                        ) : (
                          <MenuDivider key={i} />
                        )
                      )}
                    </BpMenu>
                  }
                >
                  <Button
                    minimal
                    text={type?.name}
                    rightIcon="caret-down"
                    icon={type?.icon}
                  />
                </Popover2>
              }
              isOpen={isOpen}
              isCloseButtonShown={true}
            >
              <DialogStep
                id="prompt"
                title="Prompt"
                panel={
                  <DialogBody id="setup-panel">
                    <Button
                      minimal
                      // icon="edit"
                      id="custom-prompt-toggle"
                      icon={
                        customPromptOpen ? (
                          'circle'
                        ) : (
                          <Icon icon="tick-circle" color="black" />
                        )
                      }
                      text="Templated Prompt"
                      small
                      onClick={() => setCustomPromptOpen(prev => !prev)}
                      style={{
                        ...(!customPromptOpen ? { color: 'black' } : null),
                      }}
                    />
                    <p
                      id="prompt-template"
                      className={
                        (customPromptOpen ? 'inactive ' : '') +
                        Classes.TEXT_MUTED
                      }
                      style={{ padding: 12 }}
                    >
                      You will receive comments from{' '}
                      <EditableText
                        className="inline-editable-prompt-text"
                        value={audience}
                        onChange={value => {
                          setAudience(value)
                          setInstructions(
                            `You will receive comments from ${
                              value ? value : 'audience members'
                            } about ${topic ? topic : 'the topic'}.`
                          )
                        }}
                        selectAllOnFocus
                        ref={audienceInput}
                        placeholder="audience"
                        minWidth={0}
                        disabled={customPromptOpen}
                      />
                      about{' '}
                      <EditableText
                        className="inline-editable-prompt-text"
                        value={topic}
                        onChange={value => {
                          setTopic(value)
                          setInstructions(
                            `You will receive comments from ${
                              audience ? audience : 'audience members'
                            } about ${value ? value : 'the topic'}.`
                          )
                        }}
                        selectAllOnFocus
                        ref={topicInput}
                        placeholder="topic"
                        minWidth={0}
                        disabled={customPromptOpen}
                      />
                    </p>
                    <Button
                      minimal
                      // icon="edit"
                      id="custom-prompt-toggle"
                      icon={
                        !customPromptOpen ? (
                          'circle'
                        ) : (
                          <Icon icon="tick-circle" color="black" />
                        )
                      }
                      text="Custom Prompt"
                      small
                      onClick={() => setCustomPromptOpen(prev => !prev)}
                      style={{
                        marginTop: 14,
                        ...(customPromptOpen ? { color: 'black' } : null),
                      }}
                    />
                    <Collapse keepChildrenMounded isOpen={customPromptOpen}>
                      <div style={{ padding: 12 }}>
                        <TextArea
                          growVertically
                          rows={10}
                          className={Classes.INPUT}
                          small
                          fill
                          value={instructions}
                          onChange={ev => setInstructions(ev.target.value)}
                        />
                      </div>
                    </Collapse>
                  </DialogBody>
                }
              />
              <DialogStep
                id="variable"
                title="Variable"
                nextButtonProps={selectedVariable ? {} : { disabled: true }}
                panel={
                  <DialogBody id="variable-panel">
                    <FormGroup
                      label="Open End Variable to Code"
                      labelFor="variable"
                    >
                      <Select2
                        id="variable"
                        items={textVariables}
                        itemPredicate={filterVariable}
                        itemRenderer={variableRenderer}
                        onItemSelect={setSelectedVariable}
                        selectedItem={selectedVariable}
                        noResults={
                          <MenuItem
                            disabled={true}
                            text="No results."
                            roleStructure="listoption"
                          />
                        }
                      >
                        <Button
                          text={
                            selectedVariable
                              ? variableOrQuestionToString(selectedVariable)
                              : 'Select a variable'
                          }
                          rightIcon="double-caret-vertical"
                          icon="manually-entered-data"
                          placeholder="Select a variable"
                          style={{ backgroundColor: 'white' }}
                        />
                      </Select2>
                    </FormGroup>
                  </DialogBody>
                }
              />
              <DialogStep
                id="codes"
                title="Codes"
                nextButtonProps={{
                  onMouseDown: ev => {
                    if (hasChanged) {
                      setCurrentRespondent()
                      setPreviewData()
                      setTaskId()
                      if (Object.keys(errors).length > 0) {
                        console.log('errors', errors)
                        ev.preventDefault()
                        ev.stopPropagation()
                        return
                      }
                    }
                  },
                  disabled: Object.keys(errors).length > 0,
                  intent: hasChanged ? 'success' : null,
                  text: hasChanged ? 'Request Preview' : 'Go Back to Preview',
                  rightIcon: hasChanged ? null : 'chevron-right',
                }}
                panel={
                  <DialogBody id="options-panel" className="options-panel">
                    <FormGroup
                      label="Codes"
                      labelFor="codes-input"
                      subLabel="Enter one code per line. Use a consistent format for all codes."
                      // style={{ flexBasis: '50%' }}
                    >
                      <TextArea
                        id="codes-input"
                        growVertically
                        rows={17}
                        className={Classes.INPUT}
                        style={{ textWrap: 'nowrap' }}
                        placeholder="Positive - Form factor and aesthetic looking technology"
                        small
                        fill
                        onChange={ev => setCodes(ev.target.value)}
                        value={codes}
                      />
                    </FormGroup>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 6,
                      }}
                    >
                      <ControlGroup
                        style={{ columnGap: '2px', alignItems: 'center' }}
                      >
                        {/* <FormGroup label="Test on" labelFor="num-respondents"> */}
                        <span>Test on</span>
                        <NumericInput
                          id="num-respondents"
                          small
                          min={1}
                          max={Math.min(nRespondents, 1000) ?? undefined}
                          style={{ width: '40px' }}
                          clampValueOnBlur
                          value={numRespondents}
                          onValueChange={v => setNumRespondents(v)}
                        />
                        {/* </FormGroup> */}
                        <span>
                          respondent{numRespondents !== 1 && 's'}, starting with
                          respondent
                        </span>
                        <NumericInput
                          id="start-respondent"
                          small
                          min={1}
                          style={{ width: '40px' }}
                          max={nRespondents - 1}
                          value={startRespondent}
                          onValueChange={v => setStartRespondent(v)}
                        />
                      </ControlGroup>
                      <p
                        className={
                          Classes.TEXT_MUTED + ' ' + Classes.TEXT_SMALL
                        }
                      >
                        AI can code 2 respondents per minute.
                      </p>
                    </div>
                  </DialogBody>
                }
              />
              <DialogStep
                id="preview"
                title="Preview"
                backButtonProps={{
                  text: 'Go Back to Options',
                  icon: 'chevron-left',
                }}
                panel={
                  <div>
                    <Alert
                      isOpen={confirmAlertOpen}
                      loading={false}
                      cancelButtonText="Cancel"
                      confirmButtonText="Code Survey"
                      onConfirm={handleFullRunRequest}
                      onCancel={() => setConfirmAlertOpen(false)}
                      cancel
                      intent="success"
                    >
                      <p>
                        Proceeding will code the open ends for all{' '}
                        {Intl.NumberFormat().format(nRespondents)} respondents
                        in the survey.
                      </p>
                      <p>
                        It will take about{' '}
                        <Duration seconds={(nRespondents / 10) * 60} /> to
                        complete
                      </p>
                    </Alert>
                    <DialogBody>
                      {progress !== 1 ? (
                        <div>
                          <p>{status ?? 'Waiting in line for task to start'}</p>
                          <ProgressBar
                            stripes={progress !== 1}
                            value={
                              progress !== undefined && progress > 0
                                ? progress
                                : undefined
                            }
                            intent={
                              progress === undefined
                                ? null
                                : progress === 0
                                ? 'primary'
                                : 'success'
                            }
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <FormGroup
                              labelFor="cutoff-input"
                              label="Cutoff"
                              subLabel="How confident the AI needs to be to apply a code"
                              // inline
                              helperText="Applies to all respondents"
                              // style={{ alignItems: 'center' }}
                            >
                              <NumericInput
                                rightElement={
                                  <Icon
                                    icon="percentage"
                                    style={{
                                      transform: 'translate(-20%,20%)',
                                      color: '#5f6b7c',
                                    }}
                                    size={14}
                                  />
                                }
                                style={{ width: '45px' }}
                                value={parseInt(cutoff * 100)}
                                stepSize={5}
                                min={0}
                                max={100}
                                onValueChange={value => setCutoff(value / 100)}
                                small
                                id="cutoff-input"
                              />
                            </FormGroup>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                                alignItems: 'self-end',
                              }}
                              className={TEXT_MUTED}
                            >
                              <Button
                                onClick={downloadTaskData}
                                minimal
                                text="Download preview data"
                                icon="download"
                                // style={{ height: '100%' }}
                                disabled={hasDownload}
                                intent={hasDownload ? 'success' : 'primary'}
                              />
                              <Text>
                                <span>{'Average Confidence: '}</span>
                                {(
                                  previewData?.average_confidence * 100
                                ).toFixed(1) + '%'}
                                <Tooltip2
                                  content={
                                    <span
                                      className={TEXT_SMALL}
                                      style={{ whiteSpace: 'pre' }}
                                    >
                                      {`The AI makes a yes or no decision for every code.\nThis number represents average confidence across all decisions.`}
                                    </span>
                                  }
                                  minimal
                                >
                                  <Icon
                                    icon="small-info-sign"
                                    size={14}
                                    style={{
                                      marginLeft: 2,
                                    }}
                                  />
                                </Tooltip2>
                              </Text>
                              <Text>
                                {'Respondents with Errors: '}
                                {previewData?.total_errors}
                                <Tooltip2
                                  content={
                                    <span
                                      className={TEXT_SMALL}
                                      style={{ whiteSpace: 'pre' }}
                                    >
                                      {`This represents the number of times the AI did not follow formatting instructions.\nRespondents with errors will be indicated in yellow. Check codes for accuracy.`}
                                    </span>
                                  }
                                  minimal
                                >
                                  <Icon
                                    icon="small-info-sign"
                                    size={14}
                                    style={{
                                      marginLeft: 2,
                                    }}
                                  />
                                </Tooltip2>
                              </Text>
                            </div>
                          </div>
                          <Divider style={{ marginBottom: 12 }} />
                          {previewData && (
                            <div id="comment-data">
                              <div id="comment-text">
                                <div style={{ display: 'flex', columnGap: 5 }}>
                                  <Button
                                    icon="chevron-left"
                                    disabled={currentRespondent === 0}
                                    onClick={() =>
                                      setCurrentRespondent(prev => prev - 1)
                                    }
                                  />
                                  <div style={{ flexGrow: 1 }}>
                                    <blockquote
                                      className="comment"
                                      style={
                                        respHasError ? { color: '#935610' } : {}
                                      }
                                    >
                                      {
                                        previewData.respondents[
                                          currentRespondent
                                        ].comment
                                      }
                                    </blockquote>
                                    <Text
                                      className={TEXT_MUTED}
                                      style={{
                                        textAlign: 'end',
                                        paddingRight: 12,
                                        color: respHasError ? '#935610' : null,
                                      }}
                                    >
                                      Respondent {currentRespondent + 1} of{' '}
                                      {previewData.respondents.length}
                                      {respHasError && (
                                        <Tooltip2
                                          content="Check codes for accuracy. The AI did not follow formatting instructions."
                                          intent="warning"
                                        >
                                          <Icon
                                            icon="warning-sign"
                                            intent="warning"
                                            style={{ marginLeft: 4 }}
                                          />
                                        </Tooltip2>
                                      )}
                                    </Text>
                                  </div>
                                  <Button
                                    icon="chevron-right"
                                    disabled={
                                      currentRespondent ===
                                      previewData.respondents.length - 1
                                    }
                                    onClick={() =>
                                      setCurrentRespondent(prev => prev + 1)
                                    }
                                  />
                                </div>
                              </div>
                              <div id="comment-codes">
                                <fieldset>
                                  <legend>Selected Codes</legend>
                                  {respondentData
                                    .filter(({ prob }) => prob >= cutoff)
                                    .map(({ prob, code }, i) =>
                                      codeRenderer({ prob, code }, i)
                                    )}
                                </fieldset>
                                <fieldset
                                  style={{ minWidth: '100%', opacity: 0.6 }}
                                >
                                  <legend>Unselected Codes</legend>
                                  <OverflowList
                                    items={respondentData.filter(
                                      ({ prob }) => prob < cutoff
                                    )}
                                    visibleItemRenderer={codeRenderer}
                                    collapseFrom="end"
                                    overflowRenderer={
                                      items => (
                                        // items.map(({ code }) => code).join(', ')
                                        <Popover2
                                          minimal
                                          content={
                                            <div
                                              style={{
                                                maxWidth: '60vw',
                                                maxHeight: '60vh',
                                                overflow: 'auto',
                                                padding: 10,
                                              }}
                                            >
                                              {items.map((item, i) =>
                                                codeRenderer(item, i)
                                              )}
                                            </div>
                                          }
                                        >
                                          <Tooltip2
                                            content={`+${items.length} more`}
                                            minimal
                                            placement="right"
                                          >
                                            <Button
                                              // icon="more"
                                              small
                                              minimal
                                              rightIcon="more"
                                            />
                                          </Tooltip2>
                                        </Popover2>
                                      )
                                      // '+' + items.length
                                    }
                                  />
                                </fieldset>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </DialogBody>
                  </div>
                }
              />
            </MultistepDialog>
          )
        }}
      </AutoSizer>
    </>
  )
}