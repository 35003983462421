export function mean(arr) {
  return (
    arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length
  )
}

export function sum(arr) {
  return arr.reduce((acc, curr) => acc + curr, 0)
}

export function variance(arr) {
  const arr_mean = mean(arr)
  const mse = arr.map(k => {
    return (k - arr_mean) ** 2
  })

  const mse_sum = sum(mse)

  return mse_sum / arr.length
}

export function normalize(arr) {
  const min = Math.min(...arr)
  const max = Math.max(...arr)
  const range = max - min
  return arr.map(i => (i - min) / range)
}

export function zScores(arr, normalized = false) {
  const arr_std = std(arr)
  const arr_mean = mean(arr)
  const arr_zscores = arr.map(i => (i - arr_mean) / arr_std)

  return normalized ? normalize(arr_zscores) : arr_zscores
}

export function std(arr) {
  return Math.sqrt(variance(arr))
}

export function range(s, e = null, d = 1) {
  if (e == null) [s, e] = [0, s] //missing e? s is e
  if (s > e) d = d < 0 ? d : -d //s,e backwards? might flip sign
  return [...Array(((e - s) / d + 1) << 0).keys()].map(x => d * x + s)
}

export function transpose(matrix) {
  return matrix[0].map((_, c) => matrix.map(row => row[c]))
}
