import Menu from '../components/Menu'
import ToolPage from '../components/ToolPage'
import Users from '../components/Users'
import { useDocumentTitle } from '../utils/hooks'

export default function UsersPage() {
  const pageName = 'Users'
  useDocumentTitle(pageName)
  return <ToolPage menu={<Menu pageName={pageName} />} tool={<Users />} />
}
