import { FormGroup, Icon, TagInput } from '@blueprintjs/core'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from '../../contexts/ApiProvider'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { downloadFile } from '../../utils/downloadFile'
import { LoadingIcon } from '../LoadingSpinner'
import { AppToaster, ProgressToaster } from '../toaster'

import { IconNames } from '@blueprintjs/icons'
import { useInstructionsDialog } from '../../contexts/InstructionsDialogProvider'
import { useTURF } from '../../contexts/TURFProvider'
import { TURFInstructions } from './Instructions'

export default function TURFExporter({ exportFileName = 'TURF' }) {
  const { loading } = useMaxDiff()
  const { mode, setMode } = useTURF()
  const { openDialog } = useInstructionsDialog()

  const menu = useMemo(
    () => [
      {
        label: 'View',
        oreder: 2,
        items: [
          {
            label: 'Normal mode',
            selected: mode === 'normal',
            icon: loading ? <LoadingIcon type="menu" /> : IconNames.Comparison,
            onClick: () => setMode('normal'),
            shouldDismissPopover: false,
          },
          {
            label: 'Build mode',
            selected: mode === 'build',
            icon: loading ? (
              <LoadingIcon type="menu" />
            ) : (
              <Icon
                icon={IconNames.Comparison}
                style={{ transform: 'scaleX(-1)' }}
              />
            ),
            onClick: () => setMode('build'),
            shouldDismissPopover: false,
          },
        ],
      },
      {
        order: 3,
        label: 'Help',
        items: [
          {
            label: 'Instructions',
            icon: loading ? <LoadingIcon type="menu" /> : IconNames.HELP,
            onClick: () => {
              openDialog(<TURFInstructions />, 'TURF Instructions')
            },
          },
        ],
      },
    ],
    [setMode, mode, loading, openDialog]
  )

  useRegisterMaxDiffMenu('TURFExporterMenu', menu)

  return (
    <FormGroup
      label="Saved Scenarios"
      inline={true}
      className="turf-exporter-form-group"
    >
      <ExportSetTagInput exportFileName={exportFileName} />
    </FormGroup>
  )
}

function ExportSetTagInput({ exportFileName }) {
  const { maxDiffId } = useParams()
  const {
    setLoading,
    loading,
    maxDiffName,
    setSelectedGroupVariables,
    exportSets,
    removeExportSet,
    createExportSet,
  } = useMaxDiff()
  const { setClaimIds, claimIds } = useTURF()
  const api = useApi()

  const [selectedTag, setSelectedTag] = useState(null)

  const exportToExcel = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    const response = await api.get(`/maxdiffs/${maxDiffId}/export_to_excel`)
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [maxDiffId, api, maxDiffName, exportFileName])

  const menu = useMemo(
    () => [
      {
        order: 1,
        label: 'File',
        items: [
          {
            label: 'Export to Excel',
            icon: loading ? <LoadingIcon type="menu" /> : IconNames.EXPORT,
            onClick: exportToExcel,
            disabled: loading || exportSets?.length === 0,
          },
        ],
      },
    ],
    [exportToExcel, loading, exportSets]
  )
  useRegisterMaxDiffMenu('ExportSetTagInput', menu)

  const onTagClick = useCallback(
    index => {
      // fetch the export set
      setLoading(true)
      const exportSet = exportSets[index]
      console.log(`Clicked on ${exportSet.name}`)
      const consideredVariables = exportSet.considered_variables || []
      const selectedVariables = exportSet.selected_variables || []
      const subgroupVariables = exportSet.subgroup_variables || []
      const select = selectedVariables.map(v => v.id)
      const consider = [...consideredVariables.map(v => v.id), ...select]
      setSelectedTag(index)
      setSelectedGroupVariables(() => subgroupVariables.map(v => v.id))
      console.log({ select, consider })
      setClaimIds({
        selectedClaimsIds: select,
        consideredClaimsIds: consider,
      })
    },
    [
      setClaimIds,
      setSelectedGroupVariables,
      setLoading,
      exportSets,
      setSelectedTag,
    ]
  )

  const onTagRemove = useCallback(
    (value, index) => {
      setLoading(true)
      const id_to_delete = exportSets[index].id
      console.log(`Removing ${value} with id ${id_to_delete}`)
      removeExportSet(id_to_delete)
      setSelectedTag(null)
      setSelectedGroupVariables([])
      setClaimIds(prev => {
        console.log('resetting claim ids')
        console.log(prev)
        return { selectedClaimsIds: [], consideredClaimsIds: [] }
      })
      setLoading(false)
    },
    [
      setLoading,
      setClaimIds,
      setSelectedGroupVariables,
      setSelectedTag,
      exportSets,
      removeExportSet,
    ]
  )

  // How each tag should look and behave
  const tagProps = useCallback(
    (value, index) => ({
      interactive: true,
      large: true,
      intent: index === selectedTag ? 'primary' : 'none',
      onClick: () => onTagClick(index),
      onRemove: () => onTagRemove(value, index),
    }),
    [onTagClick, onTagRemove, selectedTag]
  )

  const onAdd = useCallback(
    new_values => {
      setLoading(true)
      if (new_values.length != 1) {
        console.error(`Trying to add ${new_values.length} values`)
      }
      const new_value = new_values[0]
      if (exportSets.map(t => t.name).includes(new_value)) {
        console.log(`Value ${new_value} already exists`)
        return
      }

      const { selectedClaimsIds, consideredClaimsIds } = claimIds

      // create ExportSet
      createExportSet(new_value, selectedClaimsIds, consideredClaimsIds)
      setSelectedTag(exportSets.length)
      setLoading(false)
    },
    [exportSets, claimIds, createExportSet, setLoading, setSelectedTag]
  )

  return (
    <TagInput
      values={exportSets !== undefined ? exportSets.map(t => t.name) : []}
      tagProps={tagProps}
      onAdd={onAdd}
      disabled={loading}
      inputProps={{ className: 'turf-exporter-input' }}
    />
  )
}
