import React from 'react'
import { useUser } from '../contexts/UserProvider'
import { Navigate } from 'react-router-dom'

export default function RequireRole({ children, roles = [] }) {
  const { user } = useUser()
  if (user === undefined) {
    return null
  }
  if (roles.includes(user.role)) {
    return children
  }
  return <Navigate to="/" />
}
