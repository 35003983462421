import { memo, useContext, useEffect } from 'react'

import { useCallback, useRef } from 'react'
import {
  ContextMenuItem,
  ContextMenuComponentProps,
} from 'react-datasheet-grid/dist/types'
import { SurveyContext } from '../../contexts/SurveyProvider'
import { VariableContextMenuContext } from './VariablesUI'
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { MenuItem2 } from '@blueprintjs/popover2'

/**
 *
 * @param {ContextMenuItem} item
 * @returns
 */
const renderItem = item => {
  if (item.type === 'DELETE_ROW') {
    return 'Delete row'
  }

  if (item.type === 'DELETE_ROWS') {
    return (
      <>
        Delete rows <b>{item.fromRow}</b> to <b>{item.toRow}</b>
      </>
    )
  }

  if (item.type === 'INSERT_ROW_BELLOW') {
    return 'Insert row below'
  }

  if (item.type === 'DUPLICATE_ROW') {
    return 'Duplicate row'
  }

  if (item.type === 'DUPLICATE_ROWS') {
    return (
      <>
        Duplicate rows <b>{item.fromRow}</b> to <b>{item.toRow}</b>
      </>
    )
  }

  return item.type
}

const useDocumentEventListener = (type, listener) => {
  useEffect(() => {
    document.addEventListener(type, listener)

    return () => {
      document.removeEventListener(type, listener)
    }
  }, [listener, type])
}

export const VariableContextMenu = memo(
  /**
   *
   * @param {ContextMenuComponentProps} param0
   * @returns
   */
  ({ clientX, clientY, items, close, cursorIndex }) => {
    const containerRef = useRef()
    const { setSelectedVariables, variables, selectedVariables } =
      useContext(SurveyContext)
    const { openQuestionDialog, openDeleteVariablesDialog } = useContext(
      VariableContextMenuContext
    )

    const onClickOutside = useCallback(
      event => {
        const clickInside = containerRef.current?.contains(event.target)

        if (!clickInside) {
          close()
        }
      },
      [close]
    )
    useDocumentEventListener('mousedown', onClickOutside)
    const deleteRowsAction = items.find(({ type }) => type === 'DELETE_ROWS')
    let { fromRow, toRow } = deleteRowsAction ?? {
      fromRow: undefined,
      toRow: undefined,
    }

    if (!fromRow) {
      fromRow = cursorIndex.row + 1
      toRow = cursorIndex.row + 1
      // close()
    }

    return (
      <Menu
        ulRef={containerRef}
        className="dsg-context-menu"
        style={{ left: clientX + 'px', top: clientY + 'px' }}
      >
        <MenuItem
          onClick={() => {
            close()
            setSelectedVariables(
              variables.slice(fromRow - 1, toRow).map(({ id }) => id)
            )
            openQuestionDialog()
          }}
          text="Set Question"
          icon="group-objects"
        />
        {/* <MenuDivider />
        <MenuItem
          text="Delete Variables"
          intent="danger"
          icon="trash"
          onClick={() => {
            close()
            setSelectedVariables(
              variables.slice(fromRow - 1, toRow).map(({ id }) => id)
            )
            openDeleteVariablesDialog()
          }}
        /> */}
      </Menu>
      // <div
      //   className="dsg-context-menu"
      //   style={{ left: clientX + 'px', top: clientY + 'px' }}
      //   ref={containerRef}
      // >
      //   <div
      //     onClick={() => {
      //       close()
      //       setSelectedVariables(
      //         variables.slice(fromRow - 1, toRow).map(({ id }) => id)
      //       )
      //       openQuestionDialog()
      //     }}
      //     className="dsg-context-menu-item"
      //   >
      //     Set Question
      //   </div>
      //   <hr />
      //   <div
      //     onClick={() => {
      //       close()
      //       setSelectedVariables(
      //         variables.slice(fromRow - 1, toRow).map(({ id }) => id)
      //       )
      //       openQuestionDialog()
      //     }}
      //     className="dsg-context-menu-item"
      //   >
      //     Delete Variables
      //   </div>
      //   {/* {items.map(item => (
      //     <div
      //       key={item.type}
      //       onClick={item.action}
      //       className="dsg-context-menu-item"
      //     >
      //       {renderItem(item)}
      //     </div>
      //   ))} */}
      // </div>
    )
  }
)
