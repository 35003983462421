// https://github.com/miguelgrinberg/react-microblog/blob/3247788c609214bc9269f2ea4a0c0bae0df24381/src/contexts/ApiProvider.js

import { createContext, useContext, useCallback, useMemo } from 'react'
import ApiClient from '../ApiClient'

export const ApiContext = createContext()

export default function ApiProvider({ children }) {
  const onError = useCallback(() => {
    alert('An unexpected error has occurred. Please try again later.')
  }, [])

  const api = useMemo(() => new ApiClient(onError), [onError])

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export function useApi() {
  return useContext(ApiContext)
}
