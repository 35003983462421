import { Button, Card, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { ContextMenu2, Tooltip2 } from '@blueprintjs/popover2'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSurvey } from '../contexts/SurveyProvider'
import { VizContext } from '../contexts/VizProvider'
import { WindowContext } from '../contexts/WindowProvider'
import { entityListToIdString } from '../utils/variableOrQuestionToString'
import './SlidesPanel.css'

export default function SlidesPanel() {
  const { currentReportId } = useSurvey()
  const [searchParams] = useSearchParams()
  const tableType = searchParams.get('tableType')
  const { banner, stub, filter, setSlide } = useContext(VizContext)

  const {
    currentSurvey,
    addSlideToReport,
    deleteSlide,
    renameSlide,
    settings: {
      statTesting,
      statTest,
      percentages,
      highlighting,
      showIndexTabs,
      showMeanTabs,
      chart,
    },
  } = useContext(WindowContext)

  const currentReport = currentReportId
    ? currentSurvey.reports.find(r => r.id === currentReportId)
    : null

  const currentBannerIds = entityListToIdString(banner)
  const currentStubIds = entityListToIdString(stub)
  const currentFilterIds = entityListToIdString(filter)
  const currentSlide = currentReport?.slides.find(s => {
    const slideBannerIds = entityListToIdString(s.banner)
    const slideStubIds = entityListToIdString(s.stub)
    const slideFilterIds = entityListToIdString(s.filter)
    return (
      s.type === tableType &&
      slideBannerIds === currentBannerIds &&
      slideStubIds === currentStubIds &&
      slideFilterIds === currentFilterIds &&
      s.settings.chart === chart
    )
  })

  const handleAddSlide = position => {
    const name = prompt('Enter a name for the new slide')
    const slide = {
      name,
      position,
      banner: banner.map(e => e.id),
      stub: stub.map(e => e.id),
      filter: filter.map(e => e.id),
      type: tableType,
      settings: {
        statTesting,
        statTest,
        percentages,
        highlighting,
        showIndexTabs,
        showMeanTabs,
        chart,
      },
    }
    addSlideToReport(slide, currentReportId)
  }

  const handleRenameSlide = (slideId, name) => {
    const newName = prompt('Enter a new name for the slide', name)
    if (!newName) return
    renameSlide(slideId, newName)
  }

  return (
    <div
      id="slides-panel-container"
      style={{
        height: 'fit-content',
        width: '257px',
        padding: '14px',
        // background: 'white',
      }}
    >
      <div id="slides-panel">
        {currentReport !== undefined
          ? currentReport !== null
            ? currentReport.slides.map((slide, i) => (
                <div className="slide-container" key={i}>
                  <div className="slide-number">{i + 1}</div>
                  <ContextMenu2
                    content={
                      <Menu>
                        <Tooltip2
                          targetTagName="MenuItem"
                          minimal
                          content={'Add current viz as a slide after this one'}
                          placement="right"
                        >
                          <MenuItem
                            onClick={() => handleAddSlide(slide.position + 1)}
                            text="New Slide"
                            icon="add-row-bottom"
                          />
                        </Tooltip2>
                        <MenuDivider />
                        <MenuItem
                          text="Rename Slide"
                          icon="edit"
                          onClick={() =>
                            handleRenameSlide(slide.id, slide.name)
                          }
                        />
                        <Tooltip2
                          targetTagName="MenuItem"
                          minimal
                          content={
                            'Delete this slide from the report (Warning: this cannot be undone)'
                          }
                          intent="danger"
                          placement="right"
                        >
                          <MenuItem
                            text="Delete Slide"
                            icon="trash"
                            intent="danger"
                            onClick={() =>
                              deleteSlide(slide.id, currentReportId)
                            }
                          />
                        </Tooltip2>
                      </Menu>
                    }
                  >
                    <Card
                      interactive={currentSlide?.id !== slide.id}
                      className={`slide-card${
                        currentSlide?.id === slide.id ? ' active' : ''
                      }`}
                      onClick={() => setSlide(slide)}
                    >
                      {slide.name}
                    </Card>
                  </ContextMenu2>
                </div>
              ))
            : 'No Reports Yet'
          : 'Loading...'}
        {currentReport && currentReport.slides.length === 0 ? (
          <div className="slide-container">
            <div className="slide-number">1</div>
            <Tooltip2
              minimal
              content={'Add current viz as your first slide'}
              placement="bottom"
              targetTagName="slide-card"
            >
              <Button
                icon="plus"
                fill
                large
                outlined
                style={{
                  margin: '12px 0',
                  width: 170,
                  aspectRatio: 1.6667,
                  height: 100,
                }}
                // intent="primary"
                onClick={() => handleAddSlide(1)}
              />
            </Tooltip2>
          </div>
        ) : null}
      </div>
    </div>
  )
}
