import {
  Button,
  Card,
  Colors,
  Icon,
  Menu as BpMenu,
  MenuItem,
  Text,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import { useSurvey } from '../contexts/SurveyProvider'
import { useUser } from '../contexts/UserProvider'
import UserManagementDialog from './UserManagementDialog'
import './SurveyModules.css'
import { UpdateMaxdiffDialog } from './NewMaxDiff'

export default function Modules() {
  const { maxDiffs, surveyId } = useSurvey()
  const { user } = useUser()
  return (
    <div>
      {user.role !== 'client' && (
        <div className="modules-container">
          <UserManagementDialog />
          <DataCard />
          <VariablesCard />
          <VizCard />
          <UpdateMaxdiffDialog />
        </div>
      )}
      {user.role !== 'client' && <hr />}
      <div className="modules-container">
        {maxDiffs &&
          maxDiffs.map(maxdiff => (
            <MaxDiffSimulatorCard
              key={maxdiff.id}
              maxdiff={maxdiff}
              surveyId={surveyId}
            />
          ))}
        {user.role !== 'client' && (
          <>
            {/* <DriversCard /> */}
            <SuperGrouperCard />
          </>
        )}
      </div>
    </div>
  )
}

function SuperGrouperCard() {
  const navigate = useNavigate()
  return (
    <Card
      interactive
      className="module-card"
      onClick={() => navigate('grouper')}
      role="button"
    >
      <div className="module-icon-container">
        <Icon icon={IconNames.INHERITED_GROUP} iconSize={56} color="#a82255" />
      </div>
      <div className="module-info">
        <Text className="module-type" style={{ color: '#a82255' }}>
          Super Grouper
        </Text>
      </div>
    </Card>
  )
}

function DriversCard() {
  const navigate = useNavigate()
  return (
    <Card
      interactive
      className="module-card"
      onClick={() => navigate('drivers')}
      role="button"
    >
      <div className="module-icon-container">
        <Icon icon={IconNames.BUGGY} iconSize={56} color="#238551" />
      </div>
      <div className="module-info">
        <Text className="module-type" style={{ color: '#238551' }}>
          Racecar Drivers
        </Text>
      </div>
    </Card>
  )
}

function MaxDiffSimulatorCard({ maxdiff, surveyId }) {
  const navigate = useNavigate()
  const { user } = useUser()

  const isAnalyst = user.role === 'analyst'

  return (
    <Card
      interactive
      className="module-card"
      style={{
        paddingTop: 60,
        paddingBottom: 50,
      }}
      onClick={() => navigate(`/survey/${surveyId}/maxdiffs/${maxdiff.id}`)}
      role="button"
    >
      <div
        className="module-icon-container"
        style={{
          height: 45,
        }}
      >
        <Icon icon={<MaxDiffLogo />} iconSize={56} intent="primary" />
      </div>
      <div className="module-info">
        <Tooltip2 content={maxdiff.name}>
          <Text
            className="module-name"
            style={{
              color: '#184A90',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100px',
              height: '20px',
            }}
          >
            {maxdiff.name}
          </Text>
        </Tooltip2>
      </div>
      {/* Only an analyst can edit or delete a maxdiff */}
      {isAnalyst && (
        <div className="bottom-icon-group">
          <MaxDiffEditButton maxdiff={maxdiff} />
          <MaxDiffDeleteButton maxdiff={maxdiff} />
        </div>
      )}
    </Card>
  )
}

function DataCard() {
  const navigate = useNavigate()
  return (
    <Card
      interactive
      className="module-card"
      onClick={() => navigate('explorer')}
      role="button"
    >
      <div className="module-icon-container">
        <Icon icon="panel-table" iconSize={56} color={Colors.DARK_GRAY3} />
      </div>
      <div className="module-type" style={{ color: Colors.DARK_GRAY3 }}>
        Data
      </div>
    </Card>
  )
}

function VariablesCard() {
  const navigate = useNavigate()
  return (
    <Card
      interactive
      className="module-card"
      onClick={() => navigate('variables')}
      role="button"
    >
      <div className="module-icon-container">
        <Icon icon="variable" iconSize={56} color={Colors.DARK_GRAY3} />
      </div>
      <div className="module-type" style={{ color: Colors.DARK_GRAY3 }}>
        Variables
      </div>
    </Card>
  )
}

function VizCard() {
  const [vizMenuOpen, setVizMenuOpen] = useState(false)
  return (
    <Card
      interactive
      className="module-card"
      id="viz-card"
      onClick={() => setVizMenuOpen(!vizMenuOpen)}
      role="button"
    >
      <div className="module-icon-container">
        <Icon icon="series-search" iconSize={56} color={Colors.DARK_GRAY3} />
      </div>
      <Popover2
        isOpen={vizMenuOpen}
        onClose={() => setVizMenuOpen(false)}
        minimal
        content={
          <BpMenu>
            <MenuItem
              icon="th-disconnect"
              text={
                <Link className="unstyled-link" to="./viz?tableType=cross">
                  Crosstab
                </Link>
              }
            />
            <MenuItem
              icon="panel-stats"
              text={
                <Link className="unstyled-link" to="./viz?tableType=index">
                  Indextab
                </Link>
              }
            />
          </BpMenu>
        }
      >
        <Button
          className="module-type"
          style={{ color: Colors.DARK_GRAY3 }}
          rightIcon="caret-down"
          minimal
        >
          Viz
        </Button>
      </Popover2>
    </Card>
  )
}

export function MaxDiffLogo({
  color = '#0072c6',
  width = 100,
  height = 60,
  scale = 1,
} = {}) {
  const offsetX = ((1 - scale) * width) / 2
  const offsetY = ((1 - scale) * height) / 2

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g transform={`translate(${offsetX}, ${offsetY}) scale(${scale})`}>
        <rect x="50" y="5" width="50" height="10" fill={color} />
        <rect x="50" y="20" width="25" height="10" fill={color} />
        <rect x="25" y="35" width="25" height="10" fill={color} />
        <rect x="0" y="50" width="50" height="10" fill={color} />
      </g>
    </svg>
  )
}

function MaxDiffEditButton({ maxdiff }) {
  const { setSurveyDialogOpen } = useSurvey()
  return (
    <div className="bottom-icon">
      <Tooltip2 content="Edit maxdiff">
        <Button
          icon="edit"
          intent="primary"
          small
          minimal
          fill={false}
          onClick={ev => {
            ev.stopPropagation()
            setSurveyDialogOpen('UpdateMaxdiffDialog:' + maxdiff.id)
          }}
        />
      </Tooltip2>
    </div>
  )
}

function MaxDiffDeleteButton({ maxdiff }) {
  const { setMaxDiffs } = useSurvey()
  const api = useApi()

  const handleDeleteMaxdiff = async ev => {
    ev.preventDefault()
    ev.stopPropagation()

    const confirmed = window.confirm(
      'Are you sure you want to delete this maxdiff? This cannot be undone.'
    )
    if (!confirmed) return
    const response = await api.delete(`/maxdiffs/${maxdiff.id}`)
    if (response.ok) {
      setMaxDiffs(prev => prev.filter(m => m.id !== maxdiff.id))
    } else {
      alert('Error deleting maxdiff')
    }
  }

  return (
    <div className="bottom-icon">
      <Tooltip2 content="Delete maxdiff">
        <Button
          icon="trash"
          intent="danger"
          small
          minimal
          fill={false}
          onClick={ev => handleDeleteMaxdiff(ev)}
        />
      </Tooltip2>
    </div>
  )
}
