import { Classes, Icon, Text } from '@blueprintjs/core'
import './Modules.css'

export default function Modules() {
  return (
    <div className="modules-container">
      <div className="module-card">
        <div className="module-icon-container">
          <Icon icon="regression-chart" iconSize={72} intent="success" />
        </div>
        <div className="module-name" style={{ color: '#165A36' }}>
          Drivers
        </div>
      </div>
      <div className="module-card">
        <div className="module-icon-container">
          <Icon icon="desktop" iconSize={72} intent="primary" />
        </div>
        <Text className="module-name" style={{ color: '#184A90' }}>
          Simulator
        </Text>
      </div>
      <div className="module-card">
        <div className="module-icon-container">
          <Icon icon="inherited-group" iconSize={72} color="#A82255" />
        </div>
        <div className="module-name" style={{ color: '#A82255' }}>
          Segments
        </div>
      </div>
      <div className="module-card">
        <div className="module-icon-container">
          <Icon icon="presentation" iconSize={72} color="#5642A6" />
        </div>
        <div className="module-name" style={{ color: '#5642A6' }}>
          Reports
        </div>
      </div>
    </div>
  )
}
