import React from 'react'
import { useDocumentTitle } from '../utils/hooks'
import Upload from '../components/Upload'
import ToolPage from '../components/ToolPage'
import Menu from '../components/Menu'
import { useParams } from 'react-router-dom'

export default function UploadPage() {
  const { surveyId } = useParams()
  const pageName = surveyId ? 'Add Data' : 'Upload Survey'
  useDocumentTitle(pageName)
  return <ToolPage menu={<Menu pageName={pageName} />} tool={<Upload />} />
}
