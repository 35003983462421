import React from 'react'
import { Cell, Column, Table2, RenderMode } from '@blueprintjs/table'
import { HotkeysProvider } from '@blueprintjs/core'
import { useContext } from 'react'
import { DataContext } from '../contexts/DataProvider'
import AutoSizer from 'react-virtualized-auto-sizer'
import '@blueprintjs/table/lib/css/table.css'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import { SurveyContext } from '../contexts/SurveyProvider'
import LoadingSpinner from './LoadingSpinner'

export default function DataTable() {
  const { selectedColumns: columns, data, numRows } = useContext(DataContext)
  const { variables } = useContext(SurveyContext)
  return (
    <>
      {columns === undefined || data === undefined ? (
        <LoadingSpinner />
      ) : columns === null || data === null ? (
        <p>Could not retrieve columns.</p>
      ) : columns.length === 0 ? (
        <p style={{ margin: '10px 4px', fontWeight: 'bold' }}>
          No columns selected
        </p>
      ) : (
        <HotkeysProvider>
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ height: height, width }}>
                <Table2
                  numFrozenColumns={1}
                  numRows={numRows}
                  cellRendererDependencies={[data, columns]}
                  renderMode={RenderMode.BATCH}
                  getCellClipboardData={(row, column) => data[columns[column]]}
                >
                  {columns.map((column, c) => (
                    <Column
                      key={column}
                      name={variables.find(v => v.id === parseInt(column)).name}
                      cellRenderer={rowIndex => (
                        <Cell>{data?.[rowIndex]?.[c]}</Cell>
                      )}
                    />
                  ))}
                </Table2>
              </div>
            )}
          </AutoSizer>
        </HotkeysProvider>
      )}
    </>
  )
}
