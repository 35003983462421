import {
  Button,
  ButtonGroup,
  Icon,
  Menu,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

export function CrosstabMenu() {
  return (
    // <div
    //   style={{
    //     display: 'flex',
    //     alignItems: 'center',
    //     padding: '3px',
    //     height: '36px',
    //   }}
    // >
    <Navbar id="app-menu" className={['slim-navbar'].join(' ')}>
      <NavbarGroup>
        <NavbarHeading className="navbar-heading">
          {/* <Icon icon="wrench" style={{ marginRight: '14px' }} size={16} /> */}
          <Icon icon="wrench" style={{ marginRight: '14px' }} size={15} />
          Toolkit
        </NavbarHeading>
        <NavbarDivider />
        <ButtonGroup minimal>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <Menu>
                <MenuItem text="New" icon="document" />
                <MenuItem text="Open" icon="folder-shared" />
              </Menu>
            }
          >
            <Button text={'File'} />
          </Popover2>
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <Menu>
                <MenuItem text="Explorer" icon="th" href="/explorer" />
              </Menu>
            }
          >
            <Button text={'View'} />
          </Popover2>
        </ButtonGroup>
      </NavbarGroup>
      {/* <NavbarGroup align="right">
        <ButtonGroup minimal>
          <Button
            icon="heat-grid"
            active={viz === 'table'}
            onClick={() => setOptions(prev => ({ ...prev, viz: 'table' }))}
          />
          <Button
            icon="timeline-bar-chart"
            active={viz === 'chart'}
            onClick={() => setOptions(prev => ({ ...prev, viz: 'chart' }))}
          />
        </ButtonGroup>
      </NavbarGroup> */}
    </Navbar>
    // </div>
  )
}
