import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Alert, Spinner } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { useApi } from '../../contexts/ApiProvider'
import { useInstructionsDialog } from '../../contexts/InstructionsDialogProvider'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { useSurvey } from '../../contexts/SurveyProvider'
import { downloadFile } from '../../utils/downloadFile'
import { LoadingIcon } from '../LoadingSpinner'
import { AppToaster, ProgressToaster } from '../toaster'
import ClaimsSelector from './ClaimsSelector'
import { PreferenceProfilesInstructions } from './Instructions'
import PreferenceProfilesGraph from './PreferenceProfilesGraph'

export default function PreferenceProfiles({
  exportFileName = 'PreferenceProfiles',
}) {
  const { maxDiffId } = useParams()
  const { weights } = useSurvey()
  const {
    maxDiffName,
    lowSampleWarningThreshold,
    setPreferenceProfilesWithLowSamples,
  } = useMaxDiff()
  const [loading, setLoading] = useState(false)
  const api = useApi()
  const { openDialog } = useInstructionsDialog()

  const [subgroupQuestions, setSubgroupQuestions] = useState([])
  const [selectedClaims, setSelectedClaims] = useState([])
  const [totalRespondents, setTotalRespondents] = useState(0)

  // Fetch the preference profiles
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const variableIds = selectedClaims.map(c => c.id)
      if (variableIds.length === 0) {
        setLoading(false)
        return
      }
      const response = await api.post(
        `/maxdiffs/${maxDiffId}/preference_profile`,
        { variable_ids: variableIds }
      )
      if (response.ok) {
        setSubgroupQuestions(response.body.subgroup_questions)
        setTotalRespondents(response.body.total_respondents)
      } else {
        console.error(response)
      }
      setLoading(false)
    })()
  }, [maxDiffId, selectedClaims, api, weights])

  // Check for low sample profiles
  useEffect(() => {
    if (subgroupQuestions.length > 0) {
      if (selectedClaims.length === 0) {
        setPreferenceProfilesWithLowSamples(null)
        return
      }
      const lowSampleProfiles = subgroupQuestions.filter(s => {
        const totalRespondents = Math.round(
          s.variables.reduce((acc, cur) => acc + cur.respondents, 0)
        )
        if (totalRespondents <= lowSampleWarningThreshold) {
          return true
        }
        return false
      })
      setPreferenceProfilesWithLowSamples(lowSampleProfiles)
    }
    return () => {
      // Reset the low sample profiles when unmounting
      setPreferenceProfilesWithLowSamples(null)
    }
  }, [
    subgroupQuestions,
    lowSampleWarningThreshold,
    setPreferenceProfilesWithLowSamples,
    selectedClaims,
  ])

  const exportToExcel = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    const response = await api.post(
      `/maxdiffs/${maxDiffId}/preference_profile/export_to_excel`
    )
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [maxDiffId, maxDiffName, api, exportFileName])

  const menu = useMemo(
    () => [
      {
        label: 'File',
        items: [
          {
            label: 'Export to Excel',
            onClick: exportToExcel,
            icon: IconNames.EXPORT,
            disabled: false,
          },
        ],
      },
      {
        label: 'Help',
        items: [
          {
            label: 'Instructions',
            shouldDismissPopover: true,
            icon: loading ? <LoadingIcon type="menu" /> : IconNames.HELP,
            onClick: () => {
              openDialog(
                <PreferenceProfilesInstructions />,
                'Preference Profiles'
              )
            },
          },
        ],
      },
    ],
    [openDialog, exportToExcel, loading]
  )

  useRegisterMaxDiffMenu('PreferenceProfiles', menu)

  return (
    <>
      <div className="preference-profiles">
        <div className="preference-profiles-controls">
          <ClaimsSelector
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
            singleSelect
          />
        </div>
        <div className="preference-profiles-graph">
          {loading ? (
            <Spinner />
          ) : selectedClaims.length === 0 ? (
            <p>Select an item to inspect its reach profile</p>
          ) : totalRespondents === 0 ? (
            <Alert intent="warning">No respondents reached by this item.</Alert>
          ) : (
            <PreferenceProfilesGraph
              selectedClaim={selectedClaims}
              subgroupQuestions={subgroupQuestions}
            />
          )}
        </div>
      </div>
    </>
  )
}
