import { Button, H1, InputGroup, Intent } from '@blueprintjs/core'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AppToaster } from '../components/toaster'
import { useApi } from '../contexts/ApiProvider'
import Logo from '../Logo'

export default function MagicRequestPage() {
  const emailField = useRef()
  // const [formErrors, setFormErrors] = useState({})
  const [loading, setLoading] = useState()
  const api = useApi()
  const location = useLocation()

  useEffect(() => {
    emailField.current.focus()
  }, [])

  const onSubmit = async event => {
    event.preventDefault()
    setLoading('loading')
    let next = '/'
    if (location.state && location.state.next) {
      next = location.state.next
    }
    const response = await api.get('/get_magic_link', null, {
      headers: {
        Authorization: 'Basic ' + btoa(`${emailField.current.value}:none`),
      },
      referrer: next,
      credentials: 'omit',
    })
    if (!response.ok) {
      setLoading('error')
      // setFormErrors(response.body.errors.json)
      alert(response.body?.message)
    } else {
      setLoading('success')
      AppToaster.show({
        message: 'Magic Link Sent',
        isCloseButtonShown: false,
        timeout: 0,
        icon: 'envelope',
        intent: 'success',
      })
      // setFormErrors({})
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        minHeight: 'calc(100vh)',
        align: 'center',
        justifyContent: 'center',
        padding: '36px, 12px',
        background: 'whitesmoke',
      }}
    >
      <div
        style={{
          padding: '32px',
          justifyContent: 'center',
          display: 'flex',
          minWidth: '500px',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            // transform: 'translateY(-50%)',
            padding: '40px',
            border: '1px solid gainsboro',
            textAlign: 'center',
            background: 'white',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Logo />
          <H1 style={{ margin: '24px 0', textTransform: 'uppercase' }}>
            Sign in
          </H1>
          <form
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
            onSubmit={onSubmit}
          >
            <InputGroup
              large
              inputRef={emailField}
              intent={loading === 'error' ? Intent.DANGER : Intent.NONE}
              disabled={loading === 'success'}
              placeholder={'jsmith@radiusinsights.com'}
              style={{ marginTop: '8px' }}
            />
            {/* <input type="email" ref={emailField} /> */}
            <Button
              type="submit"
              loading={loading === 'loading'}
              disabled={loading === 'success'}
              intent={loading === 'success' ? Intent.SUCCESS : Intent.NONE}
              rightIcon="envelope"
              style={{ marginTop: '16px' }}
              large
            >
              {loading === 'success' ? 'Magic Link Sent' : 'Send Magic Link'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
