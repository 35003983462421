import { Card } from '@blueprintjs/core'
import TURFExporter from './TURFExporter'
import TURFNextBestClaimButton from './TURFNextBestClaimButton'
import TURFTable from './TURFTable'
import TURFTotals from './TURFTotals'

export default function TURF({ exportFileName = 'TURF' }) {
  return (
    <div className="turf">
      <Card className="turf-header">
        <TURFNextBestClaimButton />
        <TURFExporter exportFileName={exportFileName} />
        <TURFTotals />
      </Card>

      <TURFTable />
    </div>
  )
}
