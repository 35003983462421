import { FileInput, H1, Spinner } from '@blueprintjs/core'

import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import { useApi } from '../contexts/ApiProvider'
import { useContext, useState } from 'react'
import { WindowContext } from '../contexts/WindowProvider'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { TEXT_LARGE } from '@blueprintjs/core/lib/esm/common/classes'
import './Upload.css'
import { flattenApiVariables, SurveyContext } from '../contexts/SurveyProvider'
function Upload() {
  const api = useApi()
  const { surveyId } = useParams()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [fileInputText, setFileInputText] = useState('Choose a file...')
  const { setSurveys, setCurrentSurvey } = useContext(WindowContext)
  const { setVariables, setPrevVariables } = useContext(SurveyContext)
  const navigate = useNavigate()

  const uploadFile = async file => {
    if (!file) return
    setFileInputText(file.name)
    document.body.style.cursor = 'progress'
    setLoading('loading')
    setError()

    const response = await api.put(`/survey/${surveyId}/upload`, file)
    if (response.ok) {
      setLoading('success')
      const survey = response.body
      setSurveys(prev => {
        // If the survey already exists in the list, replace it
        const oldSurveyIndex = prev.findIndex(s => s.id === Number(surveyId))
        if (oldSurveyIndex !== -1) {
          prev[oldSurveyIndex] = survey
          return prev.sort((a, b) => a.id - b.id)
        }
        return [...prev, survey].sort((a, b) => a.id - b.id)
      })
      const variables = flattenApiVariables(survey.variables)
      setVariables(variables)
      setPrevVariables(variables)
      setCurrentSurvey(
        Object.fromEntries(
          Object.keys(survey)
            .filter(k => k !== 'variables')
            .map(k => [k, survey[k]])
        )
      )
      navigate('/survey/' + surveyId + '/variables')
    } else {
      setFileInputText('Choose a file...')
      if (response.status === 415) {
        setError('Unsupported file format.')
      } else if (response.status === 400) {
        setError(
          'Number of respondents in uploaded data does not match existing survey data.'
        )
      }
      setLoading('error')
    }
    document.body.style.cursor = 'default'
  }
  const { currentSurvey } = useContext(WindowContext)

  return currentSurvey !== undefined ? (
    <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          marginTop: '64px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {['error', undefined].includes(loading) ? (
          <>
            <H1 style={{ marginBottom: '16px' }}>Upload Data File</H1>
            <div id="upload-info-text">
              {currentSurvey?.has_data ? (
                <ul>
                  <li>
                    Merges on an ID variable are not supported yet.
                    <ul>
                      <li>
                        {' '}
                        Make sure you have a row of data for every respondent in
                        your survey and that respondents are in the same order
                        as the data you've already uploaded. If you're unsure,
                        you go{' '}
                        <Link to="../explorer" target="_blank">
                          take a peek at your data before uploading.
                          <svg
                            viewBox="0 0 24 24"
                            focusable="false"
                            style={{ height: 13 }}
                          >
                            <g
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="2"
                            >
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                              <path d="M15 3h6v6"></path>
                              <path d="M10 14L21 3"></path>
                            </g>
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    This study currently has{' '}
                    <b>{currentSurvey.n} respondents</b> and{' '}
                    <b>{currentSurvey.num_variables} variables.</b>
                  </li>
                </ul>
              ) : (
                <p>
                  "This survey doesn't have any data yet. Upload a data file to
                  get started."
                </p>
              )}
            </div>
            <FileInput
              inputProps={{
                accept:
                  '.sav,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                multiple: false,
              }}
              large
              text={fileInputText}
              buttonText={loading !== 'loading' ? 'Browse' : 'Uploading...'}
              disabled={['loading', 'success'].includes(loading)}
              onInputChange={ev => {
                uploadFile(ev.target.files[0])
                ev.target.value = ''
              }}
            />
            {error !== undefined ? (
              <p
                style={{
                  color: '#AC2F33',
                  marginTop: '8px',
                  fontWeight: 'bold',
                }}
              >
                {error}
              </p>
            ) : null}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '12px' }} className={TEXT_LARGE}>
              Uploading {fileInputText}...
            </div>
            <Spinner />
          </>
        )}
      </div>
    </div>
  ) : null
}

export default Upload
