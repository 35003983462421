import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useApi } from '../../contexts/ApiProvider'
import DecisionTreeGraph from './DecisionTreeGraph'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'
import chroma from 'chroma-js'
import { downloadFile } from '../../utils/downloadFile'
import LoadingSpinner, { LoadingIcon } from '../LoadingSpinner'
import { AppToaster } from '../toaster'
import { ProgressToaster } from '../toaster'
import { DecisionTreeInstructions } from './Instructions'
import { useInstructionsDialog } from '../../contexts/InstructionsDialogProvider'

export default function DecisionTree({ exportFileName = 'DecisionTree' }) {
  const [totalRespondents, setTotalRespondents] = useState(0)
  const [enableExport, setEnableExport] = useState(false)
  const {
    maxDiffId,
    selectedGroupVariables,
    maxdiffQuestion,
    loading,
    maxDiffName,
  } = useMaxDiff()

  const exportLeaves = useRef([])
  const { openDialog } = useInstructionsDialog()

  // const pallette = distinctColors({
  //   count: maxdiffQuestion.variables.length * 2,
  //   lightMin: 25,
  //   lightMax: 80,
  // })

  // Instead of offering a list of colours, we offer a list of color
  // object (from the e-chart library) that can be used to generate
  // not only colours, but also patterns or textures.

  // Use blueprint's qualitative palette
  const bpQualPallette = [
    '#147EB3',
    '#29A634',
    '#D1980B',
    '#D33D17',
    '#9D3F9D',
    '#00A396',
    '#DB2C6F',
    '#8EB125',
    '#946638',
    '#7961DB',
  ]

  const pallette = bpQualPallette.map(c => chroma(c))
  const shapes = [
    {
      name: 'circle',
      path: 'M 15.020461,7.9889402 A 7.0315211,7.0315211 0 0 1 7.9889402,15.020461 7.0315211,7.0315211 0 0 1 0.95741904,7.9889402 7.0315211,7.0315211 0 0 1 7.9889402,0.95741904 7.0315211,7.0315211 0 0 1 15.020461,7.9889402 Z',
      viewBox: '0 0 15.97788 15.97788',
    },
    { name: 'square', path: 'M 3,3 H 21 V 21 H 3 Z', viewBox: '0 0 24 24' },
    {
      name: 'star',
      path: 'm12 17.328-5.403 3.286a.75.75 0 0 1-1.12-.813l1.456-6.155-4.796-4.123a.75.75 0 0 1 .428-1.316l6.303-.517 2.44-5.835a.75.75 0 0 1 1.384 0l2.44 5.835 6.303.517a.75.75 0 0 1 .427 1.316l-4.795 4.123 1.456 6.155a.75.75 0 0 1-1.12.813L12 17.328z',
      viewBox: '0 0 24 24',
    },
    {
      name: 'shield',
      path: 'M12 22c-1.148 0-3.418-1.362-5.13-3.34C4.44 15.854 3 11.967 3 7a1 1 0 0 1 .629-.929c3.274-1.31 5.88-2.613 7.816-3.903a1 1 0 0 1 1.11 0c1.935 1.29 4.543 2.594 7.816 3.903A1 1 0 0 1 21 7c0 4.968-1.44 8.855-3.87 11.66C15.419 20.637 13.149 22 12 22z',
      viewBox: '0 0 24 24',
    },
  ]

  const api = useApi()

  const fetchNode = useCallback(
    async claimsReached => {
      const response = await api.post(
        `/maxdiffs/${maxDiffId}/decision_tree/node_children`,
        {
          node_claims_ids: claimsReached,
          subgroup_variables_ids: selectedGroupVariables,
        }
      )
      if (response.ok) {
        const { total_respondents, node_children } = response.body
        return {
          totalRespondents: total_respondents,
          nodeChildren: node_children,
        }
      } else {
        console.error(response)
      }
    },
    [maxDiffId, api, selectedGroupVariables]
  )

  const treeExport = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    const response = await api.post(
      `/maxdiffs/${maxDiffId}/decision_tree/export_to_excel`,
      {
        nodes: exportLeaves.current,
        subgroup_variables_ids: selectedGroupVariables,
      }
    )
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.messages?.json?.nodes})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [
    maxDiffId,
    api,
    selectedGroupVariables,
    exportLeaves,
    maxDiffName,
    exportFileName,
  ])

  useEffect(() => {
    ;(async () => {
      const { totalRespondents } = await fetchNode([])
      setTotalRespondents(totalRespondents)
    })()
  }, [fetchNode])

  const menu = useMemo(
    () => [
      {
        label: 'File',
        items: [
          {
            label: 'Export',
            icon: 'export',
            disabled: enableExport === false,
            onClick: () => {
              treeExport()
            },
          },
        ],
      },
      {
        label: 'Help',
        items: [
          {
            label: 'Instructions',
            icon: loading ? <LoadingIcon type="menu" /> : 'help',
            onClick: () => {
              openDialog(<DecisionTreeInstructions />, 'Decision Routes')
            },
          },
        ],
      },
    ],
    [treeExport, enableExport, loading, openDialog]
  )

  useRegisterMaxDiffMenu('DecisionTree', menu)

  return (
    <div className="decision-tree">
      {maxdiffQuestion && (
        <DecisionTreeGraph
          shapes={shapes}
          pallette={pallette}
          fetchNode={fetchNode}
          mostRespondents={totalRespondents}
          exportLeaves={exportLeaves}
          setEnableExport={setEnableExport}
        />
      )}
      {loading && <LoadingSpinner />}
    </div>
  )
}
