import {
  Button,
  Classes,
  Dialog,
  FileInput,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core'
import { useState } from 'react'
import { useApi } from '../../contexts/ApiProvider'
import { useSurvey } from '../../contexts/SurveyProvider'
import { useWindow } from '../../contexts/WindowProvider'
import { AppToaster } from '../toaster'

export default function UploadClaimsDialog({
  afterDialogClose = null,
  showUploadDialog,
  setShowUploadDialog,
}) {
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [name, setName] = useState()
  const { setQuestions } = useSurvey()
  const { currentSurvey } = useWindow()

  const handleSubmit = async () => {
    setLoading(true)
    const response = await api.post(
      `/maxdiffs/upload/${currentSurvey.id}/${name}`,
      file
    )
    if (response.ok) {
      setQuestions(prev => [...prev, response.body])
      setShowUploadDialog(false)
      afterDialogClose(response.body)
    } else {
      const message = Object.values(response.body.messages.json).join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Dialog
      isOpen={showUploadDialog}
      onClose={() => setShowUploadDialog(false)}
      title={
        <>
          <div>Upload MaxDiff claims</div>
        </>
      }
      icon="add-to-artifact"
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label="New MaxDiff question name"
          labelFor="maxdiff-name"
          helperText={
            <>
              Choose a unique maxdiff question name. The claims/items in the
              file you upload will be collected under a question with this name,
              which you can later select in any MaxDiff module.
            </>
          }
        >
          <InputGroup
            id="maxdiff-name"
            placeholder="Enter MaxDiff name..."
            onChange={e => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="File to upload"
          labelFor="maxdiff-file"
          helperText="Choose a CSV file to upload."
        >
          <FileInput
            id="maxdiff-file"
            inputProps={{ accept: '.csv', multiple: false }}
            text={file ? file.name : 'Choose file...'}
            onInputChange={e => {
              setFile(e.target.files[0])
            }}
            disabled={['loading', 'success'].includes(loading)}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => setShowUploadDialog(false)}
            minimal
            intent="danger"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} intent="success" loading={loading}>
            Upload
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
