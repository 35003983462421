import { useContext, useRef, useState } from 'react'
import { WindowContext } from '../contexts/WindowProvider'
import { useApi } from '../contexts/ApiProvider'
import { downloadFile } from '../utils/downloadFile'
import { AppToaster } from './toaster'
import {
  Button,
  Classes,
  Dialog,
  FileInput,
  FormGroup,
} from '@blueprintjs/core'

export default function CreateAxesDialog() {
  const { dialogOpen, setDialogOpen, loading, setLoading } =
    useContext(WindowContext)

  const handleClose = () => setDialogOpen(null)
  const api = useApi()
  const [fileInputText, setFileInputText] = useState('Choose a zip file...')
  const fileRef = useRef()

  const handleSubmit = async () => {
    setLoading(true)
    const response = await api.post('/tabs/axes', fileRef.current.files[0])

    if (response.ok) {
      const data = await response.body.blob()
      const filename = `NEW_AXES`
      downloadFile(data, filename)
      AppToaster.show({
        message: 'NEW_AXES Download Started',
        intent: 'success',
        icon: 'tick',
      })
      handleClose()
    } else {
      const message = Object.values(response.body.messages.json).join(', ')
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }

    fileRef.current.value = ''
    setFileInputText('Choose a zip file...')
    setLoading(false)
  }

  return (
    <Dialog
      isOpen={dialogOpen === 'CreateAxesDialog'}
      onClose={handleClose}
      title="Create Consolidated AXES file"
    >
      <form
        onSubmit={ev => {
          ev.preventDefault()
          handleSubmit()
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText="Choose a zip file containing the AXES file and .prn files"
            label="Zip file"
            labelFor="axes-zip-file"
          >
            <FileInput
              id="axes-zip-file"
              inputProps={{ accept: '.zip', multiple: false, ref: fileRef }}
              // large
              text={fileInputText}
              buttonText={loading !== 'loading' ? 'Browse' : 'Uploading...'}
              disabled={['loading', 'success'].includes(loading)}
              onInputChange={ev => {
                setFileInputText(
                  ev?.target?.files[0]?.name ?? 'Choose a zip file...'
                )
              }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={handleClose}
              minimal
              disabled={loading}
              intent="danger"
            >
              Cancel
            </Button>
            <Button
              intent="success"
              loading={loading}
              type="submit"
              disabled={loading || !fileRef?.current?.value}
              rightIcon="download"
            >
              Generate File
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  )
}
