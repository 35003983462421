import { Icon, Tab, Tabs } from '@blueprintjs/core'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'normalize.css'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { TURFProvider } from '../../contexts/TURFProvider'
import AverageUtilities from './AverageUtilities'
import DecisionTree from './DecisionTree'
import JointPreferences from './JointPreferences'
import './MaxDiffUI.css'
import PreferenceProfiles from './PreferenceProfiles'
import TURF from './TURF'
import { IconNames } from '@blueprintjs/icons'
import { Card, Elevation } from '@blueprintjs/core'
import { TURFSolver } from './TURFSolver'

const toolDescriptions = new Map([
  [
    'average-utilities',
    {
      icon: IconNames.HorizontalBarChartDesc,
      title: 'Average Scores',
      description: 'View the average scores for each item in the MaxDiff.',
    },
  ],
  [
    'turf',
    {
      icon: IconNames.Form,
      title: 'TURF',
      description:
        'Create optimal bundles that maximize reach for your audiences.',
    },
  ],
  [
    'turf-bundler',
    {
      icon: IconNames.ScatterPlot,
      title: 'TURF Bundler',
      description:
        'Create optimal bundles that maximize reach for your audiences.',
    },
  ],
  [
    'preference-profiles',
    {
      icon: IconNames.User,
      title: 'Preference Profiles',
      description: 'Explore the profile of the audience reached by an item.',
    },
  ],
  [
    'joint-preferences',
    {
      icon: IconNames.HeatGrid,
      title: 'Joint Preferences',
      description: 'Explore combinations of items that are preferred together.',
    },
  ],
  [
    'decision-tree',
    {
      icon: IconNames.FlowBranch,
      title: 'Decision Routes',
      description:
        'Create bundles of items in an interactive way and compare them graphically.',
    },
  ],
])

export default function MaxDiffUI() {
  const navigate = useNavigate()
  const { subgroupQuestions, enabledTools } = useMaxDiff()
  const [currentTab, setCurrentTab] = useState()

  const { toolId } = useParams()

  const enabledToolIds = useMemo(() => {
    // Proceed only if enabledTools has been defined (i.e., the fetching
    // of the MaxDiff data has completed)
    if (enabledTools) {
      return enabledTools.map(t => t.toLowerCase().replace(' ', '-'))
    }
    return undefined
  }, [enabledTools])

  useEffect(() => {
    // Check if enabledTools has been defined
    if (enabledToolIds !== undefined) {
      // If the tool ID is not in the list of enabled tool IDs, redirect to the home
      // page. Otherwise, set the current tab to the tool ID.
      if (toolId && !enabledToolIds.includes(toolId)) {
        navigate('..', { replace: true })
      } else {
        setCurrentTab(toolId)
      }
    }
  }, [toolId, enabledToolIds, navigate])

  return (
    <div className="maxdiff-ui">
      <Tabs
        id="maxdiff-tabs"
        renderActiveTabPanelOnly
        onChange={newTabId => {
          navigate(`../${newTabId}`)
        }}
        selectedTabId={currentTab}
        className="maxdiff-tabs"
        style={{ position: 'sticky', top: 0 }}
      >
        {/* The home tab */}
        <Tab
          id="home"
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon={IconNames.HOME} style={{ marginRight: '0.5rem' }} />
              Home
            </span>
          }
        />
        {/* The tool tabs */}
        {enabledTools?.map(tool => {
          const id = tool.toLowerCase().replace(' ', '-')
          const { title, icon } = toolDescriptions.get(id)
          const exportFileName = title.replace(' ', '')

          const disabled =
            id === 'preference-profiles' &&
            (!subgroupQuestions || subgroupQuestions.length === 0)

          let panel = null
          switch (id) {
            case 'average-utilities':
              panel = <AverageUtilities exportFileName={exportFileName} />
              break
            case 'turf':
              panel = (
                <TURFProvider>
                  <TURF exportFileName={exportFileName} />
                </TURFProvider>
              )
              break
            case 'turf-bundler':
              panel = (
                <TURFProvider>
                  <TURFSolver exportFileName={exportFileName} />
                </TURFProvider>
              )
              break
            case 'preference-profiles':
              panel = <PreferenceProfiles exportFileName={exportFileName} />
              break
            case 'joint-preferences':
              panel = <JointPreferences exportFileName={exportFileName} />
              break
            case 'decision-tree':
              panel = <DecisionTree exportFileName={exportFileName} />
              break
            default:
              panel = null
          }
          return (
            <Tab
              key={id}
              id={id}
              title={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon icon={icon} style={{ marginRight: '0.5rem' }} />
                  {title}
                </span>
              }
              panel={panel}
              className={`${id}-tab`}
              disabled={disabled}
            />
          )
        })}

        <Tabs.Expander />
      </Tabs>
    </div>
  )
}

export function MaxDiffHome() {
  // No menu items for the home page

  const { enabledTools } = useMaxDiff()
  const enabledToolIds = enabledTools?.map(t =>
    t.toLowerCase().replace(' ', '-')
  )

  return (
    <div
      className="instructions"
      style={{
        backgroundColor: 'white',
        width: '100%',
        maxWidth: '70%',
        margin: '1rem auto',
        padding: '1rem',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <section
        style={{
          padding: '2rem',
          borderRadius: '8px',
          marginBottom: '1rem',
        }}
      >
        <h1
          style={{
            fontSize: '2.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: '#106ba3',
          }}
        >
          MaxDiff Analysis Tool
        </h1>

        <p>
          Welcome to the MaxDiff Analysis Tool! This platform offers a
          collection of tools designed specifically for analyzing MaxDiff data.
          <br />
          Click on any of the tools below to begin. For guidance, refer to the
          "Help" menu within each tool.
        </p>
      </section>

      <section>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {enabledToolIds?.map(toolId => {
            const { title, description, icon } = toolDescriptions.get(toolId)
            return (
              <div key={toolId} style={{ flex: '0 0 50%', padding: '1rem' }}>
                <ToolCard
                  toolId={toolId}
                  title={title}
                  description={description}
                  icon={icon}
                />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}

function ToolCard({ toolId, title, description, icon }) {
  return (
    <Link
      to={`../${toolId}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Card
        interactive={true}
        elevation={Elevation.TWO}
        style={{ marginBottom: '1rem' }}
      >
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            icon={icon}
            iconSize={24}
            style={{ paddingRight: '0.5rem', color: '#106ba3' }}
          />
          {title}
        </h3>
        <p>{description}</p>
      </Card>
    </Link>
  )
}
