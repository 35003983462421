import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  TagInput,
  Tag,
} from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { useContext, useEffect, useMemo } from 'react'
import { VizContext } from '../contexts/VizProvider'
import variableOrQuestionToString from '../utils/variableOrQuestionToString'

export default function StubPanel({ banner }) {
  const {
    validStubQuestions,
    stubQueryChange,
    filteredStubOptions,
    stubQuery,
    filteredStubSelection,
    selectStub,
    stub,
    stubFilterName,
    setStubFilterName,
  } = useContext(VizContext)
  useEffect(() => {
    stubQueryChange('')
  }, [validStubQuestions, stubQueryChange])

  // Sort by position
  const sortedFilteredStubOptions = useMemo(() => {
    return [...filteredStubOptions].sort((a, b) => a.position - b.position)
  }, [filteredStubOptions])

  // console.log('original', filteredStubOptions)
  // console.log('sorted', sortedFilteredStubOptions)

  return (
    <div id="stub-select-container" style={{ overflow: 'auto' }}>
      <FormGroup style={{ flexGrow: 2 }}>
        <InputGroup
          leftIcon="filter"
          placeholder="Filter stub list"
          rightElement={
            <>
              {filteredStubOptions.length !== validStubQuestions.length ? (
                <Tag minimal>
                  {filteredStubOptions.length}/{validStubQuestions.length}
                </Tag>
              ) : null}
              <Tooltip2
                content={stubQuery || stubFilterName ? 'Clear filter' : null}
                minimal
                placement="right"
              >
                <Button
                  minimal
                  title="Clear filter"
                  icon="cross"
                  disabled={!stubQuery && !stubFilterName}
                  onClick={() => {
                    stubQueryChange('')
                    setStubFilterName()
                  }}
                />
              </Tooltip2>
            </>
          }
          style={{ marginBottom: 10 }}
          value={stubQuery}
          onChange={e => {
            stubQueryChange(e.target.value)
          }}
        />
        <select
          className={Classes.INPUT}
          id="inputs"
          multiple
          style={{
            width: '100%',
            height: 'calc(100vh - 300px)',
            overflowX: 'auto',
          }}
          value={filteredStubSelection.map(o => o.id)}
          onChange={e => {
            const selected = [...e.target.selectedOptions].map(o => {
              return sortedFilteredStubOptions.find(
                v => `${v.id}` === `${o.value}`
              )
            })
            selectStub(selected)
          }}
        >
          {sortedFilteredStubOptions.map(v => {
            const text = variableOrQuestionToString(v, null)
            return (
              <option
                value={v.id}
                key={v.id}
                disabled={banner.find(banner => banner.id === v.id)}
              >
                {text}
              </option>
            )
          })}
        </select>
      </FormGroup>
      <FormGroup
        label="Stubs Selected"
        labelInfo="(above)"
        labelFor="stubs-selected"
      >
        <TagInput
          id="stubs-selected"
          values={[]}
          onRemove={(_, index) =>
            selectStub([...stub.slice(0, index), ...stub.slice(index + 1)])
          }
          inputProps={{
            disabled: true,
            value: '',
            onFocus: e => e.target.blur(),
          }}
          disabled={stub.length === 0}
          tagProps={{ minimal: true }}
          fill
          placeholder={stub.length ? '' : 'Select stubs above'}
        >
          {stub.map((v, i) => (
            <Tooltip2
              key={v.id}
              popoverClassName="input-tooltip"
              className="input-tooltip"
              content={
                v?.label ? (
                  <span style={{ fontSize: 12 }}>{v.label}</span>
                ) : null
              }
              minimal
            >
              <Tag
                key={v.id}
                minimal
                onRemove={() =>
                  selectStub([...stub.slice(0, i), ...stub.slice(i + 1)])
                }
              >
                {v.name.length > 29 ? v.name.slice(0, 29) + '...' : v.name}
              </Tag>
              {/* </Popover2> */}
            </Tooltip2>
          ))}
        </TagInput>
      </FormGroup>
    </div>
  )
}
