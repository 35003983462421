import React from 'react'
import { Spinner } from '@blueprintjs/core'

export default function LoadingSpinner({
  menu = true,
  footer = false,
  size = 225,
  intent = 'primary',
  progress = undefined,
}) {
  return (
    <div
      id="loading-spinner"
      style={{
        // height: `calc(100vh - ${24 * (!!menu + !!footer)}px)`,
        height: `100%`,
      }}
    >
      <Spinner size={size} intent={intent} value={progress} />
    </div>
  )
}

export function LoadingIcon({ type = 'toast' }) {
  let style = type === 'toast' ? { margin: 12, marginRight: 0 } : {}
  style.color = 'rgba(255, 255, 255, 0.7) !important'
  return (
    <div style={style}>
      <Spinner size="16" />
    </div>
  )
}
