import Menu from '../components/Menu'
import Survey from '../components/NewSurvey'
import ToolPage from '../components/ToolPage'
import { useDocumentTitle } from '../utils/hooks'

export default function NewSurveyPage() {
  const pageName = 'New Survey'
  useDocumentTitle(pageName)
  return <ToolPage menu={<Menu pageName={pageName} />} tool={<Survey />} />
}
