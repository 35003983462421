import { Classes } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { interpolatePRGn } from 'd3-scale-chromatic'
import { useWindow } from '../../contexts/WindowProvider'
import getContrastText, { lighten } from '../../utils/getContrastRatio'
import { sum } from '../../utils/stats'
import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
import { formatValue } from './CrosstabTable'
import './IndexTable.css'

export default function IndexTable({ data }) {
  const {
    settings: { showIndexTabs, showMeanTabs },
  } = useWindow()
  return (
    <div style={{ display: 'flex', height: 'calc(100vh-64px)' }}>
      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          whiteSpace: 'nowrap',
          padding: '9px 0',
          height: 'fit-content',
        }}
      >
        <div
          key="stubs"
          className="inline-table-container"
          style={{ position: 'sticky', left: 0, background: 'white' }}
        >
          <table
            className={[
              Classes.HTML_TABLE,
              Classes.INTERACTIVE,
              Classes.HTML_TABLE_CONDENSED,
              'inline-table',
            ].join(' ')}
          >
            <thead>
              <tr>
                <th colSpan={2} className="right-align">
                  Question
                </th>
              </tr>
              <tr>
                <th colSpan={2} className="right-align">
                  Name
                </th>
              </tr>
              <tr>
                <th colSpan={1}>&nbsp;</th>
                <th colSpan={1} className="right-align">
                  Label
                </th>
              </tr>
              <tr>
                <th colSpan={2} className="right-align">
                  n=
                </th>
              </tr>
            </thead>
            <tbody>
              {data.stubs.map((stub, s) => (
                <tr key={s}>
                  <th>{s + 1}</th>
                  <th>{variableOrQuestionToString(stub, 65)}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: 'calc(100vh -54px)',
          overflow: 'auto',
          width: '100%',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          padding: '9px 0 9px 5px',
        }}
      >
        {[
          ...data.tables.flatMap(
            ({ id, counts, headers, impact_scores, index, means, name }) => [
              showMeanTabs ? (
                <div key={`${id}-mean`} className="inline-table-container">
                  <table
                    className={[
                      Classes.HTML_TABLE,
                      Classes.INTERACTIVE,
                      Classes.HTML_TABLE_CONDENSED,
                      'inline-table',
                    ].join(' ')}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={headers.length + 1}
                          className="table-name-cell"
                        >
                          {name} - Means
                        </th>
                      </tr>
                      <tr>
                        {headers.map((header, h) => (
                          <th key={h}>
                            {header[0] !== header[1] ? (
                              header[0]
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </th>
                        ))}
                        <th />
                      </tr>
                      <tr>
                        {headers.map((header, h) => {
                          const label = header[1]
                          return label?.length > 10 ? (
                            <th key={h}>
                              <Tooltip2 content={label} minimal>
                                <span className="has-tooltip">
                                  {header[1].slice(0, 7).trim() + '...'}
                                </span>
                              </Tooltip2>
                            </th>
                          ) : (
                            <th key={h}>{label}</th>
                          )
                        })}
                        <th>mean</th>
                      </tr>
                      <tr>
                        {counts.map((count, c) => (
                          <th key={c}>
                            {formatValue(count / sum(counts), 1, true)}%
                          </th>
                        ))}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {means.map((row, r) => (
                        <tr key={r}>
                          {row.map((v, c) => (
                            <td
                              key={c}
                              className={`numeric-cell${
                                c === row.length - 1 ? ' mean-cell' : ''
                              }`}
                            >
                              {formatValue(
                                v,
                                2,
                                data.stubs[r].question.type.includes(
                                  'multi-select'
                                )
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null,
              showIndexTabs ? (
                <div key={`${id}-impact`} className="inline-table-container">
                  <table
                    className={[
                      Classes.HTML_TABLE,
                      Classes.INTERACTIVE,
                      Classes.HTML_TABLE_CONDENSED,
                      'inline-table',
                    ].join(' ')}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={headers.length + 1}
                          className="table-name-cell"
                        >
                          {name} - Indicies
                        </th>
                      </tr>
                      <tr>
                        {headers.map((header, h) => (
                          <th key={h}>
                            {header[0] !== header[1] ? (
                              header[0]
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {headers.map((header, h) => {
                          const label = header[1]
                          return label?.length > 10 ? (
                            <th key={h}>
                              <Tooltip2 content={label} minimal>
                                <span
                                  style={{ borderBottom: '1px dotted gray' }}
                                >
                                  {header[1].slice(0, 7).trim() + '...'}
                                </span>
                              </Tooltip2>
                            </th>
                          ) : (
                            <th key={h}>{label}</th>
                          )
                        })}
                      </tr>
                      <tr>
                        {counts.map((count, c) => (
                          <th key={c}>{formatValue(count, 1, false)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {index.map((row, r) => (
                        <tr key={r}>
                          {row.map((v, c) => {
                            const impactScore = impact_scores[r][c]
                            const backgroundColor =
                              impactScore !== 0
                                ? lighten(
                                    interpolatePRGn((impactScore + 4) / 8),
                                    0.25
                                  )
                                : 'white'
                            const color =
                              impactScore !== 0
                                ? getContrastText(backgroundColor)
                                : 'rgba(0, 0, 0, 0.75)'

                            return (
                              <td
                                className="numeric-cell"
                                key={c}
                                style={{
                                  backgroundColor,
                                  color,
                                }}
                              >
                                {formatValue(v, 0, false) ?? '~'}
                              </td>
                            )
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null,
            ]
          ),
        ]}
      </div>
    </div>
  )
}
