import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { MultiSelect2, Select2 } from '@blueprintjs/select'
import { MenuItem, FormGroup, Button } from '@blueprintjs/core'
import highlightText from '../../utils/highlightText'

export default function ClaimsSelector({
  setSelectedClaims,
  selectedClaims,
  singleSelect = true,
  useLabels = true,
  formGroupLabel,
  selectProps,
  formGroupProps,
  containerProps,
}) {
  const { maxdiffQuestion } = useMaxDiff()

  const claims = maxdiffQuestion
    ? maxdiffQuestion.variables.map(v => ({
        id: v.id,
        name: useLabels && v.label ? v.label : v.name,
      }))
    : []

  const isClaimSelected = claim => selectedClaims.some(c => c.id === claim.id)

  const deselectClaim = claim =>
    setSelectedClaims(selectedClaims.filter(c => c !== claim))

  const handleItemSelected = claim => {
    if (singleSelect) {
      setSelectedClaims([claim])
    } else {
      setSelectedClaims(prev =>
        prev.some(c => c.id === claim.id)
          ? prev.filter(c => c.id !== claim.id)
          : [...prev, claim]
      )
    }
  }

  const filterClaim = (query, claim, _index, exactMatch) => {
    const normalizedName = claim.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedQuery === normalizedName
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0
    }
  }

  const claimRenderer = (
    item,
    { handleClick, handleFocus, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = `${item.name}`
    return (
      <MenuItem
        selected={modifiers.active}
        active={modifiers.active}
        icon={isClaimSelected(item) ? 'tick' : 'blank'}
        shouldDismissPopover={true}
        disabled={modifiers.disabled}
        // label={item.name}
        key={item.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={highlightText(text, query)}
      />
    )
  }

  return (
    <div className="claims-selector" {...containerProps}>
      <FormGroup
        labelFor="claims"
        style={{ paddingLeft: '24px' }}
        label={
          formGroupLabel !== undefined
            ? formGroupLabel
            : singleSelect
            ? 'Item'
            : 'Items'
        }
        inline={true}
        {...formGroupProps}
      >
        {singleSelect ? (
          <Select2
            items={claims.filter(c => !isClaimSelected(c))}
            itemRenderer={claimRenderer}
            itemsEqual={(a, b) => a.id === b.id}
            placeholder="Select an item"
            onItemSelect={item => handleItemSelected(item)}
            itemPredicate={filterClaim}
            noResults={<MenuItem disabled={true} text="No results." />}
            popoverProps={{
              popoverClassName: 'claims-selector-popover',
              minimal: true,
            }}
            resetOnSelect={true}
            {...selectProps}
          >
            <Button
              text={
                selectedClaims.length
                  ? selectedClaims[0].name
                  : 'Select an item '
              }
              rightIcon="caret-down"
            />
          </Select2>
        ) : (
          <MultiSelect2
            id="claims"
            selectedItems={selectedClaims ?? []}
            items={claims}
            itemsEqual={(a, b) => a.id === b.id}
            onItemSelect={item => handleItemSelected(item)}
            onRemove={deselectClaim}
            onClear={() => setSelectedClaims([])}
            tagRenderer={claim => claim.name}
            itemPredicate={filterClaim}
            itemRenderer={claimRenderer}
            noResults={<MenuItem disabled={true} text="No results." />}
            popoverProps={{
              popoverClassName: 'claims-selector-popover',
              minimal: true,
            }}
            placeholder="Select one or more items"
            resetOnSelect={true}
            {...selectProps}
          />
        )}
      </FormGroup>
    </div>
  )
}
