import { Classes } from '@blueprintjs/core'
import { ContextMenu2 } from '@blueprintjs/popover2'
import { useEffect, useState } from 'react'
import { useApi } from '../../contexts/ApiProvider'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import './context-menu-option.css'

function ContextMenuContent({ column, isOpen }) {
  const api = useApi()
  const [labels, setLabels] = useState()

  useEffect(() => {
    ;(async () => {
      if (!isOpen) return
      const response = await api.post('/value_labels', { column })
      console.log('fetch ' + column)
      if (response.ok) {
        setLabels(response.body)
      } else {
        setLabels(null)
      }
    })()
  }, [api, column, isOpen])

  return labels ? (
    <div className="option-context-menu">
      <table className={Classes.HTML_TABLE + '-condensed'}>
        {labels.map((row, r) => (
          <tr key={r}>
            <th>{row[0]}</th>
            {row.length === 3 ? <th>{row[1]}</th> : null}
            {(row.length === 3 ? row.slice(2) : row.slice(1)).map((col, c) => (
              <td key={c}>{col}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  ) : (
    'loading...'
  )
}

export default function ContextMenuOption({ column, offset, children }) {
  return (
    <ContextMenu2
      popoverProps={{
        popoverClassName: 'option-popover',
        modifiers: {
          offset: { enabled: true, options: { offset } },
        },
      }}
      content={ctxMenuProps => (
        <ContextMenuContent column={column} isOpen={ctxMenuProps.isOpen} />
      )}
      tagName="option"
      value={column}
    >
      {children}
    </ContextMenu2>
  )
}
