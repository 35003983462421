import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import chroma from 'chroma-js'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from '../../contexts/ApiProvider'
import { useInstructionsDialog } from '../../contexts/InstructionsDialogProvider'
import { useRegisterMaxDiffMenu } from '../../contexts/MaxDiffMenuProvider'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'
import { useSurvey } from '../../contexts/SurveyProvider'
import { downloadFile } from '../../utils/downloadFile'
import LoadingSpinner, { LoadingIcon } from '../LoadingSpinner'
import { AppToaster, ProgressToaster } from '../toaster'
import { JointPreferencesInstructions } from './Instructions'
import JointPreferencesClaimsSelector from './JointPreferencesClaimsSelector'
import JointPreferencesGraph from './JointPreferencesGraph'

export default function JointPreferences({
  exportFileName = 'JointPreferences',
}) {
  const { weights } = useSurvey()
  const {
    maxdiffQuestion,
    selectedGroupVariables,
    maxDiffName,
    totalRespondentsInSubgroup,
    loading,
    setLoading,
  } = useMaxDiff()
  const { maxDiffId } = useParams()
  const api = useApi()

  const [data, setData] = useState([])
  const [banners, setBanners] = useState([])
  const [stubs, setStubs] = useState([])
  const [showPercentages, setShowPercentages] = useState(true)
  const { openDialog } = useInstructionsDialog()

  // const valueColorScale = chroma.scale('PRGn').mode('lab')
  // const percentageColorScale = chroma.scale('PRGn').mode('lab')
  const valueColorScale = chroma.scale(['white', 'green']).mode('lab')
  const percentageColorScale = chroma.scale(['white', 'green']).mode('lab')

  const exportToExcel = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
    })
    setLoading(true)
    const response = await api.post(
      `/maxdiffs/${maxDiffId}/joint_preferences/export_to_excel`,
      {
        subgroup_variables_ids: selectedGroupVariables,
      }
    )
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${maxDiffName}_${exportFileName}.xlsx`)
    } else {
      console.error(response)
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
    setLoading(false)
  }, [
    maxDiffId,
    api,
    maxDiffName,
    selectedGroupVariables,
    setLoading,
    exportFileName,
  ])

  useEffect(() => {
    ;(async () => {
      if (!maxdiffQuestion) return
      const banners_ids = banners.map(b => b.id)
      const stubbs_ids = stubs.map(s => s.id)
      if (banners_ids.length === 0 || stubbs_ids.length === 0) return

      setLoading(true)
      const response = await api.post(
        `/maxdiffs/${maxDiffId}/joint_preferences`,
        {
          subgroup_variables_ids: selectedGroupVariables,
          banner_ids: banners_ids,
          stubb_ids: stubbs_ids,
        }
      )
      if (response.ok) {
        setData(response.body.counts)
      } else {
        console.error(response)
      }
      setLoading(false)
    })()
  }, [
    maxDiffId,
    api,
    selectedGroupVariables,
    maxdiffQuestion,
    banners,
    stubs,
    weights,
    setLoading,
  ])

  const menu = useMemo(
    () => [
      {
        label: 'File',
        items: [
          {
            label: 'Export to Excel',
            onClick: exportToExcel,
            icon: 'export',
            disabled: false,
          },
        ],
      },
      {
        label: 'View',
        items: [
          {
            label: 'Show percentages',
            onClick: () => setShowPercentages(true),
            icon: IconNames.PERCENTAGE,
            disabled: false,
            selected: showPercentages,
            shouldDismissPopover: false,
          },
          {
            label: 'Show counts',
            onClick: () => setShowPercentages(false),
            icon: IconNames.NUMERICAL,
            disabled: false,
            selected: !showPercentages,
            shouldDismissPopover: false,
          },
        ],
      },
      {
        label: 'Help',
        items: [
          {
            label: 'Instructions',
            shouldDismissPopover: true,
            icon: loading ? <LoadingIcon type="menu" /> : IconNames.HELP,
            onClick: () => {
              openDialog(<JointPreferencesInstructions />, 'Joint Preferences')
            },
          },
        ],
      },
    ],
    [
      weights,
      maxdiffQuestion,
      selectedGroupVariables,
      maxDiffId,
      api,
      showPercentages,
    ]
  )

  useRegisterMaxDiffMenu('JointPreferences', menu)

  return (
    <div className="joint-preferences">
      <div className="joint-preferences-claims-selector">
        <JointPreferencesClaimsSelector
          selectedClaims={banners}
          setSelectedClaims={setBanners}
          style={{ paddingLeft: '24px' }}
          label={
            <span>
              <Icon
                icon="people"
                size={14}
                className={Classes.TEXT_MUTED}
                style={{ marginRight: 3 }}
              />
              Banners
            </span>
          }
        />
      </div>
      <div className="joint-preferences-claims-selector">
        <JointPreferencesClaimsSelector
          selectedClaims={stubs}
          setSelectedClaims={setStubs}
          label={
            <span>
              <Icon
                icon="list"
                size={14}
                className={Classes.TEXT_MUTED}
                style={{ marginRight: 3 }}
              />
              Stubs
            </span>
          }
        />
      </div>
      {loading && (
        <div
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
      {!loading &&
        data.length > 0 &&
        banners.length > 0 &&
        stubs.length > 0 && (
          <JointPreferencesGraph
            banners={banners}
            stubs={stubs}
            data={data}
            valueColorScale={valueColorScale}
            percentageColorScale={percentageColorScale}
            showPercentages={showPercentages}
            totalRespondentsInSubgroup={totalRespondentsInSubgroup}
          />
        )}
      {!loading && data.length === 0 && (
        <div className="no-data">
          <p>
            No data available. Please select at least one banner and one stub.
          </p>
        </div>
      )}
    </div>
  )
}
