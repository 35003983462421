import { createContext, useContext, useEffect, useState, useRef } from 'react'

export const MaxDiffMenuContext = createContext()

export default function MaxDiffMenuProvider({ children }) {
  const [menu, setMenu] = useState([])

  // List to keep track of component menus
  const registeredMenus = useRef([])

  const registerMenu = (componentId, componentMenu) => {
    registeredMenus.current.push({ componentId, menu: componentMenu })
    rebuildMenu()
  }

  const unregisterMenu = componentId => {
    registeredMenus.current = registeredMenus.current.filter(
      menu => menu.componentId !== componentId
    )
    rebuildMenu()
  }

  const rebuildMenu = () => {
    let newMenu = []

    registeredMenus.current.forEach(({ menu: componentMenu }) => {
      componentMenu.forEach(componentCategory => {
        let mainCategory = newMenu.find(
          cat => cat.label === componentCategory.label
        )

        if (!mainCategory) {
          newMenu.push({
            ...componentCategory,
            items: [...componentCategory.items],
          })
        } else {
          // Merge items of this category
          componentCategory.items.forEach(componentItem => {
            const existingItemIndex = mainCategory.items.findIndex(
              item => item.label === componentItem.label
            )

            if (existingItemIndex > -1) {
              mainCategory.items[existingItemIndex] = componentItem
            } else {
              mainCategory.items.push(componentItem)
            }
          })
        }
      })
    })
    newMenu.sort((a, b) => a.order - b.order)
    // Sort items in each category
    newMenu.forEach(category => {
      category.items.sort((a, b) => a.order - b.order)
    })

    setMenu(newMenu)
  }

  return (
    <MaxDiffMenuContext.Provider
      value={{
        menu,
        registerMenu,
        unregisterMenu,
      }}
    >
      {children}
    </MaxDiffMenuContext.Provider>
  )
}

export function useMaxDiffMenu() {
  const context = useContext(MaxDiffMenuContext)
  if (context === undefined) {
    throw new Error('useMaxDiffMenu must be used within a MaxDiffMenuProvider')
  }
  return context
}

export function useRegisterMaxDiffMenu(uniqueId, newMenu) {
  const { registerMenu, unregisterMenu } = useMaxDiffMenu()

  useEffect(() => {
    registerMenu(uniqueId, newMenu)

    return () => {
      unregisterMenu(uniqueId)
    }
  }, [uniqueId, newMenu])
}
