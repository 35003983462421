import {
  Alignment,
  Button,
  ButtonGroup,
  Icon,
  Menu as BpMenu,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Tab,
  Tabs,
  MenuDivider,
} from '@blueprintjs/core'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import './Menu.css'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useWindow } from '../contexts/WindowProvider'
import { Popover2 } from '@blueprintjs/popover2'
import { useUser } from '../contexts/UserProvider'

export default function Menu({ children, pageName = '' }) {
  const [currentPage] = useLocation()
    .pathname.toLowerCase()
    .split('/')
    .slice(-1)
  const { surveyId } = useParams()
  const [searchParams] = useSearchParams()
  const tableType = searchParams.get('tableType')
  const navigate = useNavigate()
  const { currentSurvey } = useWindow()
  const { logout, user } = useUser()

  return (
    <Navbar id="app-menu" className="slim-navbar">
      <NavbarGroup>
        <NavbarHeading className="navbar-heading">
          <Popover2
            minimal
            placement="bottom-start"
            content={
              <BpMenu>
                <MenuItem
                  disabled
                  text={<Link to="/tasks">View My Tasks</Link>}
                  icon="form"
                />
                {user.role === 'analyst' && (
                  <>
                    <MenuItem
                      disabled
                      text={<Link to="/users">View All Users</Link>}
                      icon="people"
                    />
                    <MenuDivider />
                  </>
                )}
                <MenuItem
                  disabled
                  text={<span>Logged in as {user.email.split('@')[0]}</span>}
                />
                <MenuItem
                  icon="log-out"
                  text="Log out"
                  intent="danger"
                  onClick={logout}
                />
              </BpMenu>
            }
          >
            <Button
              minimal
              alignText="left"
              icon={<Icon icon="wrench" size={15} style={{ color: 'black' }} />}
              style={{ marginRight: '6px', padding: 0, color: 'black' }}
            />
          </Popover2>
          <Link to="/">Toolkit</Link>
        </NavbarHeading>
        {surveyId ? (
          <>
            <Link id="menu-survey-link" to={`/survey/${surveyId}`}>
              {currentSurvey?.name ? (
                <span>
                  <Icon
                    icon="home"
                    style={{ marginRight: 4, verticalAlign: 'baseline' }}
                    size={11}
                  />
                  {currentSurvey.name}
                </span>
              ) : (
                `Survey ${surveyId}`
              )}
            </Link>
            {/* <NavbarDivider /> */}
          </>
        ) : null}
        <NavbarDivider />
        <ButtonGroup minimal>{children}</ButtonGroup>
      </NavbarGroup>
      <NavbarGroup
        className="navbar-heading"
        style={{
          left: '69vw',
          position: 'absolute',
          transform: 'translateX(-50%)',
        }}
        id="page-name-container"
      >
        <NavbarHeading id="page-name">{pageName}</NavbarHeading>
      </NavbarGroup>
      {surveyId && user.role !== 'client' ? (
        <NavbarGroup align={Alignment.RIGHT}>
          <NavbarDivider />
          <Tabs id="page-tabs" animate selectedTabId={currentPage}>
            <Tab id="explorer">
              <Link to={`/survey/${surveyId}/explorer`}>Data</Link>
            </Tab>
            <Tab id="variables">
              <Link to={`/survey/${surveyId}/variables`}>Variables</Link>
            </Tab>
            <Tab id="viz">
              <Popover2
                minimal
                placement="bottom-start"
                content={
                  <BpMenu>
                    <MenuItem
                      icon="th-disconnect"
                      onClick={ev => {
                        if (tableType === 'cross') {
                          ev.preventDefault()
                          return
                        }
                        navigate(`/survey/${surveyId}/viz?tableType=cross`)
                      }}
                      text={
                        <Link
                          className="unstyled-link"
                          to={`/survey/${surveyId}/viz?tableType=cross`}
                        >
                          Crosstab
                        </Link>
                      }
                      labelElement={
                        <Icon
                          icon={tableType === 'cross' ? 'small-tick' : 'blank'}
                        />
                      }
                    />
                    <MenuItem
                      icon="panel-stats"
                      onClick={ev => {
                        if (tableType === 'index') {
                          ev.preventDefault()
                          return
                        }
                        navigate(`/survey/${surveyId}/viz?tableType=index`)
                      }}
                      text={
                        // <Link
                        //   className="unstyled-link"
                        //   to={`/survey/${surveyId}/viz?tableType=index`}
                        // >
                        'Indextab'
                        // </Link>
                      }
                      labelElement={
                        <Icon
                          icon={tableType === 'index' ? 'small-tick' : 'blank'}
                        />
                      }
                    />
                  </BpMenu>
                }
              >
                <Button
                  alignText="left"
                  rightIcon="caret-down"
                  text={'Viz'}
                  minimal
                />
              </Popover2>
            </Tab>
          </Tabs>
        </NavbarGroup>
      ) : null}
    </Navbar>
  )
}
