import {
  Button,
  MenuDivider,
  MenuItem,
  Menu as BpMenu,
  Icon,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LoadingSpinner, { LoadingIcon } from '../components/LoadingSpinner'
import Menu from '../components/Menu'
import SurveyModules from '../components/SurveyModules'
import { AppToaster, ProgressToaster } from '../components/toaster'
import ToolPage from '../components/ToolPage'
import { useApi } from '../contexts/ApiProvider'
import { useWindow } from '../contexts/WindowProvider'
import { downloadFile } from '../utils/downloadFile'
import { useDocumentTitle } from '../utils/hooks'
import { useSurvey } from '../contexts/SurveyProvider'
import { useUser } from '../contexts/UserProvider'
import { archiveIcon, csvIcon } from '../icons/customIcons'
import NewMaxdiffDialog from '../components/NewMaxDiff'

export default function SurveyHomePage() {
  const { currentSurvey } = useWindow()
  const { loading: surveyLoading, loadingProgress } = useSurvey()

  const pageName = `${currentSurvey ? currentSurvey.name : 'Loading...'}`
  useDocumentTitle(pageName)

  if (surveyLoading) {
    return <ToolPage tool={<LoadingSpinner progress={loadingProgress} />} />
  }

  return (
    <ToolPage
      menu={
        <Menu pageName={pageName}>
          <FileMenu />
          <EditMenu />
          <WindowMenu />
          <HelpMenu />
        </Menu>
      }
      tool={<SurveyModules />}
    />
  )
}

function FileMenu() {
  const api = useApi()
  const { user } = useUser()
  const isAnalyst = user.role === 'analyst'
  const { surveyId } = useParams()
  const { setSurveyDialogOpen } = useSurvey()
  const { currentSurvey } = useWindow()
  const navigate = useNavigate()

  const downloadBackup = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Downloading Backup...',
      intent: 'primary',
      icon: <LoadingIcon />,
      timeout: 0,
    })
    const response = await api.get(`/survey/${surveyId}/backup`, undefined, {
      cache: 'no-store',
    })
    ProgressToaster.dismiss(toasterId)
    console.log(response)
    if (response.ok) {
      const data = await response.body.blob()
      downloadFile(data, `${currentSurvey.name}.backup`)
      ProgressToaster.show({
        message: 'Download Successful',
        isCloseButtonShown: false,
        icon: 'tick-circle',
        intent: 'success',
      })
    } else {
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [api, currentSurvey, surveyId])

  const exportSurveyData = useCallback(async () => {
    const toasterId = ProgressToaster.show({
      message: 'Exporting to excel...',
      intent: 'primary',
      icon: <LoadingIcon />,
      timeout: 0,
    })
    const response = await api.get(`/survey/${surveyId}/data`, undefined, {
      cache: 'no-store',
    })
    ProgressToaster.dismiss(toasterId)
    if (response.ok) {
      const data = await response.body.blob()
      console.log({ data })
      downloadFile(data, `${currentSurvey.name}.zip`)
      ProgressToaster.show({
        message: 'Download Successful',
        isCloseButtonShown: false,
        icon: 'tick-circle',
        intent: 'success',
      })
    } else {
      const message = `Error exporting (${response.status} - ${response?.body?.error})`
      AppToaster.show({
        message,
        intent: 'danger',
        icon: 'error',
      })
    }
  }, [api, currentSurvey, surveyId])

  return (
    <>
      <NewMaxdiffDialog />
      {isAnalyst && (
        <Popover2
          minimal
          placement="bottom-start"
          content={
            <BpMenu>
              <MenuItem
                text="Add Data"
                onClick={ev => {
                  ev.preventDefault()
                  navigate(`/survey/${surveyId}/upload`)
                }}
                href={`/survey/${surveyId}/upload`}
                icon="add-column-right"
              />
              <MenuDivider />
              <MenuItem
                text="New MaxDiff"
                onClick={ev => {
                  ev.preventDefault()
                  setSurveyDialogOpen('NewMaxDiffDialog')
                }}
                href={`/survey/${surveyId}/maxdiffs/new`}
                icon={'horizontal-bar-chart'}
              />
              <MenuDivider />
              <MenuItem
                text="Download CSV"
                onClick={ev => {
                  ev.preventDefault()
                  exportSurveyData()
                }}
                // href={`/survey/${surveyId}/data`}
                icon={csvIcon}
              />
              <MenuItem
                text="Download Backup"
                onClick={ev => {
                  ev.preventDefault()
                  downloadBackup()
                }}
                // href={`/survey/${surveyId}/data`}
                icon={archiveIcon}
              />
            </BpMenu>
          }
        >
          <Button text={'File'} />
        </Popover2>
      )}
    </>
  )
}

function EditMenu() {
  const { user } = useUser()
  const isAnalystOrResearcher =
    user.role === 'analyst' || user.role === 'researcher'
  const { setSurveyDialogOpen, renameSurvey } = useSurvey()

  return (
    isAnalystOrResearcher && (
      <Popover2
        minimal
        placement="bottom-start"
        content={
          <BpMenu>
            <MenuItem
              text="User management"
              onClick={ev => {
                setSurveyDialogOpen('UserManagementDialog')
              }}
              icon="people"
            />
            <MenuItem
              text="Rename survey..."
              onClick={ev => {
                const newName = prompt('New survey name')
                if (newName) {
                  renameSurvey(newName)
                }
              }}
              icon="edit"
            />
          </BpMenu>
        }
      >
        <Button text={'Edit'} />
      </Popover2>
    )
  )
}

function WindowMenu() {
  const { setSurveyDialogOpen } = useSurvey()
  const { user } = useUser()
  const isAnalystOrResearcher =
    user.role === 'analyst' || user.role === 'researcher'
  return (
    isAnalystOrResearcher && (
      <Popover2
        minimal
        placement="bottom-start"
        content={
          <BpMenu>
            <MenuItem
              text="Segmentation Explorer"
              icon="inherited-group"
              onClick={() => setSurveyDialogOpen('SegmentationExplorerDialog')}
            />
          </BpMenu>
        }
      >
        <Button text="Window" />
      </Popover2>
    )
  )
}

function HelpMenu() {
  const { user } = useUser()
  const isAnalystOrResearcher =
    user.role === 'analyst' || user.role === 'researcher'

  return (
    isAnalystOrResearcher && (
      <>
        <Popover2
          minimal
          placement="bottom-start"
          content={
            <BpMenu>
              <MenuItem
                text={
                  <Link to="/help/survey-home" target="_blank">
                    Survey Home Help Video
                    <Icon className="new-page-icon" icon="share" size={12} />
                  </Link>
                }
              />
            </BpMenu>
          }
        >
          <Button text={'Help'} />
        </Popover2>
      </>
    )
  )
}
